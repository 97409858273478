// @ts-strict-ignore
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { ChartRange } from 'phoenix/constants';
import { AssetTradeabilityProfile } from 'phoenix/constants/AssetTradeability';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { CalculatedAccountValuation } from 'phoenix/hooks/UseAccountValuation';
import { PriceFormatInfo } from 'phoenix/hooks/UsePriceFormatInfo';
import { Account, AccountSummary, MyInfo, Order, OrderType, TradeableSecurityType } from 'phoenix/redux/models';
import { AggregateBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { Platform } from 'phoenix/resolvers/Platform';
import { MarketTimeSegment, MoneyFormatOptions } from 'phoenix/util';
import { ApiOrderType, ApiTimeInForce } from '../ApiTradeRequest';

type DataTree = {
    id: string;
    label: string;
    value: string;
    bold?: boolean;
    children?: DataTree;
}[];

export type ChartRangeItem = {
    label: string;
    value: ChartRange;
};

export type AssetFamily = 'equities' | 'futures' | 'cryptos';
export type DerivativeType = 'base' | 'option' | 'time-spread';
export type TradePriceType = 'last' | 'bid-ask';

export type AdvancedChartResolutionType = '1' | '5' | '10' | '30' | '60' | '1D' | '1W';

type PriceFormulaOptions = Partial<{
    orderPrice: number;
    unitFactor: number;
    precision?: number;
}>;

type PlatformFeatureFlags = {
    view?: string; // If false, users may not search or view security
    trade?: string; // If false, users may not trade security
};

// Take a number of shares and tell me how much it's going to cost
type QuantityToPriceFormula = (quantity: number, quote: StandardQuote, options?: PriceFormulaOptions) => number;

// Take a dollar amount and tell me the number of share's I'll get for that much
type PriceToQuantityFormat = (usdAmount: number, quote: StandardQuote, options?: PriceFormulaOptions) => number;

export type PriceVariant = 'month-only' | 'no-date-short' | 'extra-short' | 'short' | 'full';

export interface AssetClass {
    // Typing
    type: TradeableSecurityType;
    family: AssetFamily;
    derivative: DerivativeType;
    derivatives: DerivativeType[];
    algoliaTypes: string[]; // Name for this asset class in Algolia

    // Trading + Tradeability
    tradeability: AssetTradeabilityProfile;
    accountCanTradeThis: (account: Account) => boolean;
    /** @deprecated Please use defaultTradePrice */ defaultLimitPriceToLatestPrice?: boolean;
    defaultOrderQuantity?: number;
    defaultOrderType?: (marketTimeSegment?: MarketTimeSegment) => ApiOrderType;
    defaultTimeInForce?: (MarketTimeSegment?: MarketTimeSegment) => ApiTimeInForce;
    defaultTradePrice?: Partial<Record<OrderType, TradePriceType>>;
    canLiquidate?: boolean;
    actionStyle?: 'standard' | 'near-far'; // standard = ['Buy', 'Sell'], near-far = ['Buy-Sell', 'Sell-Buy']
    userAllowedToTradeThis: () => boolean;
    optionsUseToOpenToClose?: boolean;
    negativePriceAllowed?: boolean;
    showStopPriceShortcutButtons?: boolean;
    hideTimeInForceUi?: boolean;

    // Features
    flags?: Record<Platform, PlatformFeatureFlags>;
    hasDeepAnalysis: boolean;
    canCreateAlerts: boolean;
    /** @deprecated Please reference derivatives */
    hasOptions: boolean;
    optionsType?: TradeableSecurityType;
    canWatchlist: boolean;
    tradePriceShortcutButtonsVariant?: 'tick' | 'percent';
    hasMultiLeg?: boolean;
    /** @deprecated To check if this asset has multiple derivatives, please check derivatives.length */ hasDerivatives: () => boolean;

    // Charting
    neverShowChartPulse: boolean;
    hasAdvancedChart: boolean;
    advancedChartConfiguration: {
        supported_resolutions: AdvancedChartResolutionType[];
    };
    chartVariants: ('line' | 'candles' | 'dom')[];
    /**@deprecated please use accountChartRanges or securityChartRanges */ chartRanges: () => ChartRangeItem[];
    accountChartRanges: ChartRange[];
    securityChartRanges: ChartRange[];

    // Formatting + Codec
    getPriceFormatInfo: (meta?: SecurityMetadata) => PriceFormatInfo;
    formatPrice: (price: number, meta?: SecurityMetadata) => string;
    getQuantityFormatOptions?: (meta?: SecurityMetadata) => MoneyFormatOptions;
    formatQuantity: (quantity: number, meta?: SecurityMetadata) => string;
    unit: 'share' | 'contract' | 'bond';
    idMatches: (id: string) => boolean;

    getSymbolName: (qsi: string, lang: Snex1LanguagePack, variant?: PriceVariant, metadata?: SecurityMetadata) => string;
    getSymbolNameLines: (qsi: string, lang: Snex1LanguagePack, meta?: SecurityMetadata) => string[];
    getBaseSymbol: (qsi: string) => string; // Gives the base-most symbol related to the security, e.g., for F:ZC240101C00010000, this gives F:ZC
    getConcreteSymbol: (qsi: string) => string; // For securities like futures which have a base (e.g., F:ZC) and concrete (F:ZCZ23), this gives concrete; for all others, this gives base

    formatOrderQuantity: (o: Order) => string;

    // Colors
    /** @deprecated Please do not use this value. It does not use theming Use useAssetClassColors hook */

    primaryColor?: string;
    positiveChangeColor: string;
    negativeChangeColor: string;
    showColorHalo?: boolean;

    // TODO -- Market Timing

    // Pricing
    /** @deprecated Please use getPriceForQuantity */ getQuantityPrice: QuantityToPriceFormula;
    getPriceForQuantity: QuantityToPriceFormula;
    getQuantityForPrice: PriceToQuantityFormat;
    hideTradeImpact?: boolean;
    getTodaysEarningsLabel?: (lang: Snex1LanguagePack) => string;
    getTotalEarningsLabel?: (lang: Snex1LanguagePack) => string;
    // TODO -- this
    // getExtendedGainLossFigures: (positions: ApiPosition[], quote: StandardQuote, lots: TaxlotItem[]) => GainLossFigures

    // Account Data -- For information related to specific accounts
    getBuyingPowerFigures: (power: AggregateBuyingPower, lang: Snex1LanguagePack) => DataTree;
    getAccountSummaryFigures: (summary: AccountSummary, lang: Snex1LanguagePack) => DataTree;
    getAccountGlanceFigures?: (val: CalculatedAccountValuation, lang: Snex1LanguagePack) => DataTree;

    // Documents
    documentDeliveryOptions: 'mail-only' | 'electronic-only' | 'mail-electronic';

    // Misc
    getNumberOfStrikes?: (user: MyInfo) => number;
    showShortPositionIcon?: boolean;
}

export const StandardQuantityToPriceFormula: QuantityToPriceFormula = (quantity, quote, opt) => {
    if (!quote) return NaN;
    const precision = opt?.precision || 2; // Dollars are usually truncated to cents, but precision can be configured
    const raw = quantity * (opt?.orderPrice || quote?.price) * (opt?.unitFactor || quote.unitFactor || 1);
    return truncateToPrecision(raw, precision);
};

export const StandardPriceToQuantityFormula: PriceToQuantityFormat = (usdAmount, quote, opt) => {
    if (!quote) return NaN;
    // Dollars for all units / dollars for one unit = number of units
    const raw = usdAmount / ((opt?.orderPrice || quote?.price) * (opt?.unitFactor || quote.unitFactor || 1));

    return opt?.precision ? truncateToPrecision(raw, opt?.precision) : raw;
};

const truncateToPrecision = (value: number, precision: number): number => {
    const regex = `\\d*.?\\d{0,${precision}}`;
    const match = `${value}`.match(regex);
    const first = match?.[0];
    const parse = parseFloat(first);
    return parse;
};
