import React, { ComponentType } from 'react';

interface StyledIconProps {
    IconComponent: ComponentType<any>;
    style?: React.CSSProperties;
    size?: string | number;
    onClick?: (e?: any) => void;
    onMouseEnter?: (e?: any) => void;
    onMouseLeave?: (e?: any) => void;
    variant?: 'span' | 'div'
}

export const StyledIcon = ({ style = {}, IconComponent, onClick = () => undefined, ...props }: StyledIconProps) => {
    if (!IconComponent) return null;
    return (
        <span onClick={onClick} role='button' {...props} style={{display: props.variant === 'div'? 'block' : 'inline-block', height: props.size, width: props.size}}>
            <IconComponent
                style={{
                    transition: '0.2s',
                    ...(props.size ? { height: props.size, width: props.size } : {}),
                    ...style
                }}
            />
        </span>
    );
};
