// @ts-strict-ignore
import { ChevronRight, Description, Help, ZoomIn } from '@mui/icons-material';
import { IconButton, Link, Skeleton, Switch, Tooltip, Typography } from '@mui/material';
import { SetSelectedAccount, useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { EditReinvestmentOptionsPage } from 'components/TradeTicket/Fund/EditReinvestmentOptionsPage';
import { GetTradeTicketQuote } from 'components/TradeTicket/Shared/helpers';
import { format } from 'date-fns';
import { useColors } from 'hooks/UseColors';
import { T } from 'phoenix/assets/lang/T';
import { DocumentTypeV2Ids } from 'phoenix/constants/DocumentTypes';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { SearchDocumentsActionV2 } from 'phoenix/redux/actions/DocumentsActions';
import { GetOpenTaxlotsAction } from 'phoenix/redux/actions/TaxlotsActions';
import { ApiPosition } from 'phoenix/redux/models';
import { SnexDocument } from 'phoenix/redux/models/Documents/SnexDocument';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { FormatNumber, getDecimalPlacesFromTickSize, GroupBySelect, IsMutualFundByMetadata, IsMutualFundBySymbol, Sum } from 'phoenix/util';
import { GetGainLossFiguresAll } from 'phoenix/util/HoldingsCalculations';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import { DocumentPreviewModal } from 'screens/DocumentsScreen/DocumentPreviewModal';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { Routes } from 'util/Routes';
import { Flex } from '..';
import { Card } from '../Card';
import { Spinny } from '../Spinny/Spinny';
import './SecurityHoldings.scss';

type HoldingsFigure = {
    label: string;
    value: string;
    children?: { label: string; sublabel: string | JSX.Element; value: string }[];
};

export const SecurityHoldings = React.memo(({ symbol }: { symbol: string }) => {
    const secId = QualifiedSecurityId.Parse(symbol);
    const dispatch = useDispatch();
    const { load: loadPositions } = usePositionsStore();
    const colors = useColors();
    const [previewDocument, setPreviewDocument] = useState(null);
    const [loadedDocsSymbol, setLoadedDocsSymbol] = useState('');
    const confirms = useSnexStore((s) => s.documents.searchResultsV2);
    const openLots = useSnexStore((s) => s.taxlots.openTaxlots);
    const allPositions: ApiPosition[] = usePositionsStore((s) => s.positions);
    const positionsLoading = usePositionsStore((s) => s.isLoading);
    const quote = GetTradeTicketQuote(secId?.id);
    const metadata = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata);
    const text = useText((s) => s.securityScreen.holdings);
    const lastPriceText = useText((s) => s.optionListScreen.price);
    const [isAccountSpecific, setIsAccountSpecific] = useState(false);
    const [showEditReinvestment, setReinvest] = useState(false);
    const [isPremarket, setIsPremarket] = useState(false);
    const [marketSegment] = useMarketTimeSegmentV2();

    const assetClass = useAssetClass(symbol);
    const accountByAssetClass = useSelectedAccountByAssetFamily(assetClass.family);
    const accountNumber = useMemo(() => (isAccountSpecific ? accountByAssetClass : null), [accountByAssetClass, isAccountSpecific]);
    const isFuture = assetClass.family === 'futures';
    const isCrypto = assetClass.family === 'cryptos';
    const relevantLots = openLots.data?.taxlGroupItems?.filter((t) => secId.MatchesTaxLot(t)) || [];
    const relevantItems = accountNumber ? relevantLots.flatMap((l) => l.items.filter((i) => i.accountNumber === accountNumber)) : relevantLots.flatMap((l) => l.items);
    const { formatPrice, formatQuantity } = useAssetClass(symbol);

    let relevantPositions = useMemo(() => allPositions?.filter((p) => secId.MatchesPosition(p)) || [], [allPositions, secId]);
    const numRelatedAccounts = useMemo(() => Object.entries(GroupBySelect(relevantPositions, (item) => item.accountNumber)).length, [relevantPositions]);
    if (accountNumber) relevantPositions = relevantPositions?.filter((p) => p.accountNumber === accountNumber);
    const noData = !openLots?.data || !allPositions?.length;
    const loading = noData && (openLots.loading || positionsLoading);

    const { quantity, marketValue, glToday, glTodayPercent, glTotal, glTotalPercent, costBasis, avgCost, avgELotCost } = GetGainLossFiguresAll(
        relevantPositions,
        quote,
        relevantItems,
        metadata?.data?.unitFactor
    );

    const portfolioMarketValue = Sum(
        allPositions
            ?.filter((p) => p.productType !== 'Cash & Equivalents')
            .map((p) => (FuturesSymbol.IsFuturesSymbol(p.symbol) ? p?.netPrice * p?.netVolume : p.marketValue))
    );

    const diversity = 100 * (marketValue / portfolioMarketValue);

    const handleAccountHyperlinkClick = (event: React.SyntheticEvent, acct: string) => {
        event.preventDefault();
        SetSelectedAccount(acct);
        setIsAccountSpecific(true);
    };

    const lotSummaries = relevantItems.map((i) => ({
        label: `${i.quantity} ${T((s) => s.general.unit(symbol, i.quantity))}`,
        sublabel: (
            <span>
                {format(new Date(i.openDate), 'dd MMM yyyy')}
                {!accountNumber ? (
                    <Link style={{ marginLeft: 15, cursor: 'pointer', color: colors.primaryItemColor }} onClick={(e) => handleAccountHyperlinkClick(e, i.accountNumber)}>
                        {i.accountNumber}
                    </Link>
                ) : null}
            </span>
        ),
        value: FormatNumber.toMoney(i.costAmount)
    }));

    const isMutualFund = IsMutualFundByMetadata(metadata?.data);
    const hasIntradayReturn = !(isMutualFund || isCrypto);
    const todaysReturnPercent = isPremarket ? FormatNumber.toPercent2(glTodayPercent) : FormatNumber.toPercent(glTodayPercent);
    const displayGlTotalPercent = isPremarket ? FormatNumber.toPercent2(glTotalPercent) : FormatNumber.toPercent(glTotalPercent * 100);

    const template = isFuture
        ? {
              header: {
                  label: lastPriceText,
                  value: formatPrice(quote?.price, metadata?.data)
              },
              rows: [
                  { label: text.positionPrice, value: formatPrice(avgELotCost, metadata?.data) },
                  { label: text.openPnL, value: FormatNumber.toMoney(glTotal) },
                  { label: T((s) => s.general.unit(symbol, 0)), value: FormatNumber.toCommas(quantity) },
                  { label: text.portfolioDiversity, value: FormatNumber.toPercent(diversity, 'never') }
              ]
          }
        : {
              header: {
                  label: text.yourMarketValue,
                  tooltip: (
                      <Tooltip placement='right' title={text.marketValueTooltip} tabIndex={0}>
                          <Help focusable style={{ fontSize: 17, marginLeft: 10, color: colors.primaryItemColor }} />
                      </Tooltip>
                  ),
                  value: formatPrice(marketValue, metadata?.data)
              },
              rows: [
                  { label: text.cost, value: FormatNumber.toMoney(costBasis), children: lotSummaries },
                  { label: text.averageCost, value: FormatNumber.toMoneyOpt2(avgCost) },
                  { label: text.todaysReturn, value: hasIntradayReturn ? `${FormatNumber.toMoneyOpt2(glToday)} (${todaysReturnPercent})` : 'N/A' },
                  { label: text.openPnL, value: `${FormatNumber.toMoneyOpt2(glTotal)} (${displayGlTotalPercent})` },
                  { label: T((s) => s.general.unit(symbol, 0)), value: formatQuantity(quantity) },
                  { label: text.portfolioDiversity, value: FormatNumber.toPercent(diversity, 'never') }
              ]
          };

    const AccountLabelText = useMemo(() => {
        if (accountNumber) return text.acct(accountNumber);
        else if (numRelatedAccounts === 1) return relevantPositions ? text.acct(relevantPositions[0].accountNumber) : '';
        else return text.portfolio;
    }, [accountNumber, numRelatedAccounts, relevantPositions, text]);

    const HasAccountLabelText = useMemo(() => {
        if (accountNumber) return true;
        else if (numRelatedAccounts === 1) return true;
        else return false;
    }, [accountNumber, numRelatedAccounts]);

    useEffect(() => {
        async function getIsMutual() {
            const isMutual = HasAccountLabelText && accountByAssetClass && (await IsMutualFundBySymbol(symbol));
            setReinvest(isMutual);
        }
        getIsMutual();
    }, [symbol, HasAccountLabelText, accountByAssetClass]);

    useEffect(() => {
        loadPositions();
        if (accountByAssetClass) setIsAccountSpecific(true);
    }, [accountByAssetClass, loadPositions]);

    useEffect(() => {
        // Only load confirms and tax lots if you have a position in this security
        if (relevantPositions?.length && loadedDocsSymbol !== symbol) {
            const docFilter = accountNumber
                ? {
                      accountNumbers: accountNumber,
                      symbol
                  }
                : {
                      allAccounts: true,
                      symbol
                  };

            dispatch(GetOpenTaxlotsAction());
            dispatch(SearchDocumentsActionV2(DocumentTypeV2Ids.tradeConfirmations, docFilter));
            setLoadedDocsSymbol(symbol);
        }
    }, [symbol, accountNumber, relevantPositions, loadedDocsSymbol, dispatch]);

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(symbol));
    }, [dispatch, symbol]);

    useEffect(() => {
        setIsPremarket(marketSegment === 'premarket');
    }, [marketSegment]);

    if (!relevantItems?.length && !relevantPositions?.length) return null;

    return (
        <Flex id={'security-holdings-section'} column align='stretch' style={{ width: '100%' }}>
            <ErrorBoundary>
                <DocumentPreviewModal document={previewDocument} open={!!previewDocument} onClose={() => setPreviewDocument(null)} />

                <div style={{ marginBottom: 24, boxSizing: 'border-box' }}>
                    <Card>
                        <Flex column fullWidth style={{ padding: '20px' }}>
                            <Flex row justify='space-between'>
                                <Flex column>
                                    <Flex row align='center'>
                                        <Typography variant='h6'>{template.header.label}</Typography>
                                        {template.header.tooltip}
                                    </Flex>
                                    {loading ? <Skeleton animation='wave' style={{ width: 128 }} /> : <Typography variant='h4'>{template.header.value}</Typography>}
                                </Flex>
                                {accountByAssetClass && numRelatedAccounts > 1 && (
                                    <Flex column align='flex-end'>
                                        <Typography style={{ marginLeft: 1, fontSize: '0.85rem', fontWeight: 400, color: 'rgb(110,110,110)' }} variant='caption'>
                                            {text.showAllAccounts}
                                        </Typography>
                                        <Flex align='center' justify='flex-end'>
                                            <Switch checked={!isAccountSpecific} size='medium' onChange={(e) => setIsAccountSpecific(!e.target.checked)} />
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>

                            <Flex column fullWidth className='holding-table-card-table'>
                                <Flex row justify='flex-end'>
                                    <Typography variant='caption'>{AccountLabelText}</Typography>
                                </Flex>
                                {template.rows.map((f, key) => (
                                    <HoldingsCardRow figure={f} key={key} loading={loading} />
                                ))}
                                {showEditReinvestment ? <EditFundReinvestmentChoiceButton symbol={symbol} accountNumber={accountByAssetClass} /> : null}
                            </Flex>
                            {!showEditReinvestment && !isCrypto ? (
                                <Flex column style={{ minHeight: 64 }}>
                                    {confirms.loading ? (
                                        <>
                                            <HoldingsCardConfirm skeleton />
                                        </>
                                    ) : (
                                        confirms.data
                                            ?.slice(0, 3)
                                            .map((d, key) => <HoldingsCardConfirm confirm={d} key={key} onPreviewClick={() => setPreviewDocument(d)} />)
                                    )}
                                </Flex>
                            ) : null}

                            {confirms.data?.length > 3 ? (
                                <Link component={ReactLink} to={Routes.documents(symbol)}>
                                    <Flex row align='center' justify='center' style={{ padding: 10 }}>
                                        <Typography color='primary' variant='h6'>
                                            {text.viewMore}
                                        </Typography>
                                    </Flex>
                                </Link>
                            ) : null}
                        </Flex>
                    </Card>
                </div>
            </ErrorBoundary>
        </Flex>
    );
});

const HoldingsCardRow = React.memo((p: { figure: HoldingsFigure; loading: boolean }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Flex column className='holding-table-card-row'>
            <Flex row align='center' justify='space-between' onClick={p.figure.children ? () => setExpanded(!expanded) : undefined}>
                <Flex row>
                    <div style={{ marginTop: -3 }}>
                        {p.figure.children && p.figure.children.length > 0 ? (
                            <Spinny spun={expanded} targetDegrees={90}>
                                <ChevronRight />
                            </Spinny>
                        ) : null}
                    </div>
                    <Typography style={{ margin: 0, padding: 0 }} variant='h6'>
                        {p.figure.label}
                    </Typography>
                </Flex>
                {p.loading ? <Skeleton animation='wave' style={{ width: 96 }} /> : <Typography variant='h6'> {p.figure.value} </Typography>}
            </Flex>
            {expanded &&
                p.figure.children?.map((c, key) => (
                    <Flex row align='center' justify='space-between' key={key} style={{ margin: '4px 0' }}>
                        <Flex column>
                            <Typography variant='body1'>{c.label}</Typography>
                            <Typography color='textSecondary' variant='body1'>
                                {c.sublabel}
                            </Typography>
                        </Flex>
                        <Typography variant='h6'>{c.value}</Typography>
                    </Flex>
                ))}
        </Flex>
    );
});

const HoldingsCardConfirm = (p: { confirm?: SnexDocument; onPreviewClick?: () => any; skeleton?: boolean }) => {
    return (
        <Flex row justify='space-between' style={{ padding: 10 }}>
            <Flex row align='center'>
                <Description />
                {p.skeleton ? (
                    <Skeleton animation='wave' style={{ width: 120, marginLeft: 10 }} />
                ) : (
                    <Typography variant='h6'>{format(new Date(p.confirm?.documentDate), 'dd MMM yyyy')}</Typography>
                )}
            </Flex>
            <IconButton disabled={p.skeleton} onClick={p.onPreviewClick}>
                <ZoomIn fontSize='small' />
            </IconButton>
        </Flex>
    );
};

const EditFundReinvestmentChoiceButton = (props: { symbol: string; accountNumber: string }) => {
    const [expanded, setExpanded] = useState(false);
    const editButtonText = T((s) => s.tradeTicket.reinvestmentOptions.editButtonText);

    return (
        <Flex column className='holding-table-card-row' style={{ marginTop: '16px' }}>
            <Flex row align='center' onClick={() => setExpanded(!expanded)}>
                <Spinny spun={expanded} targetDegrees={90}>
                    <ChevronRight />
                </Spinny>
                <Typography style={{ margin: '0 0 0 0', padding: '0 0 0 2px' }} variant='h6'>
                    {editButtonText}
                </Typography>
            </Flex>
            {expanded && (
                <div style={{ paddingTop: '20px', boxSizing: 'border-box' }}>
                    <EditReinvestmentOptionsPage symbol={props?.symbol} accountNumber={props?.accountNumber} />
                </div>
            )}
        </Flex>
    );
};
