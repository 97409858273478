import { AssetFamily } from "phoenix/models/AssetClasses/AssetClass";
import { SnexError } from "phoenix/models/SnexError";
import { create } from "zustand";
import { SnexAxios } from "./AxiosHelpers";
import { Urls } from "phoenix/constants";
import { ErrorLogger } from "phoenix/util/ErrorLogger";

interface MetadataV2Data extends MetadataV2FromApi {
    isInAlgolia?: boolean
}
interface MetadataV2Store {
    loading: { [qsi:string]: boolean },
    symbolLoaded: (qsi: string) => boolean,
    data: { [qsi: string]: MetadataV2Data }
    loadData: (qsi: string, forceReload?: boolean) => Promise<void>
    getMetadataBySymbol: (qsi: string) => MetadataV2Data
}
interface MetadataV2FromApi {
    qsi?: string,
    type?: string,
    family?: AssetFamily,
    derivative?: string,
    equity?: {
        cusip?: string,
        sedol?: string,
        isin?: string,
        securityNumber?: string,
    },
    mutualFund?: {
        minimumInitialInvestment?: number,
        isOffshore?: boolean,
        offshoreCashDividendsAvailable?: boolean
    },

    future?: {
        baseSymbol?: string,
        underlyingFrontMonthIndex?: number,
        underlyingFrontMonthYear?: number,
        group?: string,
        session?: 'Open' | 'Closed',
        sessionStart?: string, /* datetime */
        sessionEnd?: string, /* datetime */
        hasTimeSpreads?: boolean,
        maintenanceMargin?: number,
        dayTradeMargin?: number,
        initialMargin?: number
    },
    option?: {
        underlyingSymbol?: string,
        putCall?: 'Put' | 'Call',
        strikePrice?: number,
        expirationDate?: string, /* datetime */
        isAdjusted?: boolean
    },
    timeSpread?: {
        nearDate?: string, /* datetime */
        farDate?: string, /* datetime */
        buyIndication?: 'BuyNearSellFar' | 'BuyFarSellNear'
    },
    sizing?: {
       multiplier?: number,
       deliverableCount?: number,
       minimumIncrement?: number,
       minimumIncrementUsd?: number,
    },
    formatting?: {
        maxDecimalPlaces?: number,
        minDecimalPlaces?: number,
        isFractional?: boolean,
        fractionalParts?: number,
        fractionalGranularity?: 'WholeParts' | 'Cents'
    }, 
    exchange?: string,
    currencyCode?: string,
    sourceSystem?: string
}
export interface MetadataV2StoreResponse {
    data: MetadataV2FromApi[]
    errors: SnexError[]
}

export const useSecurityMetadataV2 = create<MetadataV2Store>((set, get) => {
    return {
        loading: {},
        data: {},
         //@ts-ignore
        symbolLoaded: (qsi: string) => !!get().data[qsi.toUpperCase()],
        loadData: async (qsi: string, forceReload?: boolean) => {
            const isLoading = get().loading[qsi.toUpperCase()] || false
            if(!forceReload && isLoading) return /* do not load if already loading */
            set(s => ({...s, loading: {...s.loading, [qsi.toUpperCase()]: true}})) /* set loading for qsi */
            try
            {
                const result = await SnexAxios.ApiGet<{data:MetadataV2FromApi[]}>(Urls.securities.core.getV2Metadata(qsi)).run()
                const _data = get().data
                 //@ts-ignore
                _data[qsi.toUpperCase()] = {...result?.data[0], isInAlgolia: !!result?.data[0]?.sourceSystem?.includes('algolia')}
                set(s => ({...s, loading: {...s.loading, [qsi.toUpperCase()]: false }, data: _data}))
            }
            catch (err: any) {
                ErrorLogger.RecordError(err as Error)
            }
        },
        //@ts-ignore
        getMetadataBySymbol: (qsi: string) => get().data[qsi.toUpperCase()]
    }
})
