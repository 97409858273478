// No business logic allowed.
// This is meant to be generic and reusable.
// Anything that touches the DOM, uses refs, or calls IChartApi/ISeriesApi methods should be in here.
// If you need to call a Lightweight API method, add generic support for it in here.
// If you use refs so you can call the API from outside this component, I will cry.

import { ReplayRounded } from '@mui/icons-material';
import { StyledIcon } from 'components/StyledIcon';
import { ChartOptions, createChart, DeepPartial, IChartApi, LogicalRange, MouseEventHandler, Range, Time } from 'lightweight-charts';
import { isEqual } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { SeriesConfig } from '../SeriesConfig';
import { getShouldSetScalingActive } from './Helpers';
import { useSelfCleaningRef } from './UseSelfCleaningRef';

export type LightweightChartProps = React.PropsWithChildren<{
    onCrosshairMove?: MouseEventHandler<Time>;
    options?: DeepPartial<ChartOptions>;
    canScale?: boolean;
    multiSeries?: SeriesConfig[];
    style?: React.CSSProperties;
    visibleLogicalRange?: LogicalRange;
}>;

export const LightweightChartApiContext = React.createContext<IChartApiCustom | undefined>(undefined);

const LightweightChartUnmemoized = (props: LightweightChartProps): ReactElement => {
    const { canScale = false, options = {}, onCrosshairMove = () => undefined, visibleLogicalRange } = props;
    const [isScalingActive, setIsScalingActive] = useState<boolean>(false);

    // Updating the ref does not notify the component (mutates current prop); store the chart API here for easier use and re-renders
    // TODO: This may be redundant, but relying on the ref alone sometimes leads to the chart never getting initialized
    const [chartApi, setChartApi] = useState<IChartApiCustom>();

    // TODO: Remove defaults, they cause a lot of problems
    // const fullOptions = useMemo(() => merge(ChartOptionsDefaults, options), [options]);
    const fullOptions = options;
    const initialOptionsRef = useRef(fullOptions);

    const chartApiRef = useRef<IChartApiCustom | null>(null);
    const chartRefCallback = useSelfCleaningRef<HTMLDivElement>(
        useCallback((node) => {
            lwcLog('ref callback fired for', chartApiRef.current?.debugName);
            if (chartApiRef.current !== null) {
                throw new Error('ChartApi was not cleaned up before the RefCallback fired again');
            }

            const chartApiNew = createChartCustom(node, initialOptionsRef.current);
            chartApiRef.current = chartApiNew;
            setChartApi(chartApiNew);

            return () => {
                removeChartCustom(chartApiNew);
                chartApiRef.current = null;
                setChartApi(undefined);
            };
        }, [])
    );

    const currentTimeScale = chartApi?.timeScale()?.getVisibleLogicalRange();

    // Sometimes the chart tries to change the timescale on its own (usually while initializing). In this case, revert it back
    // TODO figure out why it does this and fix it proactively instead of reactively
    useEffect(() => {
        if (visibleLogicalRange && !isScalingActive && !isEqual(currentTimeScale, visibleLogicalRange)) {
            chartApi?.timeScale()?.setVisibleLogicalRange(visibleLogicalRange);
        }
    }, [chartApi, currentTimeScale, isScalingActive, visibleLogicalRange]);

    const handleVisibleLogicalRangeChanged = useCallback(
        (range: LogicalRange | null) => {
            const shouldSetScalingActive = getShouldSetScalingActive({ canScale, currentRange: visibleLogicalRange, newRange: range });

            if (shouldSetScalingActive) {
                setIsScalingActive(true);
            } else setIsScalingActive(false);
        },
        [canScale, visibleLogicalRange]
    );

    // apply chart options if they change
    useEffect(() => {
        lwcLog('Apply chart options hook', fullOptions);
        if (chartApi) {
            chartApi.applyOptions(fullOptions);
            chartApi.priceScale('right')?.applyOptions({ scaleMargins: fullOptions?.rightPriceScale?.scaleMargins });
        }
    }, [chartApi, fullOptions]);

    // subscribe to crosshair move events
    useEffect(() => {
        if (chartApi && onCrosshairMove !== undefined) {
            chartApi.subscribeCrosshairMove(onCrosshairMove);

            return () => {
                chartApi.unsubscribeCrosshairMove(onCrosshairMove);
            };
        }
    }, [chartApi, chartApiRef, onCrosshairMove]);

    // subscribe to timescale changes (scrollwheel, etc)
    // call the parent prop, so the parent can handle the state of what's currently visible
    useEffect(() => {
        if (chartApi) {
            chartApi.timeScale().subscribeVisibleLogicalRangeChange(handleVisibleLogicalRangeChanged);

            return () => {
                chartApi.timeScale().unsubscribeVisibleLogicalRangeChange(handleVisibleLogicalRangeChanged);
            };
        }
    }, [chartApi, chartApiRef, handleVisibleLogicalRangeChanged, visibleLogicalRange]);

    const chartApiForChildren = chartApi?.destroyed ? undefined : chartApi;

    return (
        <LightweightChartApiContext.Provider value={chartApiForChildren}>
            <div ref={chartRefCallback} style={props.style}>
                <LightweightResetRangeButton
                    chartApi={chartApi}
                    isScalingActive={isScalingActive}
                    setIsScalingActive={setIsScalingActive}
                    visibleLogicalRange={visibleLogicalRange}
                />
                {props.children}
            </div>
        </LightweightChartApiContext.Provider>
    );
};

export const LightweightChart = React.memo(LightweightChartUnmemoized);

interface IChartApiCustom extends IChartApi {
    debugName: string;
    destroyed: boolean;
}

/**
 * Same as LWC's createChart, but also sets some of our debug properties.
 * @param node the empty HTMLDivElement within which to render the chart
 * @param options any subset of ChartOptions to be applied at start
 * @returns an interface to the created chart
 */
function createChartCustom(node: HTMLDivElement, options: DeepPartial<ChartOptions>): IChartApiCustom {
    const chartApiNew = createChart(node, options) as IChartApiCustom;
    chartApiNew.debugName = generateDebugName();
    lwcLog(chartApiNew.debugName, 'created');
    return chartApiNew;
}

/**
 * Same as LWC's {@link IChartApi.remove}, but also sets some of our debug properties.
 *
 * "Removes the chart object including all DOM elements. This is an irreversible operation, you cannot do anything with the chart after removing it."
 * @param chartApi
 */
function removeChartCustom(chartApi: IChartApiCustom): void {
    lwcLog(chartApi.debugName, 'destroyed');
    chartApi.remove();
    chartApi.destroyed = true;
}

function generateDebugName(): string {
    return `LightweightChart-${Math.floor(Math.random() * 1000000)}`;
}

const enableDebugLogging = false;

export function lwcLog(message?: unknown, ...optionalParams: unknown[]): void {
    if (enableDebugLogging) {
        // eslint-disable-next-line no-console -- centralized logger
        console.log('Lightweight-charts logging: ', message, ...optionalParams);
    }
}

function LightweightResetRangeButton({
    chartApi,
    isScalingActive,
    setIsScalingActive,
    visibleLogicalRange
}: {
    chartApi: IChartApiCustom | undefined;
    isScalingActive: boolean;
    setIsScalingActive: any; // TODO
    visibleLogicalRange: LogicalRange | undefined;
}) {
    function resetChartScale() {
        chartApi?.timeScale()?.setVisibleLogicalRange(visibleLogicalRange as Range<number>);
        if (chartApi) setIsScalingActive(false);
    }

    return isScalingActive ? (
        <button className='reset-chart-scale' onClick={() => resetChartScale()}>
            Reset Chart <StyledIcon IconComponent={ReplayRounded} size={15} style={{ marginLeft: 5 }} />
        </button>
    ) : null;
}
