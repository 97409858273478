// @ts-strict-ignore
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { Ts } from 'phoenix/assets/lang/T';
import { ChartRanges } from 'phoenix/constants';
import { AssetTradeability } from 'phoenix/constants/AssetTradeability';
import { AssetClass, StandardPriceToQuantityFormula, StandardQuantityToPriceFormula } from 'phoenix/models/AssetClasses/AssetClass';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { formatOptionExpiration, formatOptionSymbolName } from 'phoenix/models/AssetClasses/utils';
import { AccountSummary, MyInfo, OptionSymbol } from 'phoenix/redux/models';
import { AggregateBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { FormatNumber, MarketTimeSegment } from 'phoenix/util';

export const EquityOptionsAssetClass: AssetClass = {
    // Typing
    type: 'option',
    family: 'equities',
    derivative: 'option',
    derivatives: [],
    algoliaTypes: ['unknown'],
    defaultTradePrice: {
        market: 'bid-ask',
        limit: 'bid-ask',
        stop: 'bid-ask',
        stoplimit: 'bid-ask'
    },

    // Tradeability
    tradeability: AssetTradeability?.option,
    accountCanTradeThis: EquitiesAssetClass?.accountCanTradeThis,
    userAllowedToTradeThis: EquitiesAssetClass?.userAllowedToTradeThis,
    optionsUseToOpenToClose: true,
    defaultOrderType: (marketTimeSegment?: MarketTimeSegment) => (marketTimeSegment === 'postmarket' ? 'limit' : 'market'),
    defaultTimeInForce: (marketTimeSegment?: MarketTimeSegment) => (marketTimeSegment === 'postmarket' ? 'GTC' : 'Day'),
    negativePriceAllowed: false,

    // Features
    hasDeepAnalysis: false,
    canCreateAlerts: false,
    hasOptions: false,
    hasMultiLeg: true,
    canWatchlist: true,
    tradePriceShortcutButtonsVariant: 'percent',
    showStopPriceShortcutButtons: true,
    hasDerivatives: () => EquityOptionsAssetClass?.derivatives.length > 1,

    // Charting
    neverShowChartPulse: false,
    hasAdvancedChart: true,
    advancedChartConfiguration: {
        supported_resolutions: ['1', '10', '30', '1D', '1W']
    },
    chartVariants: ['line', 'candles'],
    chartRanges: () => {
        const text = Ts((s) => s.misc.ranges);

        return [
            { label: text.oneDay, value: ChartRanges.oneDay },
            { label: text.fiveDay, value: ChartRanges.fiveDays },
            { label: text.oneMonth, value: ChartRanges.oneMonth },
            { label: text.threeMonths, value: ChartRanges.threeMonths },
            { label: text.oneYear, value: ChartRanges.oneYear },
            { label: text.fiveYear, value: ChartRanges.fiveYears }
        ];
    },
    accountChartRanges: EquitiesAssetClass?.accountChartRanges,
    securityChartRanges: ['1d', '5d', '1m', '3m', '1y', '5y'],

    formatOrderQuantity: (o) => {
        const { showDecimals = true, decimalPlaces = 2 } = o?.quantityQualifier === 'Shares' ? { showDecimals: true, decimalPlaces: 8 } : {};
        let qty = FormatNumber.removeDecimalsIfZero(FormatNumber.toCommas(o.orderQuantity, showDecimals, decimalPlaces));

        if (o.quantityQualifier === 'EvenDollar') {
            qty = `${FormatNumber.toMoneyOpt2(o?.cost)}`;
        }

        return qty;
    },

    // Formatting + Codec
    getPriceFormatInfo: () => {
        return {
            decimalPlaces: undefined,
            tickSize: 0.01,
            moneyFormatOptions: {
                decimalPlaces: 2,
                hideCurrencySymbol: false,
                maxDecimalPlaces: 2,
                minDecimalPlaces: 2,
                min: 0,
                showDecimals: true,
                tickSize: 0.01
            }
        };
    },
    formatPrice: (price: number, meta: SecurityMetadata) => FormatNumber.toMoneyOpt2(price, EquityOptionsAssetClass?.getPriceFormatInfo(meta).moneyFormatOptions),
    formatQuantity: EquitiesAssetClass?.formatQuantity,
    unit: 'contract',
    idMatches: (id: string) => OptionSymbol.IsOptionSymbol(id) && !/^[A-Z]:/.test(id),

    getSymbolName: (s, lang, variant, meta) => {
        const optSym = new OptionSymbol(s);
        const strike = meta ? EquityOptionsAssetClass?.formatPrice(optSym.strike, meta) : FormatNumber.toMoneyOpt2(optSym.strike);
        return formatOptionSymbolName(optSym, lang, strike, variant);
    },

    getSymbolNameLines: (s, lang, meta) => {
        const first = EquityOptionsAssetClass?.getSymbolName(s, lang, 'short', meta);
        const exp = formatOptionExpiration(s);
        return [first, exp];
    },

    getBaseSymbol: (s) => new OptionSymbol(s)?.underlyingSymbol,
    getConcreteSymbol: (s) => new OptionSymbol(s)?.underlyingSymbol,

    // Colors
    positiveChangeColor: 'rgb(2,179,52)',
    negativeChangeColor: 'rgb(178,41,46)',

    // Pricing
    getQuantityPrice: StandardQuantityToPriceFormula,
    getPriceForQuantity: StandardQuantityToPriceFormula,
    getQuantityForPrice: StandardPriceToQuantityFormula,
    getTodaysEarningsLabel: (l) => l.positionsScreen.todaysGainLoss,
    getTotalEarningsLabel: (l) => l.positionsScreen.openPnL,

    // Account Data
    getBuyingPowerFigures: (power: AggregateBuyingPower, lang: Snex1LanguagePack) => [],
    getAccountSummaryFigures: (summary: AccountSummary, lang: Snex1LanguagePack) => [],
    getNumberOfStrikes: (user: MyInfo) => user.strikes,

    // Documents
    documentDeliveryOptions: 'mail-electronic',

    showShortPositionIcon: false
};
