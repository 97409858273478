import { ArrowUpward } from '@mui/icons-material';
import { useColors } from 'hooks/UseColors';
import React from 'react';
import { SnexTableHeaderCell } from '.';
import { Flex, Spinny, StyledIcon } from '..';
import { TableCell, colors, Typography } from '@mui/material';
import { AdjustColorTransparency } from 'phoenix/util';

export type SortDirection = 'asc' | 'desc';

export interface SortData {
    field?: string;
    direction?: SortDirection;
}

export interface SnexTableHeaderCellProps {
    align?: 'right' | 'left' | 'center';
    field?: string;
    colSpan?: number;
    style?: React.CSSProperties;
    children?: any;
    onClick?: (field: string) => void;
    currentSort: SortData;
    width?: React.CSSProperties['width'];
    dualTitles?: string[];
    titleFields?: string[];
    primaryColor?: React.CSSProperties['color'];
    secondaryColor?: React.CSSProperties['color'];
    dividerColor?: React.CSSProperties['color'];
    primaryClick?: () => void;
    secondaryClick?: () => void;
}

export const SortableSnexTableHeaderCell = (p: SnexTableHeaderCellProps) => {
    const colors = useColors();
    return p.dualTitles ? (
        <TableCell align={p.align} colSpan={p.colSpan} style={{ borderBottom: `1px solid ${colors.dividerColor}`, fontWeight: 700, cursor: 'pointer', fontSize: 15, padding: '0 16px', paddingBottom: '15px', ...p.style }}>
            <Flex column align={p.align === 'left' ? 'flex-start' : p.align === 'right'? 'flex-end' : 'center'} >
                <Flex row justify={'center'} style={{ width: p.width }} onClick={() => { if(p.primaryClick) p.primaryClick()}}>
                    <div
                        style={{
                            paddingBottom: 1,
                            //opacity: p.primaryColor ? 1 : 0.5,
                            color: p.primaryColor,
                            borderBottom: `1px solid ${p.dividerColor || colors.inputBorderActiveColor}`,
                            marginBottom: 1,
                            fontWeight: 700,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {p.dualTitles[0]}
                    </div>
                    <Flex style={{ position: 'relative', width: 1, height: 16, marginLeft: 2 }}>
                        <div style={{ position: 'absolute', top: 0 }}>
                            {p.titleFields && p.currentSort.field === p?.titleFields[0] ? (
                                <Spinny spun={p.currentSort.direction === 'desc'}>
                                    <StyledIcon IconComponent={ArrowUpward} size='18px' variant='div' style={{ color: colors.selectListSelectedItemColor }} />
                                </Spinny>
                            ) : (
                                ''
                            )}
                        </div>
                    </Flex>
                </Flex>
                <Flex row align='center' justify={p.align} style={{ width: p.width}} onClick={() => {if(p.secondaryClick) p.secondaryClick()}}>
                    <div style={{ textAlign: 'center', fontWeight: 700, textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: p.secondaryColor }}>{p.dualTitles[1]}</div>
                    <Flex style={{ position: 'relative', width: 1, height: 16, marginLeft: 2 }}>
                        <div style={{ position: 'absolute', top: 0 }}>
                            {p.titleFields && p.currentSort.field === p?.titleFields[1] ? (
                                <Spinny spun={p.currentSort.direction === 'desc'}>
                                    <StyledIcon IconComponent={ArrowUpward} size='18px' variant='div' style={{ color: colors.selectListSelectedItemColor }} />
                                </Spinny>
                            ) : (
                                ''
                            )}
                        </div>
                    </Flex>
                </Flex>
            </Flex>
        </TableCell>
    ) : (
        <SnexTableHeaderCell align={p.align} width={p.width} onClick={() => { if(p.onClick) p.onClick(p.field || '')} }>
            <Flex row align='center' justify={p.align}>
                <div>{p.children}</div>
                <Flex style={{ position: 'relative', width: 1, height: 16, marginLeft: 2 }}>
                    <div style={{ position: 'absolute', top: 0 }}>
                        {p.currentSort.field === p.field ? (
                            <Spinny spun={p.currentSort.direction === 'desc'}>
                                <StyledIcon IconComponent={ArrowUpward} size='18px' variant='div' style={{ color: colors.selectListSelectedItemColor }} />
                            </Spinny>
                        ) : (
                            ''
                        )}
                    </div>
                </Flex>
            </Flex>
        </SnexTableHeaderCell>
    );
};
