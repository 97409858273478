// @ts-strict-ignore
import { useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { QuantityInputSplit } from 'components/InputSplit/QuantityInputSplit';
import useOptionsOpenClose from 'hooks/useOptionsOpenClose';
import { T } from 'phoenix/assets/lang/T';
import { TradeActions } from 'phoenix/constants/Trade';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { EquityOptionsAssetClass } from 'phoenix/models/AssetClasses/EquityOptionsAssetClass';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { SafeFormat, toMoneyOpt2 } from 'phoenix/util';
import { getMultiLegDefaultPrice } from 'phoenix/util/OptionsHelpers';
import { XS } from 'phoenix/xstream/XS';
import React, { Fragment, memo, ReactElement, useState } from 'react';
import { useText } from '../../../phoenix/hooks/UseText';
import { OptionSymbol } from '../../../phoenix/redux/models';
import { OptionContractSummaryRow } from '../Shared/TradeFormComponents';
import { OptionsTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { getDefaultPrice, useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';

interface OptionTradeListProps {
    orderAgain?: boolean;
}

export const OptionTradeListComponent = (props: OptionTradeListProps): ReactElement => {
    const { initialQuantity, leg2Quantity, leg2Symbol, leg2TradeAction, limitPrice, quantity, setViewModel, symbol, tradeAction } =
        useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    // Use quantity input key to force a remount of the quantity input component
    // such as when using "Sell All" button to override user input
    const [quantityInputKey, setQuantityInputKey] = useState<number>(0);
    const quote = XS.OptionQuotes.use(symbol);
    const ac = useAssetClass(symbol);
    const selectedAccountNumber = useSelectedAccountByAssetFamily(ac.family);
    const leg1Quote = XS.OptionQuotes.use(symbol);
    const leg2Quote = XS.OptionQuotes.use(leg2Symbol);
    const optSym = new OptionSymbol(symbol);
    const leg2OptSym = new OptionSymbol(leg2Symbol);
    const { openClose, leg2OpenClose } = useOptionsOpenClose();
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const text = useText((s) => s.tradeTicket.input);
    const { putCallWord } = useText((t) => t.general.options);
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const leg2Meta = useSnexStore((s) => s.securities.bySymbol[leg2Symbol]?.metadata?.data);
    const sharesLabel = typeof meta?.deliverableCount === 'number' && text.options.sharesPerContract(meta?.deliverableCount);
    const showCloseButton = props.orderAgain ? !props.orderAgain : true;

    // Replace the first leg with the 2nd
    const handleCancelFirstLeg = () => {
        setViewModel({
            debitCredit: null,
            initialLimitPrice: getDefaultPrice({ marketTimeSegment, assetClass: EquityOptionsAssetClass, quote: leg2Quote, tradeAction: leg2TradeAction }),
            initialQuantity: leg2Quantity, // Initialize qty for the single leg ticket
            leg2Quantity: null,
            leg2Symbol: null,
            leg2TradeAction: null,
            limitPrice: null,
            quantity: leg2Quantity,
            symbol: leg2Symbol,
            tradeAction: leg2TradeAction
        });
    };

    const handleCancelSecondLeg = () => {
        setViewModel({
            debitCredit: null,
            initialLimitPrice: getDefaultPrice({ marketTimeSegment, assetClass: EquityOptionsAssetClass, quote: leg1Quote, tradeAction }),
            initialQuantity: quantity, // Initialize qty for the single leg ticket
            leg2Quantity: null,
            leg2Symbol: null,
            leg2TradeAction: null,
            limitPrice: null
        });
    };

    const handleQuantityChange = (value: number, field: 'quantity' | 'leg2Quantity') => {
        // Base net bid/ask on current selections
        const leg1QuantityCurrent = field === 'quantity' ? value : quantity || initialQuantity;
        const leg2QuantityCurrent = field === 'leg2Quantity' ? value : leg2Quantity;

        const defaultPrice = getMultiLegDefaultPrice({
            leg1Quote,
            leg2Quote,
            quantity: leg1QuantityCurrent || initialQuantity,
            tradeAction,
            leg2Quantity: leg2QuantityCurrent,
            leg2TradeAction
        });

        setViewModel({
            debitCredit: defaultPrice.debitCredit,
            // If a user has already entered a limit price, do not set a new initial price
            // Prevents problem edge case when a user clicks back from review and re-initializes the trade ticket
            ...(limitPrice ? {} : { initialLimitPrice: defaultPrice?.initialPrice }),
            [field]: value
        });
    };

    const optionsList = [
        {
            handleCancel: handleCancelFirstLeg,
            initialQuantity,
            openClose,
            optSym,
            quote,
            tradeAction,
            meta,
            handleChange: (value: number) => handleQuantityChange(value, 'quantity')
        },
        {
            handleCancel: handleCancelSecondLeg,
            initialQuantity: leg2Quantity,
            openClose: leg2OpenClose,
            optSym: leg2OptSym,
            quote: leg2Quote,
            tradeAction: leg2TradeAction,
            meta: leg2Meta,
            handleChange: (value: number) => handleQuantityChange(value, 'leg2Quantity')
        }
    ];

    return (
        <>
            {optionsList.map((option, i) => (
                <Fragment key={option.optSym?.occSymbol}>
                    <OptionContractSummaryRow
                        action={option.openClose !== 'Loading' ? text.options.actionToOpenClose({ action: option.tradeAction }, option.openClose) : option.tradeAction}
                        ask={toMoneyOpt2(option.quote?.ask)}
                        bid={toMoneyOpt2(option.quote?.bid)}
                        expDate={SafeFormat(option.optSym.expDate, 'MM/dd/yyyy')}
                        last={toMoneyOpt2(option.quote?.last ?? option.tradeAction === TradeActions.Buy ? option.quote?.ask : option.quote?.bid)}
                        onCancel={option.handleCancel}
                        putCall={putCallWord(option.optSym.putCall)}
                        showCloseButton={showCloseButton}
                        showBidAsk
                        strike={toMoneyOpt2(option.optSym.strike)}
                        symbol={optSym.underlyingSymbolWithPrefix}
                    />
                    <QuantityInputSplit
                        accountNumber={selectedAccountNumber}
                        arrows={true}
                        formatter={ac?.formatQuantity}
                        initialValue={option.initialQuantity}
                        key={quantityInputKey}
                        label={T((t) => t.general.contracts(0))}
                        liquidateOnSellAll={false}
                        onBuySellAll={() => setQuantityInputKey(quantityInputKey + 1)}
                        onValueChange={(v) => option.handleChange(v)}
                        showPosition={true}
                        showSellAll={false}
                        step={1} // Used for shares only, 1 share per tick
                        sublabel={sharesLabel}
                        symbol={option.optSym.occSymbol}
                        tradeAction={tradeAction}
                    />
                </Fragment>
            ))}
        </>
    );
};

export const OptionTradeList = memo(OptionTradeListComponent);
