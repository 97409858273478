import { CSSObject } from '@emotion/serialize';
import { ExpandMore } from '@mui/icons-material';
import { MenuItem, TextField } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { DropdownOption } from 'phoenix/models/DropdownOptions';
import React, { ComponentType, Dispatch, ReactElement, SetStateAction } from 'react';
import ReactSelect, { GroupTypeBase, OptionProps, OptionTypeBase } from 'react-select';

interface DropdownComponentProps {
    defaultValue?: string | DropdownOption;
    disabled?: boolean;
    implementation?: 'react-select' | 'mui' | 'native';
    onChange?: ((value: DropdownOption | undefined) => void) | Dispatch<SetStateAction<DropdownOption | undefined>>;
    onValueChange?: (value: string | number) => void;
    optionComponent?: ComponentType<OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>>;
    options?: DropdownOption[];
    placeholder?: string;
    required?: boolean;
    noBorder?: boolean;
    hasDividers?: boolean;
    style?: CSSObject;
    value?: OptionTypeBase | string | number | null | undefined;
    noOptionsMessage?: string;
    maxMenuHeight?: number;
    readonly?: boolean;
}

export const DropdownComponent = ({
    defaultValue,
    disabled,
    implementation,
    onChange,
    onValueChange,
    optionComponent,
    options = [],
    placeholder,
    required,
    noBorder,
    hasDividers,
    style,
    value,
    noOptionsMessage,
    maxMenuHeight,
    readonly
}: DropdownComponentProps): ReactElement => {
    const colors = useColors();
    const handleChange = (change: DropdownOption | undefined) => {
        if (onChange) onChange(change);
        if (onValueChange) onValueChange(change?.value as string | number);
    };

    const selectStyles = {
        container: (styles: CSSObject) => {
            return { ...styles, width: '100%', padding: '0', ...style };
        },
        control: (styles: CSSObject) => {
            return {
                ...styles,
                backgroundColor: colors.mainBackgroundColor,
                borderRadius: 5,
                '&:hover, &:focus-within': {
                    borderColor: required && !(value || (typeof value === 'number' && value === 0)) ? colors.requiredBorderColor : colors.inputBorderActiveColor
                }, // border style on hover
                border: !noBorder
                    ? `1px solid ${required && !(value || (typeof value === 'number' && value === 0)) ? colors.requiredBorderColor : colors.inputBorderColor}`
                    : 'none', // default border color
                boxShadow: 'none' // no box-shadow
            };
        },
        indicatorSeparator: () => ({ color: 'transparent', backgroundColor: 'transparent' }),
        input: (styles: CSSObject) => ({
            ...styles,
            color: colors.generalTextColor
        }),
        dropdownIndicator: (styles: CSSObject, { isDisabled }: { isDisabled: boolean }) => ({
            ...styles,
            color: isDisabled ? colors.blurredAdornmentColor : colors.focusedAdornmentColor
        }),
        placeholder: (styles: CSSObject, { isDisabled }: { isDisabled: boolean }) => ({
            ...styles,
            fontSize: 15,
            fontWeight: 500,
            color: isDisabled ? colors.blurredAdornmentColor : colors.generalTextColor
        }),
        singleValue: (styles: CSSObject, { isDisabled }: { isDisabled: boolean }) => ({
            ...styles,
            fontSize: 15,
            fontWeight: 500,
            color: isDisabled ? colors.blurredAdornmentColor : colors.generalTextColor
        }),
        menu: (style: CSSObject) => ({
            ...style,
            padding: 0,
            overflow: 'hidden',
            zIndex: '999'
        }),
        menuList: (style: CSSObject) => ({
            ...style,
            padding: 0,
            overflow: 'auto'
        }),
        option: (style: CSSObject, { isDisabled, isFocused, isSelected }: { isDisabled?: boolean; isFocused?: boolean; isSelected?: boolean }) => {
            const { backgroundColor, color } =
                isSelected || isFocused
                    ? { backgroundColor: colors.cardBackgroundColor, color: colors.primaryItemColor }
                    : { backgroundColor: colors.mainBackgroundColor, color: colors.generalTextColor };

            return {
                ...style,
                fontSize: 13,
                borderBottom: hasDividers ? '1px solid rgba(100,100,100,0.1)' : undefined,
                // boxSizing: 'border-box',
                color: isDisabled ? colors.blurredAdornmentColor : color,
                backgroundColor: isDisabled ? colors.mainBackgroundColor : backgroundColor,
                '&:hover': {
                    color: isDisabled ? colors.blurredAdornmentColor : colors.primaryItemColor,
                    backgroundColor: isDisabled ? colors.mainBackgroundColor : colors.cardBackgroundColor
                }
            };
        }
    };

    const isMatch = (optionValue: string | number, valueToMatch: OptionTypeBase | string | number | null | undefined) => {
        if (typeof valueToMatch === 'number') {
            return parseFloat(optionValue?.toString()) === valueToMatch;
        }
        if (typeof optionValue === 'string') {
            return optionValue?.toString().toLowerCase() === valueToMatch?.toString().toLowerCase();
        }
        return `${optionValue}` === valueToMatch;
    };

    const actualValue = options.find((o) => isMatch(o.value, value)) || value;

    if (implementation === 'mui' || implementation === 'native') {
        return (
            <TextField
                select
                defaultValue={defaultValue}
                disabled={disabled}
                placeholder={placeholder}
                SelectProps={{
                    IconComponent: ExpandMore,
                    style: { fontSize: 14 }
                }}
                value={value}
                variant='outlined'
                onChange={(e) => handleChange(options.find((o) => o.value === e.target.value))}
            >
                {options.map((o, key) => {
                    return (
                        <MenuItem key={key} style={{ padding: 8, fontSize: 14 }}>
                            {o.label}
                        </MenuItem>
                    );
                })}
            </TextField>
        );
    } else {
        return (
            <ReactSelect
                {...(optionComponent ? { components: { Option: optionComponent } } : {})}
                defaultValue={defaultValue}
                isDisabled={disabled}
                options={options}
                noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : () => ''}
                placeholder={placeholder}
                styles={selectStyles}
                value={actualValue}
                maxMenuHeight={maxMenuHeight}
                menuPlacement='auto'
                isSearchable={!readonly}
                onChange={handleChange}
            />
        );
    }
};
