// @ts-strict-ignore
import { ChevronRight } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Paper, TextField, Typography } from '@mui/material';
import { useAppDispatch } from 'AppRoot';
import { UseSelectedAccount } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { Flex } from 'components/Flex';
import { SnexButton } from 'components/SnexButton';
import { Spinny } from 'components/Spinny';
import { TradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { convertViewModelToOrderRequest, useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { useColors } from 'hooks/UseColors';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { IsDebugWhitelabel } from 'phoenix/constants/Whitelabels';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { GetStoreSizeAction, OnApiAction, OnPollingCount, OrderSearchFiltersV3 } from 'phoenix/redux/actions';
import { useBuyingPowerStore } from 'phoenix/stores/BuyingPowerStore';
import { useFundingLimitsStore } from 'phoenix/stores/FundingLimitsStore';
import { useFundingSourcesStore } from 'phoenix/stores/FundingSourcesStore';
import { SendMockOrderUpdateMessage } from 'phoenix/stores/messageHelpers';
import { OrdersStore_RefreshOrders } from 'phoenix/stores/OrdersStore';
import { usePlaidStore } from 'phoenix/stores/PlaidStore';
import {
    AllDebugFlags, FormatNumber, GetBuyingPowerDebug, GetDebugToasts, GetFundDebug, GetLocalUrls, GetShowDebugHud,
    GetTradeDebug, GetUseWhitelabel, GroupBySelect, Sum,
    TruncateText
} from 'phoenix/util';
import { TicketManager, TicketTelemetryItem } from 'phoenix/util/TicketManager';
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { Link } from 'react-router-dom';
import { useAcatTicketStore } from 'screens/FundingScreen/Acat/Helpers/AcatTicketStore';
import { useFundingTicketStore } from 'screens/FundingScreen/Transfers/FundingTicketStore';
import { Routes } from 'util/Routes';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
let apicalls: { [key: string]: { [key: string]: number } } = {};

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const TicketItemForCopy = (props: { items: TicketTelemetryItem[]; namespace: string }) => {
    const size = Sum(props.items.map((g) => g.tickets.size));
    const title = `${props.namespace} [${size}]`;

    const details = props.items.map((i) => `${TruncateText(i.item, 50)} (${FormatNumber.toCommas(i.updates, false)}) : ${i.tickets.size}T`);
    return [title, ...details].join('\r\n');
};

const TicketItem = (props: { items: TicketTelemetryItem[]; namespace: string }) => {
    const [expanded, setExpanded] = useLocalStorage('debugNS' + props.namespace, true);

    const size = Sum(props.items.map((g) => g.tickets.size));

    return (
        <Flex column style={{ fontSize: '13px', marginBottom: '0' }}>
            <Flex row align='center' onClick={() => setExpanded(!expanded)}>
                <Spinny spun={expanded} targetDegrees={90}>
                    <ChevronRight />
                </Spinny>
                <span>
                    {props.namespace} [ {size}T ]
                </span>
            </Flex>
            {expanded ? (
                <ul style={{ padding: 0, margin: 0 }}>
                    {props.items.map((i, idx) => (
                        <li key={idx} style={{ marginLeft: '32', opacity: 0.75 }}>
                            {TruncateText(i.item, 50)} ({FormatNumber.toCommas(i.updates, false)}) : {i.tickets.size}T
                        </li>
                    ))}
                </ul>
            ) : null}
        </Flex>
    );
};

const DebugSection = ({
    title,
    values,
    name,
    copyData,
    children
}: PropsWithChildren<{ title: string; values?: string[] | JSX.Element[]; name: string; copyData?: string }>) => {
    const [sectionExpanded, setSectionExpanded] = useLocalStorage('debugSection_' + name, true);
    const colors = useColors();

    const handleCopy = async () => {
        try {
            await navigator?.clipboard.writeText(copyData);
            console.log('Content copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const SectionIconButtonStyle = { color: colors.orange, height: 29, width: 29, padding: 8 };

    return (
        <Flex column fullWidth style={{ maxWidth: 500 }}>
            <Flex row center fullWidth style={{ fontSize: 13, marginBottom: 8, paddingBottom: 3, borderBottom: `1px dotted ${colors.orange}`, cursor: 'pointer' }}>
                <Typography onClick={() => setSectionExpanded(!sectionExpanded)} variant='h6' style={{ flex: '1 1 auto' }}>
                    {title}:
                </Typography>
                {copyData ? (
                    <IconButton title={'Copy to clipboard'} onClick={handleCopy} style={SectionIconButtonStyle}>
                        <ContentCopyIcon style={{ width: 13, height: 13 }} />
                    </IconButton>
                ) : null}
                <IconButton title={sectionExpanded ? 'Show less' : 'Show more'} onClick={() => setSectionExpanded(!sectionExpanded)} style={SectionIconButtonStyle}>
                    <Spinny spun={sectionExpanded} targetDegrees={90}>
                        <ChevronRight style={{ width: 18, height: 18 }} />
                    </Spinny>
                </IconButton>
            </Flex>
            <Flex column fullWidth style={{ padding: '8px' }}>
                {sectionExpanded
                    ? values?.map((d, index) =>
                          typeof d === 'string' ? (
                              <Typography key={`debug_${name}_${index}`} variant='h6' style={{ fontSize: 13, paddingBottom: 3, wordBreak: 'break-all' }}>
                                  {d}
                              </Typography>
                          ) : (
                              d
                          )
                      )
                    : null}
                {sectionExpanded ? children : null}
            </Flex>
        </Flex>
    );
};

export const DebugModal = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [tickets, setTickets] = useState<{ [namespace: string]: TicketTelemetryItem[] }>({});
    const [rxsize, setRxsize] = useState<{ [key: string]: number }>({});
    const [polls, setPolls] = useState([]);

    const colors = useColors();
    const localUrls = GetLocalUrls() || [];
    const debugFlags = AllDebugFlags?.filter((f) => f.get() === true).filter((f) => !['Show Debug HUD', 'Debug Trades'].includes(f.label)) || [];
    const whitelabel = IsDebugWhitelabel() === true;
    const showDebugHud = GetShowDebugHud();
    const showTradeDebug = GetTradeDebug();
    const showBuyingPower = GetBuyingPowerDebug();
    const showDebugToasts = GetDebugToasts();
    const showFunding = GetFundDebug();
    const dispatch = useAppDispatch();
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const [selectedAccountNumber] = UseSelectedAccount();

    const [orderId, setOrderId] = React.useState<string>('TestOrderUpdate');
    const [symbol, setSymbol] = React.useState<string>('ZVZZT');
    const orderFilter: OrderSearchFiltersV3 = {
        take: 20
    };

    const isDebugging = () => {
        let _isDebugging = false;
        if (localUrls?.length > 0) _isDebugging = true;
        if (debugFlags?.length > 0) _isDebugging = true;
        if (whitelabel) _isDebugging = true;
        if (showDebugHud) _isDebugging = true;
        if (showTradeDebug) _isDebugging = true;
        return _isDebugging;
    };

    const debugContainerStyle = {
        maxwidth: 400,
        minHeight: 24,
        backgroundColor: colors.cardBackgroundColor,
        color: colors.orange,
        zIndex: 999999,
        top: 86,
        right: 20,
        borderRadius: 6,
        opacity: 0.9,
        border: `1px solid ${colors.inputBorderColor}`
    };

    const relays = useSnexStore((s) => s.relay);
    const relayAnalytics = useMemo(() => {
        return Object.entries(relays).map((kv) => ({ topic: kv[0], value: kv[1]?.toString() }));
    }, [relays]);

    const sortedSize = useMemo(() => Object.entries(rxsize).sort((a, b) => b[1] - a[1]), [rxsize]);

    const handleReduxAction = (action: string, type: string) => {
        apicalls = {
            ...apicalls,
            [type]: {
                ...apicalls[type],
                [action]: (apicalls[type]?.[action] || 0) + 1
            }
        };
    };

    useEffect(() => {
        dispatch(GetStoreSizeAction());
        OnPollingCount(setPolls);
        TicketManager.OnTelemetryChange((t) => setTickets(GroupBySelect(t, (x) => x.namespace)));
        OnApiAction(handleReduxAction);
        // @ts-ignore
        setInterval(async () => setRxsize(await dispatch(GetStoreSizeAction())), 5000);
    }, []);

    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();
    const meta = useSnexStore((s) => s.securities.bySymbol[viewModel?.symbol]?.metadata?.data);
    const { summary: aggregateBuyingPower } = useBuyingPowerStore();
    const { limits } = useFundingLimitsStore();
    const { fundingSources } = useFundingSourcesStore();
    const { transfer, transferErrors, ticketState } = useFundingTicketStore();
    const { transfer: acatTransfer, page: acatPage, canSubmitReason, isSubmitComplete, canSubmit: canSubmitPage } = useAcatTicketStore();

    const debugModalRef = useRef(null);

    const LSSubsForDisplay = useMemo(() => Object.entries(tickets).map((ns, i) => <TicketItem items={ns[1]} key={`${ns[0]}-${i}`} namespace={ns[0]} />), [tickets]);

    const LSSubsForCopy = useMemo(() => {
        const text = Object.entries(tickets).map((ns) => TicketItemForCopy({ items: ns[1], namespace: ns[0] }));
        return text.join('\r\n');
    }, [tickets]);

    const underline = '----------------------------\r\n';
    const tradeCopyData = useMemo(
        () =>
            'TradeTicket:\r\n' +
            underline +
            JSON.stringify(
                { viewModel, convertedToOrderRequest: convertViewModelToOrderRequest({ marketTimeSegment, meta, selectedAccountNumber, viewModel }) },
                null,
                2
            ),
        [marketTimeSegment, meta, selectedAccountNumber, viewModel]
    );
    const buyingPowerCopyData = useMemo(() => 'BuyingPower:\r\n' + underline + JSON.stringify(aggregateBuyingPower, null, 2), [aggregateBuyingPower]);
    const rxCopyData = useMemo(
        () =>
            `RX: Store Size: ${FormatNumber.toCommas(rxsize.total / 1024) || '--'}KB:\r\n` +
            underline +
            sortedSize.map((ss) => `${ss[0]}: ${FormatNumber.toCommas(ss[1] / 1024) || '--'}KB`).join('\r\n'),
        [rxsize.total, sortedSize]
    );
    const fundingLimitsCopyData = useMemo(() => 'Funding Limits:\r\n' + underline + JSON.stringify({ limits }, null, 2), [limits]);
    const fundingSourcesCopyData = useMemo(() => 'Funding Sources:\r\n' + underline + JSON.stringify(fundingSources, null, 2), [fundingSources]);
    const fundingTicketCopyData = useMemo(
        () => 'Funding Ticket:\r\n' + underline + JSON.stringify({ transfer, transferErrors, ticketState }, null, 2),
        [transfer, transferErrors, ticketState]
    );
    const acatTicketCopyData = useMemo(
        () => 'ACAT Ticket:\r\n' + underline + JSON.stringify({ acatTransfer, acatPage, canSubmitReason, isSubmitComplete, canSubmitPage }, null, 2),
        [acatTransfer, acatPage, canSubmitReason, isSubmitComplete, canSubmitPage]
    );
    const lsCopyData = useMemo(() => 'LS: Active Subs:\r\n' + underline + LSSubsForCopy, [LSSubsForCopy]);
    const pollingCopyData = useMemo(() => 'Polling Intervals:\r\n' + underline + polls.map((p) => p).join('\r\n'), [polls]);
    const relayCopyData = useMemo(() => 'Relays:\r\n' + underline + relayAnalytics.map((a) => `${a.topic}: ${a.value}`).join('\r\n'), [relayAnalytics]);

    const allCopyData = useMemo(
        () => [tradeCopyData, rxCopyData, lsCopyData, pollingCopyData, relayCopyData, fundingLimitsCopyData, fundingSourcesCopyData, fundingTicketCopyData].join('\r\n'),
        [tradeCopyData, rxCopyData, lsCopyData, pollingCopyData, relayCopyData, fundingLimitsCopyData, fundingSourcesCopyData, fundingTicketCopyData]
    );

    const handleLoginReset = async () => {
        await usePlaidStore.getState().resetAuthorization(transfer?.fundingSourceId);
    };

    const handleCopy = async () => {
        try {
            await navigator?.clipboard.writeText(allCopyData);
            console.log('Content copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return isDebugging() ? (
        <Draggable handle='#draggable-handle' bounds={'parent'}>
            <Paper ref={debugModalRef} elevation={3} style={{ ...debugContainerStyle, boxSizing: 'border-box', position: 'fixed' }}>
                <Flex id={'draggable-handle'} row center={!isExpanded}>
                    {!isExpanded ? (
                        <Flex row center style={{ paddingLeft: '16px' }}>
                            <WarningIcon className={'handle'} style={{ height: 14, width: 14, marginRight: 12 }} />
                            <Typography variant='h6' style={{ fontSize: 13, flex: '1' }}>
                                Debug On
                            </Typography>
                        </Flex>
                    ) : (
                        <Flex
                            column
                            style={{ maxWidth: '400', maxHeight: `${getWindowDimensions().height * 0.75}px`, overflowX: 'hidden', overflowY: 'scroll', padding: '8px' }}
                        >
                            {whitelabel ? (
                                <Typography variant='h6' style={{ fontSize: 13, paddingBottom: 3 }}>
                                    Whitelabel: {GetUseWhitelabel()}
                                </Typography>
                            ) : null}
                            {debugFlags.length > 0 ? <DebugSection title={'Flags'} values={debugFlags.map((d) => d.label)} name='flags' /> : null}
                            {localUrls.length > 0 ? (
                                <DebugSection
                                    title={'Local Urls'}
                                    values={localUrls.map((d) => d.replace('^(', '').replace(')$', '').replaceAll('(.*?)', '*'))}
                                    name='localUrls'
                                />
                            ) : null}
                            {showTradeDebug ? (
                                <DebugSection
                                    copyData={tradeCopyData}
                                    title={'Trade Ticket'}
                                    values={[
                                        <pre key='tradeState'>
                                            {JSON.stringify(
                                                {
                                                    viewModel,
                                                    convertedToOrderRequest: convertViewModelToOrderRequest({ marketTimeSegment, meta, selectedAccountNumber, viewModel })
                                                },
                                                null,
                                                2
                                            )}
                                        </pre>
                                    ]}
                                    name={'TradeTicket'}
                                />
                            ) : null}
                            {showFunding ? (
                                <>
                                    <DebugSection
                                        copyData={fundingLimitsCopyData}
                                        title={'Funding Limits'}
                                        values={[<pre key='fundingLimitsState'>{JSON.stringify(limits, null, 2)}</pre>]}
                                        name={'FundingLimits'}
                                    />
                                    <DebugSection
                                        copyData={fundingSourcesCopyData}
                                        title={'Funding Sources'}
                                        values={[<pre key='fundingSourcesState'>{JSON.stringify(fundingSources, null, 2)}</pre>]}
                                        name={'FundingSources'}
                                    />
                                    <DebugSection
                                        copyData={fundingTicketCopyData}
                                        title={'Funding Ticket'}
                                        values={[<pre key='fundingTicketState'>{JSON.stringify({ transfer, transferErrors, ticketState }, null, 2)}</pre>]}
                                        name={'FundingTicket'}
                                    />
                                    <DebugSection
                                        copyData={acatTicketCopyData}
                                        title={'ACAT Ticket'}
                                        values={[
                                            <pre key='acatState'>
                                                {JSON.stringify({ acatTransfer, acatPage, canSubmitReason, isSubmitComplete, canSubmitPage }, null, 2)}
                                            </pre>
                                        ]}
                                        name={'ACATTicket'}
                                    />
                                    <SnexButton flavor='secondary' onClick={handleLoginReset} style={{ color: colors.orange }}>
                                        Reset Plaid Login
                                    </SnexButton>
                                </>
                            ) : null}
                            {showBuyingPower ? (
                                <DebugSection
                                    copyData={buyingPowerCopyData}
                                    title={'Buying Power'}
                                    values={[<pre key='buyingPowerState'>{JSON.stringify(aggregateBuyingPower, null, 2)}</pre>]}
                                    name={'Buying Power'}
                                />
                            ) : null}
                            {showDebugHud ? (
                                <>
                                    <DebugSection
                                        copyData={rxCopyData}
                                        title={`RX: Store Size: ${FormatNumber.toCommas(rxsize.total / 1024) || '--'}KB`}
                                        values={sortedSize.map((ss) => `${ss[0]}: ${FormatNumber.toCommas(ss[1] / 1024) || '--'}KB`)}
                                        name={'RXSizes'}
                                    />
                                    <DebugSection copyData={lsCopyData} title={'LS: Active Subs'} values={LSSubsForDisplay} name={'LSSubs'} />
                                    <DebugSection copyData={pollingCopyData} title={'Polling Intervals'} values={polls.map((p) => p)} name={'PollingIntervals'} />
                                    <DebugSection
                                        copyData={relayCopyData}
                                        title={'Relays'}
                                        values={relayAnalytics.map((a) => `${a.topic}: ${a.value}`)}
                                        name={'Relays'}
                                    />
                                </>
                            ) : null}
                            {showDebugToasts ? (
                                <DebugSection title={'Debug Toasts'} name={'Debug Toasts'}>
                                    <Flex column fullWidth style={{ gap: 8 }}>
                                        <Flex row fullWidth style={{ gap: 12 }}>
                                            <TextField sx={{ width: '100%' }} size='small' onChange={(a) => setOrderId(a.target.value)} value={orderId} label='OrderId' />
                                            <TextField sx={{ width: '100%' }} size='small' onChange={(a) => setSymbol(a.target.value)} value={symbol} label='Symbol' />
                                        </Flex>
                                        <Flex row fullWidth style={{ gap: 12 }}>
                                            <SnexButton onClick={async () => await SendMockOrderUpdateMessage('New', orderId, symbol)}>Send Update Open</SnexButton>
                                            <SnexButton onClick={async () => await SendMockOrderUpdateMessage('Canceled', orderId, symbol)}>
                                                Send Update Canceled
                                            </SnexButton>
                                        </Flex>
                                        <Flex row fullWidth style={{ gap: 12 }}>
                                            <SnexButton onClick={async () => await SendMockOrderUpdateMessage('Partial Fill', orderId, symbol)}>
                                                Send Update Partial Fill
                                            </SnexButton>
                                            <SnexButton onClick={async () => await SendMockOrderUpdateMessage('Filled', orderId, symbol)}>Send Update Filled</SnexButton>
                                        </Flex>
                                        <SnexButton onClick={async () => await OrdersStore_RefreshOrders(orderFilter)}>Refresh Orders</SnexButton>
                                    </Flex>
                                </DebugSection>
                            ) : null}
                        </Flex>
                    )}
                    <div style={{ height: 40, width: 40 }}>
                        <IconButton onClick={() => setIsExpanded(!isExpanded)} style={{ color: colors.orange }}>
                            <Spinny spun={isExpanded} targetDegrees={90}>
                                <ChevronRight />
                            </Spinny>
                        </IconButton>
                        {isExpanded ? (
                            <Link to={Routes.profile('debug')}>
                                {' '}
                                <IconButton title={'Open debug settings'} style={{ color: colors.orange }}>
                                    <SettingsIcon />
                                </IconButton>
                            </Link>
                        ) : null}
                        {isExpanded && allCopyData ? (
                            <IconButton title={'Copy to clipboard'} onClick={handleCopy} style={{ color: colors.orange }}>
                                <ContentCopyIcon />
                            </IconButton>
                        ) : null}
                    </div>
                </Flex>
            </Paper>
        </Draggable>
    ) : null;
};
