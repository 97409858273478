import React, { ReactNode, useEffect, useMemo, useRef } from "react"
import { DisclosureFooter, DisclosureFooterProps } from "components/DisclosureFooter/DisclosureFooter"
import { Flex } from "components/Flex"
import { Header } from "components/Header"
import { PortfolioGlance } from "components/PortfolioGlance"
import { useAppWindowSize, WindowSizes } from "hooks/UseWindowSize"
import { Card } from "components/Card"
import { PositionScreenFilters } from "screens/PositionsScreen/HoldingsTableCommonProps"
import { UseTickerTape } from "phoenix/hooks/UseTickerTape"
import { Routes } from "util/Routes"
import { ErrorBoundary } from "@sentry/react"
import { TradingViewMarquee } from "components/TradingViewMarquee/TradingViewMarquee"
import { GlobalMessageBanner } from "components/GlobalMessageBanner/globalMessageBanner"
import { useGlobalMessageStore } from "components/GlobalMessageBanner/store"

export interface BaseScreenLayoutProps  {
    children: ReactNode;
    style?: React.CSSProperties;
    backgroundColor?: string;
    hideHeader?: boolean;
    disclosures?: DisclosureFooterProps;
    hideGlance?: boolean;
    selectedAccount?: string;
    glanceFilters?: PositionScreenFilters;
    sidebarId?: string,
    sidebar?: ReactNode,
    sidebarLeft?: boolean
    sidebarWidth?: number
    fullWidthContent?: boolean
    noCard?: boolean
}

export const BaseScreenLayout = (props: BaseScreenLayoutProps) => {

    const [appWindowSize, _] = useAppWindowSize();

    const sidebarWidth = useMemo(() => {
        if(props.sidebarWidth) return props.sidebarWidth
        switch (appWindowSize) {
            case WindowSizes.tablet: return 370;
            case WindowSizes.small: return 370 ;
            case WindowSizes.regular: return 420;
            case WindowSizes.large:
            default:
                return 420;
        }
    }, [appWindowSize]);

    const headerSize = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet: return 208;
            case WindowSizes.small: return 208;
            case WindowSizes.regular: return 124;
            case WindowSizes.large:
            default:
                return 124;
        }
    }, [appWindowSize])

    const containerRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const disclosuresRef = useRef<HTMLDivElement>(null)
    const contentGap = 16
    const contentWidth = useMemo(() => (contentRef?.current?.clientWidth || 0 - contentGap) - sidebarWidth, [sidebarWidth, contentRef?.current?.clientWidth, contentGap])
    const glanceRef = useRef<HTMLDivElement>(null)
    const contentHeight = useMemo(() => {
        const result = containerRef?.current?.clientHeight || 0 - ((headerRef?.current?.clientHeight || 0) + 
        (disclosuresRef?.current?.clientHeight || 0) + (glanceRef?.current?.clientHeight || 0))
        return result > 0 ? result : undefined
    }, [
            containerRef?.current?.clientHeight,
            headerRef?.current?.clientHeight,
            disclosuresRef?.current?.clientHeight,
            glanceRef?.current?.clientHeight
        ])

    const isFullScreenRoute = useMemo(() => {
        const fullScreenRoutes = [
            Routes.positions(),
            Routes.fplOnboarding(),
            '/onboarding',
            Routes.onboardingSelect(),
            '/neil',
            '/registration',
            Routes.deadLogin(),
            Routes.localLogin(),
            Routes.loggedOut(),            
        ];
        return location?.pathname?.toLowerCase() === Routes.portfolio() ||
        fullScreenRoutes.some((x) => location?.pathname?.toLowerCase().includes(x.toString().toLowerCase()));
    }, [location.pathname]);
    const tickerTapeEnabled = UseTickerTape();
    const showTickerTape = useMemo(() => {
        const fullScreenRouteExceptions = [Routes.positions()];
        return tickerTapeEnabled && (!isFullScreenRoute || fullScreenRouteExceptions.some((x) => location?.pathname?.toLowerCase().includes(x.toString().toLowerCase())));
    }, [isFullScreenRoute, location?.pathname, tickerTapeEnabled]);

    const { message: globalMessage } = useGlobalMessageStore();
    return (
        <Flex column ref={containerRef} fullWidth center style={{ height: '100vh', background: props.backgroundColor || undefined }}>
            {!props.hideHeader && (
                <Flex ref={headerRef} column fullWidth id='header-section' style={{zIndex: 99999, position: 'sticky', height: headerSize}}>
                    <Header nonFixed/>
                </Flex>
            )}
            {
                globalMessage && <div id={'global-banner-section'} style={{boxSizing: 'border-box', width: '100%', padding: '0 32px 12px'}}><GlobalMessageBanner /></div>
            }
            <Flex ref={contentRef} row fullWidth reverse={props.sidebarLeft} style={{  
                boxSizing: 'border-box', 
                height: contentHeight,
                padding: '0 32px',
                gap: contentGap, 
                overflow: 'hidden', 
                maxWidth: props.fullWidthContent? undefined : 1450 + 64 /* max width + padding */
                }} id='content-section'>
                <div id={'main-content'} style={{width: props.sidebar ? contentWidth : '100%', overflow: 'scroll'}}>{props.children}</div>
                {props.sidebar && <div id={props.sidebarId || 'sidebar-content'} style={{overflow: 'scroll', width: sidebarWidth, minWidth: sidebarWidth}}>
                    {props.noCard? props.sidebar : <Card borderRadius={16}>
                    {props.sidebar}
                    </Card>}
                </div> }
            </Flex>
            {props.disclosures && (
                <Flex ref={disclosuresRef} column center align='flex-end' fullWidth id='disclosures-section' style={{padding: '12px 0'}}>
                    <DisclosureFooter noMargin {...props.disclosures}/>
                </Flex>
            )}
            {!props.hideGlance && (
                <Flex ref={glanceRef} column fullWidth id='glance-section'>
                    <PortfolioGlance
                        nonFixed
                        accountNumber={props.selectedAccount}
                        assetClass={props.glanceFilters?.productType === 'Futures' ? 'futures' : 'equities'}
                    />
                </Flex>
            )}
            {showTickerTape && (
                <ErrorBoundary>
                <TradingViewMarquee />
            </ErrorBoundary>
            )}
        </Flex>
    )
}