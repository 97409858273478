// @ts-strict-ignore
import { Link } from '@mui/material';
import { ActionModal, Flex, LoadingSpinner, OpenOrders } from 'components';
import { UseSelectedAccount, useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { BigTextLink } from 'components/BigTextLink/BigTextLink';
import { DisclosureFooter } from 'components/DisclosureFooter/DisclosureFooter';
import { SecurityHoldings } from 'components/SecurityHoldings/SecurityHoldings';
import 'components/TradeTicket/Shared/index.scss';
import { OptionsTradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { getDefaultOrderProps, useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { TradeTicket } from 'components/TradeTicket/TradeTicket';
import { TableViewMinHeight } from 'constants/GlobalDimensions';
import { useColors } from 'hooks/UseColors';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useQueryParams } from 'hooks/UseQueryParams';
import { StorageKeys } from 'phoenix/constants';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { LevelOrBetter, OptionsLevel } from 'phoenix/constants/OptionsLevels';
import { TradeActions } from 'phoenix/constants/Trade';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import {
    ConvertTradeableSecurityTypeToApiTradeableSecurityType,
    GetClientAccountsAction,
    GetSecurityMetadataAction,
    GetSecurityQuoteAction,
    PublishSubscriptionWhitelist
} from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { OptionSymbol, TradeAction } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { FuturesOptionStatsSection } from 'screens/FuturesOptionContractScreen/FuturesOptionStatsSection';
import { Routes } from 'util/Routes';
import { EnableOptionsScreen } from '../OptionsScreen/EnableOptionsScreen';
import { OptionChartSection } from './OptionChartSection';
import { OptionContractHeaderSection } from './OptionContractHeaderSection';
import { OptionStatsSection } from './OptionStatsSection';
import { AddToListModal } from 'components/Modals/AddToListModal';
import { BaseScreenLayout } from 'components/Layouts/BaseScreenLayout';
import { useOrdersStore } from 'phoenix/stores/OrdersStore';

export const OptionContractScreen = React.memo(() => {
    const [addToListOpen, setAddToListOpen] = useState(false);
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();
    const { load: loadPositions } = usePositionsStore();
    const { osiSymbol: symbol } = useParams<{ osiSymbol: string }>();
    const { searchOrders } = useOrdersStore();
    const colors = useColors();
    const optSym = new OptionSymbol(symbol);
    const occSym = optSym.toOccSymbol();
    const accounts = useSelector((s: GlobalState) => s.accounts.all);
    const text = useText((s) => s.optionContractScreen);
    const positionsText = useText((s) => s.positionsScreen);
    const assetClass = useAssetClass(symbol);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const underlying = isFuture ? optSym.underlyingSymbolWithPrefix : optSym.underlyingSymbol;
    const { setViewModel } = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const quote = XS.OptionQuotes.use(symbol);
    const [, setShowMultiLeg] = useLocalStorage(StorageKeys.ShowMultiLeg, false);
    const positions = usePositionsStore((s) => s.positions);
    const selectedAccountNumber = useSelectedAccountByAssetFamily(assetClass.family);
    const position = positions?.find((p) => p.secMasterOptionSymbol === symbol && selectedAccountNumber === p.accountNumber);
    const [selectedAccount] = UseSelectedAccount();
    // Navigate to the put chain if this symbol is a put
    const queryParams = {
        side: optSym.putCall
    };

    const rollPositionParams = {
        rollAction: position?.quantity > 0 ? TradeActions.Sell : TradeActions.Buy,
        rollQuantity: assetClass.formatQuantity(Math.abs(position?.quantity)),
        rollSymbol: symbol,
        selectedAccount
    };
    const [isTicketInitialized, setIsTicketInitialized] = useState<boolean>(false);

    const { action: initialAction } = useQueryParams();

    const { color, hasOptionsAccounts, linkText, StatsComponent } = isFuture
        ? {
              color: colors.futuresColor,
              hasOptionsAccounts: true,
              linkText: new FuturesSymbol(optSym.underlyingSymbol).noPrefix,
              StatsComponent: FuturesOptionStatsSection
          }
        : {
              color: colors.primaryItemColor,
              hasOptionsAccounts: accounts?.data?.some((a) => LevelOrBetter(a.optionsLevel, OptionsLevel.CoveredCalls)),
              linkText: optSym.underlyingSymbol,
              StatsComponent: OptionStatsSection
          };

    useEffect(() => {
        loadPositions();
        PublishSubscriptionWhitelist([LiveDataNamespaces.OptionContractScreen]);
        dispatch(GetClientAccountsAction());
        dispatch(GetSecurityMetadataAction(symbol));
        const run = async () => {
            const searchFilters = { symbol, take: 1000, tradeableSecurityType: ConvertTradeableSecurityTypeToApiTradeableSecurityType(assetClass.type) };
            await searchOrders(searchFilters);
        };
        run();
        dispatch(GetSecurityQuoteAction(symbol));
    }, [assetClass.type, dispatch, loadPositions, searchOrders, symbol]);

    useEffect(() => {
        let ticket: string;
        let uTicket: string;
        const run = async () => {
            ticket = await xdispatch(XS.OptionQuotes.start([symbol], LiveDataNamespaces.OptionContractScreen));
            if (!isFuture) uTicket = await xdispatch(XS.Quotes.start(optSym.underlyingSymbol, LiveDataNamespaces.OptionContractScreen));
        };
        run();

        return () => {
            XS.stop(ticket);
            XS.stop(uTicket);
        };
    }, [dispatch, isFuture, occSym, optSym.underlyingSymbol, symbol, xdispatch]);

    useEffect(() => {
        if (isTicketInitialized) return;

        const defaultProps = getDefaultOrderProps({
            assetClass,
            marketTimeSegment,
            quote,
            ...(meta?.isAdjusted ? { tradeAction: TradeActions.Sell } : {}) // Only allow selling adjusted options
        });

        if (initialAction) defaultProps.tradeAction = initialAction as TradeAction;

        setViewModel({
            ...defaultProps,
            state: 'input',
            symbol,
            variant: 'option-individual',
            ...(meta?.isAdjusted ? { tradeAction: TradeActions.Sell } : {}) // Only allow selling adjusted options
        });

        // TODO: quote should not need to be stringified, but due to some design flaws in XS.OptionsQuotes,
        // adding quote to deps can cause infinite loops. Re-engineer the manner in which fallbacks are assigned
        // such that the selector does not return a new object/force extra rerenders, and then replace JSON with the quote object

        if (quote) setIsTicketInitialized(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps -- See above
    }, [assetClass, initialAction, marketTimeSegment, meta, JSON.stringify(quote), setViewModel, symbol]);

    useEffect(() => {
        setIsTicketInitialized(false);
    }, [assetClass, initialAction, marketTimeSegment, meta, setViewModel, symbol]);

    useEffect(() => {
        window.scrollTo({ top: 0 });

        return () => XS.stopNs(LiveDataNamespaces.OptionContractScreen);
    }, []);

    if (hasOptionsAccounts === false) return <EnableOptionsScreen />;
    else if (hasOptionsAccounts !== true) {
        return (
            <BaseScreenLayout hideGlance>
                <Flex center column style={{ height: '80vh' }}>
                    <LoadingSpinner />
                </Flex>
            </BaseScreenLayout>
        );
    }

    return (
        <BaseScreenLayout
            hideGlance
            noCard
            sidebar={
                <div className={`trade-sidebar ${assetClass.family}`}>
                    <TradeTicket />
                    {meta && !meta?.isAdjusted ? (
                        <>
                            <div className='trade-links'>
                                {assetClass.family === 'equities' && position && (
                                    <Link onClick={() => setShowMultiLeg(true)} component={ReactLink} to={Routes.optionChain(underlying, rollPositionParams)}>
                                        {positionsText.rollPosition}
                                    </Link>
                                )}
                                <Link component={ReactLink} to={Routes.security(underlying)}>
                                    {text.tradeUnderlying(linkText)}
                                </Link>
                            </div>
                            <BigTextLink color={color} route={Routes.optionChain(underlying, queryParams)}>
                                {text.viewAllOptions}
                            </BigTextLink>
                        </>
                    ) : (
                        <br />
                    )}
                    <SecurityHoldings symbol={symbol} />
                </div>
            }
        >
            <Flex column style={{ marginTop: 20, minHeight: TableViewMinHeight(200) }}>
                <OptionContractHeaderSection symbol={symbol} onAddToListClick={() => setAddToListOpen(true)} />
                <OptionChartSection symbol={symbol} />
                <OpenOrders securityKnown label={text.history} status='All' symbol={symbol} unexpandedOrders={!isFuture ? 1 : undefined} />
                <StatsComponent symbol={symbol} />
            </Flex>
            <ActionModal closerStyle='done-button' isOpen={addToListOpen} label={'addToList'} toggleModal={() => setAddToListOpen(false)}>
                <AddToListModal symbol={symbol} />
            </ActionModal>
            <DisclosureFooter earningsAndRatingsData featuresAndBalances ordersAndActivity securityPricing clientExtras />
        </BaseScreenLayout>
    );
});
