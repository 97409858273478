// @ts-strict-ignore
import React, { useMemo, useState } from 'react';
import { Flex } from '../..';
import { useColors } from 'hooks/UseColors';
import { AlertRule } from 'phoenix/redux/models';
import { AnimatedModal } from '../AnimatedModal';
import { AlertsListView } from './AlertsListView';
import { CreateAlertView } from './CreateAlertView';
import { EditAlertView } from './EditAlertView';
import AlertsDisabledView from './AlertsDisabledView';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'util/Routes';

export type AlertsPage = 'loading' | 'list' | 'view' | 'create' | 'disabled' | 'edit';
export interface AlertsProps {
    symbol?: string;
    isOpen?: boolean;
    toggleModal?: (open: boolean) => any;
}

const AlertsContent = React.memo((props: AlertsProps) => {
    const colors = useColors();
    const [page, setPage] = useState<AlertsPage>(props.symbol ? 'create' : 'list');
    const [alert, setAlert] = useState<Partial<AlertRule>>(null);
    const navigate = useNavigate();

    const handleNavigation = (view: AlertsPage, alert?: Partial<AlertRule>) => {
        setPage(view);
        setAlert(alert || null);
    };

    const content = useMemo(() => {
        switch (page) {
            case 'loading':
                return null;
            case 'list':
                return <AlertsListView navigate={handleNavigation} />;
            case 'view':
                props.toggleModal(false);
                return navigate(Routes.security(alert.symbol));
            case 'create':
                return <CreateAlertView alert={alert} navigate={handleNavigation} symbol={props.symbol} />;
            case 'edit':
                return <EditAlertView alert={alert} navigate={handleNavigation} />;
            case 'disabled':
                return <AlertsDisabledView toggleModal={() => props.toggleModal(false)} />;
        }
    }, [page]);

    const contentHeight = useMemo(() => {
        switch (page) {
            case 'disabled':
                return 200;
            default:
                return 500;
        }
    }, [page]);

    return (
        <Flex style={{ width: 500, height: contentHeight, maxHeight: '80vh', background: colors.cardBackgroundColor, borderRadius: 10, overflow: 'hidden' }}>
            {content}
        </Flex>
    );
});

export const AlertsModal = React.memo((props: AlertsProps) => {
    return (
        <AnimatedModal open={props.isOpen} onClose={() => props.toggleModal(false)}>
            <AlertsContent {...props} />
        </AnimatedModal>
    );
});
