// @ts-strict-ignore
import React, { forwardRef, useMemo } from 'react';

type FlexProps = {
    children?: any;
    fullWidth?: boolean;
    style?: React.CSSProperties;
    column?: boolean;
    role?: string;
    row?: boolean;
    reverse?: boolean;
    id?: string;
    wrap?: boolean;
    inline?: boolean;
    justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | string;
    align?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | string;
    center?: boolean;
    className?: string;
    onClick?: (e?: any) => any;
    onContextMenu?: (e?: any) => any;
    onFocus?: (e?: any) => any;
    onBlur?: (e?: any) => any;
    onKeyDown?: (e?: any) => any;
    onMouseEnter?: (e?: any) => any;
    onMouseLeave?: (e?: any) => any;
    tabIndex?: number;
};
const FlexComponent = (props, ref) => {
    const flexDirection: any = useMemo(() => {
        const direction = props.column ? 'column' : 'row';
        return props.reverse ? `${direction}-reverse` : direction;
    }, [props.column, props.row, props.reverse]);

    return (
        <div
            className={props.className}
            id={props.id}
            ref={ref}
            style={{
                width: props.fullWidth ? '100%' : null,
                ...props.style,
                display: props.style?.display || props.inline ? 'inline-flex' : 'flex',
                cursor: props.onClick ? 'pointer' : undefined,
                flexDirection,
                justifyContent: (props.center ? 'center' : undefined) || props.justify || undefined,
                flexWrap: props.wrap ? 'wrap' : 'nowrap',
                alignItems: (props.center ? 'center' : undefined) || props.align || undefined,
            }}
            onBlur={props.onBlur}
            onClick={props.onClick}
            onContextMenu={props.onContextMenu}
            onKeyDown={(e) => (props.onKeyDown ? props.onKeyDown(e) : e.key === 'Enter' && props.onClick && props.onClick(e))}
            onFocus={props.onFocus}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            role={props?.role || 'presentation'}
            tabIndex={ typeof props.tabIndex === 'number' ? props.tabIndex : (props.role === 'button' ? 0 : undefined)}
        >
            {props.children}
        </div>
    );
};

export const Flex = forwardRef<HTMLDivElement, FlexProps>(FlexComponent);
