import { Brightness5, PriorityHigh, TimerOffOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useSelectedAccountByAssetFamily, useSelectedAccountByComponent } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { DropdownComponent } from 'components/DropdownComponent';
import { InputSplit } from 'components/InputSplit/InputSplit';
import { AlgoStrat, GetCanChangeTimeInForce } from 'phoenix/constants/AssetTradeability';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useText } from 'phoenix/hooks/UseText';
import { TifOption, useTimeInForceValues } from 'phoenix/hooks/useTimeInForceValues';
import { ApiOrderType, ApiTimeInForce, ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import React, { ComponentType, ReactElement } from 'react';
import { Sunrise } from 'react-feather';
import { CloudyNightOutline } from 'react-ionicons';
import { useLocation } from 'react-router-dom';
import { components as ReactSelectComponents, GroupTypeBase, OptionProps, OptionTypeBase } from 'react-select';
import { TradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';

const iconMap = {
    Day: Brightness5,
    GTC: TimerOffOutlined,
    GTXPre: Sunrise,
    GTXPost: CloudyNightOutline,
    NTE: CloudyNightOutline,
    FOK: PriorityHigh
};

export const TimeInForceOption: ComponentType<OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>> = ({
    data,
    ...rest // These are react-select component props that get passed down from the parent
}: OptionProps<OptionTypeBase, boolean>) => {
    const { disabled, label, selected, sublabel, value }: TifOption = data;
    const Icon = iconMap?.[value];
    return (
        <ReactSelectComponents.Option {...(rest as OptionProps<OptionTypeBase, boolean>)}>
            <button className={`tif-option${selected ? ' selected' : ''}`} disabled={disabled}>
                <div className='icon'>
                    <Icon />
                </div>
                <div>
                    <Typography variant='h5'>{label}</Typography>
                    <Typography variant='body2'>{sublabel}</Typography>
                </div>
            </button>
        </ReactSelectComponents.Option>
    );
};

export function TimeInForceSelect({ onValueChange }: { onValueChange: (value: ApiTimeInForce) => void }): ReactElement {
    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();
    const assetClass = useAssetClass(viewModel?.symbol || '');
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const {
        algoStrategy = '',
        modifyingOrder = false,
        orderType = assetClass?.defaultOrderType ? assetClass?.defaultOrderType(marketTimeSegment) : ('market' as ApiOrderType),
        quantity,
        symbol,
        timeInForce,
        tradeAction
    } = viewModel;
    const { pathname } = useLocation();
    const [selectedAccountByComponent] = useSelectedAccountByComponent('positionsScreenTradeTicket');
    const selectedAccountByAssetFamily = useSelectedAccountByAssetFamily(assetClass?.family);
    const accountNumber = (pathname === '/positions' ? selectedAccountByComponent : selectedAccountByAssetFamily) || '';
    const allText = useText((t) => t);
    const tifValues = useTimeInForceValues({
        action: tradeAction as ApiTradeAction,
        accountNumber,
        modifyingOrder: viewModel?.modifyingOrder,
        orderType,
        quantity,
        securityId: symbol,
        strategy: algoStrategy as AlgoStrat,
        timeInForce
    });

    const disabled = !GetCanChangeTimeInForce({ modifyingOrder, orderType, timeInForce: timeInForce as ApiTimeInForce, tProfile: assetClass.tradeability });

    return (
        <InputSplit className={`time-in-force-select${disabled ? ' disabled' : ''}`} label={allText.tradeTicket.input.timeInForce}>
            <DropdownComponent
                disabled={disabled}
                options={tifValues?.options}
                optionComponent={TimeInForceOption}
                value={timeInForce}
                onValueChange={(value) => onValueChange(value as ApiTimeInForce)}
            />
        </InputSplit>
    );
}
