import { AssetFamily, AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { create } from 'zustand';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { getAccountAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { pullValuesFromUrl } from 'hooks/UseQueryParams';
import { QualifiedId } from 'phoenix/util/QualifiedId';

type AccountSelectByComponent = 'positionsScreenTradeTicket';

export const GetAssetClassFromAccountNumber = (accountNumber: string): AssetClass => {
    const c = QualifiedId.Class(accountNumber);
    switch (c) {
        case 'crypto':
            return CryptosAssetClass;
        case 'futures':
            return FuturesAssetClass;
        case 'equities':
        default:
            return EquitiesAssetClass;
    }
};

type AccountSelectionStoreProps = {
    selectedAccount?: string;
    selectedAccountByAssetClass: { [assetClass in AssetFamily]: string | null };
    selectedAccountByComponent: { [key in AccountSelectByComponent]: string | null };
};

type AccountSelectionStoreMethods = {
    getSelectedAccountByAssetClass: (assetClass: AssetClass) => string | null;
    setSelectedAccountByAssetClass: (assetClass: AssetClass, accountNumber: string) => void;
    getSelectedAccountByComponent: (component: AccountSelectByComponent) => string | null;
    setSelectedAccountByComponent: ({ accountNumber, component }: { accountNumber?: string; component: AccountSelectByComponent }) => void;
    setSelectedAccount: (accountNumber: string) => void;
};

type AccountSelectionStore = AccountSelectionStoreProps & AccountSelectionStoreMethods;

const UseAccountSelectionStore = create<AccountSelectionStore>((set, get) => {
    const { selectedAccount } = pullValuesFromUrl();
    const assetFamily = GetAssetClassFromAccountNumber(selectedAccount).family;
    const defaults: AccountSelectionStoreProps = {
        ...(selectedAccount ? { selectedAccount } : {}),
        selectedAccountByAssetClass: {
            futures: null,
            equities: null,
            cryptos: null
        },
        selectedAccountByComponent: {
            positionsScreenTradeTicket: null
        }
    };

    if (selectedAccount) defaults.selectedAccountByAssetClass[assetFamily] = selectedAccount;

    return {
        ...defaults,
        setSelectedAccount: (accountNumber?: string) => {
            set((s) => ({ ...s, selectedAccount: accountNumber }));
        },
        getSelectedAccountByAssetClass: (assetClass: AssetClass) => get().selectedAccountByAssetClass[assetClass.family],
        setSelectedAccountByAssetClass: (assetClass: AssetClass, accountNumber?: string) =>
            set((s) => ({ ...s, selectedAccountByAssetClass: { ...s.selectedAccountByAssetClass, [assetClass.family]: accountNumber } })),
        getSelectedAccountByComponent: (component: AccountSelectByComponent) => get().selectedAccountByComponent[component],
        setSelectedAccountByComponent: ({ accountNumber, component }: { accountNumber?: string; component: AccountSelectByComponent }) => {
            const varAccNum = accountNumber || null;
            set((s) => ({ ...s, selectedAccountByComponent: { ...s.selectedAccountByComponent, [component]: varAccNum } }));
        }
    };
});

const assetFamilyToAssetClass = (af: AssetFamily): AssetClass => {
    switch (af) {
        case 'cryptos':
            return CryptosAssetClass;
        case 'futures':
            return FuturesAssetClass;
        case 'equities':
        default:
            return EquitiesAssetClass;
    }
};

export const useSelectedAccountByAssetFamily = (assetFamily: AssetFamily): string | null =>
    UseAccountSelectionStore((s) => s).getSelectedAccountByAssetClass(assetFamilyToAssetClass(assetFamily));

export const UseSelectedAccount = (): [string | undefined, (accountNumber: string) => void, (assetClass: AssetClass) => string | null] => {
    const store = UseAccountSelectionStore;
    return [store((s) => s).selectedAccount, SetSelectedAccount, GetSelectedAccountByAssetClass];
};

export const useSelectedAccountByComponent = (component: AccountSelectByComponent): [string | null, (accountNumber?: string) => void] => {
    const store = UseAccountSelectionStore((s) => s);
    return [store.selectedAccountByComponent[component], (accountNumber?: string) => store.setSelectedAccountByComponent({ accountNumber, component })];
};

export const GetSelectedAccount = (): string | undefined => UseAccountSelectionStore.getState().selectedAccount;
export const SetSelectedAccount = (accountNumber: string): void => {
    const store = UseAccountSelectionStore;

    const prevState = store.getState();
    const selectedAccountByAssetClass =
        accountNumber === 'all'
            ? prevState.selectedAccountByAssetClass
            : { ...prevState.selectedAccountByAssetClass, [getAccountAssetClass(accountNumber).family]: accountNumber };

    const newState = {
        ...prevState,
        selectedAccount: accountNumber,
        selectedAccountByAssetClass
    };
    store.setState(newState);
};

export const GetSelectedAccountByAssetClass = (assetClass: AssetClass): string | null => UseAccountSelectionStore.getState()?.getSelectedAccountByAssetClass(assetClass);

export const GetSelectedAccountNonReactive = (): string | undefined => UseAccountSelectionStore.getState()?.selectedAccount;

// Selected account for an order can be isolated from the globally selected account in particular cases (currently only on the positions screen)
export const useSelectedAccountForOrderRequest = (assetClass: AssetClass): string | null => {
    const store = UseAccountSelectionStore((s) => s);
    return location.pathname === '/positions' ? store?.selectedAccountByComponent.positionsScreenTradeTicket : GetSelectedAccountByAssetClass(assetClass);
};

export const GetSelectedAccountForOrderRequest = (assetClass: AssetClass): string | null => {
    return location.pathname === '/positions'
        ? UseAccountSelectionStore.getState()?.selectedAccountByComponent.positionsScreenTradeTicket
        : GetSelectedAccountByAssetClass(assetClass);
};
