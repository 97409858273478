// @ts-strict-ignore
import { getWeekOfMonth, isSameYear } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';
import { ShadedTextUtils } from 'phoenix/assets/lang/ShadedTextUtils';
import { AppliedAppTheme } from 'phoenix/constants/AppliedAppTheme';
import { PremiumProducts } from 'phoenix/constants/PremiumProducts';
import { GetPriceFormatInfo } from 'phoenix/hooks/UsePriceFormatInfo';
import { ApiTradeRequest, ApiTradeStrategy } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { TransferRequest } from 'phoenix/models/TransferRequest';
import { CryptoSymbol } from 'phoenix/redux/models/Crypto/CryptoSymbol';
import { FundingLimits } from 'phoenix/redux/models/Funding/FundingLimits';
import { FundingSource } from 'phoenix/redux/models/Funding/FundingSource';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { Environment } from '../../constants';
import { IndustryGroups } from '../../constants/IndustryGroups';
import { SecurityTypes } from '../../constants/SecurityTypes';
import { OrderTypes } from '../../constants/Trade';
import { Account } from '../../redux/models/Accounts/Account';
import { SnexDocument } from '../../redux/models/Documents/SnexDocument';
import { FundingManagementLimits } from '../../redux/models/Funding/FundingManagementLimits';
import { FuturesSymbol } from '../../redux/models/Futures/FuturesSymbol';
import { OptionSymbol } from '../../redux/models/Options/OptionSymbol';
import { Order } from '../../redux/models/Orders/Order';
import { OptionsPutCallAbbreviation, OrderType, TradeableSecurityType, TradeRequest } from '../../redux/models/Trading/TradeRequest';
import { Transaction } from '../../redux/models/Transactions/Transaction';
import { SpokenJoin } from '../../util/ArrayMutations';
import { SafeFormat, SafeFormatLocale, SafeFormatToNow } from '../../util/DateFormatting';
import { DetermineOrderType } from '../../util/DetermineOrderType';
import { FormatNumber } from '../../util/FormatNumber';
import { DecodeSecurityMasterType } from '../../util/IsMutualFund';
import { QualifiedId } from '../../util/QualifiedId';
import { disclosures, proDisclosures } from './disclosures';
import { ActionSummaryProps, getActionSummary, getOffshoreMutualFundStatInfo } from './helpers';
import { Dark, Light, ShadedTextList, Snex1LanguagePack } from './Snex1LanguagePack';

const DelayedParagraph = [{ text: 'Update frequency: 15-min delay' }];

const nums = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

export const Snex1English: Snex1LanguagePack = {
    funding: {
        delete: {
            title: 'Delete Account',
            message: 'Are you sure you want to remove this account?',
            submitButton: 'Remove Account',
            cancelButton: 'No, Keep Account'
        }
    },
    errorBoundary: {
        title: 'Whoops!',
        sorryMessage: 'Sorry! Looks like something went wrong.',
        oopsSomethingWentWrong: 'Oops, something went wrong.',
        tryAgain: 'Try again',
        contactSupport: 'Contact Support',
        onboardingErrorOccurred: 'Error Occurred',
        onboardingTryAgainOrContactSupport: 'Please try again, or contact Support if the issue persists'
    },
    welcome: {
        skipTour: 'Skip Tour',
        showMeAround: 'Show Me Around!',
        pickLang: 'Pick the language you are most comfortable with',
        successText: 'Your account was created successfully!',
        loadingText: 'Checking the status of your account...',
        errorText: `There was a problem locating your account. It may take a few minutes for your account to initialize. If it is unavailable after 10 minutes, please reach out to our support at`,
        noAccountsText:
            "It looks like your account isn't quite ready yet. It may take a few minutes for your account to initialize. If it is unavailable after 10 minutes, please reach out to our support at"
    },
    webTos: {
        title: 'Accept Terms and Conditions',
        url: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX_Client_TC.pdf',
        linkText: 'View Terms and Conditions',
        agreeButtonLabel: 'I Accept'
    },
    general: {
        at: 'at',
        around: 'around',
        above: 'above',
        below: 'below',
        to: 'to',
        ai: 'AI',
        languageNameEnglish: 'English',
        languageNameLocal: 'English',
        dateFnsLocale: enUS,
        localeShort: 'en',
        localeLong: 'en-US',
        submit: 'Submit',
        continue: 'Continue',
        acceptRateAndSubmit: 'Accept Rate and Submit',
        acceptTermsAndSubmit: 'Accept Terms and Submit',
        submitting: 'Submitting...',
        loading: 'Loading',
        cancel: 'Cancel',
        news: (showMI?: boolean) => `News${showMI ? ' and Market Intelligence' : ''}`,
        writtenInteger0Thru10: (n: number) => (n >= 0 && n <= 10 ? nums[n] : FormatNumber.toCommas(n)),
        unavailable: 'Unavailable',
        open: 'Open',
        done: 'Done',
        none: 'None',
        dismiss: 'Dismiss',
        confirmAndContinue: 'Confirm and Continue',
        contactSupport: 'Contact Support',
        search: 'Search',
        trade: 'Trade',
        dateUnknown: 'Date Unknown',
        unknown: 'Unknown',
        unableToAuthenticate: 'Sorry, we were unable to authenticate you',
        browseDataUnavailable: 'Sorry, browse data is currently unavailable.',
        yesLeave: 'Yes, Leave',
        noStayHere: 'No, Stay Here',
        returningToStonex: 'Returning to StoneX One',
        aboutToReturnToStonex: 'You are about to return to StoneX One. Are you sure?',
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        quantity: 'Quantity',
        strikes: 'Strikes',
        shares: (n = 0) => (Math.abs(n) === 1 ? 'Share' : 'Shares'),
        fundShares: (n = 0) => (Math.abs(n) === 1 ? 'Share' : 'Shares'),
        contracts: (n = 0) => (Math.abs(n) === 1 ? 'Contract' : 'Contracts'),
        bonds: (n = 0) => (Math.abs(n) === 1 ? 'Bond' : 'Bonds'),
        coins: (n = 0) => (Math.abs(n) === 1 ? 'Coin' : 'Coins'),
        unit: (symbol: string, quantity = 0, typeHint: string = null) => {
            if (FuturesSymbol.IsFuturesSymbol(symbol)) return Snex1English.general.contracts(quantity);
            if (OptionSymbol.IsOptionSymbol(symbol) || DecodeSecurityMasterType(typeHint) === 'option') return Snex1English.general.contracts(quantity);
            if (CryptoSymbol.IsCryptoSymbol(symbol)) return Snex1English.general.coins(quantity);
            const torchType = typeHint;
            if (torchType === 'Fixed Income' || torchType === 'Debt') return Snex1English.general.bonds(quantity);
            return Snex1English.general.shares(quantity);
        },

        symbolName: (
            symbol: string,
            optionVariant: 'extra-short' | 'short' | 'long' | 'no-exp' | 'symbol-putcall' = 'short',
            quantity = 1,
            secMeta: SecurityMetadata = null
        ): string => {
            const optSym = new OptionSymbol(symbol);

            if (optSym.isOption) {
                const { underlyingSymbol, expDate } = optSym;
                const strike = secMeta?.strikePrice || optSym.strike;
                const term = getWeekOfMonth(expDate, { weekStartsOn: 6 }) !== 3 ? 'weekly' : 'monthly';
                const putness = Snex1English.general.options.putCallWord(optSym.putCall);
                const strikeFormat = secMeta ? GetPriceFormatInfo(symbol, secMeta.tickSize) : undefined;
                const strikeStr = FormatNumber.toMoneyOpt2(strike, strikeFormat?.moneyFormatOptions);
                const { exp } = Snex1English.general.options;
                const locale = Snex1English.general.dateFnsLocale;

                switch (optionVariant) {
                    case 'long':
                        return `${underlyingSymbol} ${SafeFormatLocale({ date: expDate, formatString: 'dd MMMM yyyy', locale })} ${term} ${strikeStr} ${putness}${
                            quantity === 1 ? '' : 's'
                        }`;
                    case 'no-exp':
                        return `${underlyingSymbol} ${strikeStr} ${putness}${quantity === 1 ? '' : 's'}`;
                    case 'symbol-putcall':
                        return `${optSym.underlyingSymbol} ${putness}`;
                    case 'extra-short': {
                        const dateFmt = isSameYear(expDate, new Date()) ? 'MM/dd' : 'MM/dd/yy';
                        return `${optSym.underlyingSymbol} ${strikeStr}${optSym.putCall} ${SafeFormatLocale({ date: expDate, formatString: dateFmt, locale })}`;
                    }
                    case 'short':
                    default:
                        return `${underlyingSymbol} ${strikeStr} ${putness}, ${exp} ${SafeFormatLocale({ date: expDate, formatString: 'MM/dd/yyyy', locale })}`;
                }
            }

            return QualifiedId.RemovePrefix(symbol);
        },
        spokenJoin: (items: string[]) => SpokenJoin(items, 'and'),
        isShort: 'This is a short position',
        buyingPower: 'Buying Power',
        availableBalance: 'Available Balance',
        balance: 'Balance',
        pendingApproval: 'Pending Approval',
        pending: 'Pending',
        maintenanceCall: 'Maintenance Call',
        maintenanceCallForAccounts: 'Maintenance Call for one or more accounts',
        maintenanceCallTooltip:
            'A margin call occurs when the value of securities in a brokerage account falls below a certain level, known as the maintenance margin, requiring the account holder to deposit additional cash or securities to meet the margin requirements.',
        fedCallTooltip:
            'When an investor purchases stocks and does not have enough equity in the account to meet the 50% equity requirement, a fed margin call is triggered.',
        dayTradingFlag: 'This account has been flagged for day trading.',
        fplEligible: 'This account is eligible to enroll in Fully Paid Lending',
        fplEnabled: 'This account is enrolled in Fully Paid Lending',
        ninetyDayRestriction: {
            description: 'A customer may be placed on 90-day restriction for the below reasons:',
            forAccounts: '90-Day Restriction on one or more accounts',
            list: [
                'Nonpayment of a REG T obligation',
                'Any activity other than cash deposit while on extension (liquidating, cancelling, etc)',
                'Free riding',
                'Three good faith violations in a rolling 12-month period',
                'Nonpayment of a Day Trade Call'
            ],
            secondParagraph:
                'While on 90-day restriction a customer is restricted to opening transactions with settled funds only. A customer may be blocked from opening transactions when there are insufficient settled funds. Trades that exceed settled funds may also be subject to cancelation.',
            title: '90-Day Restriction',
            withDate: (date) => `90-day Restriction${date ? ` expires ${SafeFormat(date, 'MM/dd/yyyy')}` : ''}`
        },
        options: {
            put: 'Put',
            call: 'Call',
            exp: 'Exp',
            putCallWord: (putCall: OptionsPutCallAbbreviation) => {
                switch (putCall) {
                    case 'C' as OptionsPutCallAbbreviation:
                        return Snex1English.general.options.call;
                    case 'P' as OptionsPutCallAbbreviation:
                        return Snex1English.general.options.put;
                    default:
                        return Snex1English.general.unknown;
                }
            },
            adjustedOptionsSubtext: (deliverableCount: number) => {
                return `${deliverableCount} shares per Contract`;
            }
        },
        productTypes: {
            'offshore-mutual-fund': { singular: 'mutual fund', plural: 'mutual funds' },
            'mutual-fund': { singular: 'mutual fund', plural: 'mutual funds' },
            adr: { singular: 'ADR', plural: 'ADRs' },
            crypto: { singular: 'cryptocurrency', plural: 'cryptocurrencies' },
            equity: { singular: 'equity', plural: 'equities' },
            etf: { singular: 'ETF', plural: 'ETFs' },
            future: { singular: 'future', plural: 'futures' },
            option: { singular: 'option', plural: 'options', upperSingular: 'Option' },
            unknown: { singular: 'security', plural: 'securities' }
        },
        assetClassLabels: {
            equities: 'Stocks',
            futures: 'Futures',
            cryptos: 'Cryptos'
        },
        sorrySomethingWentWrong: 'Sorry, something went wrong',
        notApplicable: 'N/A',
        learnMore: 'Learn More',
        expiresSoon: 'Exp. Soon',
        expiresToday: 'Exp. Today',
        expiresTomorrow: 'Exp. Tomorrow',
        daily: 'Daily',
        oneTime: 'One-Time',
        repeatDaily: 'Repeat Daily',
        numberBought: '# Bought',
        numberSold: '# Sold',
        avgBuyPrice: 'Average Buy Price',
        avgSellPrice: 'Average Sell Price',
        topDayActivity: 'Top Day Activity',
        ascending: 'Ascending',
        descending: 'Descending',
        delayedPricing: 'Delayed pricing data shown.',
        delayedPricingTitle: 'Delayed Pricing'
    },
    webRegistration: {
        verifySMSCodeTitle: 'Verify with your phone',
        enterCodeAndLogin: "Enter your code below and let's get started!",
        verifySMSCodeSubtitle: (phone: string) => `Send a code via SMS to ${phone}`,
        messageChargesAlert: 'Carrier messaging charges may apply',
        sendingSMSCode: 'Validating code',
        invalidSMSCode: 'Invalid SMS verification code',
        resentSMSCode: 'Resent SMS verification code',
        getSMSCode: 'Receive a code via SMS',
        submitSMSCode: 'Submit code',
        alternateMFA: 'Choose a different MFA method',
        welcome: 'Welcome!',
        getAnAccount: "Let's get you a StoneX One account",
        getUserInfo: "Let's get your name and email",
        nextButton: 'Next',
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: 'Mobile Phone Number',
        verifyEmail: 'Verify Email',
        emailVerified: 'Email Verified!',
        email: 'Email',
        confirmEmail: 'Confirm Email',
        userExists: (email: string) => `User with email ${email || ''} already exists.`,
        personalInfo: 'Personal Info',
        usCitizenQuestion: 'Are you a US citizen?',
        yes: 'Yes',
        no: 'No',
        usCitizenAnswer: 'I am a US citizen',
        nonUSCitizenAnswer: 'I am not a US citizen',
        usCitizenAlert: 'Currently you must be a US citizen to open an account',
        createPassword: "Let's create a password",
        createUser: 'Create User',
        passwordRequirement: 'Password needs to be 14 characters or more',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        verifyYourEmail: "Let's verify your email",
        sendingVerificationCode: 'We are sending a verification code to the email provided earlier.',
        enterInBoxBelow: 'Please enter it in the box below.',
        invalidVerificationCode: 'Invalid Verification Code',
        resentVerificationCode: 'Resent the Verification Code',
        verificationCode: 'Verification Code',
        submit: 'Submit',
        resendCode: 'Resend Code',
        completeTitle: 'Registration Complete',
        completeSubtitle: 'Click the button below and login to StoneX One',
        login: 'Login',
        alreadyExists: 'Existing User Found',
        wouldYouLikeToLogin: 'Would you like to login to StoneX One?',
        steps: {
            welcome: 'Welcome',
            userInfo: 'User Info',
            personalInfo: 'Personal Info',
            setupPassword: 'Setup Password',
            verifyCreate: 'Verify Email',
            enrollMFA: 'Enroll MFA',
            login: 'Login'
        },
        errors: {
            required: 'Field is required',
            userInfo: {
                invalidPhone: 'Invalid phone number.',
                invalidEmail: 'Invalid email',
                emailDoesNotMatch: 'Email entries do not match',
                emailExists: 'Email already exists'
            },
            password: {
                passwordsDoNotMatch: 'Password entries do not match',
                invalidPassword: 'Passwords must be at least 14 characters'
            }
        }
    },
    assetClass: {
        futures: 'Futures',
        cryptocurrency: 'Cryptocurrency',
        crypto: 'Crypto',
        futuresOptions: 'Futures Options',
        future: 'Future',
        equitiesOption: 'Equities Option',
        otcOption: 'OTC Option',
        etf: 'ETF',
        mutualFund: 'Mutual Fund',
        oilGasReit: 'Oil & Gas REIT',
        escrowReceiptsLetterGuaranty: 'Escrow Receipt / Letter of Guarantee',
        treasury: 'Treasury',
        municipleBond: 'Municiple Bond',
        corporateUit: 'Corporate UIT',
        municipalUit: 'Municipal UIT',
        unit: 'Unit',
        preciousMetals: 'Previous Metal',
        commonStock: 'Common Stock',
        annuity: 'Annuity',
        miscellaneous: 'Miscellaneous',
        offShoreMutualFund: 'Offshore Mutual Fund',
        brokerage: 'Stocks'
    },
    appReview: {
        yes: 'Yes',
        no: 'No',
        enjoyingStoneX: 'Are you enjoying StoneX One?',
        rateApp: 'Would you like to rate the app?',
        later: 'Later',
        noThanks: 'No, Thanks',
        feedback: 'Thank you for your feedback. Would you like to talk to chat support?'
    },
    connectionStatus: {
        title: 'No Connection',
        message: "Looks like you're not connected to the Internet.",
        retryConnection: 'Retry Connection'
    },
    premiumModal: {
        addTheseTools: 'Add these tools to your arsenal:',
        ready: 'Ready to gain that extra edge?',
        unlock: 'Unlock Analysis like no other.',
        upgradeToday: 'Upgrade to Premium Today',
        deepAnalysis: {
            title: 'Access to Deep Analysis',
            content: 'Navigate the markets with real-time market data and exclusive company insight combined into a singular comprehensive view.'
        },
        multiCharts: {
            title: 'Multiple Charts, One Screen',
            content: 'Perform technical analysis on multiple charts all from a single view. Analyze and compare patterns for varying securities all from one view.'
        },
        streamingNews: {
            title: 'Streaming News',
            content: 'Move when the market moves with streaming news. New articles will appear at the top of your news streams.'
        },
        alerts: {
            title: 'Price Alerts',
            content: 'Keep tabs on securities most important to you with custom alerts. Never miss out on an opportunity.',
            isAbove: 'is above',
            isAboveOrEqual: 'is above or equal to',
            isBelow: 'is below',
            isBelowOrEqual: 'is below or equal to'
        },
        miPremium: {
            title: 'Market Intelligence',
            content:
                'News, data and commentary on all our markets with direct perspectives from brokers and analysts. Includes actionable insights to keep you a step ahead!'
        }
    },

    marketTimeBadge: {
        open: 'U.S. Equity Market is Open',
        premarket: 'U.S. Equity Pre-Market Hours',
        holiday: 'Trading Holiday',
        postmarket: 'U.S. Equity Post-Market Hours',
        closed: 'U.S. Equity Market is Closed',
        loading: 'Loading'
    },

    blankViews: {
        holdingsTable: {
            main: 'No positions found',
            secondary: 'Try changing your filters',
            notFundedMain: 'Welcome to the positions screen',
            notFundedSecondary: "Once you get your account funded, you'll find your positions and balances here",
            notFundedAction: 'Fund Account'
        },
        documents: {
            main: (documentType: string) => `You currently have no ${documentType}`,
            secondary: (documentType: string) => `Future ${documentType} will be found here once they become available`
        },
        transactions: {
            main: "Looks like you don't have any transactions",
            secondary: 'Try some other filters or check back later'
        },
        projectedIncome: {
            main: 'No projected income found for this account',
            secondary: 'Try another account or check back later'
        },
        tradeHistory: {
            main: "Looks like you don't have any recorded trades that match your search criteria",
            secondary: 'Search for a security and place a trade to see it here'
        },
        messages: {
            main: 'No messages found',
            secondary: "You'll find any messages you've received here. Check back later for anything new!"
        }
    },
    premiumScreen: {
        createAlert: (symbol: string) => `Create an Alert for ${symbol}`,
        deepAnalysisAlt: 'Preview of deep analysis screen',
        returnToSummary: 'Return to Summary',
        subHeader: 'You just upped your game. Use the buttons below to check out your new superpowers.',
        viewCharts: (symbol: string, symbol2: string) => `Compare ${symbol} and ${symbol2}`,
        viewChartsAlt: 'Preview of multiple charts',
        viewDeepAnalysis: (symbol: string) => `View ${symbol} Deep Analysis`,
        viewMi: (symbol: string) => `View Market Intelligence content for ${symbol} now!`,
        viewMiAlt: 'Imagery of futures commodities',
        welcomeHeader: (name: string) => `Welcome to ${name}!`,
        checkout: {
            title: 'Checkout',
            changePlan: 'Change Plan',
            nextBilled: (date: Date) => `You will next be billed on ${format(date, 'MMM dd, yyyy')}`,
            cancelAnytime: 'Cancel anytime',
            offerTerms: 'Offer terms apply',
            details: {
                title: 'Important',
                body: (productName: string) => `Payments for ${productName} are taken automatically through your StoneX account.`,
                multipleAccounts:
                    " Since you have multiple accounts, you'll need to tell us which account you'd like to use. Please do so below (you can always change this later):"
            },
            selectAccount: (hasMultipleAccounts?: boolean) => (hasMultipleAccounts ? 'Select a brokerage account to debit' : 'Brokerage account to debit'),
            buttonText: 'Start Subscription'
        },
        selectProduct: {
            goPremium: 'Go Premium',
            or: 'Or',
            pickYourPackage: 'Pick Your Package',
            perMonth: 'per month',
            comingSoon: 'Coming Soon!',
            comingSoonToStonexOne: 'Coming soon to StoneX One:',
            select: 'Select',
            bestValue: 'Best Value!',
            jumbotron: {
                ourBestDeal: 'Our Best Deal!',
                title: [
                    // [
                    // { text: 'StoneX One Premium', weight: 'bold', size: 'title' },
                    // { text: ' and ', size: 'title' },
                    // { text: 'Market Intelligence', weight: 'bold', size: 'title' },
                    // { text: ' in one convenient bundle.', size: 'title' }
                    // ]
                ],
                perMonthAbbr: '/mo',
                moreOptions: 'More Options',
                getStarted: 'Get Started'
            },
            products: {
                sx1Premium: 'StoneX One Premium',
                miPremium: 'Market Intelligence Premium',
                sx1miBundle: 'StoneX One + MI Bundle'
            },
            features: {
                deepAnalysis: {
                    title: 'Deep Analysis',
                    subtitle: 'Insiders, Earnings Calls, and more!'
                },
                multiChart: { title: 'Multiple Charts' },
                customAlerts: { title: 'Custom Alerts' },
                miPremiumArticles: { title: 'Premium Commodities Research' }
            }
        }
    },

    managePremiumScreen: {
        premium: 'Premium',
        havePremiumTitle: 'You have StoneX One Premium!',
        havePremiumSubtitle: 'Rock on!',
        dontHavePremiumTitle: "You don't have Premium",
        dontHavePremiumSubtitle: 'Unlock analysis like no other.',
        features: {
            earningsCalls: 'Listen to recent and historical earnings calls for all equities, with transcripts.',
            financials:
                'Gain access to financials like sentiment analysis, balance sheets, income statements, cash flow reports, projected revenue, dividend history, and more for all stocks.',
            institutions: 'See which institutions, funds, and leaders are trading the most shares for any company.'
        },
        startForJust: (price: string | number) => `Start for just $${price}/mo.`,
        startPremiumNow: 'Start Premium Now!',
        howPaymentsAreMade: 'Payments are made directly from your StoneX account, no card required.',
        paymentAccount: 'Payment Account',
        selectPaymentAccount: 'Select Payment Account',
        nextBill: 'Next Bill',
        contactSupportForBillingHistory: 'Please contact support with inquiries about your billing history',
        contactSupport: 'Contact Support',
        cancelMyPremium: 'Cancel My Premium',
        cancelAreYouSure: 'Are you sure you want to cancel?',
        cancelYouCanUncancel: (ex: Date) => `You can choose to undo your cancelation anytime before ${SafeFormat(ex, 'MMMM d')} to keep your premium.`,
        yesCancelMyPremium: 'Yes, Cancel My Premium',
        noKeepMyPremium: 'No, Keep My Premium',
        premiumEnd: 'Premium End',
        canceledTitle: (ex: Date) => `Your premium will end on ${SafeFormat(ex, 'MMMM d')}`,
        canceledSubtitle: 'Undo your cancelation below to keep your premium',
        undoCancelation: 'Undo Cancelation'
    },

    weather: {
        sectionTitle: 'Weather',
        current: {
            chanceOfPrecipitation: 'Precipitation',
            dewPoint: 'Dew Point',
            humidity: 'Humidity',
            visibility: 'Visibility',
            feelsLike: 'Feels like',
            airPressure: 'Air Pressure',
            wind: 'Wind',
            gusts: 'Gusts',
            sprayCondition: 'Spray'
        },
        hourly: {
            hour: 'Hour',
            climate: 'Climate',
            rainChance: 'Rain Chance',
            rainAmount: 'Rain Amount',
            temperature: 'Temperature',
            windGust: 'Wind (Gust)'
        }
    },
    misc: {
        backToTorch: 'View Previous Version',
        manageAccountApplications: 'Manage Account Applications',
        selectAnAccount: 'Select An Account',
        account: 'Account',
        allAccounts: 'All Accounts',
        showFewer: 'Show Fewer',
        showLess: 'Show Less',
        showXMore: (n: number) => `Show ${n} More`,
        showMore: 'Show More',
        accounts: (n: number) => `Account${n === 1 ? '' : 's'}`,
        heldBy: 'held by',
        addAccountButton: 'Add Account',
        addAccount: 'Add Account',
        addEquitiesAccount: 'Add Equities Account',
        addFuturesAccount: 'Add Futures Account',
        noResults: 'No Results',
        noMoreResults: 'No More Results',
        errorLoadingResults: 'Error loading results',
        tryAgain: 'Try again',
        today: 'Today',
        ranges: {
            oneDay: '1D',
            twentyFourHours: '24h',
            fiveDay: '5D',
            oneWeek: '1W',
            oneMonth: '1M',
            threeMonths: '3M',
            sixMonths: '6M',
            oneYear: '1Y',
            fiveYear: '5Y',
            all: 'ALL'
        },
        search: {
            noResults: 'No results found, try another search',
            placeholder: 'Search for a security...'
        },
        news: {
            noTitle: 'No Title',
            noSummaryAvailable: 'Summary Unavailable'
        },
        tradeTypeButton: {
            buyToOpen: 'Buy to Open',
            sellToOpen: 'Sell to Open',
            sellToClose: 'Sell to Close',
            buyToClose: 'Buy to Close',
            buy: 'Buy',
            sell: 'Sell',
            shortSell: 'Short Sell',
            buySell: 'Buy-Sell',
            sellBuy: 'Sell-Buy',
            liquidate: 'Liquidate',
            liquidatePosition: (securityId: string) => `Or, you may liquidate your position, which will sell all of your shares in ${securityId}.`
        },
        chatWithSupport: 'Chat With Us',
        showVolume: 'Show Volume',
        showWorkingOrders: 'Show Working Orders',
        loggingOut: 'Logging you out...',
        amountEstimate: 'This amount is an estimate. Your order will execute at the best possible price',
        newIPO: 'New IPOs on the horizon.',
        offeringPrice: 'Offering Price:',
        screenNotExist: "This screen doesn't exist.",
        goHomeScreen: 'Go to home screen!',
        stopAllStreaming: 'Stop All Streaming'
    },

    disclosureMessages: {
        tax: 'StoneX One does not provide tax advice.',
        finra: 'StoneX © 2021. Accounts carried by StoneX Financial Inc., member FINRA/SIPC, and wholly owned subsidiary of StoneX Group Inc.',
        delayedQuotes: 'Quotes are 15 minutes delayed.',
        glancePreviousClose: 'Change as of previous close',
        projectedOrderCost: 'Order cost projected based on limit price; actual cost may vary',
        topMovers: `SECTORS and MOVERS lists are generated using data sourced from third-party data providers.
        A list of companies in a specific business sector may display a company if the source data indicates that the company derives a portion of its revenues from the relevant sector.
        The list is provided for informational purposes only and is not investment advice or a recommendation to buy, hold, or sell any security.
        Lists are not personalized recommendations to you or to any other customer, and the securities listed may not be suitable for you.
        You should not buy or sell any security without first determining it is appropriate for your portfolio or investment strategy.
        StoneX One does not make any warranty or guarantee relating to the accuracy, timeliness or completeness of any third-party information, and the provision of this information does not constitute a recommendation.`,
        totalPnL: 'This amount reflects the unrealized profit/loss for this position'
    },

    header: {
        alerts: 'Alerts',
        tip: 'Tip',
        trySearch: 'Try searching for your favorite security',
        summary: 'Summary',
        positions: 'Positions',
        banking: 'Cash',
        messages: 'Messages',
        profile: 'Profile',
        help: 'Help',
        legalMore: 'Legal & More',
        goPaperless: 'Go Paperless',
        documents: 'Documents',
        tradeHistory: 'Orders',
        fixedPlus: 'Fixed+',
        transactions: 'Transactions',
        projectedIncome: 'Projected Income',
        disclosures: 'Disclosures',
        logOut: 'Log Out',
        buyingPower: 'Buying Power',
        portfolioValue: 'Portfolio Value',
        holdings: 'Holdings',
        upcomingEarnings: 'Upcoming Earnings',
        upcomingIpos: 'Upcoming IPOs',
        searchForAnything: 'Search for anything to trade...',
        exploreMarkets: 'Explore the markets',
        addToList: 'Add To List',
        optionsHint: 'for options',
        chatWithUs: 'Chat with us',
        emailUs: 'Email us',
        resetTours: 'View Tour',
        verifyResetTours: 'Are you sure you want to reset your tour progress?',
        amex: 'Try AMEX today!',
        helpPopup: {
            contactUs: 'Contact Us',
            liveChat: 'Live Chat'
        }
    },

    notFoundSceen: {
        header: 'Looks like this page does not exist...',
        securityHeader: 'Looks like this security does not exist...',
        otherOptions: 'Here are some other options you might like',
        portfolioButton: 'My portfolio',
        positionsButton: 'My positions',
        mobile: {
            wrongTurn: "Sorry, looks like we've made a wrong turn!",
            backToStoneX: 'Back to StoneX'
        }
    },

    watchlistScreen: {
        items: (numItems?: number) => `${numItems} ${numItems === 1 ? 'item' : 'items'}`,
        notFound: {
            primary: "Looks like this watchlist doesn't exist",
            secondary: "Select another watchlist or create one by clicking the ' + ' on the right"
        },
        noItems: {
            primary: 'Looks like this list is empty',
            secondary: (name: string) => `Any items added to '${name}' will appear here.`
        },
        marketCap: {
            header: 'What is Market Cap?'
        },
        noLists: {
            noListTitle: 'No Lists',
            noListSubtitle: 'Keep an eye on your favorite securities with a list. Create your first list below.',
            createNewList: 'Create New List',
            addToList: 'Add To List',
            emptyListTitle: 'Looks like this list is empty',
            emptyListSubtitle: 'Keep up with your favorite companies by adding them to this list.',
            addMore: 'Add More'
        },
        defaultDescription: 'Add a watchlist description',
        createNewWatchlist: 'Create New Watchlist',
        addToWatchlist: (qsi: string) => `Add ${qsi} To Watchlist`,
        editWatchlist: 'Edit watchlist name',
        createWatchlist: 'Create watchlist',
        watchlistName: 'Watchlist name',
        create: 'Create',
        update: 'Update',
        topStories: 'Top Stories'
    },

    portfolioScreen: {
        skipToWatchlists: 'Skip to watchlists',
        portfolio: 'Portfolio',
        buyingPower: {
            buyingPower: 'Buying Power',
            marginCashAvailable: 'Margin Cash Available',
            cashAvailable: 'Cash Available',
            marginAvailable: 'Margin Available',
            nyseExcess: 'NYSE Excess',
            startUsingMargin: 'Start Using Margin',
            totalAvailableForTrading: 'Total Available for Trading',
            whichAccountToAddMargin: 'Which account do you want to add margin to?',
            initialMargin: 'Initial Margin',
            netLiquidity: 'Net Liquidity'
        },
        summary: {
            allAccounts: 'All Accounts',
            assetsHeldAway: 'Assets Held Away',
            buyingPower: 'Buying Power',
            cash: 'Cash',
            cashAndEquivalents: 'Cash & Equivalents',
            initialMargin: 'Initial Margin',
            longMarketValue: 'Long Market Value',
            longOptionValue: 'Long Option Value',
            totalAccountValue: 'Total Account Value',
            netLiq: 'Net Liquidation Value',
            netOptionValue: 'Net Option Value',
            openPnL: 'Open P&L',
            realizedPnL: "Today's (Realized) P&L",
            shortMarketValue: 'Short Market Value',
            shortOptionValue: 'Short Option Value',
            summary: 'Summary',
            totalPnL: 'Total P&L',
            unrealizedPnL: 'Open (Unrealized) P&L',
            viewProjectedIncome: 'View Projected Income'
        },
        portfolioBreakdown: {
            cashAndEquivalents: 'Cash & Equivalents',
            equities: 'Equities',
            stocks: 'Stocks',
            futures: 'Futures',
            mutualFunds: 'Mutual Funds',
            fixedIncome: 'Fixed Income',
            rightsAndWarrants: 'Rights and Warrants',
            options: 'Options',
            uits: 'UITs',
            others: 'Others',
            preciousMetals: 'Precious Metals',
            entertainment: 'Entertainment',
            miscellaneous: 'Miscellaneous',
            telecommunications: 'Telecommunications',
            broadcastingRadioAndTv: 'Broadcasting - Radio & TV',
            automobileAndTrucks: 'Automobiles/Trucks',
            instrumentation: 'Instrumentation',
            notGrouped: 'Not grouped',
            investmentCompanies: 'Investment Companies',
            portfolioBreakdown: 'Portfolio Breakdown',
            contractsBreakdown: 'Contracts Breakdown',
            accountBreakdown: 'Account Breakdown',
            holdingsBreakdown: 'Holdings Breakdown',
            shares: 'Shares',
            breakdown: 'Breakdown',
            sectorExposure: 'Sector Exposure',
            top10holdings: 'Top 10 Holdings',
            investing: 'Investing',
            representingAssets: (number: string) => `Representing ${number} of Total Assets`
        },
        sectors: {
            sectors: 'Sectors',
            asOf: (date: Date) => `As of ${SafeFormat(date, 'dd MMM yyyy, hh:mm bb')}`,
            customerDiscretionary: 'Consumer Discretionary',
            healthCare: 'Health Care',
            technology: 'Technology',
            realEstate: 'Real Estate',
            consumerStaples: 'Consumer Staples',
            utilities: 'Utilities',
            communicationServices: 'Communication Services',
            financials: 'Financials',
            materials: 'Materials',
            industrials: 'Industrials',
            energy: 'Energy',
            addToWatchlist: 'Add To Watchlist',
            noSecurities: 'No Securities',
            emptyList: 'Looks like this list is empty.'
        },
        topMovers: {
            futuresTopMovers: 'Front Month Movers',
            topMovers: 'Top Movers',
            topGainers: 'Top Gainers',
            topLosers: 'Top Losers',
            unavailable: 'Data Unavailable',
            topMoversUnavailable: 'Top movers are currently unavailable',
            frontMoversUnavailable: 'Front month movers are currently unavailable',
            topGainersUnavailable: 'Top gainers are currently unavailable',
            topLosersUnavailable: 'Top losers are currently unavailable'
        },
        glance: {
            title: 'Investing Terms',
            portfolioValue: 'Portfolio Value',
            accountValue: 'Account Value',

            amountInvested: 'Amount Invested',
            amountInvestedInfo:
                'This amount includes the open securities positions from your brokerage account as well as the cash and cost basis of your Futures Account. ',

            todaysGainLoss: "Today's Profit / Loss",
            todaysGainLossInfo: 'This calculation does not include any Futures positions.',

            unrealizedGainLoss: 'Open Profit / Loss',
            unrealizedGainLossInfo: 'Current Unrealized P/L of all Open positions.',

            buyingPower: 'Buying Power',

            cash: 'Cash',

            asOfPreviousClose: '(as of previous close)',
            lastUpdated: (updated: string) => `Last updated ${updated}`
        },
        sidebar: {
            stocks: 'Stocks',
            mutualFunds: 'Mutual Funds',
            fixedIncome: 'Fixed Income',
            options: 'Options',
            shares: 'Shares',
            watchlists: 'Watchlists',
            sortBy: 'Sort By',
            price: 'Price',
            value: 'Value',
            display: 'Display',
            delete: 'Delete',
            add: 'Add',
            edit: 'Edit details...',
            expires: (date: Date) => `Expires ${SafeFormat(date, 'dd MMM yyyy')}`,
            strike: 'Strike'
        },
        warnings: {
            pleaseCheckLater: 'Please check back later for further developments on this issue.',
            systemBulletin: 'System Bulletin',
            resolvingIssues: "We're resolving an incident affecting the availability of our ",
            system: 'system',
            systems: 'systems',
            weAreResolvingIssues: "We're resolving a system incident.",
            theseSystems: 'these systems',
            theSystem: 'the system',
            incidentFirstReported: ' This incident was first reported ',
            statusEvaluated: (systems: string) => ` The status of ${systems} was last evaluated `
        },
        totalPortfolioValue: 'Total Portfolio Value',
        asOfDate: 'As-Of Date',
        commonStock: 'Common Stock',
        preferredStock: 'Preferred Stock',
        newListName: 'New List Name',
        create: 'Create',
        documents: 'Documents',
        readyToStart: (isBeta: boolean) => (isBeta ? 'Ready to get started?' : 'Ready to start trading?'),
        depositFunds: 'Add Funds',
        getStarted: 'Get started by placing your first trade.',
        myPositions: 'My Positions',
        topStories: 'Top Stories'
    },

    accountScreen: {
        accountBreakdown: 'Account Breakdown',
        summaryDeficientChartPoints:
            "Currently, the summary chart doesn't include one or more of your accounts' values. This has no impact on your actual accounts or buying power.",
        accountDeficientChartPoints: "Currently, the account chart doesn't have enough data to present. This has no impact on your actual account or buying power.",
        pending: {
            title: (plural?: boolean) => `${plural ? 'Accounts' : 'Account'} Pending Approval`,
            info: (plural?: boolean) =>
                `Your ${
                    plural ? 'accounts are' : 'account is'
                } in the process of getting approval from one of our specialists. Please check back later to see if this status has been updated.`,
            extrasHeader: 'While you wait',
            explore: 'Explore the markets',
            getHelp: 'Get help from our support team'
        },
        viewDetails: 'View Details',
        noAssetBreakdownAvailable: 'No asset breakdown available for this account.',
        noIndustryBreakdownAvailable: 'No industry breakdown available for this account.',
        byAsset: 'By Asset',
        byIndustry: 'By Industry',
        buyingPower: {
            initialMargin: 'Initial Margin',
            netLiquidity: 'Net Liquidity'
        },
        myAccountStatusApplication: {
            docsRequired: {
                title: 'Documents Required',
                info: 'We need need some documents from you in order to process your application.'
            },
            inProgress: {
                title: 'Application in Review',
                info: 'Your account is currently being reviewed by one of our specialists. Check back later to see if your account has been approved.'
            },
            rejected: {
                title: 'Application Rejected',
                info: 'Your account application has been rejected. View your account application or reach out to our support team below.'
            },
            verificationFailed: {
                title: 'Account Verification Failed',
                info: 'We were not able to verify some information in our application. View your account application or reach out to our support team below.'
            },
            submitted: {
                title: 'Application Submitted',
                info: 'Your account application has been submitted! Check back later to see if your account has been approved.'
            },
            viewApplication: 'View application status'
        }
    },

    positionsScreen: {
        account: 'Account',
        allIndustries: 'All Industries',
        allTypes: 'All Types',
        averageCost: 'Avg. Cost',
        change: 'Change',
        changePercent: 'Change %',
        closed: 'Closed',
        cost: 'Cost',
        date: 'Date',
        downloadAll: 'Download All',
        downloadClosed: 'Download Closed Positions',
        downloadClosedFileName: () => `closed-positions-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        downloadFilename: () => `positions-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        hide: 'Hide',
        longAmount: 'Long Amount',
        marketValue: 'Mkt Value',
        marketValueFull: 'Market Value',
        name: 'Name',
        noOpenLots: 'No open tax lots available for this position.',
        notGrouped: 'Not grouped',
        open: 'Open',
        openPnL: 'Open P/L',
        openPnLPercent: 'Open P/L %',
        positions: 'Positions',
        price: 'Price',
        quantity: 'Quantity',
        realizedPnlFor: (thingItsFor: string) => `Realized Profit and Loss for ${thingItsFor}`,
        rollPosition: 'Roll Position',
        shortAmount: 'Short Amount',
        show: 'Show',
        showClosedLots: 'Show closed tax lots',
        taxLotPreviousDayDisclosure: 'Cost and Gain & Loss values are calculated using Adjusted Cost where available.',
        taxLotsFor: (thingItsFor: string) => `Tax lots for ${thingItsFor}`,
        taxYear: 'Tax Year',
        term: 'Term',
        todaysGainLoss: "Today's P/L",
        todaysGainLossPercent: "Today's P/L %",
        todaysGainLossTab: "Today's Profit / Loss",
        totalCost: 'Total Cost',
        totalGainLoss: 'Total P/L',
        totalGainLossPercent: 'Total P/L %',
        totalGainLossTab: 'Total Profit / Loss',
        type: 'Type',
        unrealizedProfitLoss: 'Unrealized Profit/Loss',
        sortBy: 'Sort By',
        industryGroups: {
            [IndustryGroups.cashAndEquivalents]: 'Cash & Equivalents',
            [IndustryGroups.automotiveAndTrucks]: 'Automotives / Trucks',
            [IndustryGroups.broadcastingRadioAndTv]: 'Broadcasting - Radio & TV',
            [IndustryGroups.conglomerates]: 'Conglomerates',
            [IndustryGroups.metalsAndMining]: 'Metals & Mining',
            [IndustryGroups.miscellaneous]: 'Miscellaneous',
            [IndustryGroups.mutualFunds]: 'Mutual Funds',
            [IndustryGroups.consumerProducts]: 'Consumer Products',
            [IndustryGroups.commercialPaper]: 'Commercial Paper',
            [IndustryGroups.treasuryBillsFlat]: 'Treasury Bills - Flat',
            [IndustryGroups.corporateBonds]: 'Corporate Bonds',
            [IndustryGroups.mortgageBackedBonds]: 'Mortgage Backed Bonds',
            [IndustryGroups.govermentBonds]: 'Goverment Bonds',
            [IndustryGroups.municipalBonds]: 'Municipal Bonds',
            [IndustryGroups.municipalBondsPut]: 'Municipal Bonds-Put',
            [IndustryGroups.preferredStocks]: 'Preferred Stocks',
            [IndustryGroups.aerospace]: 'Aerospace',
            [IndustryGroups.advertising]: 'Advertising',
            [IndustryGroups.airTransport]: 'Air Transport',
            [IndustryGroups.aluminum]: 'Aluminum',
            [IndustryGroups.autoParts]: 'Auto Parts',
            [IndustryGroups.banks]: 'Banks',
            [IndustryGroups.beveragesBrewers]: 'Beverages - Brewers',
            [IndustryGroups.beveragesDistillers]: 'Beverages - Distillers',
            [IndustryGroups.beveragesSoftDrinks]: 'Beverages - SoftDrinks',
            [IndustryGroups.biotechnology]: 'Biotechnology',
            [IndustryGroups.brokerageFirms]: 'Brokerage Firms',
            [IndustryGroups.buildingMaterials]: 'Building Materials',
            [IndustryGroups.businessServices]: 'Business Services',
            [IndustryGroups.cement]: 'Cement',
            [IndustryGroups.chemicalDiversified]: 'Chemical - Diversified',
            [IndustryGroups.chemicalSpecialties]: 'Chemical Specialties',
            [IndustryGroups.coal]: 'Coal',
            [IndustryGroups.copper]: 'Copper',
            [IndustryGroups.containersMetalGlass]: 'Containers Metal / Glass',
            [IndustryGroups.containersPlastic]: 'Containers - Plastic',
            [IndustryGroups.cosmetics]: 'Cosmetics',
            [IndustryGroups.dataProcessing]: 'Data Processing',
            [IndustryGroups.drugs]: 'Drugs',
            [IndustryGroups.electricEquipment]: 'Electric Equipment',
            [IndustryGroups.ermergingGrowth]: 'Ermerging Growth',
            [IndustryGroups.entertainment]: 'Entertainment',
            [IndustryGroups.finance]: 'Finance',
            [IndustryGroups.fertilizers]: 'Fertilizers',
            [IndustryGroups.foods]: 'Foods',
            [IndustryGroups.foodServiceAndVending]: 'Food Service & Vending',
            [IndustryGroups.forestProducts]: 'Forest Products',
            [IndustryGroups.gaming]: 'Gaming',
            [IndustryGroups.healthCareFacilities]: 'Health Care Facilities',
            [IndustryGroups.homeFurnishings]: 'Home Furnishings',
            [IndustryGroups.hospitalSupplies]: 'Hospital Supplies',
            [IndustryGroups.hotelsMotels]: 'Hotels & Motels',
            [IndustryGroups.householdAppliances]: 'Household Appliances',
            [IndustryGroups.industrialServices]: 'Industrial Services',
            [IndustryGroups.instrumentation]: 'Instrumentation',
            [IndustryGroups.insurance]: 'Insurance',
            [IndustryGroups.investmentCompanies]: 'Investment Companies',
            [IndustryGroups.leisureTime]: 'Leisure Time',
            [IndustryGroups.machinery]: 'Machinery',
            [IndustryGroups.machineTools]: 'Machine Tools',
            [IndustryGroups.manufacturing]: 'Manufacturing',
            [IndustryGroups.metalsFabricating]: 'Metals - Fabricating',
            [IndustryGroups.mobileHomes]: 'Mobile Homes',
            [IndustryGroups.officeEquipment]: 'Office Equipment',
            [IndustryGroups.oilAndGas]: 'Oil & Gas',
            [IndustryGroups.oilService]: 'Oil Service',
            [IndustryGroups.paper]: 'Paper',
            [IndustryGroups.photography]: 'Photography',
            [IndustryGroups.printingAndPublishing]: 'Printing & Publishing',
            [IndustryGroups.railroads]: 'Railroads',
            [IndustryGroups.realEstate]: 'Real Estate',
            [IndustryGroups.restaurants]: 'Restaurants',
            [IndustryGroups.retailStores]: 'Retail - Stores',
            [IndustryGroups.retailFoodChains]: 'Retail - Food Chains',
            [IndustryGroups.retailDrugChains]: 'Retail - Drug Chains',
            [IndustryGroups.retailMerchandising]: 'Retail - Merchandising',
            [IndustryGroups.savingsAndLoan]: 'Savings & Loan',
            [IndustryGroups.shoes]: 'Shoes',
            [IndustryGroups.soaps]: 'Soaps',
            [IndustryGroups.steels]: 'Steels',
            [IndustryGroups.telecommunications]: 'Telecommunications',
            [IndustryGroups.textiles]: 'Textiles',
            [IndustryGroups.tiresAndRubber]: 'Tires & Rubber',
            [IndustryGroups.tobacco]: 'Tobacco',
            [IndustryGroups.toys]: 'Toys',
            [IndustryGroups.transportation]: 'Transportation',
            [IndustryGroups.trucking]: 'Trucking',
            [IndustryGroups.utilitiesElectric]: 'Utilities - Electric',
            [IndustryGroups.utilitiesGas]: 'Utilities - Gas',
            [IndustryGroups.utilitiesOther]: 'Utilities - Other',
            [IndustryGroups.options]: 'Options'
        },
        closedPositions: {
            parentHeader: {
                symbolId: 'Symbol / ID',
                description: 'Description',
                totalQuantity: 'Total Quantity',
                totalCost: 'Total Cost',
                totalProceeds: 'Total Proceeds',
                totalRealizedGainLoss: 'Total Realized Profit / Loss',
                totalRealizedGainLossPercent: 'Total Realized Profit / Loss %'
            },
            childHeader: {
                adjustedCost: 'Adjusted Cost',
                closeDate: 'Close Date',
                closePrice: 'Close Price',
                cost: 'Cost',
                exchange: 'Exchange',
                openDate: 'Open Date',
                price: 'Price',
                priceOf: (qsi: string) => `${qsi} Price`,
                proceeds: 'Proceeds',
                quantity: 'Quantity',
                realizedGainLoss: 'Realized Profit / Loss',
                realizedGainLossPercent: 'Realized Profit / Loss %',
                realizedGl: 'Realized Profit/Loss',
                side: 'Side',
                term: 'Term',
                totalCost: 'Total Cost',
                tradeDate: 'Trade Date'
            }
        }
    },

    bankingScreen: {
        errors: {
            ainsIdentity: {
                title: 'Unable to verify identity.',
                description: "We're sorry, but  we were unable to verify your identity. Please reach to us below and we'll do our best to get things straightened out."
            },
            ainsMax: 'Sorry, you have reached the maximum number of linked bank accounts permitted. Please remove one to continue.',
            ainsMaxVoid: 'Sorry, bank account removal is limited to one removal every two weeks. Please try again later.',
            betaDowntimeWindow: 'Transfers are unavailable from 5pm - 8pm EST.',
            transfersUnavailable: 'Transfers cannot be submitted at this time.',
            insufficientWithdrawalBalanceTitle: 'Insufficient Funds',
            insufficientWithdrawalBalance:
                'You have insufficient funds for the transfer you have requested. If you have recently sold one or more positions, you may need to wait until the next trading day for your funds to settle and become available for transfer.',
            noAccountsAvailable: {
                title: 'No Accounts are available to select.',
                description:
                    "We're sorry, but there are no accounts available to you to add. Please reach to us below and we'll do our best to get things straightened out."
            },
            pendingAccount: 'Pending Account',
            retrievingAccountsFailed: {
                title: 'Sorry there was an error retrieving your accounts. Please try again later or ',
                actionTitle: 'contact support.'
            }
        },
        nextVoidAllowed: (nextVoid: Date) => `You can delete this account on ${format(nextVoid, 'MM/DD/YYYY')}.`,
        maxReached: (maxAccounts: number) => `Maximum of ${maxAccounts} accounts reached.`,
        fundingError: {
            title: 'An error occurred',
            subtitle: 'Please try again or contact us for assistance',
            tryAgain: 'Try again'
        },
        tryAgainIn: (dateForUnlock: Date) => `Try again ${SafeFormatToNow(dateForUnlock)}`,
        tryAgainTomorrow: 'Try again tomorrow',
        tryAgainOnMonday: 'Try again on monday',
        dontSeeAccount: "Don't see the account you want to add? Please reach out to us below and we'll get things straightened out.",
        history: 'History',
        transactionHistory: 'Transaction History',
        requests: 'Requests',
        disclaimer:
            'The information above is for supplemental information and for informational purposes only. Please see your daily statement for the official record of your account.',
        transferFunds: 'Transfer Funds',
        depositVerb: 'Deposit',
        withdrawVerb: 'Withdraw',
        depositNoun: 'Deposit',
        withdrawalNoun: 'Withdrawal',
        fundsWillBeCredited: 'Funds will be credited to your bank account within the next several days.',
        fundsWillBeDebited: 'Your funds will be available shortly.',
        from: 'From',
        to: 'To',
        amount: 'Amount',
        review: 'Review',
        reviseTransfer: 'Revise Order',
        depositsLocked: (days: number) =>
            days === 1
                ? 'Deposits locked from too many failed attempts. Please try again tomorrow or contact support.'
                : `Deposits locked from too many failed attempts. Please try again in ${days} days or contact support.`,
        withdrawLocked: (days: number) =>
            days === 1
                ? 'Withdrawals locked from too many failed attempts. Please try again tomorrow or contact support.'
                : `Withdrawals locked from too many failed attempts. Please try again in ${days} days or contact support.`,
        cashHeader: 'Cash',
        depositFunds: 'Deposit funds right to your StoneX account from a linked bank account.',
        withdrawFunds: 'Withdraw funds from your StoneX account to a linked bank account.',
        selectLinkedBankAcct: 'Select the linked bank account you would like to use.',
        retrievingBankAcct: 'Retrieving your bank accounts...',
        linkBankAcctWith: 'Link Bank Account With',
        amountMayNotExceed: (limit: number) => `Amount may not exceed ${limit}.`,
        amountMustBeAtleast: (limit: number) => `Amount must be at least ${limit}.`,
        dailyLimitText: (limits) => [
            Light('Remaining daily limit '),
            Dark(FormatNumber.toMoney(limits?.remainingClearance)),
            Light(' of '),
            Dark(FormatNumber.toMoney(limits?.dailyMaximum))
        ],
        dailyMaximumText: (limits: FundingLimits, isDeposit: boolean) => [
            Light('Remaining daily limit '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.remainingDepositAmount : limits?.remainingWithdrawAmount)),
            Light(' of '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.maxDailyDepositAmount : limits?.maxDailyWithdrawAmount))
        ],
        dailyLimitText2: (limits) => {
            const cented = (money: number) => FormatNumber.toMoney(money).replace(/\.00$/, '');
            return `${cented(limits.transferredToday)} of ${cented(limits.dailyMaximum)} transferred today.`;
        },
        minimumAllowedDepositText: (isDeposit: boolean, minAllowedDeposit: number | null) => {
            const minAllowedDepositText = isDeposit && !!minAllowedDeposit ? [Light(' ( '), Dark(FormatNumber.toMoney(minAllowedDeposit)), Light(' minimum )')] : [];
            return minAllowedDepositText;
        },
        managementLimitText: (limits: FundingManagementLimits | null) => {
            if (!limits) return '';
            if (!limits.canAdd) {
                if (!limits.canVoid) return 'To add or remove linked accounts, please contact support';
                else return 'To add a linked account, please contact support';
            } else if (!limits.canVoid) return 'To remove a linked account, please contact support';
            else return '';
        },
        fundingAvailableBalance: (availableBalance?: number) => [Light('Available to withdraw: '), Dark(FormatNumber.toMoneyOpt2(availableBalance))],
        amountToTransfer: (transferDirection?: 'deposit' | 'withdraw') => `Amount to ${transferDirection === 'deposit' ? 'Deposit' : 'Withdraw'}`,
        amountExceedsLimit: 'Amount exceeds daily limit',
        amountExceedsBalance: 'Amount exceeds bank balance',
        amountExceedsAccountBalance: 'Amount exceeds account balance',
        accountIsPending: 'Account is pending',
        plaidReauthNeeded: 'Re-authentication with Plaid required',
        plaidAuthenticationBroken: 'Plaid authentication broken please contact support',
        addYourBankAccount: 'Add your bank account',
        addABankAccount: 'Add A Bank Account',
        addASandboxBankAccount: 'Add A Sandbox Bank Account',
        back: 'Back',
        pleaseWait: 'Please wait...',
        validatingTransfer: 'Validating Transfer',
        thereWasAProblem: 'There Was A Problem',
        unknownError: {
            title: 'Something unexpected went wrong.',
            subtitle: 'Please contact support.'
        },
        fundingNotAllowed: {
            title: 'Funding is not allowed.',
            subtitle: 'Please contact support if you feel you have received this in error.'
        },
        failedDailyFundingLimitCheck: {
            title: 'Your request exceeds the daily funding limit.',
            subtitle: 'Please contact support if you feel you have received this in error.'
        },
        cosmosPlaidAccessFailed: {
            title: 'Failed to retrieve Plaid data.',
            subtitle: 'Please contact support or try again.'
        },
        plaidBalanceCheckFailed: {
            title: 'Not enough funds in account.',
            subtitle: 'Please contact support if you feel you have received this in error.'
        },
        cosmosPlaidLinkIdMatchFailed: {
            title: 'Linked account lookup error.',
            subtitle: 'Please contact support or try again.'
        },
        plaidIdentityInfoFailed: {
            title: 'Failed to retrieve bank data from Plaid.',
            subtitle: 'Please contact support or try again.'
        },
        likeKindNameCheckFailed: {
            title: 'Unable to verify account ownership.',
            subtitle: 'Please contact support.'
        },
        previouslyLinkedAccountIdLookupFailed: {
            title: 'Failed to link Plaid account to StoneX One account.',
            subtitle: 'Please contact support or try again.'
        },
        failedToLinkAccount: {
            title: 'Failed to link Plaid account to StoneX One account.',
            subtitle: 'Please contact support or try again.'
        },
        transferWindowClosed: {
            title: 'Transfer system is down for maintenance.',
            subtitle: 'Please contact support if you feel you have received this in error.'
        },
        transferFailed: {
            title: 'Transfer failed.',
            subtitle: 'Please contact support or try again.'
        },
        transferRequestFailed: 'Request failed',
        submittedRequestSuccessfully: 'Request submitted',
        asyncInfo: 'You will be notified once this request is processed. Please do not logout until this request has processed.',
        depositsLockedError: {
            title: 'Deposits Locked',
            subtitle: 'Too many failed attempts.'
        },
        minimumDepositNotMet: {
            title: (amt?: number) => (amt ? `Minimum deposit amount [${FormatNumber.toMoneyOpt2(amt)}] not met.` : 'Minimum deposit amount not met.'),
            subtitle: 'Please contact support if you feel you have received this in error.'
        },
        maximumAccountsReached: {
            title: 'Unable to add account. Maximum number of accounts reached',
            subtitle: 'Please contact support if you feel you have received this in error.'
        },
        ainsInstructionsInactive: {
            title: 'Unable to retrieve bank account information for transfer.',
            subtitle: 'Please contact support.'
        },
        maximumDepositsReached: {
            title: 'Deposits Locked',
            subtitle: (amt: number, days: number) => (days === 1 ? `Only ${amt} deposits can be made per day.` : `Only ${amt} deposits can be made every ${days} days.`)
        },
        maximumWithdrawalsReached: {
            title: 'Withdrawals Locked',
            subtitle: (amt: number, days: number) =>
                days === 1 ? `Only ${amt} withdrawals can be made per day.` : `Only ${amt} withdrawals can be made every ${days} days.`
        },
        pleaseTryAgain: 'Please try again',
        submittedSuccessfully: 'Transfer Submitted!',
        operationSubmittedSuccessfully: (transfer) => {
            const operation = (() => {
                switch (transfer.transferDirection) {
                    case 'deposit':
                        return 'Deposit';
                    case 'withdraw':
                        return 'Withdrawal';
                    default:
                        return '';
                }
            })();

            return `${operation} Submitted`;
        },
        returnToSummary: 'Return to Summary',
        startAnotherTransfer: 'Start another transfer',
        bankingUnavailable: 'Banking Unavailable',
        pleaseComeBackLaterOrContactUs:
            "We're sorry, banking is currently unavailable. Please try again later, or reach out to us below and we'll get things taken care of.",
        depositUnavailableExplanation:
            "We're sorry, deposit is currently unavailable. Please try again later, or reach out to us below and we'll get things taken care of.",
        depositUnavailableDueToTimeExplanation:
            "We're sorry, deposit is not available between 2PM and 8AM EST. Please try again outside of these hours, or reach out to us below and we'll get things taken care of.",
        depositUnavailableDueToTimeExplanationShort: 'Deposit is not available between 5PM and 6PM EST',
        depositUnavailableDueHolidaysExplanationShort: 'Deposit is not available during trading holidays',
        withdrawUnavailableExplanation:
            "We're sorry, withdraw is not available between 2PM and 8AM EST. Please try again outside of these hours, or reach out to us below and we'll get things taken care of.",
        withdrawUnavailableExplanationShort: 'Withdraw is not available between 2PM and 7AM EST',
        withdrawUnavailableWeekendsExplanation: 'Withdraw is not available during weekends',
        withdrawUnavailableHolidaysExplanation: 'Withdraw is not available during holidays',
        accountPending: 'Your selected StoneX account is pending approval',
        billPay: 'Bill Pay',
        creditCardDirect: 'Credit Card Direct',
        wiringInstructions: 'Wiring Instructions',
        viewWiringInstructions: 'View Wiring Instructions',
        wiringInstructionsPdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/WiringInstructions.pdf',
        verificationStatus: (status) => (status.includes('pending') ? 'Pending Verification' : 'Verification Failed'),

        // Mobile
        selectAccount: 'Select Account',
        forAddedSecurity: 'For added security, you will be asked to re-enter your banking credentials',
        cannotAddLimitReachedTitle: 'Cannot Add Another Account',
        cannotOpenPlaid: 'Cannot open Plaid, no link token provided',
        cannotAddLimitReachedExplainer: 'Only two bank accounts may be linked at a time',
        linkAcctContactSupport: 'To add a linked account, please contact support',
        confirmText: (transfer: Partial<TransferRequest>, snexAccount: Account, fundingSource?: FundingSource) => {
            const isDeposit = transfer.transferDirection === 'deposit';
            const extPreposition = isDeposit ? 'from' : 'into';
            const snexPreposition = isDeposit ? 'into' : 'from';
            const source = fundingSource || transfer.fundingSource;

            return [
                { text: `You are ${isDeposit ? 'depositing' : 'withdrawing'}`, shade: 'light' },
                { text: FormatNumber.toMoneyOpt2(transfer.transferAmount), shade: 'dark' },
                { text: `${extPreposition} your`, shade: 'light' },
                { text: source?.name, shade: 'dark' },
                { text: 'account ending in', shade: 'light' },
                { text: `****${source?.mask}`, shade: 'dark' },
                { text: `${snexPreposition}`, shade: 'light' },
                { text: snexAccount?.nickname || snexAccount?.name, shade: 'dark' }
            ];
        },
        lost: {
            title: 'Not Found',
            subtitle: 'Looks like we made a wrong turn! Please try again, and if the issue persists please reach out to us.',
            startOver: 'Try Again'
        },

        limitReached: 'Limit Reached',
        linkYourBankAccount: 'Link Your Bank Account',
        limitReachedExplainer: (limits: FundingManagementLimits): ShadedTextList => {
            const maxLinked = limits?.maxLinkedCount;
            const maxVoid = limits?.maxVoidCount;
            const voidDays = limits?.maxVoidFrequencyDays;
            const s = (n: number) => (n === 1 ? '' : 's');
            const num = Snex1English.general.writtenInteger0Thru10;

            const addRule: ShadedTextList = [
                {
                    text: 'As a security measure, you are only allowed to add ',
                    shade: 'light'
                },
                {
                    text: `${num(maxLinked)} bank account${s(maxLinked)}`,
                    shade: 'dark'
                },
                { text: '. ', shade: 'light' }
            ];
            const voidRule: ShadedTextList = [
                {
                    text: 'Be advised that you also may only remove ',
                    shade: 'light'
                },
                { text: `${num(maxVoid)} account${s(maxVoid)}`, shade: 'dark' },
                { text: ' every ', shade: 'light' },
                { text: `${num(voidDays)} day${s(voidDays)}`, shade: 'dark' },
                { text: '. ', shade: 'light' }
            ];

            const contactSupport: ShadedTextList = [
                {
                    text: 'If you wish to add or remove an account, please contact support below.',
                    shade: 'light'
                }
            ];

            return [...addRule, ...voidRule, ...contactSupport];
        },
        limitReachedExplainerV2: (maxAdd: number, maxVoid: number, voidDays: number): ShadedTextList => [
            { text: 'As a security measure, you are only allowed to add', shade: 'light' },
            { text: `${maxAdd} bank account${maxAdd === 1 ? '' : 's'}`, shade: 'dark' },
            { text: '. Be advised that you may also only remove ', shade: 'light' },
            { text: `${maxVoid} account${maxVoid === 1 ? '' : 's'}`, shade: 'dark' },
            { text: ' every ', shade: 'light' },
            { text: `${voidDays} day${voidDays === 1 ? '' : 's'}`, shade: 'dark' },
            { text: '. If you wish to add or remove an account, please reach out below.', shade: 'light' }
        ],
        youMayLinkNMoreAccounts: (n) => {
            if (n <= 0) return 'You may not link any more bank accounts';
            return `You may add ${n} more bank account${n === 1 ? '' : 's'}`;
        },
        linkYourAccountToAddFunds: 'Link your bank account to add funds to your StoneX account.',
        authenticateWithPlaid: 'Authenticate With Plaid',
        noBankAccounts: 'No Bank Accounts',
        linkAnotherBankAccount: 'Link another bank account',

        acats: {
            acatsComingSoon: {
                title: 'Account transfers coming soon!',
                subtitle: 'Please reach out to us with any questions.'
            },
            infoIcon: 'ACATS Rejections - Most Common Causes',
            transferAccount: 'Transfer Account',
            introText:
                'Transfer cash and investments from another brokerage into your StoneX One account, either fully or partially. Submit your transfer in minutes, and see your transferred assets appear in your account in 2-3 weeks.',
            from: 'From',
            accountNumber: 'Account Number',
            accountTitle: 'Account Title',
            accountSubtitle: 'Account Subtitle',
            to: 'To',
            start: 'Start',
            continue: 'Continue',
            skip: 'Skip',
            back: 'Back',
            submit: 'Submit',
            selectTransferType: 'Select Transfer Type',
            fullTransfer: 'Full Transfer',
            fullTransferDescription: 'Transfer all cash and positions from your external account to your StoneX One account',
            partialTransfer: 'Partial Transfer',
            partialTransferDescription: 'Select the cash and positions you would like to move over to your StoneX One account',
            cashOptions: 'Cash Options',
            transferAllCash: 'Transfer All Cash',
            transferNoCash: 'Transfer No Cash',
            transferCustomCash: 'Transfer Custom Amount',
            positions: 'Positions',
            transferNoPositions: 'Transfer No Positions',
            addPosition: 'Add Positions',
            positionAccountType: 'Account Type',
            cash: 'Cash',
            margin: 'Margin',
            all: 'All',
            custom: 'Custom',
            or: 'Or',
            positionQuantity: 'Quantity',
            done: 'Done',
            transferringAllCash: 'All Cash',
            transferringNoneCash: 'No Cash',
            transferringCustomCash: (amount: number) => `Transfer ${FormatNumber.toMoney(amount)}`,
            transferringNoPositions: 'No Positions',
            confirmTransfer: (type: 'Full' | 'Partial' | 'Unknown') => {
                if (type === 'Full' || type === 'Partial') return `Confirm ${type} Transfer`;
                else return 'Confirm Transfer';
            },
            transferringFull: 'All assets will be transferred to your StoneX One account.',
            assets: 'Assets',
            noAssetsSelected: {
                title: 'Cannot submit transfer; no assets selected',
                subtitle: 'To complete your transfer, please go back and add cash and/or positions to your transfer, or change your transfer to a full transfer',
                switchToFull: 'Switch to full transfer'
            },
            submitting: {
                title: 'Submitting your transfer',
                subtitle: 'Please wait...'
            },
            success: {
                title: 'Transfer submitted!',
                subtitle: 'Your account should be transferred within the next 14-21 business days.'
            },
            failure: {
                title: 'Something went wrong',
                subtitle: "Please contact us and we'll get things straightened out."
            },
            returnToSummary: 'Return To Summary'
        }
    },

    newVersionScreen: {
        newVersion: 'New Version',
        nowAvailable: 'Now Available',
        updateAndSee: 'Update and see how we can provide a better experience for your financial journey!',
        update: 'Update',
        notNow: 'Not Now',
        accountInfoNotShared: 'When you update, your account information will not be shared with any third-party entities.',
        privacyPolicyLearnMore: 'Learn more about our privacy policy.'
    },

    messagesScreen: {
        messages: 'Messages',
        noMessages: 'No Messages',
        errorFetchingMessages: 'Sorry, we were unable to get your messages at this time',
        viewAll: 'View All',
        read: 'Read',
        messageId: 'Message ID',
        stoneXOneTeam: 'StoneX One Team',
        unknownSender: 'Unknown Sender',
        backToAllMessages: 'Back To All Messages',
        deleteAll: 'Delete All',
        noMessagesTitle: 'Your messages will appear here',
        noMessagesSubtitle: 'Your inbox is empty. Once you receive messages they will appear here.',
        newMessage: 'New message'
    },
    profileScreen: {
        profile: 'Profile',
        logOut: 'Log Out',
        copyClipBoard: 'Copy To Clipboard',
        emailSettings: 'Email Settings',
        settings: 'Settings',
        about: 'About',
        build: 'Build',
        version: 'Version',
        snex1Premium: 'StoneX One Premium',
        deactivate: 'Deactivate Membership',
        errorDuplicateValue: 'The value entered matches the value on record. No further action required.',
        tooltip: {
            oneProNetwork:
                'One Pro Network offers in-depth analysis, real-time desk alerts, and informative videos on equities, commodities, currencies, and macro trends, all prepared by StoneX analysts. This comprehensive information empowers you to make more informed trade decisions.'
        },
        tabs: {
            personalInfo: 'Personal Info',
            accounts: 'Accounts',
            paperless: 'Paperless',
            local: 'Local'
        },
        themes: {
            dark: 'Dark',
            light: 'Light',
            system: 'System Preference',
            marketHours: 'U.S. Equity Market Hours'
        },
        personalInfo: {
            tabs: {
                profilePicture: 'Profile Picture',
                personalDetails: 'Personal Details',
                preferences: 'Preferences',
                notifications: 'Notification Settings',
                taxInformation: 'Tax Information',
                premiumSubscription: 'Your Subscription'
            },
            viewPersonalInfo: {
                title: 'Personal Details',
                name: 'Name',
                email: 'Email',
                birthDate: 'Birthdate '
            },
            loginForm: {
                signup: 'Sign Up',
                next: 'Next',
                code: 'Code',
                codeSent: 'Code Sent',
                phoneNumber: 'Phone Number',
                tosAndPrivacyConsentText: [
                    { text: 'By logging in, you agree to our ', shade: 'light' },
                    { text: 'Terms of Service', shade: 'dark', id: 'tos' },
                    { text: ' and ', shade: 'light' },
                    { text: 'Privacy Policy', shade: 'dark', id: 'privacy' },
                    { text: '.', shade: 'light' }
                ],
                verificationCodeSentMessage: "We've sent a verification code to your email address. Please enter the code here.",
                confirmPassword: 'Confirm Password',
                logIn: 'Log In',
                startTradingMessage: 'Start Trading',
                getStarted: 'Get Started',
                wantMore: 'Want More?',
                startTrading: (qsi: string) => `Start Trading ${qsi}`,
                couldNotLogIn: 'Sorry, there was a problem logging you in',
                couldNotResetYourPassword: 'Sorry, we could not reset your password',
                username: {
                    email: 'Email'
                },
                confirmSignup: {
                    newUserTitle: 'Welcome to StoneX One!',
                    newUserSubtitle: (email: string) => `Looks like we do not have the email ${email} in our system, continue to sign up?`,
                    returningUserTitle: 'Welcome back to StoneX One!',
                    returningUserSubtitle: `Looks like we weren't able to complete the registration when you previously tried signing up. We'll need to re-enter some information and verify your email. Would you like to continue?`
                },
                confirmEmployeeEmail: {
                    title: 'Employee Login Notice',
                    subtitle:
                        'StoneX employees are encouraged to use a        non-company email to access StoneX One. If you continue, you will be directed to our online login portal.',
                    androidInstructionsTitle: 'Note for Android Users',
                    androidInstructions:
                        'Android users are advised to copy the verification code from the Okta Verify app before continuing, choose to enter a code when asked, and to paste the code in when prompted.',
                    continueAnyway: 'Continue Anyway'
                },
                password: {
                    forgotPassword: 'Forgot Password',
                    resetPassword: 'Reset Password',
                    recoverAccount: 'Recover Account',
                    password: 'Password'
                },
                mfa: {
                    title: 'Multi-Factor Verification',
                    sendingCodePleaseWait: 'Sending code, please wait',
                    mfaCodeSendMessage: "We've texted a verification code to your phone number. Please enter the code here.",
                    pleaseEnterYourPhone: 'We need your phone number to authenticate you. Please enter your phone number below.'
                },
                bioauth: {
                    biometrics: 'Biometrics',
                    whatBiometricsAre: 'Would you like to use a biometric factor (fingerprint, face, etc.) to access your account?',
                    allUnlocksTitle: 'All Unlocks',
                    allUnlocksDescription: 'We will ask for your biometric factor any time you start StoneX One',
                    verificationOnlyTitle: 'Verification Only',
                    verificationOnlyDescription: 'We will ask for your biometric factor only when we need to verify your identity',
                    disableTitle: 'Disabled', // Variant for config screen
                    disableDescription: "We won't ask for your biometric factor",
                    enableTitle: 'Enable biometrics',
                    enableDescription: 'Biometric authentication will be used to access StoneX One',
                    notDesiredTitle: 'No, do not use biometrics',
                    notDesiredDescription: 'Access will not be secured with biometrics (not recommended)',
                    bioEnabledTitle: 'Biometrics Enabled',
                    bioEnabledDescription: 'Your account is secured with biometric authentication',
                    bioDisabledTitle: 'Biometrics Disabled',
                    bioDisabledDescription: 'StoneX One will not use biometric authentication when trying to log you in (not recommended)',
                    notpermitted: {
                        title: 'Biometrics not enabled',
                        message: 'Please enable biometrics in the settings to enable face ID or fingerprint log in.',
                        link: 'Enable',
                        dismiss: 'Not Now'
                    },
                    disableBiometrics: 'Disable Biometrics',
                    yesUpdateBiometrics: 'Yes, update Biometrics',
                    passwordUpdateSuccess: 'Password updated successfully!',
                    confirmReenableBioAuth: 'Would you like to re-enable biometric login with your new password?'
                },
                country: {
                    countryOfCitizenship: 'Country of Citizenship'
                },
                passwordReset: {
                    resetSent: 'Recovery Message Sent',
                    instructions: 'We have sent you an email with further steps to help you recover access to your account.',
                    returnToLogin: 'Return to Login'
                },
                whiteLabelAlert: {
                    title: 'No account found',
                    message: 'Please reach out to your sales rep or contact support if you believe this is an error.',
                    dismiss: 'Dismiss'
                }
            },
            profile: 'Profile',
            firstName: 'First Name',
            lastName: 'Last Name',
            emailAddress: 'Email Address',
            language: 'Language',
            theme: 'Theme',
            themeDescription: "System preference will follow your device's theme, and market hours will go dark when the market is not open.",
            oneClickTrading: 'One-Click Trading',
            oneClickTradingDescription:
                'One-click trading allows you to place a trade with one click without having to review the trade. You can disable one-click trading to continue to see the review screen before placing a trade.',
            advancedChartTradeTicket: 'StoneX One Advanced Chart Trade Ticket',
            advancedChartTradeTicketDescription:
                'Enable the StoneX One standard trade ticket in the Advanced Chart so you can place orders for any assets within the advanced charts including crypto currencies, orders of fractional quantities, or crypto currency orders in the US dollar denomination.',
            futuresStrikes: 'Futures Option Chain Strikes',
            oneProNetwork: 'One Pro Network',
            oneProNetworkDescription:
                'One Pro Network offers in-depth analysis, real-time desk alerts, and informative videos on equities, commodities, currencies, and macro trends, all prepared by StoneX analysts. This comprehensive information empowers you to make more informed trade decisions.',
            optionsChainStrikes: 'Options Chain Strikes',
            systemDefault: 'System Default',
            dockSidePanel: 'Dock Open Orders and Watchlist',
            tradingViewTicker: 'Ticker Bar',
            tradingViewTickerDescription:
                'The Ticker Bar allows you to grab a horizontal glance at important instrument stats, powered by TradingView. Includes the latest price of the symbols, plus daily change. Some prices may be delayed by 15 minutes or more.',
            dockSidePanelDescription:
                'The Open Orders and Watchlist side panel allows you to view your open Stocks & Options, Mutual Funds, and Futures Orders, as well as your Watchlist. Please note, this will not appear on the Summary or Account screens.',
            timezone: 'Time Zone',
            edit: 'Edit',
            smsNumber: 'SMS Phone Number',
            email: 'Email',
            smsPhone: 'SMS Phone',
            notificationsDescription: 'Let us know how you prefer to be contacted.',
            sendEmail: 'Send Me An Email',
            sendText: 'Send Me A Text',
            addPhoneNumber: 'Add Phone Number',
            verificationNeeded: 'Verification Needed',
            profilePictureDisclaimer: (maxMb: number) =>
                `Your selected profile picture will be publicly visible and is subject to our terms of use. Please select an image less than ${maxMb} MB in size.`,
            removeProfilePicture: 'Remove current picture',
            imageTooLarge: (maxMb: number) => `Sorry, your image is too large. Please select an image smaller than ${maxMb}MB`,
            unexpectedUploadError: 'An unexpected issue occurred while updating your picture',
            idType: {
                idType: 'ID Type',
                alienId: 'Alien ID',
                governmentId: 'Government ID',
                passport: 'Passport',
                ssn: 'Social Security Number',
                taxIdEin: 'US Tax ID / EIN'
            },
            mdcVerify: {
                email: {
                    title: 'Update Email',
                    inputText: 'Enter your new email address below.',
                    tryDifferent: 'Use a different email address',
                    successText: 'Email address verified and updated!'
                },
                phone: {
                    title: 'Update Phone',
                    phone: 'Phone',
                    phoneNumber: 'Phone Number',
                    inputText: 'Enter your new phone number below.',
                    tryDifferent: 'Use a different phone number',
                    successText: 'Phone number verified and updated!'
                },
                pin: 'PIN',
                badPin: 'Sorry, that PIN is not recognized',
                resendPin: 'Resend Verification PIN',
                unableToSendPin: 'Unable to deliver your code',
                unableToSendPinInstructions: (num) =>
                    `It looks like your phone number has opted out of text messages from us. Please text START to ${FormatNumber.toPhone(num)} and then try again.`,
                tryAgain: 'Try Again',
                unexpectedError: 'Sorry, we encountered an unexpected error',
                alreadyHaveAVerificationCode: 'Already have a verification PIN?',
                clickHereToEnterYourCode: 'Click here to enter your PIN',
                updateButton: 'Update',
                verifyButton: 'Verify',
                pinSentPleaseEnter: 'Verification PIN sent. Please enter the PIN you received below.',
                toRecieveTextNotifications: 'To receive notifications over text, please add your phone number.',
                addPhoneNumber: 'Add Phone Number'
            },
            taxId: 'Tax ID',
            dateOfBirth: 'Date of Birth',
            birthYear: 'Birth Year',
            birthMonth: 'Month',
            birthDay: 'Day',
            whyDoWeNeedThis: 'Why do we need this?',
            taxInfoExplainer:
                'We use your ID and date of birth to determine your relationship to different accounts, permitting you to manage your paperless delivery preferences and fund your accounts. This information is also used to verify your ownership of additional accounts. Without this information, we will be unable to provide you with full account access.',
            toUpdateThisInformation: 'To update this information, please contact StoneX One Support',
            okay: 'Okay',
            save: 'Save',
            saving: 'Saving...',
            saved: 'Saved!',
            mdcControls: {
                notificationSettings: 'Notification Settings',
                notificationSettingsToolTips: {
                    missingPhone: 'Please add a valid phone number to enable this delivery method',
                    invalidEventOrDeliveryMethod: 'Messages for this event and delivery method are not available'
                },
                email: 'Email',
                sms: 'SMS',
                smsSettings: 'SMS Settings',
                pushNotifications: 'Push Notifications',
                notifications: 'Notifications',
                events: {
                    newOrderIsPlaced: 'New Order Is Placed',
                    orderIsFilled: 'Order Is Filled',
                    orderIsPartiallyFilled: 'Order Is Partially Filled',
                    orderIsCanceled: 'Order Is Canceled',
                    orderIsRejected: 'Order Is Rejected',
                    orderIsModified: 'Order Is Modified',
                    orderIsStopped: 'Order Is Stopped',
                    orderIsSuspended: 'Order Is Suspended',
                    orderIsOpenForBidding: 'Order Is Open For Bidding',
                    customAlertFired: 'Custom Alert Is Fired',
                    optionExpiringSoon: 'Held Option Expiring Soon'
                }
            },
            managePremium: {
                cancel: 'Cancel Premium Subscription',
                canceled: (exp: string) => `You have canceled your subscription. You will have access to premium until ${exp}.`,
                cancelModalText: (exp: string) =>
                    `Are you sure you want to cancel premium?\n\nYou can choose to undo your cancelation any time before ${exp} to keep your premium.`,
                confirmCancelation: 'Confirm Cancelation',
                get: 'Get Premium',
                loading: 'One Moment Please...',
                pitch: `Listen to earnings calls, check up your favorite stocks' top investors, and analyze the full financial profile of thousands of financial products for just ${FormatNumber.toMoney(
                    PremiumProducts.premium.price
                )} per month.`,
                reactivate: 'Reactivate / Manage',
                subscribed: (exp: string) =>
                    `Congratulations! You are subscribed to StoneX One Premium. Your next bill of ${FormatNumber.toMoney(
                        PremiumProducts.premium.price
                    )} will be issued on ${exp}.`
            }
        },

        accounts: {
            accounts: 'Accounts',
            top: 'Top',
            addNewAccount: 'Add New Account',
            accountApplicationStatus: 'Account Application Status',
            accountInformation: {
                accountInformation: 'Account Information',
                acctHolderInformation: 'Account Holder Info',
                accountName: 'Account Name',
                address: 'Address',
                altPhone: 'Alternate Phone',
                dateOfBirth: 'Date Of Birth',
                email: 'Email Address',
                foreignId: 'Foreign ID',
                homePhone: 'Home Phone',
                taxId: 'Tax ID'
            },
            registration: {
                registration: 'Registration',
                accountType: 'Account Type',
                lastStatement: 'Last Statement',
                institutionCode: 'Institution Code',
                restrictionCode: 'Restriction Code',
                accountStatus: 'Account Status',
                accountOpened: 'Account Opened'
            },
            setup: {
                setup: 'Setup',
                marginAgreementOnFile: 'Margin Agreement On File',
                fplAgreementsOnFile: 'FPL Agreements On File',
                principalPayments: 'Principal Payments',
                dividendReinvest: 'Dividend Reinvest',
                cashStandingInstructions: 'Cash Standing Instructions',
                marginStandingInstructions: 'Margin Standing Instructions',
                dividendStandingInstructions: 'Dividend Standing Instructions',
                calls: 'Calls',
                puts: 'Puts',
                price: 'Price',
                noOptionsContracts: 'No option contracts are available for this security at this time.',
                optionsLevel: {
                    optionsLevel: 'Option Trading Permissions',
                    disallowed: 'Disallowed',
                    level1: 'Covered Calls', // Covered calls
                    level2: 'Purchase Calls + Puts', // Purchase Calls + Puts
                    level3: 'Spreads / Long Straddles', // Spreads / Long Straddles
                    level4: 'Equity Put Writing', // Equity Put Writing
                    level5: 'Uncovered call writing + Index put writing / short straddles', // Uncovered call writing + Index put writing / short straddles
                    level6: 'Uncovered Index Options' // Uncovered index options
                }
            },
            investmentProfile: {
                investmentProfile: 'Investment Profile',
                investmentObjective: 'Investment Objective',
                netWorth: 'Net Worth',
                federalTaxBracket: 'Federal Tax Bracket',
                liquidityNeeds: 'Liquidity Needs',
                sourceOfFunds: 'Source Of Funds',
                occupation: 'Occupation',
                stockExperience: 'Stock Experience',
                bondExperience: 'Bond Experience',
                optionExperience: 'Option Experience',
                mutualFundExperience: 'Mutual Fund Experience',
                annuityExperience: 'Annuity Experience',
                investmentTimeHorizon: 'Investment Time Horizon'
            },
            accountDetails: {
                title: 'Account Details',
                type: 'Type',
                accountNumber: 'Account Number',
                accountName: 'Account Name',
                rename: 'Rename',
                startMargin: 'Start Using Margin',

                optionsTrading: 'Options Trading',
                optionsEnabled: 'Options Enabled',
                optionsLevelsForPendingAcct: 'Once your account is approved, you will have access to options trading.',
                youveEnabledOptions: "You've enabled options for this account.",
                optionsNotEnabledDesc:
                    'This account is not enabled for options trading. Options trading allows you to trade contracts if you think the price of an asset will go up or down.',

                marginAgreementAddedDesc:
                    "Looks like you've added a margin agreement to this account. Margin gives you access to more option strategies, speculative assets like Futures and more.",
                accountDoesNotHaveMarginDesc:
                    'This account does not have a margin agreement on file. Margin gives you access to more option strategies, speculative assets like Futures and more.',

                corporateActionDesc: 'Corporate actions are actions taken by a company that impact the shareholders value directly.',
                corporateActionsAvailable: 'Corporation Actions Available',
                corporateActions: 'Corporate Actions',
                viewCorporateActions: 'View Corporate Actions',
                noCorporateActionsAvailable: 'No Corporate Actions Available',

                enableOptions: 'Enable Options Trading',
                gotoAccount: 'Go To Account',
                closeAccount: 'Close Account',
                contactUsToCloseAccount: "Please reach out to us and we'll get your account closed.",
                options: 'Options',
                margin: 'Margin',
                changeOptionLevel: 'Change Option Level',
                marginEnabled: 'Margin Enabled',
                unableToFindInformation: 'It looks like we were unable to find information for your account. Sorry about that!',
                ohNo: 'Oh no!',
                execute: 'You may execute the following:',
                useButtonModify: 'Use the button above to modify.',
                renameAccount: 'Rename Account',
                cancel: 'Cancel',
                general: {
                    general: 'General',
                    dateOpened: 'Date Opened',
                    lastStatement: 'Last Statement',
                    status: 'Status',
                    restrictionCode: 'Restriction Code'
                }
            }
        },

        paperless: {
            goPaperless: 'Go Paperless',
            paperless: 'Paperless',
            confirms: 'Confirms',
            stonexCommunications: 'StoneX Communications',
            investorRelations: 'Investor Relations',
            prospectus: 'Prospectus',
            statements: 'Statements',
            taxDocuments: 'Tax Documents',
            goCompletelyPaperless: 'Go Completely Paperless',
            goPaperlessLine1: 'Ready to go totally paperless? Press the button above to receive all of your account documents over email rather than by mail.',
            goPaperlessLine2: "Or, customize your accounts' delivery options below.",
            completelyPaperlessExplain: 'Receive all documents by email and help the environment at the same time.',
            allMail: 'All Mail',
            allEDelivery: 'All email',
            allElectronic: 'All Electronic',
            mail: 'Mail',
            edelivery: 'email',
            paperlessButton: "You're 100% Paperless!",
            customizeOptions: "Customize your accounts' delivery options below.",
            youArePaperless: (percent: string) => `You're ${percent} paperless`,
            paperlessPopup: {
                title: 'Please Confirm',
                message: 'This will update all of your accounts to no longer deliver your documents by mail. Continue?',
                confirm: 'Yes, Go Paperless'
            }
        },

        bioauth: {
            biometrics: 'Biometrics',
            whatBioauthIs: 'Biometric login allows you to quickly log in using biometric factors, such as your face or fingerprint.',
            bioauthIsCurrentlyOnOff: (on: boolean) => [
                { text: 'Biometric login is currently ', shade: 'light' },
                { text: on ? 'on' : 'off', shade: 'dark' },
                { text: '.', shade: 'light' }
            ],
            clearMyDataAndTurnBioauthOff: 'Clear My Login Data and Turn Off Biometric Login',
            turnBioauthOn: 'Turn On Biometric Login',

            whatBiounlockIs: 'If you have biometric login enabled, you may prevent others from using your login by requiring your biometric factor to unlock StoneX One.',
            biounlockIsCurrentlyOnOff: (on: boolean) => [
                { text: 'Biometric unlock is currently ', shade: 'light' },
                { text: on ? 'on' : 'off', shade: 'dark' },
                { text: '.', shade: 'light' }
            ],
            turnBiounlockOff: 'Turn Off Biometric Unlock',
            turnBiounlockOn: 'Turn On Biometric Unlock',

            errors: {
                unableToLogYouIn: 'Sorry, we were unable to log you in.',
                somethingWentWrong: 'Sorry, something went wrong.',
                couldNotVerifyYourIdentity: 'Sorry, we were unable to verify your identity.'
            },

            verifyYourLoginToActivate: 'Verify your login to activate biometrics',
            verify: 'Verify',
            email: 'Email',
            password: 'Password'
        },

        changePassword: {
            changeFailedTitle: 'Update Failed',
            changeFailedMessage: "We're sorry, but we were unable to update your password. Please try again, or reach out to support below",
            validationFailedTitle: 'Update Failed',
            validationFailedMessage:
                'Some inputs are missing or invalid. Please be sure you have entered your existing password and that you have entered your password correctly both times.',
            authenticationFailedTitle: 'Cannot Confirm Identity',
            authenticationFailedMessage: 'The current password you entered is incorrect',
            tryAgain: 'Try Again',
            contactSupport: 'Contact Support',
            changePassword: 'Change Password',
            forgotPassword: 'Forgot Password',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirmPassword: 'Confirm Password',
            updatePassword: 'Update Password',
            passwordMinLength: (minLength: number) => `Password must be at least ${minLength} characters long`,
            passwordLetter: 'Password must contain at least one letter',
            passwordRequirementsMessage: (minLength: number) =>
                `Password must be a minimum of ${minLength} characters and contain at least one uppercase letter, one lowercase letter, and one number.`,
            cannotReuseOldPassword: 'New password must not match existing password',
            newPasswordConfirmMismatch: 'New password and confirmation do not match',
            passwordRequirements: 'Password Requirements',
            passwordRecoveryRequirements: (minLength: number) => [
                `Must be a minimum of ${minLength} characters`,
                'Must contain a minimum of one uppercase letter',
                'Must contain a minimum of one number'
            ]
        }
    },
    documentsScreen: {
        documents: 'Documents',
        filters: 'Filters',
        name: 'Name',
        date: 'Date',
        account: 'Account',
        preview: 'Preview',
        download: 'Download',
        share: 'Share',
        moreActions: 'More Actions',
        viewDocument: 'View Document',
        allAccounts: 'All Accounts',
        downloading: 'Downloading...',
        saveToDownloads: 'Save To Downloads',
        documentNotFound: 'There is no file for this date.',
        docTypes: {
            taxDocuments: 'Tax Documents',
            intlPublicForms: 'INTL Public Forms',
            ifsNewAccts: 'IFS NEWACCTS',
            marginRequirements: 'Customer Margin Requirements',
            tradeConfirmations: 'Correspondent Confirms',
            checks: 'CMA Checks',
            accountStatements: 'Account Statements',
            accountNotices: 'Account Notices',
            detailedVariationAnalysis: 'Detailed Variation Analysis',
            moneyOptionReport: 'Money Option Report',
            memoStatement: 'Memo Statement',
            monthlyDiscretionaryAccounts: 'Monthly Discretionary Accounts',
            annualStatement: 'Annual Statement',
            quaterlyStatement: 'Quarterly Statement',
            monthlyStatementExcel: 'Monthly Statement Excel',
            monthlyStatement: 'Monthly Statement',
            dailyStatement: 'Daily Statement',
            fullyPaidEarnings: 'Fully Paid Earnings',
            fullyPaidLendingConfirms: 'Fully Paid Lending Confirms',
            fullyPaidLendingPositions: 'Fully Paid Lending Positions'
        },
        downloadToasts: {
            savingUnavailableTitle: 'Saving Unavailable',
            savingUnavailableDesc: 'Sorry, saving is not available on your device.',
            permissionDenied: 'Permission Denied',
            allowStonexSaveFiles: 'Please allow StoneX One to save files to your device.',
            fileSavedTitle: 'File Saved',
            fileSavedInDevice: (foldername: string) => `Your document has been saved to your device's ${foldername} folder`,
            savingErrorTitle: 'Saving Error',
            problemDownloading: 'Sorry, there was a problem while downloading your file.'
        },
        docNames: {
            statement: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'MMMM')} Statement`,
            check: (doc: SnexDocument) => doc.name,
            misc: (doc: SnexDocument) => doc.name,
            taxes: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'yyyy')} Tax Document`
        },
        symbol: 'Symbol',
        type: 'Type',
        noDocumentsTitle: 'No Documents Found',
        noDocumentsDescription: "We couldn't find any documents matching the filters below.",
        searchDocuments: 'Search Documents',
        goPaperless: 'Go Paperless',
        modal: {
            loading: 'Retrieving Document...',
            untitled: 'Untitled Document'
        }
    },

    tradeHistory: {
        tradeHistory: 'Order History',
        filters: 'Filters',
        account: (accountNumber?: string) => (accountNumber ? `Account #${accountNumber}` : 'Account'),
        allAssetClasses: 'All Products',
        equities: 'Stocks & Options',
        futures: 'Futures & Options',
        futuresOptions: 'Futures Options',
        mutualFunds: 'Mutual Funds',
        allAccounts: 'All Accounts',
        futuresTimeSpreads: 'Futures Spreads',
        cryptos: 'Cryptos',
        invalidFilters: {
            popoverHeader: 'Invalid Filters',
            popoverBody: 'Some of your filters conflict with each other. Please review your filters and try your search again.',
            popoverActionLabel: 'Okay'
        },
        status: {
            status: 'Status',
            assetClass: 'Product',
            all: 'All',
            allStatuses: 'All Statuses',
            openAll: 'All Open',
            closed: 'Closed',
            closedAll: 'All Closed',
            verification: 'Verification',
            created: 'Created',
            open: 'Open/Working',
            route: 'Route',
            routed: 'Routed',
            cancel: 'Cancel',
            canceled: 'Canceled',
            filled: 'Filled',
            rejected: 'Rejected',
            deleted: 'Deleted',
            expired: 'Expired',
            confirmed: 'Confirmed',
            unknown: 'Unknown',
            pending: 'Pending',
            completed: 'Completed',
            cancelled: 'Canceled',
            cancelPending: 'Cancel Pending',
            cancelPartialFill: 'Partially Canceled',
            allocated: 'Allocated',
            preAllocated: 'Pre-Allocated'
        },
        orders: 'Orders',
        symbol: 'Symbol',
        accountTitle: 'Account',
        downloadTooltip: 'Download All',
        fetchingError: 'Sorry! We ran into some trouble fetching your trade history.',
        noOrdersFound: 'No orders found',
        noOrdersFoundDescription: "We couldn't find any order matching the filters below",
        downloadFilename: () => `trade-history-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`
    },

    transactionsScreen: {
        tooltips: {
            partialResults: 'Partial results returned'
        },
        account: 'Account',
        transactions: 'Transactions',
        symbolId: 'Symbol',
        date: 'Date',
        type: 'Type',
        anyType: 'Any Type',
        amount: 'Amount',
        description: 'Description',
        noTransactionsTitle: 'No Transactions Available',
        noTransactionsDesc: 'Your trades, transfers, dividends, interest, and all other account activity will appear here.',
        sendingOrder: 'Sending Order..',
        tradeDescription: (m: Transaction) => {
            const verb = (() => {
                if (m.action?.toUpperCase() === 'BUY') return 'Bought';
                else if (m.action?.toUpperCase() === 'SELL') return 'Sold';
                else return m.action;
            })();
            const unit = Snex1English.general.unit(m.symbol, m.quantity, m.securityType);
            const decimalPlaces = `${m?.price}`.split('.')[1]?.length;
            return [SecurityTypes.Futures, SecurityTypes.FuturesOptions].includes(m?.securityType)
                ? `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${m.description}`
                : `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${unit} of ${m.symbol} @ ${FormatNumber.toLocaleDecimal({
                      decimalPlaces,
                      value: m.price
                  })}`;
        },
        expand: 'Expand',
        action: 'Action',
        quantity: 'Quantity',
        price: 'Price',
        baseCurrency: 'Base Currency',
        accountType: {
            accountType: 'Account Type',
            cash: 'Cash',
            longMargin: 'Margin (Long)',
            shortMargin: 'Margin (Short)',
            annuitiesAway: 'Annuities Away',
            stockLoan: 'Stock Loan',
            tefraWithhold: 'TEFRA Withhold',
            whenIssued: 'When Issued'
        },
        securityType: 'Security Type',
        asOfDate: 'As-Of Date',
        tradeDate: 'Trade Date',
        settleDate: 'Settle Date',
        principal: 'Principal',
        interest: 'Interest',
        cancelStatus: 'Cancel Status',
        rebillStatus: 'Rebill Status',
        controlNumber: 'Control Number',
        securityNumber: 'Security Number',
        security: 'Security',
        exportAll: (accountNumber: string) => `Export all transactions for ${accountNumber}`,
        filename: (accountNumber: string) => `transactions-${accountNumber}-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        filters: {
            filters: 'Filters',
            sort: 'Sort',
            date: 'Date'
        },
        processDate: 'Process Date',
        netAmount: 'Net Amount',
        defaultSort: 'Default Sort',
        ascending: 'Ascending',
        descending: 'Descending',
        search: 'Search',
        all: 'All',
        swipeUpSubmit: 'Swipe Up To Submit',
        activityType: {
            tradeActivity: 'Trade Activity',
            cashAndEquivalents: 'Cash & Equivalents',
            dividendsAndInterest: 'Dividends & Interest',
            options: 'Options',
            receiveAndDeliver: 'Receive & Deliver',
            exerciseAndExpiration: 'Exercise & Expiration',
            stockLoans: 'Stock Loans',
            misc: 'Miscellaneous',
            deposit: 'Deposit',
            withdrawal: 'Withdrawal',

            trade: 'Trade',
            cash: 'Cash',
            dividentInterest: 'Dividend / Interest',
            exerciseExpire: 'Exercise / Expiration',
            receiveDeliever: 'Receive / Deliver',
            receiveDelieverAbbrev: 'Recv / Deliv', // Abbreviated to fix on scaled Android
            funding: 'Funding',
            other: 'Other'
        }
    },

    projectedIncomeScreen: {
        projectedIncome: 'Projected Income',
        accountNumber: 'Account Number',
        symbol: 'Symbol',
        saveFilter: 'Save Filter',
        reset: 'Reset',
        viewAll: 'View All',
        pleaseSelectAnAccount: 'Select an account to see your projected income',
        noDataAvailable: 'No data is available for the selected account',
        name: 'Name',
        income: 'Income',
        yield: 'Yield',
        paymentDate: 'Payment Date',
        nextPaymentDate: 'Next Payment',
        maturityDate: 'Maturity Date',
        frequency: {
            frequency: 'Frequency',
            semiAnnually: 'Semi-Annually',
            quarterly: 'Quarterly',
            monthly: 'Monthly',
            annually: 'Annually'
        },
        projectedTotal: 'Projected Total',
        projectedFor: (qsi: string) => `Projected For ${qsi}`,
        couponRate: 'Coupon Rate',
        type: 'Type',
        downloadTooltip: 'Download Projected Icome',
        downloadFilename: () => `projected-income-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        overall: 'Overall',
        selectAnAccountEmptyTitle: 'Your projected income will display here. Select an account to view.',
        incomeBreakdown: 'Income Breakdown',
        totalProfits: 'Total profits for period:',
        noIncomeForMonth: 'No projected income for',
        selectAnotherPeriod: 'Select another period on the chart above to view your projected income.',
        noIncomeProjection: 'No Income Projection Available',
        noPositionSecurity: 'Looks like this account has no position in any securities that pay dividends.'
    },
    disclosuresPage: {
        disclosures: 'Disclosures',
        documentContent: {
            pricingDisclosure: [
                'Positions are priced as of the last trade. If an equity security has not traded today, the price information is based on the best bid price from the previous business day. The current bid or ask may be different from the price displayed'
            ],
            projectedIncomeDisclosure: [
                'Projected Income and Projected Yield for certain types of securities could include a return of principal or capital gains in which case the Projected Income and Projected Yield would be overstated.',
                'Projected Income and Projected Yield are estimates and the actual income and yield might be lower or higher than the estimated amounts',
                'Projected Yield reflects only the income generated by an investment. It does not reflect changes in its price, which may fluctuate.'
            ],
            marketIntelligenceDisclosure: [
                {
                    text: 'Nothing contained herein shall be construed as conferring on Subscriber or any third party any unlimited license or right, by implication, estoppel or otherwise, under any law (whether common law or statutory law), rule or regulation including, without limitation those related to copyright or other intellectual property rights.'
                },
                {
                    text: 'This report and its contents shall not be deemed to be financial advice. This report and its contents therein is made available to Subscriber for informational purposes and are not a substitute for obtaining professional advice from a qualified person, firm, or corporation, where required. The accuracy, completeness, adequacy or timeliness of this report and its contents is not warranted or guaranteed. Subscriber acknowledges that the report and its contents may be work product utilising, incorporating or referring to information from third parties.'
                },
                {
                    text: 'Any unauthorized use of this report and its content is wholly prohibited. This report and its contents is for use by Subscriber only in accordance with its subscription and it may not copy, modify, reproduce, republish, distribute, display, or transmit for commercial, non-profit or public purposes all or any portion thereof, and any other purpose for its benefit or the benefit of any third party. For the avoidance of doubt Subscriber may not use this report and any of its contents therein to transmit any false, misleading, fraudulent or illegal communications.'
                },
                {
                    text: 'StoneX Group Inc. disclaims all liability from the reliance, use of this report and its contents for any purpose howsoever arising from the subscription, Subscriber’s use and any third party.'
                }
            ]
        },
        documentTitles: {
            termsAndConditions: 'Terms & Conditions',
            pricingDisclosure: 'Pricing Disclosure',
            generalDisclosures: 'General Disclosures',
            cryptoDisclosure: 'Crypto Disclosure',
            projectedIncomeAndYieldDisclosure: 'Projected Income & Yield',
            marketIntelligenceDisclosure: 'Market Intelligence Disclosure'
        }
    },

    securityScreen: {
        premarket: 'Pre-Market',
        postmarket: 'Post-Market',
        afterhours: 'After hours',
        advancedChart: 'Advanced Chart',
        advancedDomAndChart: 'Advanced DOM & Chart',
        deepAnalysis: 'Deep Analysis',
        tradeOptions: 'Trade Options',
        tradeFuturesSpreads: 'Trade Futures Spreads',
        notFoundHeader: (qsi: string) => `${qsi} Not Found`,
        notFoundSubText: "Looks like we couldn't find any information about this symbol.",
        searchForSomethingElse: 'Search for something else',
        enableOptionTrading: 'Enable Option Trading',
        changeOptionLevel: 'Change Option Level',
        addToList: 'Add To List',
        notOnAnyOfYourLists: 'Not on any of your lists',
        lineChart: 'Line Graph',
        candlestick: 'Candlestick Graph',
        showVolume: 'Show Volume',
        tomorrow: 'tomorrow',
        today: 'today',
        security: 'Security',
        securities: 'Securities',
        openOrders: 'Open Orders',
        viewAll: 'View All',
        viewAllSpreads: 'View All Spreads',
        viewFewer: 'View Fewer',
        viewMore: 'View More',
        viewLess: 'View Less',
        chartDataComingSoon: 'Chart Data Coming Soon',
        problemLoadingSecuritiesFor: (qsi: string) => `There was a problem loading related securities for ${qsi}`,
        noRelatedSecuritiesFor: (qsi: string) => `No related securities are available for ${qsi}`,
        about: {
            about: (symbol: string) => 'About',
            noDescriptionAvailable: 'No Description Available',
            readMore: 'Read More',
            readLess: 'Read Less',
            ceo: 'CEO',
            employees: 'Employees',
            headquarters: 'Headquarters',
            website: 'Website',
            exchange: 'Exchange',
            open: 'Open',
            unavailable: 'Unavailable',
            whitepaper: 'Whitepaper',
            reddit: 'Reddit',
            coin: 'Coin'
        },
        stats: {
            stats: 'Stats',
            specifications: 'Specifications',
            bid: 'Bid',
            ask: 'Ask',
            open: 'Open',
            close: 'Close',
            previousClose: 'Previous Close',
            previousSettle: 'Previous Settle',
            high: 'High',
            low: 'Low',
            previousVolume: 'Previous Volume',
            volume: 'Volume',
            volume24h: 'Volume (24h)',
            averageVolume: 'Average Volume',
            averageVolume7d: 'Avg. Volume (7d)',
            week52High: '52-Week High',
            week52Low: '52-Week Low',
            marketCap: 'Market Cap',
            peRatio: 'P/E Ratio',
            ytdChange: 'YTD Change',
            startTime: 'Start Time',
            stopTime: 'Stop Time',
            endTime: 'End Time',
            contractSize: 'Contract Size',
            tickSize: 'Tick Size',
            tickPrice: 'Tick Price',
            initialMargin: 'Initial Margin',
            dayTradeMargin: 'Day Trade Margin',
            dayTradeMarginToolTip: 'Day Trade Margin is available from 7:45am to 3:30pm Central Time',
            maintenanceMargin: 'Maintenance Margin',
            changePercent: 'Change %',
            low52: '52Low',
            high52: '52High'
        },
        ratings: {
            analystRatings: 'Analyst Ratings',
            asOf: 'As Of',
            noRatingsAvailable: 'No analyst ratings available',
            analystRatingExplain: (secName: string, action: string) => `On average, analysts currently rate ${secName} as a ${action}`,
            analystRatingExplainShaded: (secName: string, action: string) => [
                {
                    shade: 'light',
                    text: 'On average, analysts currently rate '
                },
                { shade: 'dark', text: secName },
                { shade: 'light', text: ' as a ' },
                { shade: 'dark', text: action }
            ],
            aConsensusOf: (totalNumberOfRatings: number, consensusDate: string | Date) => [
                { text: 'Based on consensus of ', shade: 'light' },
                {
                    text: `${FormatNumber.toCommas(totalNumberOfRatings)} rating${totalNumberOfRatings === 1 ? '' : 's'}`,
                    shade: 'dark'
                },
                { text: ' gathered on ', shade: 'light' },
                {
                    text: SafeFormat(consensusDate, 'dd MMM yyyy'),
                    shade: 'dark'
                }
            ],
            noRatingsDataAvailble: (secName: string) => `There are no ratings available for ${secName} at this time.`,
            problemLoadingRatings: (secName: string) => `There was a problem loading ratings for ${secName}`,
            noRatingsAvailableFor: (secName: string) => `No ratings are available for ${secName}`,
            buy: 'Buy',
            sell: 'Sell',
            hold: 'Hold',
            totalRatings: 'Total Ratings'
        },
        earningsEstimates: {
            earningsAndEstimates: 'Earnings & Estimates',
            actual: 'Actual',
            estimated: 'Estimated',
            actualEPS: 'Actual EPS',
            estimatedEPS: 'Estimated EPS',
            quarter: (quarterNumber: number) => `Q${quarterNumber}`,
            noData: (securityName?: string) => `There is no earnings data available for ${securityName || 'this security'} at this time.`,
            problemLoadingEarnings: (securityName: string) => `There was a problem loading the earnings timeline for ${securityName}`,
            noEarningsTimeline: (securityName: string) => `No earnings timeline is available for ${securityName}`
        },
        options: {
            options: 'Options',
            optionsTitle: (symbol: string) => `${symbol} Options`,
            optionsSublabel: 'Trade calls and puts',
            contracts: 'Contracts',
            value: 'Value',
            todaysGainLoss: "Today's P/L",
            totalGainLoss: 'Total P/L',
            account: 'Account',
            exp: 'Exp',
            contractsLong: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1English.general.contracts(quantity)} long`,
            contractsShort: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1English.general.contracts(quantity)} short`,
            unknownExpiration: 'Unknown Expiration',
            contract: (n: number) => Snex1English.general.contracts(n),
            timeSpreadsTitle: (symbol: string) => `${symbol} Time Spreads`,
            timeSpreadsSublabel: 'Buy and sell contracts at different expiration dates',
            noTimeSpreadsSublabel: 'Time spreads are not available for this contract'
        },
        history: {
            history: 'History',
            tradeHistory: 'View Trade History',
            orderHistory: 'View Order History',
            viewMoreHistory: 'View More History'
        },
        related: {
            related: 'Related',
            relatedEtfs: 'Related ETFs',
            noRelatedStocks: 'No related securities'
        },
        holdings: {
            account: 'Account',
            accountsHolding: 'Accounts holding',
            acct: (number: string) => `Acct #${QualifiedId.Format(number)}`,
            assetsInFunds: '% Assets In Fund',
            averageCost: 'Average Cost',
            cost: 'Cost',
            marketValue: 'Market Value',
            marketValueTooltip: 'These values are based off of the previous day. Any orders filled today are not taken into account.',
            marketValueTooltipFutures: 'Market value is your contracts owned multiplied by the average cost.',
            portfolio: 'Portfolio',
            portfolioDiversity: 'Portfolio Allocation',
            positionPrice: 'Position Price',
            quantity: 'Quantity',
            share: (n: number) => Snex1English.general.shares(n),
            shares: 'Shares',
            showAllAccounts: 'Show all accounts',
            todaysReturn: "Today's P/L",
            topHoldings: 'Top Holdings',
            totalReturn: 'Total Return',
            viewMore: 'View More',
            yourMarketValue: 'Your Market Value',
            yourPosition: 'Your Position',
            buyMore: 'Buy More',
            shortMore: 'Short More',
            sell: 'Sell',
            buy: 'Buy',
            buyCover: 'Buy Cover',
            sellCover: 'Sell Cover',
            coverPosition: 'Cover',
            sellQsi: (qsi: string) => `Sell ${qsi}`,
            noTopHoldingAvailable: (security) => `No top holdings are available for ${security} at this time`,
            openPnL: 'Open P/L',
            openPnLPercent: 'Open P/L %'
        },

        news: {
            news: 'News',
            marketIntelligence: 'Market Intelligence',
            andNews: 'And News',
            newsAndMI: 'News & MI',
            newsUnavailable: 'News currently unavailable',
            noNewsFor: (symbol?: string) => `No news is available for ${symbol || 'this security'}`
        },
        crypto: {
            comingSoon: 'Crypto Trading Coming Soon',
            marketComparison: {
                title: 'Market Comparison',
                pastYear: 'Past Year'
            },
            socialActivity: {
                title: 'Social Activity',
                twitterFol: 'Twitter Followers',
                redditFol: 'Reddit Followers',
                githubFol: 'Github Followers'
            },
            popularity: 'Popularity',
            circulatingSupply: 'Circulating Supply',
            totalSupply: 'Total Supply',
            maxSupply: 'Max Supply',
            coins: 'Coins'
        }
    },

    sectorScreen: {
        sectors: 'Sectors',
        sectorNames: {
            consumerDiscretionary: 'Consumer Discretionary',
            healthCare: 'Health Care',
            technology: 'Technology',
            realEstate: 'Real Estate',
            consumerStaples: 'Consumer Staples',
            utilities: 'Utilities',
            communicationServices: 'Communication Services',
            financials: 'Financials',
            materials: 'Materials',
            industrials: 'Industrials',
            energy: 'Energy'
        },
        symbol: 'Symbol',
        name: 'Name',
        price: 'Price',
        change: 'Change',
        changePercent: 'Change Percent',
        marketCap: 'Market Cap',
        item: 'item',
        items: 'items',
        securityChangeSelector: {
            rising: 'Rising',
            falling: 'Falling'
        },
        marketCapSelector: {
            large: 'Large',
            mid: 'Mid',
            small: 'Small',
            micro: 'Micro',
            nano: 'Nano',
            andBelow: 'and below'
        }
    },

    fundScreen: {
        minimumInvestmentNotMet: (minInvestment) => `Initial Min Investment not met: ${FormatNumber.toMoneyOpt2(minInvestment, { showDecimals: false })}`,
        profile: {
            about: 'About',
            noDescription: 'No Description Available',
            category: 'Category',
            inceptionDate: 'Inception Date',
            indexTracked: 'Index Tracked',
            netAssetValue: 'Net Asset Value',
            totalAssets: 'Total Assets',
            beta: 'Beta',
            deferredLoad: 'Deferred Load',
            expenseRatio: 'Expense Ratio',
            fee12b1: 'Fee 12B1',
            holdingsTurnover: 'Holdings Turnover',
            frontLoad: 'Front Load',
            family: 'Fund Family',
            manager: 'Fund Manager',
            maxRedepmtionFee: 'Maximum Redemption Fee',
            minimumInvestment: 'Min Investment',
            minimumInitialInvestment: 'Initial Min Investment',
            aum: 'AUM',
            averageVolume: 'Average Volume',
            companySymbol: 'Company',
            priceToBook: 'Price To Book',
            priceToEarnings: 'Price To Earnings',
            website: 'Website',
            open: 'Open',
            benchmark: 'Benchmark',
            stats: 'Stats',
            bid: 'Bid',
            ask: 'Ask',
            investmentSegment: 'Investment Segment',
            status: 'Status'
        },
        countryExposure: {
            countryExposureTitle: 'Country Exposure',
            noExposureDataAvailable: 'No Exposure Data Available',
            country: 'Country',
            exposure: 'Exposure',
            countries: 'Countries',
            noCountryExposure: 'No Country Exposure Data Available'
        },
        sectorExposure: {
            sectorExposureTitle: "What's this fund made of?",
            noSectorExposure: 'No Sector Exposure Data Available',
            sectors: 'Sectors'
        },

        // Offshore mutual fund stats
        Alternative: 'Alternative',
        Equity: 'Equity',
        FixedIncome: 'Fixed Income',
        Guaranteed: 'Guaranteed',
        Hedge: 'Hedge',
        High: 'High',
        Low: 'Low',
        Medium: 'Medium',
        MoneyMarket: 'Money Market',
        MultiAsset: 'Multi Asset',
        Others: 'Others',
        RealEstate: 'Real Estate',
        Sector: 'Sector',
        SpanishDomestic: 'Spanish Domestic',
        Traditional: 'Traditional',
        active: 'Active',
        assetClass: 'Asset Class',
        cutoffTimeRedemptions: 'Cutoff Time (Redemptions)',
        cutoffTimeRedemptionsDescription: 'Indicates the cutoff time for redemptions of the fund, shown in European Standard Time.',
        cutoffTimeSubscriptions: 'Cutoff Time (Subscriptions)',
        cutoffTimeSubscriptionsDescription: 'Indicates the cutoff time for subscriptions of the fund, shown in European Standard Time.',
        fundCountry: 'Fund Country',
        fundCurrency: 'Fund Currency',
        fundGroup: 'Fund Group',
        fundGroupDescription: 'Indicates the name of the fund group, as assigned by AllFunds Bank.',
        fundHouse: 'Fund House',
        fundHouseCountry: 'Fund House Country',
        fundHouseDescription: 'Handles settlement of the fund with different classes. Includes Transfer Agents, which handles fund transfers.',
        geographicArea: 'Geographic Area',
        geographicZone: 'Geographic Zone',
        inceptionDate: 'Inception Date',
        inceptionDateDescription: 'The date in which the fund began its operations.',
        maximumAmount: 'Maximum Amount',
        maximumAmountDescription: 'Indicates the maximum amount allowed for subscriptions of the fund.',
        minAdditionalAmount: 'Min Additional Amount',
        minAdditionalAmountDescription: 'Indicates the minimum amount allowed for subsequent subscriptions of the fund.',
        minInitialAmount: 'Min Initial Amount',
        minInitialAmountDescription: 'Indicates the minimum amount allowed for the initial subscription of the fund.',
        notActive: 'Not Active',
        preNotificationDaysRedemptions: 'Pre-Notification Days (Redemptions)',
        preNotificationDaysRedemptionsDescription: 'Indicates the number of pre-notification days the fund requires for redemptions of the fund.',
        preNotificationDaysSubscriptions: 'Pre-Notification Days (Subscriptions)',
        preNotificationDaysSubscriptionsDescription: 'Indicates the number of pre-notification days the fund requires for subscriptions of the fund.',
        redemptionsByAmount: 'Redemptions By Amount',
        redemptionsByAmountDescription: 'Indicates if redemptions are allowed by a dollar amount.',
        redemptionsByShares: 'Redemptions By Shares',
        redemptionsBySharesDescription: 'Indicates if redemptions are allowed by a shares amount.',
        riskCategory: 'Risk Category',
        riskCategoryDescription: 'Based on the SRRI rating of the fund, indicates the risk category, which is based on the risks of losses or profits.',
        settlementDaysRedemptions: 'Settlement Days (Redemptions)',
        settlementDaysRedemptionsDescription: 'Indicates the number of standard settlement days for redemptions of the fund.',
        settlementDaysSubscriptions: 'Settlement Days (Subscriptions)',
        settlementDaysSubscriptionsDescription: 'Indicates the number of standard settlement days for subscriptions of the fund.',
        status: 'Status',
        subAssetClass: 'Sub Asset Class',
        subscriptionsByAmount: 'Subscriptions By Amount',
        subscriptionsByAmountDescription: 'Indicates if subscriptions are allowed by a dollar amount.',
        subscriptionsByShares: 'Subscriptions By Shares',
        subscriptionsBySharesDescription: 'Indicates if subscriptions are allowed by a shares amount.',
        totalSettlementDaysRedemptions: 'Total Settlement Days (Redemptions)',
        totalSettlementDaysRedemptionsDescription:
            'Indicates the total number of settlement days for redemptions of the fund, which also includes the number of pre-notification days as required by the fund.',
        totalSettlementDaysSubscriptions: 'Total Settlement Days (Subscriptions)',
        totalSettlementDaysSubscriptionsDescription:
            'Indicates the total number of settlement days for subscriptions of the fund, which also includes the number of pre-notification days as required by the fund.',
        transferableFund: 'Transferable Fund',
        transferableFundDescription: 'Indicates if the fund is transferable (eligible for trapaso), specifically for the Spanish market.',
        typeOfFund: 'Type of Fund',
        volatilityRange: 'Volatility Range',
        volatilityRangeDescription:
            'Based on the SRRI rating of the fund, indicates the volatility range of the fund, which is a measure of the level of risk associated with the fluctuating price of the fund.',

        srri: 'Synthetic Risk and Reward Indicator',
        potentiallyLowerReward: 'Potentially Lower Reward',
        lowerRisk: 'Lower Risk',
        higherRisk: 'Higher Risk',
        potentiallyHigherReward: 'Potentially Higher Reward',
        potentialRiskVsReward: 'Potential Risk vs Reward',
        riskIndicator: 'Risk Indicator',
        risk: 'Risk'
    },

    deepAnalysisScreen: {
        viewFull: {
            statement: 'View Full Statement',
            balanceSheet: 'View Full Balance Sheet',
            cashFlow: 'View Full Cash Flow',
            viewAll: 'View All'
        },
        deepAnalysis: 'Deep Analysis',
        quarterly: 'Quarterly',
        annual: 'Annual',
        showMore: 'Show More',
        showLess: 'Show Less',
        showAll: 'Show All',
        financials: 'Financials',
        loadingFinancials: 'Loading Financials...',
        sentimentAnalysis: 'Sentiment Analysis',
        noFinancialsFoundFor: (s) => `No financials found for ${s}`,
        noDataFoundFor: (s) => `No data found for ${s}`,
        fundamentals: {
            fundamentals: 'Fundamentals',
            projectedRevenue: 'Projected Revenue',
            dividends: 'Dividends'
        },
        balanceSheet: {
            balanceSheet: 'Balance Sheet',
            reportDate: 'Report Date',
            filingType: 'Filing Type',
            fiscalDate: 'Fiscal Date',
            fiscalQuarter: 'Fiscal Quarter',
            fiscalYear: 'Fiscal Year',
            fixedAssets: 'Fixed Assets',
            currency: 'Currency',
            currentCash: 'Current Cash',
            shortTermInvestments: 'Short-Term Investments',
            receivables: 'Receivables',
            inventory: 'Inventory',
            otherCurrentAssets: 'Other Current Assets',
            currentAssets: 'Current Assets',
            longTermInvestments: 'Long-Term Investments',
            propertyPlantEquipment: 'Property Plant Equipment',
            propertyPlantEquipmentShort: 'Property / Plant / Equip.',
            goodwill: 'Goodwill',
            intangibleAssets: 'Intangible Assets',
            otherAssets: 'Other Assets',
            totalAssets: 'Total Assets',
            accountsPayable: 'Accounts Payable',
            accountsReceivable: 'Accounts receivable',
            currentLongTermDebt: 'Current Long-Term Debt',
            otherCurrentLiabilities: 'Other Current Liabilities',
            totalCurrentLiabilities: 'Total Current Liabilities',
            longTermDebt: 'Long Term Debt',
            otherLiabilities: 'Other Liabilities',
            minorityInterest: 'Minority Interest',
            totalLiabilities: 'Total Liabilities',
            commonStock: 'Common Stock',
            retainedEarnings: 'Retained Earnings',
            treasuryStock: 'Treasury Stock',
            capitalSurplus: 'Capital Surplus',
            shareholderEquity: 'Shareholder Equity',
            netTangibleAssets: 'Net Tangible Assets',
            longTermLiabilities: 'Long Term Liabilities'
        },
        incomeStatement: {
            incomeStatement: 'Income Statement',
            reportDate: 'Report Date',
            filingType: 'Filing Type',
            fiscalDate: 'Fiscal Date',
            fiscalQuarter: 'Fiscal Quarter',
            fiscalYear: 'Fiscal Year',
            currency: 'Currency',
            totalRevenue: 'Total Revenue',
            costOfRevenue: 'Cost Of Revenue',
            grossProfit: 'Gross Profit',
            researchAndDevelopment: 'Research And Development',
            sellingGeneralAndAdmin: 'Selling General And Admin',
            operatingExpense: 'Operating Expense',
            operatingIncome: 'Operating Income',
            otherIncomeExpenseNet: 'Other Income Expense Net',
            ebit: 'EBIT',
            interestIncome: 'Interest Income',
            interestIncomeNonOperating: 'Interest Income Non Operating',
            interestExpense: 'Interest Expense Non Operating Income',
            pretaxIncome: 'Pretax Income',
            incomeTax: 'Income Tax',
            minorityInterest: 'Minority Interest',
            netIncome: 'Net Income',
            netIncomeBasic: 'Net Income Basic'
        },
        cashFlow: {
            cashFlow: 'Cash Flow',
            capitalExpenditures: 'Capital Expenditures',
            cashChange: 'Cash Change',
            cashFlowFinancing: 'Cash Flow Financing',
            changesInInventories: 'Changes In Inventories',
            changesInReceivables: 'Changes In Receivables',
            currency: 'Currency',
            depreciation: 'Depreciation',
            dividendsPaid: 'Dividends Paid',
            exchangeRateEffect: 'Exchange Rate Effect',
            filingType: 'Filing Type',
            fiscalDate: 'Fiscal Date',
            fiscalQuarter: 'Fiscal Quarter',
            fiscalYear: 'Fiscal Year',
            investingActivityOther: 'Investing Activity Other',
            investments: 'Investments',
            netBorrowings: 'Net Borrowings',
            netIncome: 'Net Income',
            otherFinancingCashFlows: 'Other Financing Cash Flows',
            reportDate: 'Report Date',
            symbol: 'Symbol',
            totalInvestingCashFlows: 'Total Investing Cash Flows'
        },
        earningsCalls: {
            earningsCalls: 'Earnings Calls',
            noEarningsCallsAvailable: 'No Earnings Calls Available',
            play: 'Play',
            playing: 'Playing',
            translateEarningsCall: (str) => str
        },
        investors: {
            investors: 'Investors',
            insiders: 'Insiders',
            institutions: 'Institutions',
            funds: 'Funds',
            insideInvestors: 'Inside Investors',
            institutionalInvestors: 'Institutional Investors',
            fundInvestors: 'Fund Investors'
        },
        insiderRoster: {
            insiderRoster: 'Insider Roster',
            noInsidersFound: (symbol: string) => `No insider roster found for ${symbol}`,
            shares: 'Shares',
            asOf: 'As Of'
        },
        topInsiders: 'Top Insiders',
        advancedStats: {
            advancedStats: 'Advanced Stats',
            beta: 'Beta',
            totalCash: 'Total Cash',
            currentDebt: 'Current Debt',
            revenue: 'Revenue',
            grossProfit: 'Gross Profit',
            totalRevenue: 'Total Revenue',
            ebitda: 'EBITDA',
            revenuePerShare: 'Revenue Per Share',
            revenuePerEmployee: 'Revenue Per Employee',
            debtToEquity: 'Debt To Equity',
            profitMargin: 'Profit Margin',
            enterpriseValue: 'Enterprise Value',
            enterpriseValueToRevenue: 'Enterprise Value To Revenue',
            priceToSales: 'Price To Sales',
            priceToBook: 'Price To Book',
            forwardPERatio: 'Forward P/E Ratio',
            pegRatio: 'PEG Ratio',
            peHigh: 'P/E High',
            peLow: 'P/E Low',
            week52HighDate: '52-Week High Date',
            week52LowDate: '52-Week Low Date',
            week52HighDateSplitAdjustOnly: '52-Week High Date Split Adjust Only',
            week52LowDateSplitAdjustOnly: '52-Week Low Date Split Adjust Only',
            putCallRatio: 'Put Call Ratio'
        },
        priceTarget: {
            priceTarget: 'Price Target',
            averagePriceTarget: 'Average Price Target',
            range: 'Range',
            low: 'Low',
            median: 'Median',
            high: 'High',
            average: 'Average'
        },
        otherInvestors: {
            insititutionalInvestors: 'Insititutional Investors',
            fundInvestors: 'Fund Investors',
            holder: 'Holder',
            shares: 'Shares',
            asOf: 'As Of',
            value: 'Value'
        },
        splits: {
            splits: 'Splits',
            noSplitsOnRecord: (symbol: string) => `No splits on record for ${symbol}`,
            exDate: 'ExDate',
            from: 'From',
            to: 'To',
            ratio: 'Ratio',
            reverse: 'Reverse',
            translateDescription: (desc: string) => desc
        },
        estimatedRevenue: {
            estimatedRevenue: 'Estimated Revenue',
            noData: (symbol: string) => `No revenue estimates availble for ${symbol}`,
            avg: 'Avg',
            high: 'High',
            low: 'Low',
            nAnalysts: '# Analysts',
            numberAnalysts: 'Number Analysts',
            period: 'Period',
            revenueAvg: 'Revenue Avg',
            revenueHigh: 'Revenue High',
            revenueLow: 'Revenue Low'
        },
        dividends: {
            dividends: 'Dividends',
            noData: (symbol: string) => `No dividends available for ${symbol}`,
            mostRecentDividend: 'Most Recent Dividend',
            dividendForDate: 'Dividend for Date',
            amount: 'Amount',
            currency: 'Currency',
            declaredDate: 'Declared Date',
            exDate: 'Ex Date',
            flag: 'Flag',
            frequency: 'Frequency',
            paymentDate: 'Payment Date',
            recordDate: 'Record Date',
            symbol: 'Symbol',
            date: 'Date'
        },
        AccountsPayable: 'Accounts Payable',
        AccumulatedMinorityInterest: 'Accumulated Minority Interest',
        BalanceSheetItemType: 'Balance Sheet Item Type',
        BookValuePerShare: 'Book Value Per Share',
        CapitalExpenditures: 'Capital Expenditures',
        CashDividendsPaid: 'Cash Dividends Paid',
        CashShortTermInvestments: 'Cash Short Term Investments',
        ChangeInCapitalStock: 'Change In Capital Stock',
        ChangeInLongTermDebt: 'Change In Long Term Debt',
        ChangesInWorkingCapital: 'Changes In Working Capital',
        CommonDividends: 'Common Dividends',
        ConsolidatedNetIncome: 'Consolidated Net Income',
        CostOfGoodsSold: 'Cost Of Goods Sold',
        DecreaseInDeposits: 'Decrease In Deposits',
        DecreaseInLoans: 'Decrease In Loans',
        DeferredTaxAssets: 'Deferred Tax Assets',
        DeferredTaxLiabilities: 'Deferred Tax Liabilities',
        DeferredTaxesInvestmentTaxCredit: 'Deferred Taxes Investment Tax Credit',
        DepreciationDepletionAmortizationCashFlow: 'Depreciation Depletion Amortization Cash Flow',
        EBITDA: 'EBITDA',
        EBITOperatingIncome: 'EBIT Operating Income',
        EPSBasicBeforeExtraordinaries: 'EPS Basic Before Extraordinaries',
        EPSDilutedBeforeUnusualExpense: 'EPS Diluted Before Unusual Expense',
        EPSFullyDiluted: 'EPS Fully Diluted',
        EquityInEarningsOfAffiliatesIncome: 'Equity In Earnings Of Affiliates Income',
        ExchangeRateEffect: 'Exchange Rate Effect',
        ExtraordinaryItem: 'Extraordinary Item',
        ForeignCurrencyAdjustment: 'Foreign Currency Adjustment',
        FreeCashFlow: 'Free Cash Flow',
        FundsFromOperations: 'Funds From Operations',
        GrossIncome: 'Gross Income',
        IncomeStatementItem: 'Income Statement Item',
        IncomeTaxPayable: 'Income Tax Payable',
        IncomeTaxes: 'Income Taxes',
        IncomeTaxesPayable: 'Income Taxes Payable',
        IncreaseInDeposits: 'Increase In Deposits',
        IncreaseInLoans: 'Increase In Loans',
        IntangibleAssets: 'Intangible Assets',
        InterestExpense: 'Interest Expense',
        Inventories: 'Inventories',
        InvestmentTaxCredits: 'InvestmentTaxCredits',
        IssuanceOfLongTermDebt: 'Issuance Of Long Term Debt',
        IssuanceOrReductionOfDebtNet: 'Issuance Or Reduction Of Debt Net',
        LongTermDebt: 'Long Term Debt',
        LongTermNoteReceivable: 'Long Term Note Receivable',
        MinorityInterestExpense: 'Minority Interest Expense',
        MiscellaneousFunds: 'Miscellaneous Funds',
        NetAssetsFromAcquisitions: 'Net Assets From Acquisitions',
        NetChangeInCash: 'Net Change In Cash',
        NetFinancingCashFlow: 'Net Financing Cash Flow',
        NetIncomeAvailableToCommonBasic: 'Net Income Available To Common Basic',
        NetIncomeCashFlow: 'Net Income Cash Flow',
        NetIncomeContinuingOperations: 'Net Income Continuing Operations',
        NetInvestingCashFlow: 'Net Investing Cash Flow',
        NetOperatingCashFlow: 'Net Operating Cash Flow',
        NetPropertyPlantEquipment: 'Net Property Plant Equipment',
        NonEquityReserves: 'Non Equity Reserves',
        NonOperatingIncomeExpense: 'Non Operating Income Expense',
        OperatingLeaseExpense: 'Operating Lease Expense',
        OtherAfterTaxAdjustments: 'Other After Tax Adjustments',
        OtherAssets: 'Other Assets',
        OtherCurrentAssets: 'Other Current Assets',
        OtherCurrentLiabilities: 'Other Current Liabilities',
        OtherFinancingActivitiesUses: 'Other Financing Activities Uses',
        OtherFinancingActivity: 'Other Financing Activity',
        OtherFunds: 'Other Funds',
        OtherLiabilities: 'Other Liabilities',
        OtherOperatingExpense: 'Other Operating Expense',
        OtherProceedsfromStockOptions: 'Other Proceeds from Stock Options',
        OtherSources: 'Other Sources',
        OtherUses: 'Other Uses',
        PreferredDividends: 'Preferred Dividends',
        PreferredDividendsCashFlow: 'Preferred Dividends Cash Flow',
        PreferredStockCarryingValue: 'Preferred Stock Carrying Value',
        PretaxIncome: 'Pretax Income',
        ProceedsFromStockOptions: 'Proceeds From Stock Options',
        ProvisionForRisksCharges: 'Provision For Risks Charges',
        PurchaseOfInvestments: 'Purchase Of Investments',
        PurchaseOrSaleOfInvestments: 'Purchase or Sale Of Investments',
        ReductionOfLongTermDebt: 'Reduction Of Long Term Debt',
        RepurchaseOfCommonPreferredStock: 'Repurchase Of Common Preferred Stock',
        ResearchAndDevelopment: 'Research And Development',
        SaleOfCommonPreferredStock: 'Sale Of Common Preferred Stock',
        SaleOfFixedAssetsBusinesses: 'Sale Of Fixed Assets Businesses',
        SaleOrMaturityOfInvestments: 'Sale Or Maturity Of Investments',
        SalesOrRevenue: 'Sales Or Revenue',
        SellingGeneralAdministrativeExpenses: 'Selling General Administrative Expenses',
        ShortTermDebt: 'Short Term Debt',
        ShortTermReceivables: 'Short Term Receivables',
        StockOptionCompensationExpense: 'Stock Option Compensation Expense',
        TangibleBookValuePerShare: 'Tangible Book Value Per Share',
        TotalAssets: 'Total Assets',
        TotalCommonEquity: 'Total Common Equity',
        TotalCurrentAssets: 'Total Current Assets',
        TotalCurrentLiabilities: 'Total Current Liabilities',
        TotalEquity: 'Total Equity',
        TotalInvestmentsAndAdvances: 'Total Investments And Advances',
        TotalLiabilities: 'Total Liabilities',
        TotalLiabilitiesStockholdersEquity: 'Total Liabilities Stockholders Equity',
        TotalShareholdersEquity: 'Total Shareholders Equity',
        UnusualExpense: 'Unusual Expense'
    },

    // Option List Screen
    optionListScreen: {
        ask: 'Ask',
        askValue: 'Ask Value',
        bid: 'Bid',
        bidValue: 'Bid Value',
        breakEven: 'Break Even',
        buy: 'Buy',
        calendarSpreads: 'Calendar Spreads',
        call: 'Call',
        change: 'Change',
        changePercent: 'Change %',
        close: 'Close',
        contractsYouSelect: 'Contracts you select will appear here.',
        expirationDate: 'Expiration Date',
        high: 'High',
        impliedVolatility: 'Implied Volatility',
        intrinsicValue: 'Intrinsic Value',
        lastPrice: 'Last Price',
        low: 'Low',
        open: 'Open',
        openInterest: 'Open Interest',
        optionChain: 'Option Chain',
        price: 'Last Price',
        put: 'Put',
        sell: 'Sell',
        sharePrice: 'Share Price',
        strikePrice: 'Strike Price',
        theGreeks: 'The Greeks',
        disclaimer: '15 Minutes Delayed',
        optionGreeksTooltipIntro:
            'Option Greeks are used to measure the risk of an option and to gauge an option’s sensitivity to the variables that make up that risk — The variables are represented by the Greek letters Delta, Gamma, Theta, Vega, and Rho.',
        optionGreeksTooltipDelta: 'Delta Δ: Rate of change per $1 of underlying security',
        optionGreeksTooltipGamma: 'Gamma Γ: Rate of change of Δ',
        optionGreeksTooltipTheta: 'Theta Θ: Rate of price change over time',
        optionGreeksTooltipVega: "Vega ν: Rate of change between the option's price and the implied volatility of the underlying security",
        optionGreeksTooltipRho: "Rho ρ: Rate of change between an option's value and a 1% change in the interest rate",
        optionGreeksDelayed: '15-minute delay',
        toBreakEven: 'To Break Even',
        tradeFutures: 'Trade Futures',
        tradeOptions: 'Trade Options',
        tradeStock: 'Trade Stock',
        viewMore: 'View More',
        viewOptionChain: 'View Option Chain',
        volume: 'Volume',
        floatingButtonText: (qsi: string) => `${qsi} Chains`
    },

    // A Piece of Logic in src/components/TradeTicket/Option/OptionTradeInputPage.tsx(83,20) prevents this from being inside optionListScreen
    greeks: {
        delta: {
            text: 'Delta',
            symbol: 'Δ',
            tooltip: 'Rate of change per $1 of underlying security.'
        },
        gamma: {
            text: 'Gamma',
            symbol: 'Γ',
            tooltip: 'Rate of change of Δ.'
        },
        theta: {
            text: 'Theta',
            symbol: 'Θ',
            tooltip: 'Rate of price change over time.'
        },
        vega: {
            text: 'Vega',
            symbol: 'v',
            tooltip: "Rate of change between the option's price and the implied volatility of the underlying security."
        },
        rho: {
            text: 'Rho',
            symbol: 'ρ',
            tooltip: "Rate of change between an option's value and a 1% change in the interest rate."
        }
    },

    enableOptionsScreen: {
        enableOptions: 'Enable Options',
        title: "Looks like you don't have access to options!",
        getStartedWithOptions: 'Get started with options trading by selecting an account below',
        noOptionsEnabled: "You don't have options enabled on your account. Would you like to?",
        enableOptionsTrading: 'Yes, Enable Options Trading',
        notNow: 'Not Now',
        noTradingOptionsEnabled: "You don't have options trading enabled on any of your accounts. Select an account below to get started."
    },

    optionContractScreen: {
        expiresOnDate: (date: Date) => `Expires ${SafeFormat(date, 'dd MMM yyyy')}`,
        expiresSoon: 'Expires Soon',
        symbolLastQuote: (symbol: string) => `${symbol} Latest Quote`,
        tradeUnderlying: (symbol: string) => `Trade ${symbol}`,
        viewAllOptions: 'View All Options',
        viewMoreHistory: 'View More History',
        viewMoreDetails: 'View More Details',
        history: 'History',
        addLeg: 'Add Leg',
        removeLeg: 'Remove Leg',
        stats: {
            stats: 'Stats',
            bid: 'Bid',
            ask: 'Ask',
            previousClose: 'Previous Close',
            breakEven: 'Break Even',
            open: 'Open',
            close: 'Close',
            high: 'High',
            low: 'Low',
            volume: 'Volume',
            openInterest: 'Open Interest',
            impliedVolatility: 'Implied Volatility',
            intrinsicValue: 'Intrinsic Value'
        }
    },

    futuresContractScreen: {
        baseContract: 'Base Contract',
        expiresDate: (date: string | Date) => `Expires ${SafeFormat(date, 'dd MMM yyyy')}`,
        upgradeToPremium: 'Upgrade to Market Intelligence Premium',
        viewMoreContracts: 'View More Contracts',
        domNotInSession: {
            title: (symbol: string) => `${symbol} not in session`,
            message: 'Due to this contract not being in session, the Order Book is not currently available.'
        }
    },
    advancedChartScreen: {
        notifications: {
            orderNotPlaced: 'Order not placed',
            invalidSecurity: 'Invalid security symbol',
            invalidAccount: 'Invalid account number',
            shortSellNotAllowed: 'Short sell not allowed from Advanced Chart'
        }
    },
    tradeTicket: {
        adjustedOptionsDisclaimer: 'Because of liquidity and settlement risk for adjusted options, we can only allow closing orders.',
        betaMaintenanceHours: 'Off Hours - Trading Unavailable From 12 AM to 1:59 AM EST, Sunday - Saturday',
        cancelEquityOrdersHours:
            'Equity Orders may only be canceled outside of system maintenance / off-hours, which are 12 AM to 1:59 AM EST, Sunday through Saturday. Please try to cancel again later.',
        cancelEquityOptionsOrdersHours:
            'Equity Option Orders may only be canceled outside of system maintenance / off-hours, which are 12 AM to 1:59 AM EST, Sunday through Saturday. Please try to cancel again later.',
        modifyEquitiesDayOrdersHours:
            'Equity Day Orders may only be edited during the hours of 8 AM to 3:59 PM EST, Monday through Friday. If you would like to change this order now, please cancel and resubmit a new order.',
        modifyEquitiesOptionsDayOrdersHours:
            'Equity Option Day Orders may only be edited during the hours of 8 AM to 3:59 PM EST, Monday through Friday. If you would like to change this order now, please cancel and resubmit a new order.',
        modifyEquitiesGtcOrdersHours:
            'Equity Good Until Canceled Orders may only be edited during the hours of 8 AM to 4:59 PM EST, Monday through Friday. If you would like to change this order now, please cancel and resubmit a new order.',
        modifyEquitiesOptionsGtcOrdersHours:
            'Equity Option Good Until Canceled Orders may only be edited during the hours of 8 AM to 4:59 PM EST, Monday through Friday. If you would like to change this order now, please cancel and resubmit a new order.',
        oneClickTradingTitle: 'Trade in One Click',
        timeFrames: 'Time Frames',
        position: 'Position',
        sellAll: 'Sell All',
        buyAll: 'Buy All',
        oneClickTradingDescription:
            'Skip this screen next time you place a trade. We won\'t make you review the trade before placing it when you opt in to one-click trading\n\nYou can turn it off by going to your "Preferences" under the "Profile" section.',
        misc: {
            noChangesToReview: 'No changes to review',
            setQuantity: 'Set Quantity',
            startTradingOptions: 'Start Trading Options',
            securityUnavailable: (symbol: string) => `Sorry, ${Snex1English.general.symbolName(symbol)} is unavailable to trade on StoneX One.`,
            enableOptionsTradingNow: 'Enable Options Trading Now',
            createFuturesAccount: 'Create a Futures Account',
            createEquitiesAccount: 'Create an Equities Account',
            accountPending: 'This account is pending approval',
            applicationPending: 'Application Pending',
            notAvailableToTrade: 'Not yet available to trade on StoneX One',
            noPricing: 'No pricing information available.',
            noPricingCall: 'Please call 1-866-443-5847 for assistance.',
            cannotEdit: 'Cannot Edit',
            orderExtendedHoursError: (orderType: string) =>
                `${(orderType.charAt(0).toUpperCase() + orderType.slice(1)).replace('Stoplimit', 'Stop limit')} orders are unavailable during extended hours trading`,
            extendedHoursMustBeChecked: 'Extended hours box must be checked to place orders during extended hours',
            badTimeInForce: 'The selected order expiration may not be used at this time',
            badTimeForStrat: 'Cannot be used at this time',
            premarketRequired: 'Only orders with pre-market execution enabled are allowed at this time',
            postmarketRequired: 'Only orders with post-market execution enabled are allowed at this time',
            notAvailableToEdit: 'Cannot edit order at this time',
            isHoliday: 'Trading is unavailable during market holidays',
            isUnderMaintenance: 'Currently in off-hours. Please try again later.',
            overnightMarginCost: 'Overnight Margin Cost',
            notInSession: (s, e) => `This security trades between ${SafeFormat(s, 'h:mm bb')} - ${SafeFormat(e, 'h:mm bb')} EST`,
            notInSessionOptions: (s, e, tz) => `Options Trades can only be placed between ${SafeFormat(s, 'h:mm bb')} - ${SafeFormat(e, 'h:mm bb')} ${tz}`,
            notInSessionOptionsSymbolic: (s, e, tz) => `Options Trades can only be placed between ${s} - ${e} ${tz}`,
            // TODO: Create a SafeFormat that uses date-fn-tz for correct timezone conversion
            notInSessionFutures: (s, e) =>
                `Trading hours for this futures contract are ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} - ${
                    e ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', { timeZone: 'America/Chicago' }) : '---'
                }`,
            notInSessionFuturesOptions: (s, e) =>
                `Trading hours for this futures contract are ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} - ${
                    e ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', { timeZone: 'America/Chicago' }) : '---'
                }`,
            notInSessionShortSell: `Short sell orders can only be placed between 9:30 AM to 4:00 PM EST, Monday through Friday.`,
            invalidEditTimeAdvisory: 'Orders cannot be edited between 5:00pm EST - 7:56am EST.',
            residualSharesWillBeSettled: 'Remaining fractional shares will show as pending and automatically be settled within two days of the transaction.',
            minimumNotMetTitle: 'Minimum Initial Investment not met',
            minimumNotMetSubtext: (minimumInvestment: number): string =>
                `This fund requires a Minimum Initial Investment of ${FormatNumber.toMoneyOpt2(minimumInvestment)}.`,
            closedToNewInvestors: 'This fund is closed to new investors',

            isWeekend: 'Trading for this security is unavailable on the weekend',
            notInSessionGeneral: 'Sorry, this security is not currently available',
            securityTypeUnavailable: (type: TradeableSecurityType | 'loading') => {
                switch (type || 'unknown') {
                    case 'loading':
                        return 'Loading';
                    case 'unknown':
                        return 'Sorry, securities of this type are not currently able to be traded';
                    default:
                        return `Sorry, ${Snex1English.general.productTypes[type]?.plural || 'securities of this type'} are not currently able to be traded`;
                }
            },
            validatingOrder: 'Validating order...'
        },

        input: {
            retry: 'Retry',
            cancel: 'Cancel',
            notes: 'Notes',
            tradeNotPermitted: 'Trade Not Permitted',
            orderNotAllowed: 'Order Not Allowed',
            cannotShort: 'Cannot Short',
            invalidQuantity: 'Invalid Quantity',
            strategy: 'Strategy',
            x1pAlgo: 'X1P Algo',
            care: 'Care',
            careDescription: 'Order not held',
            vwap: 'VWAP',
            vwapDescription: 'Volume-Weighted Average Price',
            twap: 'TWAP',
            twapDescription: 'Time-Weighted Average Price',
            pov: 'POV',
            povDescription: 'Percent of Volume',
            sor: 'SOR',
            sorDescription: 'Smart Order Routing',
            applyForMargins: 'Apply for Margins',
            unknownTitle: (isError: boolean) => (isError ? 'Unknown Error' : 'Unknown Warning'),
            unknownMessage: (isError: boolean) => (isError ? "We're sorry, but an unknown error has occurred" : "We're sorry, but an unknown warning has occurred"),

            tradeNow: 'Trade Now',
            unitName: (symbol) => (FuturesSymbol.IsFuturesSymbol(symbol) || OptionSymbol.IsOptionSymbol(symbol) ? 'contract' : 'share'),
            futures: {
                enforceFractional: 'Invalid fractional value',
                enforceTickSize: (tickSize: number) => `Price must be in increments of ${tickSize}.`,
                numberPerContract: (formattedNumber: string) => `${formattedNumber} per contract`,
                side: 'Side'
            },
            action: {
                buy: 'Buy',
                sell: 'Sell',
                tabFontSize: 20
            },
            buyingPower: 'Buying Power',
            marketValue: 'Market Value',
            buyingPowerAdvisory: (t) => `You don't have enough funds to purchase ${t.quantity} share(s) of ${t.securityId}.`,
            addFundsToContinue: 'Add funds to continue',
            depositFunds: 'Add Funds',
            dismiss: 'Dismiss',
            shares: 'Shares',
            tradeWholeShares: 'Trade in whole shares',
            tradeDollarAmount: 'Trade a dollar amount',
            securityCannotBeTraded: 'This security cannot be traded in this unit.',
            bid: 'Bid',
            ask: 'Ask',
            mid: 'Mid',
            vol: 'Vol',
            held: (symbol) => `${Snex1English.general.unit(symbol, 2)} Held`,
            options: {
                breakEven: 'Break Even',
                credit: 'Credit',
                debit: 'Debit',
                even: 'Even',
                last: 'Last',
                netAsk: 'Net Ask',
                netBid: 'Net Bid',
                midpoint: 'Midpoint',
                underlyingShares: 'Underlying Shares',
                contracts: 'Contracts',
                sharesPerContract: (n: number) => `${FormatNumber.toCommas(n)} shares per contract`,
                actionToOpenClose: (t, openClose) => `${t.action} to ${openClose === 'Open' ? 'Open' : 'Close'}`,
                unlimited: 'Unlimited',
                maxGain: 'Max Gain',
                maxLoss: 'Max Loss',
                enableOptionsTrading: 'Enable Options Trading',
                upgradeOptionsLevel: 'Upgrade Your Options Level'
            },
            advisories: {
                notice: 'Notice',
                immediateExecution: {
                    orderPriceError: (priceName: 'limit' | 'stop', priceIs: 'above-market' | 'below-market') =>
                        `Order will execute immediately since the ${priceName} price is ${priceIs === 'above-market' ? 'above' : 'below'} market price.`,
                    stopLimitPriceError: (priceIs: 'above-stop' | 'below-stop') =>
                        `Your order could execute immediately once the stop price is reached since the limit price is ${
                            priceIs === 'above-stop' ? 'above' : 'below'
                        } the stop price.`
                },
                insufficientValue: {
                    notEnoughBuyingPower: 'Not enough buying power',
                    notEnoughMarketValue: 'Not enough held',
                    depositFunds: 'Deposit Funds',
                    dismiss: 'Dismiss'
                },
                invalidStopOrder: {
                    buy: 'A buy stop order requires a stop price greater than the current market price.',
                    sell: 'A sell stop order requires a stop price less than the current market price.'
                },
                amountIsEstimate: {
                    mainText: 'This amount is an estimate.',
                    subText: 'Your order will execute at the best possible price.'
                },
                notionalValueExceeded: {
                    mainText: 'Max notional value exceeded',
                    subText: "Your trade's estimated total cost exceeds your profile's configured maximum trade value. Please contact Support for more information."
                }
            },
            dollars: 'Dollars',
            limitPrice: 'Limit Price',
            stopPrice: 'Stop Price',
            stopLimitPrice: 'Stop Limit Price',
            marketPrice: 'Market Price',
            expiration: 'Expiration',
            orderType: {
                limit: 'Limit',
                loc: 'LOC',
                market: 'Market',
                moc: 'MOC',
                stop: 'Stop',
                stoplimit: 'Stop Limit'
            },
            reviewAction: (t) => `Review ${t.action}`,
            reviewEdit: (t) => `Review ${t.action} Order Edit`,
            reviewTrade: 'Review Trade',
            buySymbol: (symbolName) => `Buy ${symbolName}`,
            sellSymbol: (symbolName) => `Sell ${symbolName}`,
            sellAll: (t, amount) => `Sell All ${FormatNumber.toDecimal(amount, 3)} ${Snex1English.general.unit(t.securityId, amount)}`,
            selectAnAccount: 'Select an Account',
            timeInForce: 'Time In Force',
            timesInForce: {
                day: 'Good For Day',
                gtc: 'Good Until Canceled',
                fok: 'Fill or Kill',
                ioc: 'Immediate or Cancel'
            },
            advancedRouting: {
                notHeld: 'Not Held'
            },
            dvp: 'DVP',
            extendedHours: {
                GTXPre: 'Allow this order to execute during extended hours',
                GTXPost: 'Allow this order to execute during extended hours',
                nte: 'Allow this order to execute during extended hours'
            },
            totalCost: 'Total Cost',
            estimatedSymbolAmount: (symbol: string) => `Estimated ${symbol} Amount`,
            estimatedTotalCredit: 'Estimated Total Credit',
            estimatedTotalCost: 'Estimated Total Cost',
            totalGain: 'Total Credit',
            estimatedTotalGain: 'Estimated Credit',
            error: "Something Isn't Right",
            warning: 'Please Confirm',
            resubmit: 'Retry',
            submitAnyway: 'Continue',
            submitShort: 'Continue Short Order',
            continue: 'Continue',
            reviseOrder: 'Revise Order'
        },

        reinvestmentOptions: {
            reinvestmentOptions: 'Reinvestment Options',
            instructions: (t) =>
                `Please indicate which of the following returns you would like to be reinvested in your position with ${t.securityId}. These will be your reinvestment preferences for this and all future purchases of ${t.securityId} made through account ${t.accountNumber}, and cannot be changed later.`,
            mutualFundInstructions: (t) =>
                `By default, mutual fund distributions are applied to a cash account as a free credit balance. If you prefer to reinvest dividends and/or capital gains distributions, you need to elect to do so below. Any reinvestment preferences set will be applied immediately and will apply to all future purchases of ${t.securityId} made through account ${t.accountNumber}. You can change your reinvestment preferences by contacting customer service. Please note that mutual fund distributions are taxable regardless of reinvesentment preferences. I want to reinvest in the security the following:`,
            offshoreCashInstructions: (accountNumber: string, isin: string) =>
                `Distributions for this offshore mutual fund are automatically applied to your account as cash (a free credit balance). This will be applied immediately and will apply to all future purchases of ${isin} made through account ${accountNumber}. By continuing with this order, you understand that the preference for this offshore mutual fund will be set to cash.`,
            offshoreReinvestInstructions: (accountNumber: string, isin: string) =>
                `Distributions for this offshore mutual fund are automatically applied to your account as a reinvestment. This will be applied immediately and will apply to all future purchases of ${isin} made through account ${accountNumber}. By continuing with this order, you understand that the preference for this offshore mutual fund will be set to reinvest.`,
            review: 'Review',
            dividends: 'Dividends',
            shortTermCapitalGains: 'Short-Term Capital Gains',
            longTermCapitalGains: 'Long-Term Capital Gains',
            editButtonText: 'Edit Reinvestment Choices',
            saveButton: 'Save Preferences',
            reinvest: 'Reinvest',
            mutualFundsDistribution: 'Mutual fund distributions are taxable regardless of reinvesentment preferences',
            usePreferences: 'Use Preferences',
            shortTermsGains: 'Short-Term Gains',
            longTermGains: 'Long-Term Gains',
            shortTerm: 'Short Term',
            longTerm: 'Long Term',
            reinvestScreenDesc: (symbol: string, accountNumber?: string) =>
                `You may allocate ${symbol} distributions to a cash account as a free credit balance, or reinvest them back into ${symbol}.These preferences will be used for all ${symbol} trades made through account ${accountNumber} once you place this trade, and can be changed at any time.`
        },

        review: {
            confirmTrade: 'Confirm',
            gtxDisclaimer:
                'Trading during Extended Hours Trading Sessions (including the Pre-Market Session (Monday through Friday 8:00 a.m. to 9:30 a.m. ET), and the After-Market Session (Monday through Friday 4:00 p.m. to 5:00 p.m. ET), in each case subject to holiday trading hours), carries unique risks, such as greater price volatility, lower liquidity, wider bid/ask spreads, and less market visibility, and may not be appropriate for all investors. StoneX One cannot guarantee that Extended Hours orders will be executed at the best price for any given security or that any such orders, including market orders, will be executed at all. By requesting access to enter orders and entering any order during Extended Hours Trading at StoneX One, including on any StoneX One trading platform or mobile platform, you acknowledge that you understand the risks of trading during Extended Hours Trading Sessions.',
            offshoreDisclaimer:
                'Offshore Mutual Fund cutoff times, settlement periods, and pre-notification periods vary by fund. You will receive the first available NAV issued by the fund. Please refer to the prospectus of the fund for cutoff times, fund fees, and other important information before investing. Offshore Mutual Fund orders may not be canceled or modified through the system once submitted. Please reach out to Support to request a cancelation or modification.',
            equities: (priceFormatter, trade, currentMarketPrice, totalCost, limitPrice?: number, stopPrice?: number) => {
                const symbol = Snex1English.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(priceFormatter(limitPrice));
                const formattedStopPrice = Dark(priceFormatter(stopPrice));
                const formattedTotalOrderPrice = Dark(`$${totalCost.toFixed(2)}`);
                const formattedCurrentMarketPrice = Dark(priceFormatter(currentMarketPrice));

                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade?.liquidate) return [Light('You are'), Dark('selling all your holdings in'), formattedSymbol, Light('at'), Dark('market price'), Light('.')];

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: 'between the hours of 8AM - 5PM ET'
                    },
                    {
                        rule:
                            trade?.timeInForce?.toUpperCase() === 'NTE' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'NTE' ||
                            trade?.timeInForce?.toUpperCase() === 'GTXPOST' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'GTXPOST',
                        value: "during today's extended post-market session (4:00 - 5:00 PM EST)"
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: 'today'
                    },
                    {
                        rule: true, // default to GTC
                        value: 'in the next 180 days'
                    }
                ];

                const tifQualifier = tifQualifierLogic.find((x) => x.rule).value;

                // if equities apply extended time in force check to tif qualifier
                const actionWording = trade?.action === 'Buy' ? 'buying' : 'selling';
                const actionWordVerb = trade?.action === 'Buy' ? 'buy' : 'sell';
                const actionWordNoun = trade?.action === 'Buy' ? 'buy order' : 'sell order';
                const xUnits = `${FormatNumber.toCommas(trade.quantity)} ${Snex1English.general.unit(trade.securityId, trade?.quantity)?.toLowerCase()}`;
                const unit = Snex1English.general.unit(trade.securityId, 1)?.toLowerCase();

                switch (true) {
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const perShare = isNaN(currentMarketPrice) ? '.' : `per ${unit}.`;
                        return [
                            Light('You are'),
                            Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`${Snex1English.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} of`),
                            formattedSymbol,
                            Light('at'),
                            Dark('market price'),
                            ...(isNaN(currentMarketPrice) ? [] : [Light('currently'), formattedCurrentMarketPrice]),
                            Light(perShare)
                        ];
                    }
                    case [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType):
                        return [
                            Light('If the price of'),
                            formattedSymbol,
                            Light('reaches'),
                            formattedLimitPrice,
                            Light(`${tifQualifier}, you will place a`),
                            Dark(actionWordNoun),
                            Light('for'),
                            Dark(xUnits),
                            Light('for'),
                            formattedTotalOrderPrice,
                            Light('or better.')
                        ];
                    case trade.orderType === 'stop':
                        return [
                            Light('If the price of'),
                            formattedSymbol,
                            Light('reaches'),
                            formattedStopPrice,
                            Light(`${tifQualifier}, you will place a`),
                            Dark(actionWordNoun),
                            Light('for'),
                            Dark(xUnits),
                            Light('at'),
                            Dark('market price'),
                            Light('.')
                        ];
                    case trade.orderType === 'stoplimit':
                        return [
                            Light('If the price of'),
                            Dark(symbol),
                            Light('reaches'),
                            formattedStopPrice,
                            Light(`${tifQualifier}, you will place a limit order to`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('for'),
                            formattedTotalOrderPrice,
                            Light('at'),
                            formattedLimitPrice,
                            Light('or better.')
                        ];
                }
            },
            tifQualifier(trade, formattedSymbol) {
                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade?.liquidate)
                    return [Light('You are'), Dark('selling all your holdings in'), Dark(formattedSymbol), Light('at'), Dark('market price'), Light('.')];

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: 'between the hours of 8AM - 5PM ET'
                    },
                    {
                        rule:
                            trade?.timeInForce?.toUpperCase() === 'NTE' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'NTE' ||
                            trade?.timeInForce?.toUpperCase() === 'GTXPOST' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'GTXPOST',
                        value: "during today's extended post-market session (4:00 - 5:00 PM EST)"
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: 'today'
                    },
                    {
                        rule: true, // default to GTC
                        value: 'in the next 180 days'
                    }
                ];

                return tifQualifierLogic.find((x) => x.rule).value;
            },
            actionText: (trade: Partial<TradeRequest>, assetClass?: AssetClass, secMeta?: SecurityMetadata) => {
                const quantity = assetClass && secMeta ? assetClass?.formatQuantity(trade?.quantity, secMeta) : FormatNumber.toCommas(trade?.quantity);
                return {
                    actionWording: trade?.action === 'Buy' ? 'buying' : 'selling',
                    actionWordVerb: trade?.action === 'Buy' ? 'buy' : 'sell',
                    actionWordNoun: trade?.action === 'Buy' ? 'buy order' : 'sell order',
                    xUnits: `${quantity} ${Snex1English.general.unit(trade.securityId, trade?.quantity)?.toLowerCase()}`,
                    unit: Snex1English.general.unit(trade.securityId, 1)?.toLowerCase()
                };
            },
            equitiesV2: (assetClass, trade, secMeta, currentMarketPrice, totalCost, limitPrice?, stopPrice?): ShadedTextList => {
                const symbol = Snex1English.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(assetClass.formatPrice(limitPrice, secMeta));
                const formattedStopPrice = Dark(assetClass.formatPrice(stopPrice, secMeta));
                const formattedTotalOrderPrice = Dark(assetClass.formatPrice(totalCost, secMeta));
                const formattedCurrentMarketPrice = Dark(assetClass.formatPrice(currentMarketPrice, secMeta));

                const tifQualifier = Snex1English.tradeTicket.review.tifQualifier(trade, formattedSymbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1English.tradeTicket.review.actionText(trade);

                const nonMarketTradeText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade?.orderType as OrderType);
                    const isStop = trade.orderType === 'stop';
                    const isStopLimit = trade.orderType === 'stoplimit';
                    let text = [Light('If the price of'), formattedSymbol, Light('reaches')];

                    isStop || isStopLimit ? text.push(formattedStopPrice) : text.push(formattedLimitPrice);

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}, you will place a limit order to`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('for'),
                            formattedLimitPrice,
                            Light('or better.')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}, you will place a`), Dark(actionWordNoun), Light('for'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('for'), formattedTotalOrderPrice, Light('or better.')]);
                    else return text.concat([Light('at'), Dark('market price'), Light('.')]);
                };

                if ([OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType)) {
                    const perShare = isNaN(currentMarketPrice) ? '.' : `per ${unit}.`;
                    return [
                        Light('You are'),
                        Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                        Light(`${Snex1English.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} of`),
                        formattedSymbol,
                        Light('at'),
                        Dark('market price'),
                        ...(isNaN(currentMarketPrice) ? [] : [Light('currently'), formattedCurrentMarketPrice]),
                        Light(perShare)
                    ];
                } else return nonMarketTradeText();
            },
            sharesV2: (assetClass, trade, secMeta, price, sharePrice?): ShadedTextList => {
                const creditDebit = trade?.leg2DebitCredit;
                const symbol = Snex1English.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1English.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1English.tradeTicket.review.actionText(trade, assetClass, secMeta);

                const isMultiLeg = !!trade?.leg2Quantity;
                const leg2ActionWordNoun = trade?.leg2Action === 'Buy' ? 'buy order' : 'sell order';
                const leg2Sym = Snex1English.general.symbolName(trade?.leg2SecurityId, 'long');

                const nonMarketNonMultiLegText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType);
                    const isStopLimit = trade.orderType === 'stoplimit';

                    let text = [Light('If the price of'), Dark(symbol), Light('reaches'), Dark(assetClass.formatPrice(trade.orderPrice, secMeta))];

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}, you will place a limit order to`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('for'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta)),
                            Light('or better.')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}, you will place a`), Dark(actionWordNoun), Light('for'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('for'), Dark(assetClass.formatPrice(price, secMeta)), Light('or better.')]);
                    else return text.concat([Light('at'), Dark('market price'), Light('.')]);
                };
                const isEven = trade.orderType === 'even';
                const formattedPrice = isEven ? '' : `${assetClass.formatPrice(price || 0, secMeta)} `;

                const forPrice = trade?.orderType === 'even' ? 'Even Money' : `${formattedPrice}Net ${creditDebit}`;

                switch (true) {
                    case isMultiLeg:
                        return [
                            Light('You are placing a'),
                            Dark(`${actionWordNoun}`),
                            Light('for'),
                            Dark(`${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`${Snex1English.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} of`),
                            Dark(symbol),
                            Light('and a'),
                            Dark(`${leg2ActionWordNoun}`),
                            Light('for'),
                            Dark(`${FormatNumber.toCommas(trade.leg2Quantity)}`),
                            Light(`${Snex1English.general.unit(trade.leg2SecurityId, trade.leg2Quantity)?.toLowerCase()} of`),
                            Dark(leg2Sym),
                            ...(trade?.orderType === 'market' ? [Light('at'), Dark('market price'), Light('.')] : [Light('for '), Dark(forPrice)])
                        ];
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType): {
                        const sharePriceFigure = 'market price';
                        const perShare = isNaN(sharePrice) ? '.' : `per ${unit}.`;
                        return [
                            Light('You are'),
                            Dark(`${actionWording} ${assetClass?.formatQuantity(trade.quantity, secMeta)}`),
                            Light(`${Snex1English.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} of`),
                            Dark(symbol),
                            Light('at'),
                            Dark(sharePriceFigure),
                            ...(isNaN(sharePrice) ? [] : [Light('currently'), Dark(assetClass.formatPrice(sharePrice, secMeta))]),
                            Light(perShare)
                        ];
                    }
                    default:
                        return nonMarketNonMultiLegText();
                }
            },
            dollarsV2: (assetClass, trade, secMeta, sharePrice?): ShadedTextList => {
                const symbol = Snex1English.general.symbolName(trade.securityId);
                const { actionWording } = Snex1English.tradeTicket.review.actionText(trade);
                const sharePriceFigure = 'market price';
                return [
                    Light(`You are ${actionWording}`),
                    Dark(assetClass.formatPrice(trade.quantity, secMeta)),
                    Light('of'),
                    Dark(symbol),
                    Light('at'),
                    Dark(sharePriceFigure),
                    ...(isNaN(sharePrice) ? [] : [Light('currently'), Dark(`${assetClass.formatPrice(sharePrice, secMeta)}`)]),
                    Light('.')
                ];
            },
            futuresV2: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const action = Snex1English.tradeTicket.review.actionText(trade).actionWordVerb;
                const symbol = Snex1English.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const s = trade.quantity === 1 ? '' : 's';

                const base: ShadedTextList = [
                    { text: 'You are placing a', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'order for', shade: 'light' },
                    { text: `${trade.quantity} ${symbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: `contract${s}`, shade: 'light' }
                ];

                const suffGtc: ShadedTextList = [{ text: ', good until canceled', shade: 'light' }];
                const suffPeriod: ShadedTextList = [{ text: '.', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: 'at', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'futures' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: 'with a stop of', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'futures' },
                    { text: 'and a limit of', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'futures' }
                ];

                const parts = [
                    base,
                    type === 'limit' || type === 'stop' ? suffPrice(trade.orderPrice) : null,
                    type === 'stop-limit' ? suffStopLimit(trade.orderPrice, trade.stopLimitPrice) : null,
                    trade.timeInForce === 'GTC' ? suffGtc : null,
                    suffPeriod
                ].filter((x) => !!x);

                return ShadedTextUtils.combine(parts);
            },
            futuresSpreads: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const futuresSym = new FuturesSymbol(trade.securityId);
                const inverseAction = futuresSym.nearMonthDirection === '-';
                const { actionWordVerb: action } = Snex1English.tradeTicket.review.actionText(trade);

                const fullSymbol = Snex1English.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const firstSymbol = futuresSym.nearMonthContract.slice(2);
                const secondSymbol = futuresSym.farMonthContract.slice(2);

                const s = trade.quantity === 1 ? '' : 's';

                const firstActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? 'buying' : 'selling';
                const secondActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? 'selling' : 'buying';

                const suffPeriod: ShadedTextList = [{ text: '.', shade: 'light' }];

                const base: ShadedTextList = [
                    { text: 'You are placing a', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'order for', shade: 'light' },
                    { text: `${trade.quantity} ${fullSymbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: `contract${s}`, shade: 'light' }
                ];

                const symbolExplanation: ShadedTextList = [
                    { text: 'You are', shade: 'light' },
                    { text: `${firstActioning} ${firstSymbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'and', shade: 'light' },
                    { text: `${secondActioning} ${secondSymbol}`, shade: 'dark', assetClass: 'futures' },
                    ...suffPeriod
                ];

                const suffGtc: ShadedTextList = [{ text: ', good until canceled.', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: 'at', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'futures' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: 'with a stop of', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'futures' },
                    { text: 'and a limit of', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'futures' }
                ];

                const flattenedArray = () => {
                    const parts = [base];
                    if (type === 'limit' || type === 'stop') parts.push(suffPrice(trade.orderPrice));
                    if (type !== 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (type === 'stop-limit') parts.push(suffStopLimit(trade.orderPrice, trade.stopLimitPrice));
                    if (type === 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (trade.timeInForce === 'GTC') parts.push(suffGtc);
                    parts.push(symbolExplanation);

                    return ShadedTextUtils.combine(parts);
                };

                return flattenedArray();
            },
            cryptos: (assetClass, trade, secMeta, price, sharePrice): ShadedTextList => {
                const symbol = Snex1English.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1English.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWordVerb, actionWording } = Snex1English.tradeTicket.review.actionText(trade);

                const isDollars = trade?.quantityQualifier === 'EvenDollar';

                if (trade.orderType === 'market') {
                    return [
                        Light('You are'),
                        isDollars
                            ? Dark(`${actionWording} ${assetClass.formatPrice(trade?.quantity)} of ${symbol}`, '', 'crypto')
                            : Dark(`${actionWording} ${assetClass.formatQuantity(trade?.quantity)} ${symbol}`, '', 'crypto'),
                        Light('at'),
                        Dark('market price', '', 'crypto'),
                        Light('currently at'),
                        Dark(assetClass.formatPrice(sharePrice, secMeta), '', 'crypto'),
                        Light('per coin.')
                    ];
                } else {
                    const isStopLimit = trade.orderType === 'stoplimit';
                    const isStop = trade.orderType === 'stop';
                    const tif = isStop ? 'gtc' : trade?.timeInForce?.toLowerCase();
                    const expandedTif = Snex1English.tradeTicket.input.timesInForce[tif].toLowerCase();

                    if (isStopLimit) {
                        return [
                            Light('If the price of'),
                            Dark(symbol, '', 'crypto'),
                            Light('reaches'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`${tifQualifier}, you will place a limit order to`),
                            isDollars
                                ? Dark(`${actionWordVerb} ${assetClass.formatPrice(trade?.quantity)} of ${symbol}`, '', 'crypto')
                                : Dark(`${actionWordVerb} ${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('for'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta), '', 'crypto'),
                            Light('or better.')
                        ];
                    } else if (isStop) {
                        return [
                            Light('You are placing a'),
                            Dark(`stop ${actionWordVerb}`, '', 'crypto'),
                            Light('order for'),
                            isDollars
                                ? Dark(`${assetClass.formatPrice(trade?.quantity)} of ${symbol}`, '', 'crypto')
                                : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('at a price of'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`or better,`),
                            Dark(`${expandedTif}.`, '', 'crypto')
                        ];
                    } else {
                        return [
                            Light('You are placing a'),
                            Dark(`limit ${actionWordVerb}`, '', 'crypto'),
                            Light('order for'),
                            isDollars
                                ? Dark(`${assetClass.formatPrice(trade?.quantity)} of ${symbol}`, '', 'crypto')
                                : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('at a price of'),
                            Dark(assetClass.formatPrice(price, secMeta), '', 'crypto'),
                            Light(`or better,`),
                            Dark(`${expandedTif}.`, '', 'crypto')
                        ];
                    }
                }
            },
            reinvestmentOptions: {
                willBeCashed: (labels: string[]) => [Light(`${SpokenJoin(labels)} will be deposited to your account as`), Dark('cash'), Light('.')],
                willBeReinvested: (labels: string[]) => [Light(`${SpokenJoin(labels)} will be`), Dark('reinvested'), Light('.')],
                allWillBeCashed: [Light('All returns will be deposited to your account as'), Dark('cash'), Light('.')],
                allWillBeReinvested: [Light('All returns will be'), Dark('reinvested'), Light('.')]
            }
        },

        receipt: {
            tradeReceipt: 'Trade Receipt',
            orderSummary: 'Order Summary',
            orderType: {
                orderType: 'Order Type',
                marketOrder: 'Market Order',
                marketOrderDesc: 'Trade immediately at the best available price',
                mocOrder: 'MOC Order',
                limitOrder: 'Limit Order',
                limitOrderMinMax: (sell: boolean) => `Trade at a ${sell ? 'minimum' : 'maximum'} price or better`,
                limitOrderDesc: 'Trade at a price or better',
                locOrder: 'LOC Order',
                stopOrder: 'Stop Order',
                stopOrderDesc: 'Trigger a market trade at a fixed stop price',
                stopLimitOrder: 'Stop-Limit Order',
                stopLimitOrderDesc: 'Trigger a limit trade at a fixed stop price',
                tradeCantBePlaced: 'This type of trade cannot be placed for this kind of security',
                setStop: 'Set Stop',
                setLimit: 'Set Limit',
                setStopLimit: 'Set Stop Limit',
                buyShareMaxPrice: 'Buy shares at a maximum price or lower.',
                sellSharesMaxPrice: 'Sell shares at a minimum price or higher.',
                triggerMarket: (orderAction: string) => `Trigger a market ${orderAction} at a fixed stop price.`,
                triggerLimit: (orderAction: string) => `Trigger a limit ${orderAction} at a fixed stop price.`
            },
            marketPrice: 'Market Price',
            limitPrice: 'Limit Price',
            stopPrice: 'Stop Price',
            stopLimitPrice: 'Stop-Limit Price',
            numberOfUnits: (symbol: string) => `Number of ${Snex1English.general.unit(symbol, 0)}`,
            orderSubmitted: 'Order Submitted',
            checkForStatusUpdatesUnderPositions: 'Check for status updates under positions',
            submitAnotherOrder: 'Submit Another Order',
            submittingOrder: 'Submitting Order',
            pleaseWait: 'Please Wait...',
            youMayClickAway: "You may click away now, we'll let you know when things go through.",
            thereWasAProblem: 'There Was A Problem',
            pleaseTryAgain: 'Please try again',
            estimatedTotalCost: 'Estimated Total Cost',
            estimatedTotalGain: 'Estimated Total Gain',
            estimatedTotalCredit: 'Estimated Total Credit',
            estimatedTotalDebit: 'Estimated Total Debit',
            leg1: 'Leg 1',
            leg2: 'Leg 2',
            timeInForce: 'Time In Force'
        },
        afterHours: {
            disclosure: {
                title: 'Extended Hours Trading Session Advisory',
                shortDisclosure: 'Trading during Extended Hours Trading Sessions carries unique risks.',
                longDisclosure: [
                    { shade: 'dark', text: 'Trading during Extended Hours Trading Sessions' },
                    {
                        shade: 'light',
                        text: '(including the Pre-Market Session (Monday through Friday 8:00 a.m. to 9:30 a.m. ET), and the After-Market Session (Monday through Friday 4:00 p.m. to 5:00 p.m. ET), in each case subject to holiday trading hours)'
                    },
                    { shade: 'dark', text: ', carries unique risks,' },
                    {
                        shade: 'light',
                        text: ' such as greater price volatility, lower liquidity, wider bid/ask spreads, and less market visibility, and may not be appropriate for all investors.\n\nStoneX One cannot guarantee that Extended Hours orders will be executed at the best price for any given security or that any such orders, including market orders, will be executed at all. By requesting access to enter orders and entering any order during Extended Hours Trading at StoneX One, including on any StoneX One trading platform or mobile platform, you acknowledge that you understand the risks of trading during Extended Hours Trading Sessions.'
                    }
                ]
            }
        },
        shortSales: {
            acceptance: (quantity, rate) => {
                return [Light('You are going short'), Dark(`${quantity} share${quantity === 1 ? '' : 's'}`), Light('at a rate of'), Dark(`${rate}%`), Light('.')];
            },
            thisWillShortYou: (quantityShort: number, rate: number) => {
                return [
                    Light('You will go short'),
                    Dark(`${quantityShort} share${quantityShort === 1 ? '' : 's'}`),
                    Light('at a rate of'),
                    Dark(FormatNumber.toPercent(rate, undefined, 4)),
                    Light('.')
                ];
            },
            disclosureTitle: 'Stock Borrow Rates',
            disclosure: (isExpanded) =>
                isExpanded
                    ? 'The stock borrow rates displayed are indicative and may not represent the actual rates paid to borrow the security. Indicative borrow rates are created by StoneX Financial Inc. based on several factors and multiple sources. The actual stock borrow rate paid on borrowing a security may vary significantly from the indicative rate based on the market conditions for that security at the time of the loan. Please call the Securities Lending desk at (212) 379-5450 with any questions.'
                    : 'The stock borrow rates displayed are indicative and may not represent the actual rates paid to borrow the security.',
            viewMore: 'View More',
            viewLess: 'View Less'
        },
        offshore: {
            disclosure: {
                title: 'Offshore Mutual Fund Trading Important Information',
                shortDisclosure: 'Offshore Mutual Funds Trading important information.',
                longDisclosure: [
                    {
                        shade: 'dark',
                        text: 'Offshore Mutual Fund cutoff times, settlement periods, and pre-notification periods vary by fund. You will receive the first available NAV issued by the fund. Please refer to the prospectus of the fund for cutoff times, fund fees, and other important information before investing. Offshore Mutual Fund orders may not be canceled or modified through the system once submitted. Please reach out to Support to request a cancelation or modification.'
                    }
                ]
            }
        },
        crypto: {
            disclosure: {
                title: 'Crypto Trading and Account Information',
                shortDisclosure: 'Cryptocurrency trading involves significant risk and may result in the loss of your invested capital. Please trade responsibly.',
                longDisclosure: [
                    {
                        lineBreak: 'double',
                        text: 'All cryptocurrency trading activities facilitated through StoneX One Pro are conducted via ETANA, a trusted third-party service provider. Please be aware that when you engage in cryptocurrency trading, you are interacting directly with ETANA, and all trades are executed on their systems.'
                    },
                    {
                        lineBreak: 'double',
                        text: '\n\nFurthermore, your cryptocurrency accounts are opened and maintained at ETANA. This means that ETANA is responsible for holding, securing, and managing your crypto assets. StoneX One Pro serves as a conduit to ETANA’s services, providing you with access to their trading environment.'
                    },
                    {
                        lineBreak: 'double',
                        text: '\n\nPlease note the following key points:'
                    },
                    {
                        text: '\n1.'
                    },
                    {
                        shade: 'dark',
                        text: 'Execution of all Trades'
                    },
                    {
                        lineBreak: true,
                        text: ': All cryptocurrency trades initiated on StoneX One Pro are executed by ETANA. We do not execute any trades on our systems.'
                    },
                    {
                        text: '\n2.'
                    },
                    {
                        shade: 'dark',
                        text: 'Account Management'
                    },
                    {
                        lineBreak: true,
                        text: ': Your cryptocurrency accounts, including all deposits and withdrawals, are managed by ETANA.'
                    },
                    {
                        text: '\n3.'
                    },
                    {
                        shade: 'dark',
                        text: 'Security and Custody'
                    },
                    {
                        lineBreak: true,
                        text: ': ETANA is responsible for the security and custody of your cryptocurrency assets. StoneX Financial does not hold or secure any cryptocurrency assets directly.'
                    },
                    {
                        text: '\n4.'
                    },
                    {
                        shade: 'dark',
                        text: 'Fees and Charges'
                    },
                    {
                        lineBreak: true,
                        text: ': Any fees or charges related to cryptocurrency trading will be determined and collected by ETANA. Please refer to ETANA’s fee schedule for detailed information.'
                    },
                    {
                        text: '\n5.'
                    },
                    {
                        shade: 'dark',
                        text: 'Regulatory Oversight'
                    },
                    {
                        lineBreak: 'double',
                        text: ': ETANA operates under its own regulatory framework. It is essential to review ETANA’s terms of service, privacy policy, and any regulatory disclosures they provide.'
                    },
                    {
                        lineBreak: 'double',
                        text: '\n\nBy using StoneX One Pro for cryptocurrency trading, you acknowledge and agree to these terms. It is important to conduct your own due diligence and understand the risks involved in cryptocurrency trading. For any issues or questions related to your crypto accounts or trades, please contact ETANA directly.'
                    },
                    {
                        shade: 'dark',
                        text: '\n\nDisclaimer'
                    },
                    {
                        text: ': Cryptocurrency trading involves significant risk and may result in the loss of your invested capital. Please trade responsibly.'
                    }
                ]
            }
        }
    },
    orders: {
        multipleOrders: 'Multiple orders',
        accountNumber: 'Account Number',
        account: 'Account',
        acct: 'Acct',
        contractSymbol: 'Contract Symbol',
        dateAndTime: 'Date & Time',
        filledAmount: 'Filled Amount',
        fillPrice: 'Fill Price',
        onClose: 'On Close',
        order: 'Order',
        openOrders: 'Open Orders',
        tradeHistory: 'Trade History',
        orderHistory: 'Order History',
        tradeConfirmation: 'View Trade Confirmations',
        expirationDate: 'Expiration Date',
        true: 'True',
        spreadSymbol: 'Spread Symbol',
        buySide: 'Buy Side',
        sellSide: 'Sell Side',
        completedDate: 'Completed Date',
        fees: 'Fees',
        averageTradePrice: 'Average Price',
        actionSummary: (props: ActionSummaryProps): string => {
            const action = Snex1English.orders.typedAction(props.order);
            const marketPriceText = props?.format === 'short' ? 'MP' : 'market price';
            return getActionSummary({ ...props, action, marketPriceText });
        },
        stratValue: (strat: ApiTradeStrategy) => {
            switch (strat) {
                case 'Care':
                    return 'Care';
                case 'SOR':
                    return 'SOR';
                case 'VWAP':
                    return 'VWAP';
                case 'TWAP':
                    return 'TWAP';
                case 'POV2':
                    return 'POV 2%';
                case 'POV5':
                    return 'POV 5%';
                case 'POV10':
                    return 'POV 10%';
                case 'POV15':
                    return 'POV 15%';
                case 'POV20':
                    return 'POV 20%';
                case 'POV25':
                    return 'POV 25%';
            }
        },

        fullActionTypeToOpenOrClose: (o) => {
            switch (o.action?.toLowerCase()) {
                case 'buytoopen':
                    return 'Buy to open';
                case 'buytoclose':
                    return 'Buy to close';
                case 'selltoopen':
                    return 'Sell to open';
                case 'selltoclose':
                    return 'Sell to close';
                case 'sellshort':
                    return 'Short sell';
                default:
                    return o.action;
            }
        },

        numberFilledOfTotal: (o) =>
            typeof o.leavesQuantity === 'number'
                ? `${FormatNumber.toDecimal(o.orderQuantity - o.leavesQuantity, 0)} of ${FormatNumber.toDecimal(o.orderQuantity, 0)} filled`
                : '',

        actionSummary2: (o, opt, hideMoneySymbol, formatValue) => {
            const act = Snex1English.orders.typedAction(o);
            const q = Snex1English.orders.quantityPriceSummary2(o, opt, hideMoneySymbol, formatValue);
            switch (opt?.format) {
                case 'short':
                    return `${act} ${q}`;
                case 'long':
                default:
                    return `${act} ${q}`;
            }
        },

        quantityPriceSummary2: (o, opt, hideMoneySymbol, formatValue) => {
            const ac = GetAssetClassForSecurity(o.symbol);
            const orderType = DetermineOrderType(o);

            const amt: string = (() => {
                const fmt = (n: number): string =>
                    formatValue ? formatValue(n) : FormatNumber.toMoney(n, undefined, hideMoneySymbol ? 'never' : undefined, true, opt?.decimalPlaces || 2);
                const mp = opt?.format === 'short' ? 'MP' : 'market price';
                switch (orderType) {
                    case 'limit':
                    case 'stoplimit':
                        return fmt(o.limitPrice);
                    case 'stop':
                        return fmt(o.stopPrice);
                    case 'market':
                        return mp;
                    case 'even':
                        return 'even';
                }
            })();
            return `${ac.formatOrderQuantity(o)} @ ${amt}`;
        },

        action: (a, secType) => {
            const actions = {
                buytoopen: secType !== 'futures' ? 'buy to open' : 'buy',
                buytoclose: secType !== 'futures' ? 'buy to close' : 'buy',
                selltoopen: secType !== 'futures' ? 'sell to open' : 'sell',
                selltoclose: secType !== 'futures' ? 'sell to close' : 'sell',
                sellshort: 'short sell',
                buytocover: 'buy to cover',
                selltocover: 'sell to cover'
            };

            const actionInput = (a || '')?.toLowerCase();
            if (actionInput === 'fullliquidation') return 'full liquidation';
            const action = actions[actionInput] || actionInput;

            return action;
        },
        multiLegAction: (o) => {
            const actionInput = (o?.action || '')?.toLowerCase();
            const leg2Input = (o?.complexOrders?.[0]?.action || '')?.toLowerCase();
            return actionInput === leg2Input
                ? `${Snex1English.orders.action(actionInput)} 2`
                : `${Snex1English.orders.action(actionInput)} 1, ${Snex1English.orders.action(leg2Input)} 1`;
        },
        typedAction: (o, secType) => {
            const orderType = DetermineOrderType(o);

            const orderTypeLabel = (() => {
                switch (orderType) {
                    case 'market':
                        return 'market';
                    case 'limit':
                        return 'limit';
                    case 'stop':
                        return 'stop';
                    case 'stoplimit':
                        return 'stop limit';
                    case 'even':
                        return 'even';
                }
            })();

            return `${orderTypeLabel} ${Snex1English.orders.action(o.action, secType)}`;
        },
        orderSetup: {
            orderSetup: 'Order Setup',
            expires: 'Expires',
            orderType: 'Order Type',
            priceType: 'Price Type',
            quantityType: 'Quantity Type',
            notional: 'Notional',
            algorithm: 'Algo Strategy',
            stratPercent: 'Strategy Percentage',
            sellAll: 'Sell All',
            done: 'Done',
            discardTitle: 'Discard',
            discardChanges: 'Discard Changes',
            discardChangesMsg: 'You have unsaved changes. Are you sure you want to discard your changes?',
            stayHere: 'Stay Here',

            action: 'Action',
            actionBottomSheet: {
                buySell: 'Buy-Sell',
                sellBuy: 'Sell-Buy',
                buyTheEarlierAndSellTheLater: 'Buy the earlier contract and sell the later contract',
                sellTheEalierAndBuyTheLater: 'Sell the earlier contract and buy the later contract',
                buying: 'Buying',
                selling: 'Selling'
            },

            expiresBottomsheet: {
                expires: 'Expires',
                goodThruTitle: 'Good Thru Day',
                goodThruSubtitle: 'Order will be good until the end of the current trading day.',
                goodTillCancelled: 'Good Til Canceled (GTC)',
                goodTillCancelltedSubtitle: 'Order will be good until it is canceled, for a maximum of 180 days.',
                dayOrdersUnavailable: 'Day orders are not available at this time',
                gtcOrdersUnavailable: 'GTC orders are not available at this time',
                premarketUnavailable: 'Pre-market orders are not available at this time',
                postMarketUnavailable: 'Post-market orders are not available at this time',

                preMarketExecution: 'Pre-Market Execution',
                preMarketActive: 'Order will be active and may execute between 8AM-5PM EST',
                postMarketExcution: 'Post-Market Execution',
                postMarketActive: 'Order will be active during the PM Session today only and may execute between 4PM and 5PM EST',
                goodTilCancelActive: 'Order will be active until it is canceled, for a maximum of 180 days.',
                goodTilCancelActiveFutures: 'Order will be active until it is canceled.',
                goodThruDayActive: 'Order will be active until the end of the current trading day.',

                fillOrKillExecution: 'Fill Or Kill',
                fillOrKillActive: 'Fill entire order immediately or cancel it'
            }
        },
        quantity: 'Quantity',
        commission: 'Commission',
        stopPrice: 'Stop Price',
        limitPrice: 'Limit Price',
        totalCost: 'Total Cost',
        orderTotalImpactLabel: (action: 'Buy' | 'Sell' | string) => {
            const regex = /sell/i; // This will match any variation of 'Sell'
            const containsSell = regex.test(action);
            return containsSell ? 'Total Credit' : 'Total Cost';
        },
        swipeUpTrade: 'Swipe up to trade',
        placed: 'Placed',
        by: (who: string) => `by ${who}`,
        timeInForce: 'Time In Force',
        gtxPre: 'Pre',
        gtxPost: 'Post',
        isin: 'ISIN',
        strategy: 'Strategy',
        averagePrice: 'Average Price',
        actionLabel: 'Action',

        placedBy: 'Placed By',
        securityName: 'Security Name',
        contactRepToChange: 'To cancel or make changes to your order, please contact Support.',
        cannotBeCanceled: 'Order cannot be canceled or modified',
        lockedForOpen: 'Order is locked for the open. Please try again at 9:30 AM EST',
        orderNumber: 'Order Number',
        orderNumbers: 'Order Numbers',
        cancelingOrder: 'Canceling Order',
        orderCanceled: 'Order Canceled',
        orderModified: 'Order Modified',
        orderFilled: 'Order Filled',
        orderPartiallyFilled: 'Order Partially Filled',
        orderPlaced: 'Order Placed',
        orderRejected: 'Order Rejected',
        orderStopped: 'Order Stopped',
        orderSuspended: 'Order Suspended',
        orderBiddingOpen: 'Bidding Open for Order',
        orderId: 'Order ID',
        cancelOrder: 'Cancel Order',
        editOrder: 'Edit Order',
        edit: 'Edit',
        cancel: 'Cancel',
        orderAgain: 'Order Again',
        cannotCancelEditOrder: (action: string) => {
            return action === 'edit' ? 'Cannot Edit Order' : 'Cannot Cancel Order';
        },
        cannotCancelEditSublabel: (action: string) => {
            return action === 'edit' ? 'This order cannot be edited at this time' : 'This order cannot be canceled at this time';
        },
        contactLabel: (useSupport: boolean) => {
            return useSupport ? 'Contact Support' : 'Contact Representative';
        },
        contactSubLabel: (onlyEdit: boolean, useSupport: boolean, editAndCancel?: boolean) => {
            return `This order can only be ${onlyEdit ? 'edited' : editAndCancel ? 'edited or canceled' : 'canceled'} by contacting ${
                useSupport ? 'Support' : 'your representative'
            }`;
        },
        partialFill: (symbol, filled, quantity) => {
            const unit = OptionSymbol.IsOptionSymbol(symbol) ? 'contracts' : 'shares';
            return `${filled} of ${quantity} ${unit} filled`;
        },
        fills: {
            sequenceNumber: 'Fill Sequence Number',
            price: 'Fill Price',
            quantity: 'Quantity',
            timestamp: 'Timestamp'
        },
        statuses: {
            open: 'Open',
            working: 'Working',
            pending: 'Pending',
            completed: 'Completed',
            filled: 'Filled',
            cancelled: 'Canceled',
            cancelPending: 'Cancel Pending',
            cancelPartialFill: 'Partially Canceled',
            allocated: 'Allocated',
            preAllocated: 'Pre-Allocated',
            created: 'Created',
            expired: 'Expired',
            route: 'Route',
            routed: 'Routed',
            verification: 'Verification',
            change: 'Change',
            notProcessed: 'Not Processed',
            processed: 'Processed',
            confirmed: 'Confirmed',
            cancel: 'Cancel',
            canceled: 'Canceled',
            rejected: 'Rejected',
            unknown: 'Unknown',
            deleted: 'Deleted'
        }
    },

    messages: {
        subjectByPayload: {
            'System:AdminBroadcast': 'Announcement',
            'System:AdminDirect': 'Administrator Message',
            'System:Welcome': 'Welcome!',
            'CustomAlert:Default': 'Alert',
            'OrderUpdate:New': 'Order Open',
            'OrderUpdate:Filled': 'Order Filled',
            'OrderUpdate:Rejected': 'Order Rejected',
            'OrderUpdate:Canceled': 'Order Canceled',
            defaultOrderUpdate: 'Order Update'
        }
    },

    tours: {
        stepsBadgePreposition: 'of',
        lastStepButtonText: 'Done',
        portfolioScreen: {
            chart: [[{ text: 'Keep track of your ' }, { text: 'portfolio', weight: 'bold' }, { text: ' using the portfolio chart.' }]],
            watchlist: [
                [
                    {
                        text: 'Positions from all your accounts are displayed here.'
                    }
                ],
                [{ text: 'You can also ' }, { text: 'customize your watchlist', weight: 'bold' }, { text: '.' }]
            ],
            depositFunds: [
                [
                    { text: 'Click here to ' },
                    { text: 'deposit funds', weight: 'bold' },
                    {
                        text: '. Once you have deposited funds, you can start trading!'
                    }
                ]
            ],
            search: [[{ text: 'Search for stocks, mutual funds, or anything else!' }]],
            profile: [
                [
                    { text: 'Click the profile image up here to view your ' },
                    {
                        text: 'account settings, transactions, and much more',
                        weight: 'bold'
                    },
                    { text: '!' }
                ]
            ]
        },
        stockScreen: {
            candlestick: [
                [{ text: 'Want a ' }, { text: 'candle-stick', weight: 'bold' }, { text: ' chart?' }],
                [
                    {
                        text: 'Click on this icon to toggle between a line and candle-stick chart.'
                    }
                ]
            ],
            advancedChart: [
                [
                    { text: 'Our ' },
                    { text: 'advanced chart', weight: 'bold' },
                    {
                        text: ' gives you access to an arsenal of charting tools and the ability to '
                    },
                    { text: 'trade directly from the chart', weight: 'bold' },
                    { text: '.' }
                ]
            ],
            addToWatchlist: [[{ text: 'Want to keep tabs on this security? ' }, { text: 'Add it your watchlists', weight: 'bold' }, { text: '.' }]],
            orderType: [
                [{ text: 'Modify the ' }, { text: 'order type', weight: 'bold' }, { text: ' by clicking on the pencil icon here.' }],
                [{ text: '(market, limit, stop, etc.)' }]
            ],
            optionsTrading: [[{ text: 'You can access the ' }, { text: 'option chain', weight: 'bold' }, { text: ' for this security by clicking here.' }]]
        },
        premiumStockScreen: {
            advancedChart: [
                [{ text: 'Multichart on a single screen.' }],
                [{ text: 'Click here to perform ' }, { text: 'technical analysis', weight: 'bold' }, { text: ' on a single view!' }]
            ],
            alerts: [
                [{ text: 'Set quick alerts.' }],
                [
                    { text: 'Click the bell icon to set up ' },
                    { text: 'triggers', weight: 'bold' },
                    { text: ' based on ' },
                    { text: 'real-time price movements', weight: 'bold' },
                    { text: '. Be the first to know!' }
                ]
            ],
            deepAnalysis: [
                [{ text: 'Dive in with data.' }],
                [
                    {
                        text: 'Click here for a comprehensive view that combines company insight with real-time market data.'
                    }
                ]
            ],
            streamingNews: [
                [{ text: 'You have real-time news!' }],
                [
                    {
                        text: "The latest news appears at the top the moment it's published."
                    }
                ]
            ]
        },
        tickerBar: {
            tradingViewMarquee: [
                [{ text: 'On the Ticker Bar, grab a horizontal glance of indices, commodities, and more.  ' }],
                [{ text: 'Click on the symbols to learn more. You can toggle this on/off from your Profile settings.' }]
            ]
        }
    },

    mobileTours: {
        summaryScreen: {
            watchlist: {
                text: 'Swipe up here to view and manage your watchlists',
                name: 'Watchlist'
            }
        },
        securityScreen: {
            alerts: {
                text: 'Tap here to manage your alerts for this security',
                name: 'Alerts'
            },
            chartToggle: {
                text: 'Switch between chart views (line/candlestick) by simply tapping here',
                name: 'Chart Toggle'
            },
            advancedChart: {
                text: 'Tap here to open the advanced chart, with features like indicators right at your fingertips',
                name: 'Advanced Chart'
            },
            trade: {
                text: 'Swipe up from here to open the trade ticket and start trading!',
                name: 'Trading'
            }
        },
        navigateToursAlert: {
            title: 'View Tours',
            message: 'Navigate through the app to view tours.'
        }
    },

    newFeatures: {
        advancedOptionTrading: {
            title: 'Advanced Option Trading',
            subtitle: 'Multi-leg options trading involves executing multiple option trades at once to create a specific strategy.',
            item1Title: 'Two Trades in One',
            item1Subtitle:
                'Multi-leg options trading involves executing several option trades simultaneously. Roll out existing covered and long positions in one trade ticket. Create spreads and straddles.',
            item2Title: 'Control Risk-Reward',
            item2Subtitle: 'By combining options, you can create specific risk-reward profiles.',
            item3Title: 'Test Your Market Predictions',
            item3Subtitle:
                'Different option strategies can be employed to grow or protect your portfolio based on your predictions about price movement, volatility changes, or time decay.'
        },
        cryptoTrading: {
            title: 'Cryptocurrencies are now available!',
            subtitle: 'Embark on Your Journey: Effortlessly Buy and Sell Cryptocurrencies and Embrace the Future of Finance!',
            item1Title: 'Execution of Trades',
            item1Subtitle: 'All cryptocurrency trades initiated on StoneX One Pro are executed by ETANA. We do not execute any trades on our systems.',
            item2Title: 'Account Management',
            item2Subtitle: 'Your cryptocurrency accounts, including all deposits and withdrawals, are managed by ETANA.',
            item3Title: 'Security and Custody',
            item3Subtitle:
                'ETANA is responsible for the security and custody of your cryptocurrency assets. StoneX Financial does not hold or secure any cryptocurrency  assets directly.'
        }
    },

    miniplayer: {
        loading: (title: string | null) => `Loading ${title || 'media'}...`,
        noTranscriptAvailable: 'No Transcript Available',
        noMediaAvailable: 'No Media Available',
        viewTranscript: 'View Transcript'
    },

    modals: {
        done: 'Done',
        appIdle: {
            modalHeader: "You're Idle!",
            noticedInactivity: "We've noticed that you've been inactive for a while",
            logoutInSeconds: (seconds: number) => `If no action is taken, you will be logged out in ${seconds} seconds.`,
            logout: 'Logout',
            stay: 'Stay'
        },
        addManyToListContent: {
            title: 'Add a security using the search above',
            button: 'Add To Watchlist',
            addSecurity: 'Add securities'
        },
        addToWatchlist: {
            addSymbolToWatchlist: (symbol: string) => `Add ${symbol} to Watchlist`,
            createAList: 'Create a List',
            newListName: 'New List Name',
            create: 'Create',
            creating: 'Creating...'
        },
        viewWatchlistDetails: 'View watchlist details',
        deleteWatchlist: {
            deleteThisList: 'Delete This List',
            areYouSure: (listName: string) => [
                [{ text: 'Are you sure you want to permanently delete ' }, { text: listName, weight: 'bold' }, { text: '? This cannot be undone.' }]
            ],
            areYouSureShaded: (listName: string) => [
                {
                    text: 'Are you sure you want to permanently delete ',
                    shade: 'light'
                },
                { text: listName, shade: 'dark' },
                { text: '? This cannot be undone.', shade: 'light' }
            ],
            yesDeleteList: (listName: string) => `Yes, delete ${listName}`,
            delete: 'Delete',
            deleting: 'Deleting...'
        },
        editWatchlistDetails: {
            editList: (listName: string) => `Edit ${listName}`,
            name: 'Watchlist name...',
            description: 'Watchlist description...',
            save: 'Save',
            saving: 'Saving...'
        },
        deleteAllMessages: {
            label: 'Delete All Messages',
            body: 'Are you sure you would like to delete all messages?',
            no: 'No',
            yes: 'Yes'
        },
        cancelOrder: {
            orderCancelled: 'Order Canceled!',
            cancelOrder: 'Cancel Order',
            cancelOrderFailed: 'Order Cancelation Failed!',
            retry: 'Please retry, or contact Support if the issue persists.',
            areYouSure: 'Are you sure you want to cancel your order?',
            cancelLabel: 'Nevermind',
            ctrlKeyTip: [
                [
                    { text: 'Tip:', weight: 'bold' },
                    { text: ' Hold the ' },
                    { text: ' Ctrl ', weight: 'bold' },
                    { text: ' or ' },
                    { text: ' Command ⌘ ', weight: 'bold' },
                    {
                        text: ' key while canceling an order to bypass this confirmation '
                    }
                ]
            ],
            cancel: 'Confirm Cancelation',
            retryCancel: 'Retry Cancelation',
            canceling: 'Canceling...'
        },
        rename: {
            rename: 'Rename',
            save: 'Save',
            saving: 'Saving...'
        },

        errorModal: {
            unknownError: 'An unknown error occurred'
        },
        existingAccountModal: {
            cannotRemoveAcctTitle: 'Cannot Remove Account',
            cannotRemoveAcctDesc: 'Sorry, accounts can only be removed once every six months. Please contact support if you would like to remove it sooner.',
            cannotRemoveAcctConfirm: 'Confirm',
            removeLinkedAcctTitle: 'Remove Linked Account',
            removeLinkedAcctConfirm: (name: string) => `Are you sure you want to remove ${name} as a linked account`,
            yesRemoveAcct: 'Yes, Remove Account',
            noKeppAcct: 'No, Keep Account',
            loadingYourAcct: 'Loading your accounts...',
            voidingAcct: 'Voiding account...',
            addAnAcct: 'Add An Account'
        },
        linkedAccountsModal: {
            retrivingLinkableAcct: 'Retrieving your linkable accounts...',
            linkingAcctWait: 'Linking your account, please wait...'
        },
        plaidModal: {
            plaidCompleted: 'Plaid completed without giving back an account',
            overlimitAccounts: "We're sorry, you may not add an account at this time.",
            holdTight: 'Hold tight, adding your account',
            verifyingBankAuth: 'Verifying your bank authorization...'
        }
    },

    toasts: {
        error: 'Error',
        sessionExpired: 'Session Expired',
        logInAgain: 'Log In Again',
        orderSubmitted: 'Order Submitted!',
        onboardingSuccess: () => {
            return 'Congratulations! Your account is currently being processed and will appear shortly.';
        },
        onboardingSuccessTitle: (accountNumber) => {
            return `Your futures account (${accountNumber}) is being created.`;
        },
        orderSubmittedContent: (trade: Partial<Order>) =>
            `Your order to ${trade?.action?.toUpperCase()} ${trade?.orderQuantity} ${Snex1English.general.unit(trade?.symbol, trade?.orderQuantity)} of ${
                trade?.symbol
            } has been submitted (${trade?.orderNumber})`,
        orderUpdateTitle: (update) => {
            const name = update.symbol ? Snex1English.general.symbolName(update.symbol) : update.symbol || update.securityName || update.securityNumber;
            return name ? `Order Update: ${name}` : 'Order Update';
        },
        orderUpdate: (update, message) => {
            const action = (() => {
                switch (update.action) {
                    case 'Buy':
                        return 'buy';
                    case 'Sell':
                        return 'sell';
                    case 'BuyToOpen':
                        return 'buy to open';
                    case 'SellToOpen':
                        return 'sell to open';
                    case 'BuyToClose':
                        return 'buy to close';
                    case 'SellToClose':
                        return 'sell to close';
                    default:
                        return 'trade';
                }
            })();

            const statusPhrase = (() => {
                switch (update.orderStatus) {
                    case 'Allocated':
                        return 'has been allocated';
                    case 'Verification':
                        return 'is being verified';
                    case 'Created':
                        return 'has been created';
                    case 'Open':
                        return FuturesSymbol.IsFuturesSymbol(update.symbol) ? 'is now working' : 'is now open';
                    case 'Route':
                        return 'is ready to be routed';
                    case 'Routed':
                        return 'has been routed';
                    case 'Cancel':
                        return 'is ready for cancelation';
                    case 'Canceled':
                        return 'has been canceled';
                    case 'Filled':
                        return 'has been filled';
                    case 'Rejected':
                        return 'has been rejected';
                    case 'Deleted':
                        return 'has been deleted';
                    case 'Expired':
                        return 'has expired';
                    case 'Pending':
                        return 'is pending';
                    case 'Completed':
                        return 'has completed';
                    case 'Cancel Pending':
                        return 'is pending cancelation';
                    case 'Pre-Allocated':
                        return 'has been pre-allocated';
                    case 'Cancel Partial Fill':
                    case 'Unknown':
                    default:
                        return `has been updated to the status "${update.orderStatus}"`;
                }
            })();

            const sym = update.symbol ? Snex1English.general.symbolName(update.symbol) : update.securityName || update.securityNumber;

            if (message.payloadType === 'OrderUpdate:Rebook') return `Your edited ${sym} order is now in effect`;

            if (update.orderQuantity === null) {
                return `Your order ${update.action ? `to ${action}` : 'for'} ${sym} ${message?.data['filledAt']}${statusPhrase} (${update.orderNumber})`;
            }
            return `Your order ${update.action ? `to ${action}` : 'for'} ${update.orderQuantity} ${Snex1English.general.unit(
                update.symbol,
                update.orderQuantity
            )} of ${sym} ${statusPhrase} (${update.orderNumber})`;
        }
    },

    infoIconHelpText: {
        client: {
            aboutSecurity: [
                [
                    {
                        text: 'Certain fundamental, market data, or other information is provided directly or indirectly by third party data providers and may vary from other sources.'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: 'The Synthetic Risk and Reward Indicator (or SRRI), is a significant piece of the Key Investor Information Document (or KIID). The SRRI is used to indicate the level of risk of a UCITS fund by formulating a number from 1 to 7, where 1 is indicating a lower risk and 7 indicating higher risk.'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'The rejection of an ACATS transfer request is typically initiated by action of the delivering broker once that broker has had an opportunity to review the request and confirm the details of the account to be transferred. In the case of certain rejection notices (i.e., categories 1-5 and 10 below), the ACATS process affords the receiving broker (IB) a 24-hour window within which revised information may be transmitted and after which time the transfer request will require resubmission by the client. During this 24-hour window, IB will attempt to contact the transferring client in an effort to reconcile any discrepancies causing the initial rejection notice. Rejections general fall into the following categories:'
                    }
                ],
                [{ text: 'Rejections by the Delivering Broker:' }],
                [
                    {
                        text: "1. Social Security Number/Tax ID Mismatch - the client taxpayer identification number as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: "2. Account Title Mismatch - the client account title as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: '3. Documentation Needed - the delivering broker requires additional account documentation in order to process a transfer request (e.g., death or marriage legal documents.'
                    }
                ],
                [
                    {
                        text: '4. Account Flat - account holds no transferable assets.'
                    }
                ],
                [
                    {
                        text: "5. Invalid Account Number - the client account number as provided by the receiving broker is not the delivering member's books."
                    }
                ],
                [
                    {
                        text: '6. Duplicate - delivering broker is already in receipt of a transfer request involving the same receiving broker and client account.'
                    }
                ],
                [
                    {
                        text: '7. Account in Distribution or Transfer - another ACATS transfer or other distribution of account assets directly to the client is already underway.'
                    }
                ],
                [
                    {
                        text: '8. Client Rescinded - client has submitted written request to cancel transfer request.'
                    }
                ],
                [
                    {
                        text: '9. Missing Authorization Signature - transfer request require an additional client and/or custodian signature.'
                    }
                ],
                [{ text: 'Rejections by the Receiving Broker:' }],
                [
                    {
                        text: '10. Credit Violation - the result of the transfer if effected would be to place the account in margin deficit and subject to forced liquidation.'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: 'Your buying power is the amount of total cash in the account available to purchase securities plus all available margin.'
                    }
                ],
                DelayedParagraph
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'The SECTORS and MOVERS lists are generated using data sourced from third party data providers. A list of companies in a specific business sector may display a company if the source data indicates that the company derives a portion of its revenues from the relevant sector. The list is provided for informational purposes only and is not investment advice or a recommendation to buy, hold, or sell any security. Lists are not personalized recommendations to you or to any other customer, and the securities listed may not be suitable for you. You should not buy or sell any security without first determining it is appropriate for your portfolio or investment strategy. StoneX One does not make any warranty or guarantee relating to the accuracy, timeliness or completeness of any third-party information, and the provision of this information does not constitute a recommendation.'
                    }
                ],
                DelayedParagraph
            ],
            news: [
                [
                    {
                        text: 'News, commentary, and events are from third-party sources unaffiliated with StoneX. StoneX does not endorse or adopt their content. StoneX makes no guarantees that information supplied is accurate, complete, or timely, and does not provide any warranties regarding results obtained from their use.'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: 'Your portfolio value is the total market value of all positions in the account, including cash balances, minus any outstanding debit balances and any amount required to cover short option positions that are in-the-money.'
                    }
                ],
                DelayedParagraph
            ]
        },
        one: {
            aboutSecurity: [
                [
                    {
                        text: 'Certain fundamental, market data, or other information is provided directly or indirectly by third party data providers and may vary from other sources.'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: 'The Synthetic Risk and Reward Indicator (or SRRI), is a significant piece of the Key Investor Information Document (or KIID). The SRRI is used to indicate the level of risk of a UCITS fund by formulating a number from 1 to 7, where 1 is indicating a lower risk and 7 indicating higher risk.'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'The rejection of an ACATS transfer request is typically initiated by action of the delivering broker once that broker has had an opportunity to review the request and confirm the details of the account to be transferred. In the case of certain rejection notices (i.e., categories 1-5 and 10 below), the ACATS process affords the receiving broker (IB) a 24-hour window within which revised information may be transmitted and after which time the transfer request will require resubmission by the client. During this 24-hour window, IB will attempt to contact the transferring client in an effort to reconcile any discrepancies causing the initial rejection notice. Rejections general fall into the following categories:'
                    }
                ],
                [{ text: 'Rejections by the Delivering Broker:' }],
                [
                    {
                        text: "1. Social Security Number/Tax ID Mismatch - the client taxpayer identification number as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: "2. Account Title Mismatch - the client account title as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: '3. Documentation Needed - the delivering broker requires additional account documentation in order to process a transfer request (e.g., death or marriage legal documents.'
                    }
                ],
                [
                    {
                        text: '4. Account Flat - account holds no transferable assets.'
                    }
                ],
                [
                    {
                        text: "5. Invalid Account Number - the client account number as provided by the receiving broker is not the delivering member's books."
                    }
                ],
                [
                    {
                        text: '6. Duplicate - delivering broker is already in receipt of a transfer request involving the same receiving broker and client account.'
                    }
                ],
                [
                    {
                        text: '7. Account in Distribution or Transfer - another ACATS transfer or other distribution of account assets directly to the client is already underway.'
                    }
                ],
                [
                    {
                        text: '8. Client Rescinded - client has submitted written request to cancel transfer request.'
                    }
                ],
                [
                    {
                        text: '9. Missing Authorization Signature - transfer request require an additional client and/or custodian signature.'
                    }
                ],
                [{ text: 'Rejections by the Receiving Broker:' }],
                [
                    {
                        text: '10. Credit Violation - the result of the transfer if effected would be to place the account in margin deficit and subject to forced liquidation.'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: 'Your buying power is the amount of total cash in the account available to purchase securities plus all available margin.'
                    }
                ]
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'The SECTORS and MOVERS lists are generated using data sourced from third party data providers. A list of companies in a specific business sector may display a company if the source data indicates that the company derives a portion of its revenues from the relevant sector. The list is provided for informational purposes only and is not investment advice or a recommendation to buy, hold, or sell any security. Lists are not personalized recommendations to you or to any other customer, and the securities listed may not be suitable for you. You should not buy or sell any security without first determining it is appropriate for your portfolio or investment strategy. StoneX One does not make any warranty or guarantee relating to the accuracy, timeliness or completeness of any third-party information, and the provision of this information does not constitute a recommendation.'
                    }
                ]
            ],
            news: [
                [
                    {
                        text: 'News, commentary, and events are from third-party sources unaffiliated with StoneX. StoneX does not endorse or adopt their content. StoneX makes no guarantees that information supplied is accurate, complete, or timely, and does not provide any warranties regarding results obtained from their use.'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: 'Your portfolio value is the total market value of all positions in the account, including cash balances, minus any outstanding debit balances and any amount required to cover short option positions that are in-the-money.'
                    }
                ]
            ],
            marketCap: [
                [
                    {
                        text: "Market capitalization is the estimated value of a company's outstanding shares. Depending on the size of that value, companies are sorted into Large, Mid, Small, Micro and Nano categories."
                    }
                ]
            ]
        }
    },

    errors: {
        imageUploadError: 'Unable to upload image. Please sure file is an image, and less than 10MB.',
        accessRestricted: 'Access Restricted',
        loginDead: "Hi! We see you're trying to get into StoneX One!\nPlease reach out to the email below to get your account turned on.",
        loginDeadWithDetail: (getIntoWhat: string, email: string): string =>
            `Hi! We see you're trying to get into ${getIntoWhat}!\nPlease reach out to support via the options below to get your user ${email} turned on.`,
        noDataFound: 'No Data Found',
        trade: {
            generalTitle: 'Sorry, there was a problem',
            generalSubmit: 'We are unable to place your order. If you feel like you have reached this in error, please contact Support.',
            generalVerify: 'We are unable to validate your order. If you feel like you have reached this in error, please contact Support.',
            restrictedError: 'Sorry, trading has been restricted for this security.',
            cantVerify: 'Sorry, we were unable to verify your order.',
            dimeProduct: 'Sorry, this product is only quoted in ten-cent increments. Please adjust your price to a multiple of ten cents and resubmit.',
            nickelProduct: 'Sorry, this product is only quoted in five-cent increments. Please adjust your price to a multiple of five cents and resubmit.',
            pennyProduct: 'Sorry, this product is only quoted in penny increments. Please adjust your price to a multiple of one cent and resubmit.',
            securityOutOfSession: 'Sorry, this product cannot be traded at this time of day',
            accountRestricted: 'This account has been restricted. Please contact us to have it reactivated.',
            securityUnavailable: 'Sorry, trading for this product is unavailable on StoneX One.',
            fundTradingDenied: 'Sorry, you are not permitted to trade mutual funds.',
            basicTradingDenied: 'Sorry, you are not permitted to trade.',
            noBuy: 'Sorry, buying is not permitted for this trade',
            noOptionPosition: 'Quantity for a close order must not exceed quantity currently held',
            optionAgreement: 'Sorry, your account is not permitted to trade options.',
            optionLevel: 'Sorry, your account is not permitted to trade options in this way.',
            optionPermission: 'Sorry, you are not permitted to trade options.',
            offHours: 'Sorry, trading is currently in off-hours. Please try again later.',
            generalNotAvailable: 'Not available to trade',
            notAvailable: (product: string) => `Sorry, ${product} is not available to trade on StoneX One`,
            sellStopLessThanMarket: 'A sell stop order requires a Stop Price less than the current market price.',
            buyStopGreaterThanMarket: 'A buy stop order requires a Stop Price greater than the current market price.',
            sxDenied: 'You are not permitted to trade. If you feel like you reached this message in error please contact support.',
            shortNotAllowed: 'Shorting is not allowed for this security.',
            thisIsAShortOrder: 'You are selling shares you do not currently own. This is a short order.',
            adjustedCloseOnly: 'Sorry, adjusted options may only be closed',
            adjustedCloseOnlyMessage: 'Due to liquidity and settlement risk, we only allow closing orders for adjusted option positions.',
            insufficientShortQuantity: (qty: number): string => {
                const sharePlurality = qty === 1 ? `There is 1 share` : `There are ${qty} shares`;
                const sharesAvailable = qty === 0 ? `There are no shares` : sharePlurality;
                const reduceQuantityText = qty > 1 ? ' Please reduce your requested quantity.' : '';
                return `There is an insufficient quantity available to authorize this request. ${sharesAvailable} available.${reduceQuantityText}`;
            },
            mutualFundQrpOnly:
                'Purchasing this US Mutual Fund is only available to qualified retirement plans. If you feel like you have reached this in error, please contact Support.',
            mutualFundNoDomesticOnForeign:
                'US Mutual Fund orders are not allowed in foreign accounts. If you feel like you have reached this in error, please contact Support.',
            mutualFundNoForeignOnDomestic:
                'Offshore Mutual Fund orders are not allowed in domestic accounts. If you feel like you have reached this in error, please contact Support.'
        },
        alerts: {
            duplicateRuleMessage: 'You have already defined an alert rule with this set of conditions.',
            duplicateRuleTitle: 'Duplicate Alert Rule',
            somethingWentWrongMessage: 'Sorry, something went wrong. Please try again later.',
            unableToVerifyCode: 'Unable to verify your code, please try again.',
            temporarilyUnavailable: 'Temporarily Unavailable',
            alertsTemporarilyUnavailableMessage:
                'We are upgrading our alerts service. Unfortunately, alerts will be down for a couple of weeks. When alerts are back, you will have to create your alerts again.'
        }
    },
    warnings: {
        pretradeDisclosures: {
            cryptos:
                'By proceeding with cryptocurrency trading on our platform, you acknowledge that you have read, understood, and accepted this disclosure statement and agree to the terms and conditions set forth by ETANA for cryptocurrency trading and account management. \n\nIf you have any questions or need further clarification regarding this disclosure statement, please contact our support team.'
        },
        trade: {
            cannotAfford: (t: Partial<TradeRequest> | ApiTradeRequest, ac?: AssetClass) => {
                const isDollar = t?.quantityQualifier === 'EvenDollar';
                const q = isDollar ? ac?.formatPrice(t.quantity) : ac?.formatQuantity(t.quantity);

                switch (t.action) {
                    case 'Buy': {
                        const unit = Snex1English.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const commonSentence = `You don't have enough funds to purchase ${q}`;
                        const unitSentence = isDollar ? `of ${QualifiedId.RemovePrefix(t.securityId)}.` : `${unit} of ${QualifiedId.RemovePrefix(t.securityId)}.`;
                        return `${commonSentence} ${unitSentence}`;
                    }
                    case 'Sell': {
                        const units = Snex1English.general.unit(t.securityId, 2)?.toLowerCase();
                        const unit = Snex1English.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const showUnit = isDollar && ac?.family === 'cryptos' ? `${q}` : `${q} ${unit}`;

                        return `You don't have enough ${units} to sell ${showUnit} of ${QualifiedId.RemovePrefix(t.securityId)}.`;
                    }
                    default:
                        return 'Sorry, you cannot afford this trade.';
                }
            },
            shortsNotPermittedWhenLong: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return 'We notice that you currently hold a long position. Please close out your long position if you would like to sell short.';
            },
            shortsNotPermittedWhenAccountNotMargin: (t: Partial<TradeRequest> | ApiTradeRequest, ac?: AssetClass) => {
                const cannotAfford = Snex1English.warnings.trade.cannotAfford(t, ac);
                return `${cannotAfford}\n\nShorting Stock is not allowed in a cash account, please review your positions and revise your order.`;
            },
            buyExceedsShortsHeld: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return 'We notice that you currently hold a short position. Please close out your short position if you would like to trade long.';
            },
            shortNotPermittedAccountValue: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return 'You are not allowed to short stock in margin account with less than $2,000 Equity, please revise your order or deposit funds.';
            },
            invalidQuantity: "We don't support trading this security with the quantity specified, please revise your quantity.",
            invalidQuantityOptions: "We don't support the entered option quantity. Please revise your order.",
            insufficientQuantity: 'There is an insufficient quantity available to authorize this request. For more information, please contact Support.',
            minimumNotMet: "Sorry, your order does not meet this mutual fund's minimum required investment.",
            possibleDuplicate: 'An open order similar to this one already exists.',
            orderGreaterThanPosition: 'Order is greater than total position. Please review order before routing.',
            accountChangedToMargin: 'Account type changed to margin.',
            accountChangedToCash: 'Account type changed to cash.',
            foreignAcctDomesticFunds: 'The selected account is a foreign account and may not trade domestic mutual funds',
            optionLevel: 'Your current option level does not allow you to execute this trade.',
            nextDayOrder: 'This order will be placed tomorrow.',
            continue: 'Are you sure you want to submit this order?',
            optionsLowVolumeWarning: 'There is low activity/interest on this order. Your order may not be filled.',
            isShort: 'You are selling shares you do not currently own. This is a short order. Please confirm this is the expected trade that you want to make.',
            cutoffTime: 'This order is past the cutoff time for the fund. This is a next-day order and will receive the first available NAV issued by the fund.',
            acknowledgeTitle: 'Acknowledge and Confirm'
        }
    },

    footer: {
        global: {
            chatWithSupport: 'Chat With Support',
            copyrightLine: [
                [
                    {
                        text: 'Check the background of StoneX Financial Inc, or one of their investment professionals on '
                    },
                    {
                        text: "FINRA's BrokerCheck",
                        url: 'https://brokercheck.finra.org/'
                    },
                    { text: '.  © ' },
                    { text: new Date().getFullYear().toString() },
                    { text: ' StoneX Group Inc. All Rights Reserved. ' }
                ]
            ],
            disclosures,
            disclosuresByEnvironement: (env: Environment) => (env === 'pro' ? proDisclosures : disclosures),
            importantDisclosures: 'Important Disclosure Information',
            marketDataDisclosure: 'Market data provided by Xignite',
            openAnAccount: 'Open an Account',
            privacy: 'Privacy',
            systemRequirements: 'System Requirements',
            termsOfUse: 'Terms of Use'
        },
        disclosurePdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/DisclosuresFull.pdf'
    },

    mobileUi: {
        bottomBar: {
            summary: 'Summary',
            cash: 'Cash',
            browse: 'Browse',
            messages: 'Messages'
        }
    },

    alerts: {
        addCondition: 'Add Condition',
        saveAlert: 'Save Alert',
        updateAlert: 'Update Alert',
        editExistingAlert: 'Edit Existing Alert',
        createAlert: 'Create New Alert',
        createAnAlertToSeeItHere: 'Create an alert to see it here.',
        interpretConditions: (field: 'latestPrice' | 'changePercent' | 'Last', operator: '>' | '>=' | '<' | '<=', valueNumberString: string) => {
            const { fieldLabel, formattedValue } = (() => {
                switch (field) {
                    case 'Last':
                    case 'latestPrice':
                        return {
                            fieldLabel: 'Price',
                            formattedValue: FormatNumber.toMoney(Number(valueNumberString))
                        };
                    case 'changePercent':
                        return {
                            fieldLabel: 'Price percent change',
                            formattedValue: FormatNumber.toPercent(Number(valueNumberString))
                        };
                    default:
                        return {
                            fieldLabel: 'Value',
                            formattedValue: FormatNumber.toCommas(Number(valueNumberString))
                        };
                }
            })();
            const op = (() => {
                switch (operator) {
                    case '>':
                        return 'greater than';
                    case '>=':
                        return 'greater than or equal to';
                    case '<':
                        return 'less than';
                    case '<=':
                        return 'less than or equal to';
                    default:
                        return 'reaches';
                }
            })();

            return `${fieldLabel} ${op} ${formattedValue}`;
        },
        priceAlert: 'Price Alert',
        youDontHaveAlerts: "Looks like you don't have any alerts!",
        mutualFundsUnavailable: 'Alerts are unavailable for Mutual Funds',
        futuresUnavailable: 'Alerts are unavailable for Futures',
        createAlertSheet: {
            selectCondition: 'Select A Condition',
            change: 'Change %',
            latestPrice: 'Latest Price',
            priceAlert: 'Price Alert',
            createAlert: 'Create Alert',
            updateAlert: 'Update Alert',
            deleteAlert: 'Delete Alert',
            alert: 'Alert',
            updateAlertFor: (symbol: string) => (symbol ? `Update Alert for ${symbol}` : 'Update Alert'),
            target: 'Target',
            chooseSecurity: 'Choose a security',
            greaterThan: 'Greater Than',
            greaterThanEqual: 'Greater Than Or Equal To',
            lessThan: 'Less Than',
            lessThanEqual: 'Less Than Or Equal To',
            alertsTitle: 'Alerts',
            newAlert: 'New Alert',
            searchForSecurity: 'Search for a security...',
            searchSecurity: 'Search Securities',
            noAlertsYet: 'No Alerts Yet',
            createNewAlertRule: 'Create New Alert Rule',
            editSymbol: 'Edit symbol',
            repeatDaily: 'Repeat Daily',
            instructions: {
                createAlertBtn: 'Create your first alert rule with the button below!',
                lookUpSecurity: 'Look up a security to create your first alert rule!',
                getNotified: "Get notified in realtime when a security's price changes."
            }
        }
    },

    equitiesMarginOnboarding: {
        title: 'You are adding margin to your account',
        agreementNotAllowed: "We're sorry, it looks like we're not able to add a margin agreement to this account.",
        contactSupport: 'If you feel that this is unexpected, please reach out to our support team',
        byChoosing: 'By choosing to add margin to your account, you are applying for authorization to borrow money from StoneX using your assets as collateral.',
        understandRisk:
            'It is important that you fully understand the risks involved in using margin, whether trading securities on margin or using your margin account equity for other purposes.',
        agreementDescription:
            'The Margin Agreement is a contractual agreement between you and the broker. This agreement enables you to buy securities with borrowed funds. Please review the Margin Agreement before trading with margin.',
        close: 'Close',
        agreeAndContinue: 'Agree and Continue',
        reviewAgreement: 'Review Margin Agreement',
        submitted: 'All Done! Your Margin Agreement has been added to your account',
        failed: "Looks like we weren't able to add the Margin Agreement to your account"
    },

    futuresOnboarding: {
        sfi: 'Futures Commission Merchant of SFI',
        stepLabels: [
            'Welcome!',
            'Contact Info',
            'Personal Info',
            'Account Info',
            'Employment Info',
            'Trading Experience',
            'Affiliations',
            'Regulatory Info',
            'Financial Info',
            'Review',
            'Agreements',
            'Certification'
        ],
        submitMessage: 'Please wait while we get your futures account ready...',
        submitMessageTitle: 'Creating your new futures account',
        successMessage: (account: string) => `Your futures account (${account}) is being created.`,
        errorMessage: 'Something went wrong.',
        tryAgain: "Please try again or contact us and we'll get things straightened out",
        okButton: 'Ok',
        closeButton: 'Close',
        accountDelayMessage: 'Congratulations! Your futures account is currently being processed and will appear shortly.',
        openAccount: 'Open a Futures Account',
        openAccountNotAllowed: 'Please reach out to your representative for assistance',
        openDocumentToEnable: 'Open and review document to enable',
        openAccountComingSoon: 'Open A Futures Account Coming Soon!',
        welcome: (name: string) => `Welcome ${name}!`,
        hasReachedMax: 'You have already onboarded a Futures account in StoneX One. Please click the below button to return to the StoneX One home screen.',
        missingRequiredData: 'Missing required data',
        nextButton: 'Next',
        returnHome: 'Return home',
        disabledNextText: 'Please complete the required items above before proceeding',
        submitButton: 'Submit',
        goBackButton: 'Go Back',
        yesButton: 'Yes',
        noButton: 'No',
        welcomeSubtitle: "Let's get you a futures account",
        contactInfo: {
            title: "Let's get your contact info",
            name: 'Name',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phone: 'Phone',
            street: 'Street',
            streetAddress: 'Street Address',
            street2: 'Apt#, Building#, etc...',
            city: 'City',
            state: 'State or Region',
            country: 'Country',
            zip: 'Zip',
            error: 'Must be at least 21 years of age'
        },
        personalInfo: {
            title: 'Personal Info',
            citizenQuestion: 'Are you a U.S. Citizen?',
            citizenYes: 'I am a U.S. Citizen',
            citizenNo: 'I am not a U.S. Citizen',
            ssnQuestion: 'Do you have a Social Security Number or ITIN?',
            ssnYes: 'I have a Social Security Number or ITIN',
            ssnNo: 'I do not have a Social Security Number or ITIN',
            ssn: 'Social Security Number or ITIN',
            country: 'Country of citizenship',
            dob: 'Date of birth',
            governmentId: 'Government Issued Id Number'
        },
        accountInfo: {
            title: "Let's gather your account info",
            accountType: 'Individual',
            accountTypeSubtitle: 'A standard account with only one owner',
            sourceOfFunds: 'Source of funds',
            intendedInitialDeposit: 'Intended initial deposit',
            expectedMonthlyTrading: 'Expected monthly trading activity',
            sourceOfFundsOptions: [
                { label: 'Broker', value: 'Broker' },
                { label: 'Gift', value: 'Gift' },
                { label: 'Inheritance', value: 'Inheritance' },
                { label: 'Insurance', value: 'Insurance' },
                { label: 'Legal Settlement', value: 'Legal Settlement' },
                { label: 'Pension', value: 'Pension' },
                { label: 'Retirement', value: 'Retirement' },
                { label: 'Sale', value: 'Sale' },
                { label: 'Wages', value: 'Wages' }
            ],
            intendedInitialDepositOptions: [
                { label: '0 - $1,000', value: 0 },
                { label: '$1,001 - $10,000', value: 1000 },
                { label: '$10,001 - $50,000', value: 10000 },
                { label: '$50,001 - $100,000', value: 50000 },
                { label: '$100,001 - $250,000', value: 100000 },
                { label: '$250,001 - $500,000', value: 250000 },
                { label: '$500,001 - $1,000,000', value: 500000 },
                { label: '$1,000,001 - $5,000,000', value: 1000000 },
                { label: '$5,000,000 or more', value: 5000000 }
            ],
            expectedMonthlyTradingOptions: [
                { label: '0 - 100', value: 0 },
                { label: '101 - 1,000', value: 100 },
                { label: '1,001 - 10,000', value: 1000 },
                { label: '10,001 - 50,000', value: 10000 },
                { label: '50,000 or more', value: 50000 }
            ]
        },
        employmentInfo: {
            title: 'Employment Info',
            status: 'Employment status',
            employedTitle: 'Employed',
            employedSubtitle: 'I am currently employed by a company',
            studentTitle: 'Self-Employed',
            studentSubtitle: 'I am currently self employed',
            unemployedTitle: 'Other',
            unemployedSubtitle: 'I am not currently employed and I am not retired',
            retiredTitle: 'Retired',
            retiredSubtitle: 'I am currently retired',
            employerAddress: {
                title: 'Employer Address',
                country: 'Country',
                street: 'Address line 1',
                street1: 'Address line 2',
                city: 'City',
                state: 'State',
                zip: 'Zip'
            },
            years: 'Years with Employer',
            employerDetailsTitle: 'Employer details',
            occupationTitle: 'Occupation',
            name: 'Employer name',
            jobTitle: 'Job title',
            industryTitle: 'Industry',
            jobFunctionTitle: 'Job function',
            primarySourceOfIncomeTitle: 'What is your primary source of income?',
            incomeOptions: [
                { label: 'Broker', value: 'Broker' },
                { label: 'Gift', value: 'Gift' },
                { label: 'Inheritance', value: 'Inheritance' },
                { label: 'Insurance', value: 'Insurance' },
                { label: 'Legal Settlement', value: 'Legal Settlement' },
                { label: 'Pension', value: 'Pension' },
                { label: 'Retirement', value: 'Retirement' },
                { label: 'Sale', value: 'Sale' },
                { label: 'Wages', value: 'Wages' }
            ],
            occupationOptions: [
                {
                    label: 'Accountant/Auditor/Bookeeper',
                    value: 'Accountant/Auditor/Bookeeper'
                },
                { label: 'Admin/Office Manager', value: 'Admin/Office Manager' }
            ],
            industryOptions: [],
            jobFunctionOptions: []
        },
        tradingExperience: {
            title: 'Tell us about your previous trading experience',
            experienceYears: [
                { label: 'less than 1 year', value: '0' },
                { label: '1 to 2 years', value: '1' },
                { label: '2 to 3 years', value: '2' },
                { label: '3 or more years', value: '3' }
            ],
            commoditiesTitle: 'Commodities Trading Experience',
            commoditiesQuestion: 'Do you have commodities trading experience?',
            commoditiesYears: 'Years of commodities experience',
            securitiesQuestion: 'Do you have securities trading experience?',
            securitiesYears: 'Years of securities experience',
            otcTitle: 'OTC Trading Experience',
            otcQuestion: 'Do you have over-the-counter (OTC) trading experience?',
            otcYears: 'Years of OTC experience',
            professionalTrader: 'Do you qualify as a professional as defined by CME for Market Data?',
            professionalTraderUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf',
            professionalTraderPdfLabel: 'Futures Market Data Subscription Terms',
            tradingExperienceTitle: 'Trading Experience',
            doTradingExperience: 'Do you have trading experience?',
            yearsExperience: 'Years of experience',
            professionalTraderTitle: 'Professional Trader',
            commodityExchangeAct: 'Commodity Exchange Act',
            governmentRelations: 'Government Relations',
            bankruptcy: 'Bankruptcy',
            legalDisputes: 'Legal Disputes',
            closedCommodityAccounts: 'Closed Commodity Accounts'
        },
        affiliationsInfo: {
            title: 'Just a few quick questions',
            politicalOrGovernment:
                'Are you or any member of your immediate family a current political official in any branch of government or affiliated with a government owned enterprise, other than the U.S. Government?',
            employeeOfStoneX:
                'Are you or any member of your immediate family an employee of, or related to an employee of the FMC Division of StoneX Financial Inc. (“FCM”) or its subsidiaries?',
            regulatoryMember: 'Are you registered with the National Futures Association (“NFA”) or any other regulatory body or member of an exchange?',
            regulatoryBody: 'Regulatory body',
            registrationNumber: 'Registration number',
            employeeName: 'Employee name',
            employeeRelationship: 'Relationship',
            nfaBody: 'NFA body name',
            nfaRegNumber: 'NFA registration number'
        },
        regulatoryInfo: {
            title: "Let's gather some regulatory info",
            registrationExemption: 'Are you operating pursuant to a registration exemption under the Commodity Exchange Act?',

            bankruptcy: 'Have you been the subject of a bankruptcy proceeding, receivership, or similar action?',
            disputeOrArbitration: 'Have you been in a legal dispute, arbitration, or reparations action related to a commodity account?',
            unsatisfiedDebitBalance: 'Have you ever closed an account with an unsatisfied debit balance at a commodity firm?'
        },
        financialInfo: {
            title: 'Financial Info',
            error: 'Financials do not meet requirements for a futures account',
            page2Title: 'Financial Info Continued',
            annualRevenue: 'Annual revenue from all sources in U.S. Dollars',
            annualRevenueOptions: [
                { label: 'Below $25,000', value: '0' },
                { label: '$25,000 - $50,000', value: '0.025' },
                { label: '$50,000 - $100,000', value: '0.05' },
                { label: '$100,000 - $250,000', value: '0.1' },
                { label: '$250,000 - $500,000', value: '0.25' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Greater than $5,000,000', value: '5' }
            ],
            netWorth: 'Total net worth in U.S. Dollars',
            netWorthOptions: [
                { label: 'Below $100,000', value: '0' },
                { label: '$100,000 - $500,000', value: '0.1' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Greater than $5,000,000', value: '5' }
            ],
            workingCapital: 'Available working capital in U.S. Dollars',
            workingCapitalOptions: [
                { label: 'Below $25,000', value: '0' },
                { label: '$25,000 - $100,000', value: '0.025' },
                { label: '$100,000 - $250,000', value: '0.1' },
                { label: '$250,000 - $500,000', value: '0.25' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Greater than $5,000,000', value: '5' }
            ],
            annualRevenueShort: 'Annual revenue',
            totalNetWorth: 'Total net worth',
            availableWorkingCapital: 'Available working capital'
        },
        review: {
            title: "Let's review your information",
            subTitle: "We're almost done! Let's look over the answers you've given to make sure everything is accurate",
            personal: 'Personal',
            account: 'Account',
            funding: 'Funding',
            trustedContact: 'Trusted Contact',
            addTrustedContact: 'Add Trusted Contact',
            tradingInfo: 'Trading Info',
            regulatoryInfo: 'Regulatory Info',
            stoneXAffiliation: 'StoneX Affiliation',
            nfaAffiliation: 'NFA Affiliation',
            jobTitle: 'Job Title',
            employer: 'Employer',
            yearsWithEmployer: 'Years with Employer',
            employment: 'Employment',
            neitherStoneXAffiliation:
                'Neither you, nor anyone in your immediate family, nor anyone in the same household is/are an employee or agent of StoneX Group Inc., or a StoneX-affiliated company.',
            neitherNfaAffiliation:
                'Neither you, nor anyone in your immediate family, nor anyone in the same household is/are employed by, or associated with, a stock exchange, a member firm of a stock exchange, FINRA, or a municipal securities dealer.',
            notice: 'Notice',
            noticeDescription: 'Futures accounts are created under Futures Commission Merchant of SFI',
            employedStatus: (employedStatus: string) => {
                return [Light('You are'), Dark(`${employedStatus}`), Light('.')];
            },
            accountOpening: (accountType: string) => {
                return [Light('You are opening an '), Dark(`${accountType}`), Light('.')];
            },
            exemptUnderCommodityExchangeAct: (areNot: string) => {
                return `You ${areNot} operating pursuant to a registration exemption under the Commodity Exchange Act.`;
            },
            relatedToGovernment: (areNot: string) => {
                return `You or any member of your immediate family ${areNot} a current political official in any branch of government or affiliated with a government owend enterprise, other than the U.S. Government.`;
            },
            hasBankruptcy: (haveNot: string) => {
                return `You ${haveNot} been the subject of a bankruptcy proceeding, recievership, or similar action.`;
            },
            hasLegalDisputeWithCommodities: (haveNot: string) => {
                return `You ${haveNot} been in a legal dispute, arbitration, or reparations action related to a commodity account.`;
            },
            hasClosedCommodityAccountWithDebitBalance: (haveNot: string) => {
                return `You ${haveNot} closed an account with an unsatified debit balance at a commodity firm.`;
            },
            isProffesionalTrader: (areNot: string) => {
                return `You ${areNot} a professional trader.`;
            }
        },
        agreements: {
            arbitration: 'Arbitration',
            accept: 'I accept',
            doNotAccept: 'I do not accept',
            acceptArbitration: 'Do you accept the arbitration agreement? (Optional)',
            arbitrationAgreement:
                'I represent and warrant that I have read the “Arbitration Agreement” and understand its provisions herein, and I am making the following election:',
            pageTitle: 'Important Documents Applicable to Your Account',
            titleText:
                'It is required that you carefully review all content on this page and each of the required documents below by clicking the document titles below to access its content. Please read all documents carefully before completing the Client Certification section.',
            part1Title: 'Part 1: Privacy Statements',
            part2Title: 'Part 2: Futures & Futures Options Risk Disclosures',
            part3Title: 'Part 3: Futures & Futures Options Account Agreements',
            part4Title: 'Part 4: Futures & Futures Options Market Data Terms',
            clientCertTitle: 'Client Certification',
            acceptAndContinueText: "By clicking the 'Accept & Continue' button below:",
            W9title: 'IRS Form W-9 Certification',
            perjuryCertTitle: 'Under penalty of perjury, I certify that:',
            perjuryCertItem1: 'I am a U.S. citizen or other U.S. person',
            perjuryCertItem2: 'is my correct taxpayer identification number',
            perjuryCertItem3: 'I am not subject to backup withholding',
            perjuryCertItem4:
                'The FATCA code entered on this form (if any) indicating that the payee is exempt from FATCA reporting is correct. (As a U.S.financial institution maintaining your account in the U.S., we have not requested a FATCA Code, and this item is not applicable).',
            clientAcknowledgementTitle: 'Client Acknowledgement',
            clientAcknowledgementItem1: [
                [
                    {
                        text: 'I acknowledge that I have read and understand all documents presented within '
                    },
                    { text: '“Part 1: Privacy Statements”', weight: 'bold' },
                    {
                        text: ', and further represent and warrant that the information I have provided in connection with opening this account is true '
                    },
                    {
                        text: 'and correct, and that the FCM Division of StoneX Financial Inc. and StoneX Markets LLC respectively, are relying on the '
                    },
                    {
                        text: 'information herein as the basis of establishing one or more Customer accounts. I certify, represent and warrant that all '
                    },
                    {
                        text: 'information, including audited or unaudited financials, or Applicants who fill out the income and net worth information '
                    },
                    {
                        text: 'in this Account Application and all other information provided is true and accurate in accordance with the Anti-Money '
                    },
                    { text: 'Laundering Policies and Procedures. ' }
                ]
            ],
            clientAcknowledgementItem2: [
                [
                    {
                        text: 'I represent and warrant that I have read and understand all documents presented within '
                    },
                    {
                        text: '“Part 2: Futures & Futures Options Risk Disclosures” ',
                        weight: 'bold'
                    },
                    {
                        text: 'including the Futures and Exchange-Traded Options Risk Disclosure, '
                    },
                    {
                        text: 'Commodities Options Risk Disclosure, and all contents of the documents within the Additional Disclosures Document.'
                    }
                ]
            ],
            clientAcknowledgementItem3: [
                [
                    {
                        text: 'I represent and warrant that I have read and understand the '
                    },
                    {
                        text: '“StoneX Financial Inc. Futures & Exchange-Traded Options Customer Agreement” ',
                        weight: 'bold'
                    },
                    {
                        text: 'in its entirety, and agree to be bound by the Terms and Conditions therein, as it may be amended from time to time in'
                    },
                    { text: 'accordance with its terms.' }
                ]
            ],
            clientAcknowledgementItem4: [
                [
                    {
                        text: 'I represent and warrant that I have read and understand the '
                    },
                    { text: '“Platform User Agreement” ', weight: 'bold' },
                    {
                        text: 'in its entirety and agree to be bound by the terms and conditions to which I am subject with respect to accessing and '
                    },
                    { text: 'otherwise using the Platform' }
                ]
            ],
            clientAcknowledgementItem5: [
                [
                    {
                        text: 'ELECTION OF ARBITRATION FOR DISPUTES RELATING TO FUTURES ACCOUNTS: ',
                        weight: 'bold'
                    },
                    {
                        text: 'I represent and warrant that I have read the “Arbitration Agreement” ',
                        weight: 'bold'
                    },
                    {
                        text: 'and understand its provisions herein, and I am making the following election:',
                        weight: 'bold'
                    }
                ]
            ],
            clientAcknowledgementItem6: [
                [
                    {
                        text: 'The following information applies for those customers electing electronic transfers via Automated Clearing House (ACH): ',
                        weight: 'bold'
                    },
                    {
                        text: 'I hereby authorize the FCM Division of StoneX Financial Inc. (“FCM”) to the extent the Customer establishes an account '
                    },
                    {
                        text: 'with the FCM, to initiate a consumer Prearranged Payment and Deposit (PPD) credit entries via Automated Clearing '
                    },
                    {
                        text: 'House (ACH) transfer to my designated account, selected and authenticated by me via the Platform, held at a '
                    },
                    {
                        text: 'depository financial institution, and to debit such account from time to time to satisfy any “Margin Call” or other deficit in '
                    },
                    {
                        text: 'my FCM account in accordance with the FCM Customer Agreement.'
                    },
                    {
                        text: 'I acknowledge that the origination of ACH transactions to my account by FCM must comply with the provisions of U.S. law. '
                    },
                    {
                        text: 'I further acknowledge that the amount of all debits executed pursuant to this authorization may vary, but each debit shall '
                    },
                    {
                        text: 'equal the amount of the then due “Margin Call” or other deficit (as determined under the FCM Customer Agreement) plus any applicable fees. '
                    }
                ]
            ],
            clientAcknowledgementItem7: [
                [
                    { text: 'I represent and warrant that I have read and understand the ' },
                    { text: '“Futures Market Data Subscription Terms” ', weight: 'bold' },
                    { text: 'in its entirety and agree to be bound by the terms and conditions to which I am subject with respect to accessing and ' },
                    { text: 'otherwise using the Platform' }
                ]
            ],
            arbitrationAcceptText: 'I Accept the Arbitration Provision',
            arbitrationDeclineText: 'I Decline the Arbitration Provision',
            IRSnote: 'The IRS does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.',
            acceptAndContinueButton: 'Accept & Continue'
        },
        documents: {
            privacyPolicy: 'StoneX Privacy Policy and StoneX Privacy Act',
            antiMoneyLaundering: 'Anti-Money Laundering Proceedures',
            commoditiesFuturesRiskDisclosure: 'Commodities Futures Trading Commission Risk Disclosure',
            commoditiesOptionsRiskDisclosure: 'Options Disclosure Statement',
            addlDisclosures: 'Additional Disclosures Document including the following:',
            subText: {
                nfaRiskDisclosure: 'NFA Additional Risk Disclosure',
                addlRiskDisclosure: 'Additional Risk Disclosure',
                materialConflicts: 'Disclosure of Material Conflicts of Interest',
                electronicTrading: 'Electronic Trading and Order Routing Disclosure',
                noticeToOmnibus: 'Notice to Omnibus Accounts Regarding Restrictions on Access for Sanctioned Parties',
                underlyingOrSpot: 'Underlying or Spot Virtual Currency Product Disclosure',
                clearedSwaps: 'Cleared Swaps Disclosure'
            },
            stoneXCustomerAgreement: 'StoneX Financial Inc. Futures & Exchange-Traded Options Customer Agreement',
            arbitrationAgreement: 'Arbitration Agreement',
            platformAgreement: 'Platform User Agreement',
            virtualCurrencyRiskDisclosure: 'Virtual Currency Risk Disclosure Statement',
            marketDataSubscriptionTerms: 'Futures Market Data Subscription Terms',
            blobStorageUrls: {
                privacyPolicies: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX-Privacy-Policies.pdf',
                commodityFuturesTradingComissionRiskDisclosure:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Commodity-Futures-Trading-Commission-Risk-Disclosure-202210X.pdf',
                optionsDisclosureStatement: 'https://snex1storage.blob.core.windows.net/$web/docs/Options-Disclosure-Statement-202210X.pdf',
                acknowledgementOfAdditionalDisclosures: 'https://snex1storage.blob.core.windows.net/$web/docs/Acknowledgement-of-Additional-Disclosures-202210X.pdf',
                virtualCurrencyRiskStatements: 'https://snex1storage.blob.core.windows.net/$web/docs/NFA-CFTC-Virtual-Currency-Risk-Statements-202210X.pdf',
                futuresAndExchangeTradedOptionsCustomerAgreement:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Futures-and-Exchange-Traded-Options-Customer-Agreement-SFI_FCM_202210X.pdf',
                platformUserAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Platform-User-Agreement-SFI_FCM_202210X.pdf',
                arbitrationAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Arbitration-Agreement-SFI_FCM_202210X.pdf',
                futuresMarketDataSubscriptionTerms: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf'
            }
        },
        submit: {
            title: 'Terms and Agreements'
        }
    },
    mediantSSO: {
        buttonText: 'Corporate Actions'
    },
    equitiesOnboarding: {
        // Look at fieldTranslations
        yes: 'Yes',
        no: 'No',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        continue: 'Continue',
        showIssues: 'Show Issues',
        are: 'are',
        areNot: 'are NOT',
        openAccount: 'Open a Stock Trading Account',
        personalInfo1: {
            title: "What's your name and birth date?",
            birthDate: 'Birth Date'
        },
        contactInfo: {
            title: 'How do we reach you?',
            // email
            validationMessage: 'Phone number must be 10 digits'
        },
        personalInfo2: {
            title: 'Your Citizenship',
            label: 'Are you a US Citizen?',
            yesCitizen: 'Yes, I am a US Citizen',
            noCitizen: 'No, I am not a US Citizen',
            ssnHeader: 'SSN or Tax ID must be 9 digits',
            ssnOr: 'SSN or ITIN',
            alert: "We're sorry, however StoneX One access is currently only available for US citizens with a social security number.",
            desc: 'Access to StoneX One is currently only available to US citizens with a social security number.'
        },
        chooseAccount: {
            title: 'Choose Your Account',
            description: 'Long press an account type to get more details',
            individual: 'Individual',
            individualDescription:
                'A taxable account with only one owner, where you can invest in stocks, bonds, ETFs, and mutual funds. You can apply for margin and options trading. This account has no required distributions, and you can deposit to and withdraw from this account whenever you’d like.',
            individualForeign: 'Individual Foreign',
            individualForeignDescription:
                'An account with only one owner, where you can invest in stocks, bonds, ETFs, and mutual funds. You can apply for margin and options trading. This account has no required distributions, and you can deposit to and withdraw from this account whenever you’d like.',
            rothIRA: 'Roth IRA',
            rothIRADescription:
                'An Individual Retirement Account which allows you to contribute after-tax dollars. While there are no current-year tax benefits, your contributions and any gains on investments are tax-free, and you can withdraw them tax- and penalty- free after age 59½ and once the account has been open for five years.',
            traditionalIRA: 'Traditional IRA',
            traditionalIRADescription:
                'An Individual Retirement Account which allows you to contribute pre-tax dollars and any gains on investments are tax-deferred until withdrawal during retirement. Certain contribution limits, early withdrawal penalties and required minimum distributions are applicable to this account.'
        },
        addressInfo: {
            title: 'Where do you live?',
            // streetAddress, City, State, Zip are all done in futuresOnboarding
            secondary: 'Apt #, Bldg #, etc.'
        },
        employmentOptions: {
            title: "What's your employment status?",
            employment: 'Employment',
            employmentStatus: {
                employed: 'Employed',
                youAreEmployed: 'employed',
                unemployed: 'Unemployed',
                youAreUnemployed: 'unemployed',
                student: 'Student',
                youAreStudent: 'a student',
                retired: 'Retired',
                youAreRetired: 'retired'
            },
            employmentInfo: {
                title: 'Employment Information',
                occupation: 'Occupation',
                employer: 'Employer',
                jobTitle: 'Job Title',
                jobFunction: 'Job Function',
                industry: 'Industry',
                employerPhone: 'Employer Phone',
                yearsEmployed: 'Years Employed'
            },
            employmentAddress: 'Employment Address',
            occupations: {
                accountingOrAuditing: 'Accounting or Auditing',
                advertisingOrMarketingSVCE: 'Advertising or Marketing (SVCE)',
                civilService: 'Civil Service',
                it: 'IT',
                domesticHelp: 'Domestic Help',
                education: 'Education',
                trainingEmployment: 'Training and Employment',
                engineering: 'Engineering',
                executiveManagement: 'Executive Management',
                finance: 'Finance',
                insurance: 'Insurance',
                doctor: 'Doctor',
                dental: 'Dental',
                nurse: 'Nurse',
                socialServices: 'Social Services',
                homemaker: 'Homemaker',
                hospitality: 'Hospitality',
                tourism: 'Tourism',
                transport: 'Transport',
                humanResources: 'Human Resources',
                manufacturing: 'Manufacturing',
                military: 'Military',
                adminSecty: 'Admin. Secty',
                customerService: 'Customer Service',
                other: 'Other',
                salesOrRetail: 'Sales or Retail',
                science: 'Science',
                agent: 'Agent',
                banker: 'Banker',
                business: 'Business',
                policeFireLaw: 'Police, Fire, Law',
                attorneyJudgeLegal: 'Attorney, Judge, Legal',
                politician: 'Politician'
            },
            jobFunction: {
                creditCollections: 'Credit Collections',
                financialPlanning: 'Financial Planning',
                financialAccounting: 'Financial Accounting',
                payroll: 'Payroll',
                procurement: 'Procurement',
                riskManagement: 'Risk Management',
                tax: 'Tax',
                treasury: 'Treasury',
                businessManagement: 'Business Management',
                adminManager: 'Admin and Manager',
                executiveAssistant: 'Executive Assistant',
                officeClerks: 'Office Clerks',
                counseling: 'Counseling',
                recruiting: 'Recruiting',
                training: 'Training',
                applicationsSystems: 'Applications and Systems',
                businessSystems: 'Business Systems',
                databaseAdmin: 'Database Admin',
                ITArchitecture: 'IT Architecture',
                ITProjectManagement: 'IT Project Management',
                networkServices: 'Network Services',
                softwareDevelopment: 'Software Development',
                systemsSupport: 'Systems Support',
                technicalServices: 'Technical Services',
                webServices: 'Web Services',
                generalCounsel: 'General Counsel',
                legalServices: 'Legal Services',
                legalSupport: 'Legal Support',
                audioVisual: 'Audio Visual',
                communications: 'Communications',
                designServices: 'Design Services',
                marketing: 'Marketing',
                mediaRelations: 'Media Relations',
                publications: 'Publications',
                writingEditorial: 'Writing and Editorial',
                other: 'Other'
            },
            industries: {
                accounting: 'Accounting',
                advertisingMarketing: 'Advertising and Marketing',
                aerospaceDefense: 'Aerospace and Defense',
                architectureDesign: 'Architecture and Design',
                automotive: 'Automotive',
                childcare: 'Childcare',
                communicationsTelecommunications: 'Communications and Telecommunications',
                constructionCarpentryLandscaping: 'Construction and Carpentry',
                education: 'Education',
                energy: 'Energy',
                engineering: 'Engineering',
                financialServices: 'Financial Services',
                gamingCasinoCardClub: 'Gaming, Casino, Card Club',
                governmentPublicAdministration: 'Government and Public Administration',
                healthcareMedicalServices: 'Healthcare and Medical Services',
                hotelHospitality: 'Hotel and Hospitality',
                informationTechnology: 'Information Technology',
                insurance: 'Insurance',
                legalServicesPublicSafety: 'Legal Services and Public Safety',
                manufacturing: 'Manufacturing',
                mediaEntertainment: 'Media and Entertainment',
                nonProfitCharity: 'Non-Profit and Charity',
                printingPublishing: 'Printing and Publishing',
                realEstate: 'Real Estate',
                restaurantFoodService: 'Restaurant and Food Service',
                transportation: 'Transportation',
                travel: 'Travel',
                other: 'Other'
            }
        },
        funding: {
            title: "What's your funding source?",
            // Source and estimatedLiqNet already completed in futures onboarding.
            sourceIncome: {
                title: 'Source of Income',
                wages: 'Wages', // all
                sale: 'Sale',
                inheritance: 'Inheritance',
                gift: 'Gift',
                insurance: 'Insurance',
                legalSettlement: 'Legal Settlement',
                pension: 'Pension',
                retirement: 'Retirement',
                broker: 'Broker'
            },
            estimatedLiquidNet: {
                title: '',
                a: 'Less than $50,000', // MoneyOptions reference MoneyOptions.ts
                b: '$50,000 - $99,999',
                c: '$100,000 - $199,999',
                d: '$200,000 - $499,999',
                e: '$500,000 - $999,999',
                f: '$1 million - $2.49 million',
                g: '$2.5 million+'
            }
        },
        trustedContact: {
            title: 'Trusted Contact',
            description: 'Would you like to add a trusted contact?',
            contactInfo: {
                title: 'Trusted Contact Information',
                relationship: 'Relationship',
                phoneNumber: 'Phone Number',
                contactsEmailOpt: "Contact's Email (optional)",
                addAddress: "Would you like to add the trusted contact's address?"
            },
            addressTitle: 'Trusted Contact Address'
        },
        stoneXAffiliation: {
            title: 'StoneX Affiliation',
            titleInfo: 'StoneX Affiliation Info',
            description:
                'Are you, or is anyone in your immediate family or living in the same household, an employee or agent of StoneX Group Inc., or a StoneX-affiliated company?',
            position: 'Position Held at StoneX',
            relationToEmployee: 'Relationship to employee',
            affiliatedEntity: 'Affiliated Entity',
            affiliationName: 'StoneX affiliation name',
            affiliationRelationship: 'Affiliation relationsip',
            relationship: {
                child: 'Child',
                self: 'Self',
                spouse: 'Spouse',
                other: 'Other'
            },
            entity: 'StoneX entity affiliated with',
            affiliate: {
                stnx: 'STNX',
                infa: 'INFA',
                sswm: 'SSWM',
                ssia: 'SSIA'
            },
            affiliation: {
                // FirstName
                // LastName
                areYou: 'Are you',
                you: 'You',
                areOtherUsBrokers: 'Are you affiliated with a US-registered broker other than the industry member?',
                areAccreditedMember: 'Are you an accredited investor by the industry member?',
                areRegisteredAdvisor: 'Are you affiliated with a US-registered investment advisor.',
                areOfficerOwnsTenPercent: 'Are you an officer, director, or own 10 percent or more shares of a public company.',
                areEmployeeOfStonex: 'Are you an employee or associated person of StoneX Financial Inc. or one of its Introducing firms.',
                areNaturalPersonOrEntityWithoutTaxId: 'Are you a natural person or legal entity that does not have a US tax identifier.',
                otherUsBrokers: 'affiliated with a US-registered broker other than the industry member.',
                accreditedMember: 'an accredited investor by the industry member.',
                registeredAdvisor: 'affiliated with a US-registered investment advisor.',
                officerOwnsTenPercent: 'an officer, director, or owner of 10 percent or more shares of a public company.',
                employeeOfStonex: 'an employee or associated person of StoneX Financial Inc.or one of its Introducing firms.',
                naturalPersonOrEntityWithoutTaxId: 'a natural person or legal entity that does not have a US tax identifier.'
            }
        },
        industryAffiliation: {
            title: 'Industry Affiliation',
            description:
                'Are you, or is anyone in your immediate family or living in the same household, employed by, or associated with, a stock exchange, a member firm of a stock exchange, FINRA, or a municipal securities dealer?',
            // yes
            // no
            firmName: 'Firm Name',
            sec144: 'Are you, or is anyone in your immediate family or living in the same household, a policy-making officer, director, a 10 percent shareholder, or otherwise considered an affiliate of a publicly traded company for purposes of SEC Rule 144?',
            companyName: 'Company Name',
            ticker: 'Company ticker symbol or CUSIP'
        },
        review: {
            title: 'Review',
            personal: 'Personal',
            // firstName
            // lastName
            // email
            // Phone Number
            countryOfCitizenship: 'Country of Citizenship', // repeated
            ssnItin: 'SSN / ITIN',
            account: 'Account',
            accountDescription: 'You are opening an ', // you are opening an
            employment: 'Employment', // Employment
            employmentDescription: 'You are ', // you are
            funding: 'Funding', // Funding
            // sourceofIncome and type (use funding above)
            // estimated net worth and amount (use funding above)
            // trusted contact
            // firstName from sharedTranslations
            // lastName from sharedTranslations
            // phoneNumber
            relationship: 'Relationship',
            noStoneXAffiliation: '',
            address: 'Address',
            // firmName
            sec144Name: 'SEC Rule 144 Company Name', // SEC Rule 144 Company Name
            sec144Ticker: 'SEC Rule 144 Company Ticker', // SEC Rule 144 Company Ticker
            notPolicyMaker:
                'Neither you, nor any one in your immediate family, nor anyone living in the same household is/are a policy-making officer, director, a 10 percent shareholder, or otherwise considered an affiliate of a publicly traded company for purposes of SEC Rule 144.',
            notEmployed:
                'Neither you, nor anyone in your immediate family, nor anyone in the same household is/are employed by, or associated with, a stock exchange, a member firm of a stock exchange, FINRA, or a municipal securities dealer.'
        },
        agreements: {
            title: 'Add Non-Objecting Beneficial Owner',
            description:
                "In accordance with regulation, StoneX Financial Inc releases the account owner's name and address to the companies or issuers of securities they hold. These companies or issuers request this information in the event they need to contact the shareholders regarding important shareholder communications.",
            label: 'Are you opting out of the release of this information to these companies and/or issuers. (Optional)',
            optIn: 'Opt In - Share my info as needed',
            optOut: 'Opt Out - Do not share my info'
        },
        disclosures: {
            title: 'Disclosures and Agreements',
            reviewAccept: 'Review and accept our disclosures and agreements',
            reviewDisclosures: 'Review Disclosures',
            disclosuresAccepted: 'Disclosures Accepted!',
            acceptContinue: 'Accept and Continue',
            pressFinishToCreate: 'Press Finish to create your account',
            finish: 'Finish',
            missingData: "Looks like we're missing some data still",
            finishLater: 'Finish Later'
        }
    },
    equitiesOptionsOnboarding: {
        gotIt: 'Got it',
        whatAreOptions: {
            title: 'What are options?',
            atItsCore:
                'At its core, options trading is like getting a "ticket" that gives you the right, but not the obligation, to buy or sell a stock at a specific price, within a specific time frame.',
            analogy: "Let's use a simple analogy:",
            imagine: `Imagine you see a concert announced for your favorite band next month, and you're unsure if you can attend. Instead of buying the ticket right away, you pay a small fee for an "option" to buy that ticket at its current price at any time before the concert.`
        },
        financialInformation: {
            title: 'Financial Information',
            annualIncome: 'Annual Income',
            estNw: 'Estimated Net Worth',
            estLiquidNw: 'Estimated Liquid Net Worth',
            selectAnnualIncome: 'Select Annual Income',
            selectEstNw: 'Select Estimated Net Worth',
            selectLiquidNw: 'Select Estimated Liquid Net Worth'
        },
        investmentExperience: {
            title: 'Investment Experience',
            0: '0 Years',
            1: '0-2 Years',
            2: '2-5 Years',
            3: '5+ Years',
            stocksAndBonds: 'Stocks and Bonds',
            mutualFunds: 'Mutual Funds',
            options: 'Options',
            annuities: 'Annuities',
            altInvestments: 'Alternative Investments',
            selectNumYears: 'Select Number of Years'
        },
        requestOptionsLevel: {
            title: 'Request an Options Level',
            description:
                'Since option trading carries different levels of risk, options trading is classified into multiple "levels" or "tiers". We require you to be approved for a specific level of trading based on your experience, financial situation, and understanding of the associated risks.'
        },
        riskTolerance: {
            title: 'Risk Tolerance',
            description: 'Long press each option to learn more',
            conservative: 'Conservative',
            moderate: 'Moderate',
            aggressive: 'Aggressive',
            speculative: 'Speculative',
            conservativeDesc:
                'You seek current income. Long-term capital growth is not an objective. You are willing to accept very low levels of volatility and the possible loss of a portion of principal, but preservation of capital is still an important concern.',
            moderateDesc:
                'You seek both capital growth and current income. You are willing to accept moderate levels of volatility in order to achieve capital appreciation or higher income returns and the possible loss of a portion of principal in order to pursue your investment goal.',
            aggressiveDesc:
                'You seek long-term capital growth. Current income is not an objective. You are willing to accept very high levels of volatility and the possible loss of a substantial portion of principal in order to pursue your investment goal.',
            speculativeDesc:
                'You seek realized gains on short-term movements in securities prices. You are willing to accept the highest levels of volatility and the possible total loss of principal that may occur in attempting to achieve your investment goal.'
        },
        optionsLevel: {
            title: 'Options Level',
            description: "We've enabled options levels based on your risk tolerance",
            level1: {
                title: 'Level 1',
                action: 'Write covered calls'
            },
            level2: {
                title: 'Level 2',
                action1: 'Level 1 strategies',
                action2: 'Purchase calls & puts'
            },
            level3: {
                title: 'Level 3',
                action1: 'Level 1-2 strategies',
                action2: 'Spreads & straddles'
            },
            level4: {
                title: 'Level 4',
                action1: 'Level 1-3 strategies',
                action2: 'Write uncovered puts',
                action3: 'Write cash-secured equity puts'
            },
            level5: {
                title: 'Level 5',
                action1: 'Level 1-4 strategies',
                action2: 'Write uncovered calls'
            },
            requiresMargin: 'Requires margin',
            addMargin: 'Add margin to your account',
            levelRequiresMargin: 'Level Requires Margin',
            level2Margin: 'For trading levels above 2, you must have a margin agreement on file.'
        },
        optionsAgreement: {
            title: 'Options Agreement',
            description: 'Before submitting your application, you must carefully read and understand the following agreement',
            review: 'Review Options Agreement',
            close: 'Close'
        },
        review: {
            title: 'Review',
            financialInfo: 'Financial Info',
            estLiquidNw: 'Estimated Liquid Net Worth',
            netWorth: 'Net Worth',
            income: 'Income',
            experienceInfo: 'Experience Info',
            stocksAndBonds: 'Stocks and Bonds',
            options: 'Options',
            mutualFunds: 'Mutual Funds',
            annuities: 'Annuities',
            altInvestments: 'Alt. Investments',
            optionsInfo: 'Options Info',
            optionsLevel: 'Options Level',
            investmentObjective: 'Investment Objective'
        }
    },
    sharedOnboarding: {
        submissionModal: {
            multiAssetTrading: 'Multi-Asset trading is right around the corner.',
            loading: 'Just a moment...',
            allDone: 'All Done! Let’s start trading.',
            notAbleToCreateAccount: 'Looks like we weren’t able to finish creating your account.',
            tapAnywhereContinue: 'Tap anywhere to continue',
            tapAnywhereForSupport: 'Tap anywhere to chat with support',
            applicationPending: "Your account is being processed. We'll email you when your account has been created.",
            applicationQueued: "Your account application has been queued. We'll email you when your account has been created."
        },
        agreementRequired: (agreementName: string) => `${agreementName} must be accepted to continue.`,
        selectAccount: {
            title: (name: string) => `Welcome ${name}!`,
            subtitle: "Let's get you a new StoneX One account",
            equitiesButtonLabelTitle: 'Stocks',
            futuresButtonLabelTitle: 'Futures',
            equitiesButtonLabelSubtitle: 'Open a Stocks Trading Account',
            futuresButtonLabelSubtitle: 'Open a Futures Trading Account'
        },
        true: 'Yes',
        false: 'No',
        signup: {
            leaveSignup: 'Leave Signup',
            confirmCancelSignup: 'Are you sure you want to cancel your signup?',
            yesCancel: 'Yes, Cancel',
            noStayHere: 'No, Stay Here'
        },
        headerTranslations: {
            personal: 'Personal Info',
            account: 'Account Info',
            address: 'Address Info',
            employment: 'Employment Info',
            funding: 'Funding Info',
            trustedContact: 'Trusted Contact',
            stonexAffiliation: 'StoneX Affiliation',
            industryAffiliation: 'Industry Affiliation',
            whichAccount: 'Which account would you like to open?'
        },
        fieldTranslations: {
            personal: {
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email',
                phoneNumber: 'Phone Number',
                usCitizen: 'Are you a U.S. citizen?',
                countryOfCitizenship: 'Country of Citizenship',
                ssnOrItin: 'Social Security Number or ITIN'
            },
            account: {
                accountType: 'Account Type'
            },
            address: {
                address: 'Address',
                city: 'City',
                state: 'State',
                zipCode: 'Zip Code'
            },
            employment: {
                employmentStatus: 'Employment Status',
                occupation: 'Occupation',
                employer: 'Employer',
                employerPhoneNumber: 'Employer Phone Number',
                jobTitle: 'Job Title',
                industry: 'Industry',
                jobFunction: 'Job Function',
                employerCountry: 'Employer Country',
                employerAddress: 'Employer Address',
                employerAddress2: 'Employer Address 2',
                employerCity: 'Employer City',
                employerState: 'Employer State',
                employerZipCode: 'Employer Zip Code'
            },
            funding: {
                sourceOfIncome: 'Source of Income',
                estimatedLiquidNetWorth: 'Estimated Liquid Net Worth'
            },
            trustedContact: {
                addTrustedContact: 'Add Trusted Contact',
                withTrustedContact: 'Add Trusted Contact?',
                trustedContactFirstName: 'Trusted Contact First Name',
                trustedContactLastName: 'Trusted Contact Last Name',
                trustedContactEmail: 'Trusted Contact Email',
                trustedContactPhoneNumber: 'Trusted Contact Phone Number',
                trustedContactRelationship: 'Trusted Contact Relationship',

                withTrustedContactAddress: 'Add Trusted Contact Address?',
                trustedContactAddress: 'Trusted Contact Address',
                trustedContactCity: 'Trusted Contact City',
                trustedContactState: 'Trusted Contact State',
                trustedContactZipCode: 'Trusted Contact Zip Code'
            },
            stonexAffiliation: {
                withStonexAffiliation: 'Add StoneX Affiliation?',
                stonexAffilFirstName: 'StoneX Affiliation First Name',
                stonexAffilLastName: 'StoneX Affiliation Last Name',
                stonexAffilPosition: 'StoneX Affiliation Position',
                stonexAffilEntity: 'StoneX Affiliation Entity',
                sameAsEmploymentInformation: 'Same as employment information',

                stonexAffilOtherUsBrokers: 'Add StoneX Affiliation Other US Brokers?',
                stonexAffilAccreditedMember: 'StoneX Affiliation Accredited Member',
                stonexAffilWithRegisteredAdvisor: 'StoneX Affiliation With Registered Advisor',
                stonexAffilOfficerOwnsTenPercent: 'StoneX Affiliation Officer Owns Ten Percent',
                stonexAffilEmployeeOfStonex: 'StoneX Affiliation Employee of StoneX',
                stonexAffilNaturalPersonOrEntityWithoutTaxId: 'StoneX Affiliation Natural Person or Entity Without Tax ID'
            },
            industryAffiliation: {
                withIndustryAffiliation: 'Add Industry Affiliation?',
                industryAffilFirmName: 'Industry Affiliation Firm Name',
                withIndustryAffiliationRule144: 'Add Industry Affiliation Rule 144?',
                industryAffilRule144CompanyName: 'Industry Affiliation Rule 144 Company Name',
                industryAffilRule144CompanySymbolOrCusip: 'Industry Affiliation Rule 144 Company Symbol or CUSIP'
            },
            whichAccount: {
                continueStockTradingApplication: 'Continue Equities Application',
                stockTradingAccount: 'Equities Trading Account',
                futuresTradingAccount: 'Futures Trading Account'
            }
        },
        accountOpeningSelection: {
            pageTitle: 'Accounts',
            pageSubtitle: 'Select an account to manage or create a new account',
            margin: 'Margin',
            options: 'Options',
            accountNumber: 'Account Number',
            accountType: 'Type',
            activeApplication: 'Active Application',
            selectedAccountOptions: {
                addMargin: 'Add Margin',
                marginSubLabel: 'Margin gives you access to various option strategies and more.',
                addOptions: 'Add Options',
                optionsSubLabel: 'Options trading allows you to trade contracts if you think the price of an asset will go up or down.',
                continueApplication: 'Continue Application',
                continueApplicationSubLabel: 'Continue your application where you left off.'
            },
            selectionBadge: {
                inProgress: 'In Progress',
                created: 'Created',
                pending: 'Pending'
            }
        }
    },
    futuresGlance: {
        labels: {
            cash: 'Cash',
            netLiq: 'Net Liq',
            openPnl: 'Open Profit/Loss',
            realizedPnl: 'Realized Profit/Loss',
            buyingPower: 'Buying Power'
        },
        info: {
            netLiq: 'Value of the account if liquidated at current market prices.',
            openPnl: 'Current Profit/Loss of all open positions.',
            realizedPnl: 'This value is the top day realized profit/loss.',
            lastUpdated: 'For your Futures account this is the Net Liquidity Value of the account.',
            buyingPower: 'Your buying power is the amount of total cash in the account available to purchase futures and futures options plus all available margin.'
        }
    },
    fplBanner: {
        bannerText: (isSummary?: boolean, theme?: AppliedAppTheme): ShadedTextList => [
            {
                text: isSummary ? 'Looks like one or more of your accounts are eligible to apply for ' : 'Looks like your account is eligible to apply for ',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: 'Fully Paid Lending',
                shade: 'dark',
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark',
                lineBreak: true
            },
            {
                text: 'Click here to learn more',
                shade: 'dark',
                color: '#2cb333'
            },
            {
                text: 'about turning idle assets into income.',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        bannerTextMobileSummary: [
            { shade: 'light', text: 'One or more of your accounts are eligible to apply for ' },
            { shade: 'dark', text: ' Fully Paid Lending! ' },
            { shade: 'light', text: 'Tap here to start turning idle assets into income.' }
        ],
        bannerTextMobileAccount: [
            { shade: 'light', text: 'This account is eligible to apply for' },
            { shade: 'dark', text: ' \nFully Paid Lending! ' },
            { shade: 'light', text: 'Tap here to start turning idle assets into income.' }
        ],

        applyNow: 'Apply Now',
        learnMore: 'Learn More'
    },
    fplModal: {
        headerText: [
            {
                text: 'Ready to turn idle assets into income?',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Unlock Fully Paid Securities Lending',
                shade: 'dark'
            }
        ],
        eligibilityText: 'You need at least $50,000 equity in a Margin Account to be eligible',
        aboutText:
            'Securities lending is the practice of lending securities from one party to another. Often this practice allows the borrower to sell and take a short position in the market.',
        revenueStreamText: [
            {
                text: 'New Revenue Stream:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'With Fully Paid Securities Lending, you can potentially increase your portfolio returns, just by lending out securities you already own.',
                shade: 'light'
            }
        ],
        maintainOwnershipText: [
            {
                text: 'Maintain Ownership:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'You’ll still retain full ownership of your investments, which means your earnings will continue to perform based on market conditions, and the borrower will make good on all dividends and interest payments paid out while the security is on loan. Although it may post as cash in lieu instead of a dividend which could have tax implications.',
                shade: 'light'
            }
        ],
        loansAreSecureText: [
            {
                text: 'Loans are Secure:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'While securities are on loan, the borrower provides you with collateral for the full value of the securities it borrows.',
                shade: 'light'
            }
        ],
        howItWorksTitle: 'How Fully Paid Securities Lending works',
        howItWorksText:
            'StoneX manages the lending process, from connecting lenders and borrowers to arranging for custody of collateral, to settling fees after the term of the loan.',
        bulletPointsText: [
            'Lender earns a return on cash investment in a positive rate environment',
            'Lender earns a fee for loans collateralized by Cash or Securities',
            'Securities are recalled upon request of the Lender or sale of securities by the lender',
            'Borrower makes good on all dividends/interest payments paid while security is under loan',
            'Customer is collateralized the same day',
            'Collateral is maintained relative to the value of the loaned asset',
            'Borrower holds any voting rights attached to the loaned security'
        ],
        invitedToFpl: `You're invited to Fully Paid Lending`,
        fullyPaidLending: 'Fully Paid Lending',
        maximizeProfits: `Maximize potential profits by lending your stocks with Fully Paid Securities Lending - Turn idle assets into income.`,
        addFunds: 'Add Funds'
    },
    fplProfile: {
        applyForFPL: 'Apply for Fully Paid Securities Lending',
        fplProfileLink: 'Fully Paid Lending',
        fplProfileBannerText: (theme?: AppliedAppTheme) => [
            {
                text: 'Maximize potential profits by lending your stocks with',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: 'Fully Paid Securities Lending',
                shade: theme === 'dark' ? 'dark' : null,
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        learnMore: 'Learn More',
        pending: {
            header: 'Fully Paid Lending - Pending',
            body: 'Application submitted'
        },
        enabled: {
            header: 'Fully Paid Lending - Enabled',
            body: `You've enabled Fully Paid Securities Lending for this account.`
        }
    },
    fplOnboarding: {
        welcome: {
            title: 'Apply for Fully Paid Securities Lending',
            text: "Earn Interest on stocks in your portfolio. Retain Ownership / Sell your shares anytime. You won't be able to vote while shares are lent. You need at least $50,000 equity in a Margin Account to be eligible."
        },
        fplInfo: {
            title: 'Select an eligible account',
            text: 'The selected Margin Account must have at least $50,000 equity'
        },
        agreements: {
            title: 'Important Agreements Applicable to Your Account',
            requiredClick:
                'It is required that you carefully review all content on this page and each of the required documents below by clicking the document titles to access its content. Please read all documents carefully before completing the section.',
            subtitle: (): ShadedTextList => [
                { text: 'It is required that you carefully review all content on this page and each of the required documents by clicking the ' },
                { text: 'document titles below to access its content. Please read all documents carefully before completing the section.' }
            ],
            clientCertTitle: 'Client Certification',
            loanAgreementText: (): ShadedTextList => [
                { text: 'In checking this box, the Lender agrees to the terms and conditions set forth herein, including the following:', lineBreak: 'double' },
                { text: '1. THE SECURITIES IN YOUR MARGIN ACCOUNT(S) AND ANY SECURITIES FOR WHICH YOU HAVE NOT FULLY PAID, TOGETHER WITH ALL ' },
                { text: 'ATTENDANT OWNERSHIP RIGHTS, MAY BE LOANED TO STONEX FINANCIAL OR LOANED OUT TO OTHERS; AND' },
                { text: '2. THIS AGREEMENT CONTAINS A PRE-DISPUTE ARBITRATION CLAUSE AT PARAGRAPH 29, BEGINNING ON PAGE 11 OF THIS AGREEMENT.', lineBreak: 'double' },
                { text: 'Determination of the loan fee', shade: 'dark', lineBreak: true },
                { text: "The Loan Fee will be a percentage of the new proceeds earned and received by Borrower for relending Lender's SecuritiesReducer. " },
                { text: 'The Loan Fee accrues daily, including the date on which the Loaned Securities are transferred to Borrower and excluding the date on ' },
                { text: 'which the Loaned Securities are returned to Lender. The Loan Fee will be automatically ' },
                { text: "credited to Lender's Account on a monthly basis." }
            ],
            loanAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_Fully%20Paid%20Master%20Securities%20Loan%20Agreement%2020231219X.pdf',
            loanAgreementPDFTitle: 'Fully Paid Master Securities Loan Agreement',
            fplAccountControlAgreementText: (): ShadedTextList => [
                { text: 'In checking this box, the Lender agrees to the terms and conditions set forth herein, including the following:', lineBreak: 'double' },
                { text: 'Notice of Exclusive Control', shade: 'dark', lineBreak: true },
                { text: 'Secured Party hereby (i) notifies Custodian that Secured Party is exercising exclusive control over the Collateral ' },
                { text: '(as defined in the ACA); (ii) instructs Custodian to stop complying with directions concerning the Collateral originated ' },
                { text: 'by Customer; and (iii) represents and warrants to Custodian that this Notice of Exclusive Control is lawful and authorized ' },
                { text: 'by the applicable agreement between Customer and Secured Party. Custodian has no duty, obligation, or authority to determine ' },
                { text: "whether Secured Party's delivery if this Notice of Exclusive Control (or the terms hereof) is proper, even if Customer " },
                { text: 'objects or directs Custodian not to honor this Notice of Exclusive Control.' }
            ],
            fplAccountControlAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Account%20Control%20Agreement%2020231227X.pdf',
            fplAccountControlAgreementPDFTitle: 'Fully Paid Lending Account Control Agreement',
            axosAcknowledgementText:
                'You will receive an email from Axos in a couple days to complete the application via DocuSign. Check this box to acknowledge that your account approval cannot be processed without completing the Axos application that will be sent to your email address on file.'
        },
        disclosures: {
            title: 'Important Disclosures Applicable to Your Account',
            requiredClick:
                'It is required that you carefully review all content on this page and the required disclosures document below by clicking the document title to access its content. Please read all documents carefully before completing the Disclosures section.',
            subtitle: 'It is required that you carefully review all of the content on this page before submitting',
            scrollTitle: 'Important Characteristics and Risks',
            scrollSubtitle: 'StoneX Fully Paid Securities Lending Program',
            importantCharAndRiskAgreementPDFUrl:
                'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Important%20Characteristics%20and%20Risks%20202401117X.pdf',
            importantCharAndRiskAgreement: 'Fully Paid Lending Important Characteristics and Risks Agreement',
            importantCharAndRiskAgreementText: (): ShadedTextList => [
                { text: "In checking this box, the Lender hereby acknowledges receipt of StoneX Financial Inc.'s disclosure document entitled" },
                { text: '"FULLY-PAID SECURITIES LENDING PROGRAM, IMPORTANT CHARACTERISTICS AND RISKS"' }
            ]
        },
        submitting: {
            title: 'Submitting your application for approval',
            completedTitle: 'Application Submitted',
            loadingMessage: (): ShadedTextList => [{ text: 'Please wait while we submit your request to enroll in Fully Paid Lending.' }],
            successMessage: (): ShadedTextList => [
                { text: 'Request successfully submitted.', shade: 'dark', lineBreak: true },
                { text: 'Estimated wait for approval is 2 business days', shade: 'light' }
            ],
            axosMessage: 'Keep an eye out for an email from Axos',
            errorMessage: 'Something went wrong while submitting your request.'
        }
    },
    loggedOutScreen: {
        title: 'You have been logged out',
        body: 'You may close this browser window or if you were logged out in error, you may click the button below to log back in.'
    },
    mobileFplOnboarding: {
        apply: {
            title: 'Apply for Fully Paid Securities Lending',
            account: 'Account',
            selectAccount: 'Select Account',
            incomeGenerationTitle: 'Income Generation',
            incomeGenerationDesc: 'Generate additional income from your existing stock portfolio without selling your assets.',
            ownershipRetentionTitle: 'Ownership Retention',
            ownershipRetentionDesc: 'Retain ownership and potential capital gains of your stocks while earning lending tests.',
            riskAdjustedReturnsTitle: 'Risk-Adjusted Returns',
            riskAdjustedReturnsDesc: 'Enhance portfolio returns with minimal risk and no additional investment required.',
            votingTitle: 'Voting',
            votingDesc: "You won't be able to vote while shares are lent.",
            eligibilityTitle: 'Eligibility',
            eligibilityDesc: 'You need at least $50,000 equity in a Margin Account to be eligible.'
        },
        disclosures: {
            title: 'Agreements',
            masterSecuritiesLoan: 'Fully Paid Master Securities Loan Agreement',
            lendingAccountControl: 'Fully Paid Lending Account Control Agreement',
            lendingImportantCharacteristicsAndRisks: 'Fully Paid Lending Important Characteristics and Risks Agreement',
            reviewAccept: 'Review and accept our disclosures and agreements',
            reviewDisclosures: 'Review Disclosures',
            disclosuresAccepted: 'Disclosures Accepted!',
            acceptContinue: 'Accept and Continue',
            pressFinishToCreate: 'Press Finish to create your account',
            finish: 'Finish',
            missingData: "Looks like we're missing some data still",
            finishLater: 'Finish Later'
        },
        flowStage: {
            submittedTitle: 'Submitted your application for approval',
            submittedSubtitle: 'Estimated 2 business days for approval. Keep an eye out for an email from Axos!',
            loadingTitle: 'Please wait while we get your FPL account ready',
            failedTitle: "Looks like we weren't able to submit your account for approval"
        },
        acknowledgements: {
            title: 'Acknowledgements',
            message:
                'You will receive an email from Axos in a couple of days to complete the application via DocuSign. By accepting below, you acknowledge that your account approval cannot be processed without completing the Axos application that will be sent to your email address on file.',
            accept: 'Accept Acknowledgement'
        }
    },
    mobileNavBar: {
        home: 'Home',
        cash: 'Cash',
        trade: 'Trade',
        orders: 'Orders'
    },
    oneProNetwork: {
        discoverMore: 'Discover more',
        insights: 'insights',
        latestPosts: 'Latest Posts',
        enableOneProNetwork: 'Enable One Pro Network'
    }
};

export const Snex1EnglishTsxStub = null;
