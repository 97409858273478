import { AlgoStrat } from 'phoenix/constants/AssetTradeability';
import { QualifiedSecurityId } from '../../../models/QualifiedSecurityId';

/** @deprecated Use phoenix/models/ApiTradeRequest#ApiTimeInForce */
export type ApiTimeInForce = 'Day' | 'GTC' | 'GTXPre' | 'GTXPost' | 'NTE' | 'FOK';

/** @deprecated Use ApiCreditDebit */
export type CreditDebit = 'Credit' | 'Debit' | 'Even' | 'Market';
export type OptionsOpenClose = 'Close' | 'Open' | 'Loading';

/** @deprecated Use ApiOrderType */
export type OrderType = 'market' | 'limit' | 'stop' | 'stoplimit' | 'even';

/** @deprecated Use ApiTradeAction */
export type TradeAction = 'Buy' | 'Sell';

export type OptionsPutCall = 'put' | 'call';
export type OptionsPutCallAbbreviation = 'P' | 'C';
export type TradeableSecurityType =
    | 'adr'
    | 'crypto'
    | 'equity'
    | 'etf'
    | 'future'
    | 'futures-option'
    | 'futures-time-spread'
    | 'loading'
    | 'mutual-fund'
    | 'offshore-mutual-fund'
    | 'option'
    | 'unknown';
export type TradeTicketState = 'input' | 'review' | 'reinvest' | 'submitting' | 'done';

export const ApiTimeInForceOptions = {
    day: 'Day',
    fillOrKill: 'FOK',
    goodTillCanceled: 'GTC',
    gtxPre: 'GTXPre',
    gtxPost: 'GTXPost',
    nte: 'NTE'
};

// Output from this function is suitable to submit to the API
/** @deprecated Please use normalize your platform's trade model into ApiTradeRequest */
export const NormalizeTrade = (trade: Partial<TradeRequest>): any => {
    const securityId = QualifiedSecurityId.FromTradeRequest(trade).toString();

    const forApi = {
        ...trade,
        securityId,
        timeInForce: (trade.extendedTimeInForce || trade.timeInForce)?.replace(/^GTC$/, 'GoodTillCancel'),
        action: trade.liquidate ? 'Liquidate' : trade.action
    };
    if (forApi.edit) {
        delete forApi.orderType;
    }

    // MOC / LOC Orders can never be sent with a floor note or Not Held = True
    if (trade?.onClose) {
        delete forApi.floorNote;
        delete forApi.notHeld;
    }

    delete forApi.option;
    delete forApi.shouldSellAll;
    delete forApi.estimatedMarketPrice;
    delete forApi.extendedTimeInForce;
    delete forApi.liquidate;
    delete forApi.edit;
    return forApi;
};

// For those wondering!
// Mobile has 150+ references to this model still all over the place and it just isn't going to be feasible to switch them all over
// at the moment. Mobile will be adding new view model fields to this for now until we have the time to cut over -- AA
/** @deprecated Please use ApiTradeRequest or your own platform's trade ticket view model */
export interface TradeRequest {
    // Core fields
    orderId?: string; // For edits
    securityNumber?: string;
    securityIdType: 'symbol' | 'cusip' | 'isin' | 'sedol' | 'secNo';
    securityId: string;
    accountNumber: string;
    action: TradeAction;
    quantity: number;

    // Equity fields -- Note, security number, commission, and solicitation fields are omitted as they are overriden by the server anyway
    orderType: OrderType;
    orderPrice?: number; // Limit price for limit orders, Stop price for stop orders
    stopLimitPrice?: number;
    timeInForce: ApiTimeInForce;
    onClose?: boolean;

    // Fund fields
    quantityQualifier?: string; // 'Shares' | 'EvenDollar',
    reinvestDividends?: boolean;
    reinvestShortTermGains?: boolean;
    reinvestLongTermGains?: boolean;
    sharesHeld?: number; // TODO -- Devs, is this really an input field or just a UI field? If just for UI, please move to "UI Fields" section below
    estimatedTotalCost?: number; // TODO -- Similar note above
    minimumInvestment?: number; // TODO -- Similar note above

    // Multi-leg option fields
    leg2Action?: 'Buy' | 'Sell';
    leg2Quantity?: number;
    leg2SecurityId?: string;
    leg2SecurityNumber?: string;
    leg2DebitCredit?: CreditDebit;

    // advancedRouting fields
    strategy?: AlgoStrat;
    stratPercentage?: number;
    floorNote?: string;
    notHeld?: boolean;
    isDvp?: boolean;

    // UI Fields, not used by the API
    option?: boolean; // We might not need this anymore, not sure
    /** @deprecated From an old feature, please don't use */
    shouldSellAll?: boolean;
    estimatedMarketPrice?: number;
    extendedTimeInForce?: 'GTXPre' | 'GTXPost' | 'NTE';
    liquidate?: boolean;
    edit?: boolean;

    // short trades
    /** @depreacted Please source this information from AssetTradeability or similar interface */
    allowShort?: boolean;
    stockLoanRate?: number;
}
