import { ApiOrderType, ApiTimeInForce } from 'phoenix//models/ApiTradeRequest';
import { AlgoStrat, GetEditRule, TifEnabledAtTime } from 'phoenix/constants/AssetTradeability';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { IsShortSell } from 'phoenix/util/IsShortSell';
import { useMemo } from 'react';

export type TifOption = {
    disabled: boolean;
    label: string;
    selected: boolean;
    sublabel: string;
    value: ApiTimeInForce;
};

export const useTimeInForceValues = ({
    action,
    accountNumber = '',
    modifyingOrder,
    orderType,
    quantity = 0,
    securityId = '',
    strategy,
    timeInForce
}: {
    accountNumber?: string;
    action?: ApiTradeAction;
    modifyingOrder?: boolean;
    orderType: ApiOrderType;
    quantity?: number;
    securityId?: string;
    strategy?: AlgoStrat;
    timeInForce?: ApiTimeInForce;
}): { options: TifOption[]; valid: boolean; validTif: ApiTimeInForce } => {
    const text = useText((s) => s.orders.orderSetup.expiresBottomsheet);
    const [time] = useMarketTimeSegmentV2();
    const ac = useAssetClass(securityId);
    const tifSet = useMemo(() => new Set(ac.tradeability.timesInForce), [ac]);
    const isDvp = useSnexStore((s) => s.user.myInfo.data?.isDvp);
    const isShort = useMemo(() => IsShortSell(securityId, accountNumber, action as 'Buy', quantity), [accountNumber, action, quantity, securityId]);

    // Figure out what TIFs should be enabled (not necessarily whether they should be shown at all)
    const tifStatuses = useMemo(
        () =>
            ['Day', 'GTC', 'GTXPre', 'GTXPost', 'FOK'].map((t) => {
                // if (modifyingOrder) return GetEditRule(ac.tradeability, { timeInForce: t as ApiTimeInForce }) === 'allowed';
                const disabledForTime = !TifEnabledAtTime(t as ApiTimeInForce, time, orderType, ac.tradeability);

                if (t === 'GTC' && strategy === 'POV') return false;
                if (isShort) {
                    if (t !== 'Day' && !isDvp) return false;
                    if (t === 'Day') return true;
                }

                return !disabledForTime;
            }),
        [modifyingOrder, ac.tradeability, timeInForce, time, orderType, strategy, isShort, isDvp]
    );

    const [dayEnabled, gtcEnabled, gtxPreEnabled, gtxPostEnabled, fokEnabled] = tifStatuses;

    // This is where we filter out times in force that aren't prescribed in the asset tradeability profile
    const options = useMemo(
        () =>
            [
                {
                    label: text.goodThruTitle,
                    value: 'Day' as ApiTimeInForce,
                    disabled: !dayEnabled,
                    sublabel: dayEnabled ? text.goodThruDayActive : text.dayOrdersUnavailable
                },
                {
                    label: text.goodTillCancelled,
                    value: 'GTC' as ApiTimeInForce,
                    disabled: !gtcEnabled,
                    sublabel: gtcEnabled ? text.goodTilCancelActive : text.gtcOrdersUnavailable
                },
                {
                    label: text.preMarketExecution,
                    value: 'GTXPre' as ApiTimeInForce,
                    disabled: !gtxPreEnabled,
                    sublabel: gtxPreEnabled ? text.preMarketActive : text.premarketUnavailable
                },
                {
                    label: text.postMarketExcution,
                    value: 'GTXPost' as ApiTimeInForce,
                    disabled: !gtxPostEnabled,
                    sublabel: gtxPostEnabled ? text.postMarketActive : text.postMarketUnavailable
                },
                {
                    label: text.fillOrKillExecution,
                    value: 'FOK' as ApiTimeInForce,
                    disabled: !fokEnabled,
                    sublabel: fokEnabled ? text.fillOrKillActive : text.postMarketUnavailable
                }
            ]
                .filter((x) => tifSet.has(x.value as ApiTimeInForce))
                .map((x) => ({ ...x, selected: x.value === timeInForce })),
        [text, dayEnabled, gtcEnabled, gtxPreEnabled, gtxPostEnabled, fokEnabled, tifSet, timeInForce]
    );

    const enabledOptions = useMemo(() => options.filter((o) => !o.disabled), [options]);

    const selectedOption = useMemo(() => options.find((o) => o.value === timeInForce), [options, timeInForce]);

    const result = {
        valid: !selectedOption?.disabled,
        validTif: (selectedOption?.disabled || !selectedOption ? enabledOptions?.[0]?.value : timeInForce) as ApiTimeInForce,
        options
    };

    return result;
};
