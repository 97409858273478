import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from '@sentry/react';
import { IdleTimeoutModal } from 'components/Modals/IdleTimeoutModal';
import { PageTitleManager } from 'components/PageTitleManager/PageTitleManage';
import { useAppTheme } from 'hooks/UseAppTheme';
import { useAppThemePreference } from 'hooks/UseAppThemePreference';
import { Environment, GetConfig, ResolveEnvironment, SetEnvironmentResolver, SetUseAlternateHostWhenLocal } from 'phoenix/constants';
import { AppliedAppTheme } from 'phoenix/constants/AppliedAppTheme';
import { Whitelabel } from 'phoenix/constants/Whitelabels';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useTextStore } from 'phoenix/hooks/UseText';
import { GetSystemAvailabilityAction } from 'phoenix/redux/actions/SystemActions';
import { CreateStore, SnexThunkMiddleware } from 'phoenix/redux/configureStore';
import { GetColors } from 'phoenix/theming/Colors';
import { ThemeVariant } from 'phoenix/theming/ThemeVariants';
import { GetDisableTimeout, GetEnableDebugLogging, SetDebugArrayPersist, SetDebugPersist, SetDebugStringPersist } from 'phoenix/util';
import { XstreamProvider } from 'phoenix/xstream/XstreamProvider';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider, useDispatch } from 'react-redux';
import { applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { BaseScreen, SplashScreen } from 'screens';
import DeniedScreen from 'screens/DeniedScreen/DeniedScreen';
import { LocalStartScreen } from 'screens/LocalStartScreen/LocalStartScreen';
import LoggedOutScreen from 'screens/LoggedOutScreen/LoggedOutScreen';
import { GetAppTheme, GetMuiTheme } from 'theming';
import { AuthorizeApp, AuthorizedApp, AuthorizedUrlsForAnonymousState, GetAppAuthorizationState } from 'util/Authorize';
import { GoToLogin } from 'util/GoToLogin';
import { GetQueryParam } from 'util/UrlHelpers';
import './AppRoot.scss';
import { Initialize } from './Startup';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

let environmentOverride: Environment | null = null;

export enum AppAuthorizationState {
    GoToLogin = 'GoToLogin',
    Loading = 'Loading',
    Allowed = 'Allowed',
    Anonymous = 'Anonymous',
    Denied = 'Denied',
    Pending = 'Pending'
}

export const store = CreateStore(() => {
    // Have to do it this way because at this point the debug storage driver won't be installed by Startup.tsx yet
    const enabled = localStorage.getItem('SHOW_REDUX_LOGGING') === 'Y';
    const snexThunk = thunk as SnexThunkMiddleware;
    if (enabled) {
        return applyMiddleware(snexThunk, createLogger({ collapsed: true }));
    } else {
        return applyMiddleware(snexThunk);
    }
});

// https://redux.js.org/usage/usage-with-typescript

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch`
export const useAppDispatch: () => AppDispatch = useDispatch;

// Set Debug Persistence Driver
SetDebugPersist({
    get: (key) => localStorage.getItem(key) === 'Y',
    set: (key, value) => localStorage.setItem(key, value ? 'Y' : 'N')
});

SetDebugStringPersist({
    get: (key) => localStorage.getItem(key),
    set: (key, value) => localStorage.setItem(key, `${value}`)
});

SetDebugArrayPersist({
    get: (key) => JSON.parse(localStorage.getItem(key) || '[]') || [],
    set: (key, value) => localStorage.setItem(key, JSON.stringify(value))
});

export const AppRoot = TelemetryProvider(() => {
    const [appAuthorizationState, setAppAuthorizationState] = useState<AppAuthorizationState>(AppAuthorizationState.Loading);

    // Log focus element - use for a11y debugging
    // useEffect(() => {
    //     const listener = () => console.log('focused: ', document.activeElement);
    //     document.addEventListener('focusin', listener, true);

    //       return document.removeEventListener('focusin', listener);
    // }, [])

    // Set Environment Resolver
    // This has to happen before splash screen loads so it renders the correct logo based on the env
    SetEnvironmentResolver(() => {
        const nonProd: [RegExp, Environment][] = [
            [/localhost/, 'local'],
            [/one\.dev\.stonex\.com/, 'development'],
            [/one\.test\.stonex\.com/, 'test']
        ];

        const prod: [RegExp, Environment][] = [
            [/one\.beta\.stonex\.com/, 'beta'],
            [/one\.staging\.stonex\.com/, 'staging'],
            [/pro\.stonexone\.com/, 'pro'],
            [/aiva\.stonexone\.com/, Whitelabel.Aiva],
            [/galicia\.stonexone\.com/, Whitelabel.Galicia],
            [/winexco\.stonexone\.com/, Whitelabel.Winexco],
            [/folionet\.stonexone\.com/, Whitelabel.FolioNet],
            [/avsecurities\.stonexone\.com/, Whitelabel.AvSecurities],
            [/conosurinversiones\.stonexone\.com/, Whitelabel.ConosurInversiones],
            [/apac\.stonexone\.com/, Whitelabel.Apac],
            [/one\.stonex\.com/, 'production']
        ];

        const href = window.location.href;
        const patterns = [...nonProd, ...prod];

        // All query param env override if running from a non-prod app (local, dev, or test)
        // Memo'd into global so it continues to work even after the URL changes
        const override = GetQueryParam('env');

        if (!!override && override !== environmentOverride && nonProd.some((p) => p[0].test(href))) {
            if (patterns.some((p) => p[1] === override)) {
                // Set if set to a valid environment value
                environmentOverride = override as Environment;
                // Unset if not
            } else environmentOverride = null;
        }

        if (environmentOverride) return environmentOverride;

        if (!patterns.some((p) => p[0].test(href))) {
            if (GetEnableDebugLogging()) console.warn('Unknown environment! Defaulting to production');
            return 'production';
        } else {
            return (patterns.find((p) => p[0].test(href)) || [/./, GetConfig()?.ApiEnvironment])[1] || 'production';
        }
    });

    SetUseAlternateHostWhenLocal('https://one.dev.stonex.com/api');

    const dispatch = store.dispatch;
    const isAnonymousRoute = AuthorizedUrlsForAnonymousState.some((x) => window.location.href.toLowerCase().includes(x.toLowerCase()));

    useEffect(() => {
        (async () => {
            await Initialize(store);

            /* get user authorizations */
            const appAuthorizationState = await GetAppAuthorizationState(dispatch, isAnonymousRoute);
            if (appAuthorizationState === AppAuthorizationState.GoToLogin) {
                // GetAppAuthorizationState will return a state of GoToLogin if it gets a 401 error
                GoToLogin();
            } else {
                // console.log(`Authorized: ${appAuthorizationState}`);
                await AuthorizeApp(dispatch, appAuthorizationState);
                setAppAuthorizationState(appAuthorizationState); /* this triggers the ui to change */
            }
        })();

        const systemStatusChecker =
            appAuthorizationState === AppAuthorizationState.Allowed || appAuthorizationState === AppAuthorizationState.Pending
                ? setInterval(() => dispatch(GetSystemAvailabilityAction()), 1000 * 60 * 2) // every 2 minutes for allowed or pending
                : null; // set to null for others as there is no need to check.

        return () => {
            if (systemStatusChecker) clearInterval(systemStatusChecker);
        };
    }, []);

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <XstreamProvider>
                    <AuthorizedApp state={appAuthorizationState}>
                        <ThemedApp appAuthorizationState={appAuthorizationState} />
                    </AuthorizedApp>
                </XstreamProvider>
            </Provider>
        </ErrorBoundary>
    );
});

interface ThemedAppProps {
    appAuthorizationState: AppAuthorizationState;
}

function ThemedApp(props: ThemedAppProps): JSX.Element {
    const { appAuthorizationState } = props;
    const [appThemePreference] = useAppThemePreference();
    const [appTheme, setAppTheme] = useAppTheme();
    const locale = useSnexStore((s) => s.user.myPrefs?.data?.preference?.language?.code);

    const [marketSegment] = useMarketTimeSegmentV2();

    useEffect(() => {
        const newWebTheme = GetAppTheme(appThemePreference as ThemeVariant);
        if (appAuthorizationState !== AppAuthorizationState.Anonymous) setAppTheme(newWebTheme);
    }, [appThemePreference, marketSegment, setAppTheme]);

    useEffect(() => {
        useTextStore.getState().updateTextByLanguageCode(locale);
    }, [locale]);

    return (
        <ErrorBoundary>
            <ThemeWrapper {...{ appTheme }}>
                <DndProvider backend={HTML5Backend}>
                    <CssBaseline />
                    <Screen appAuthorizationState={appAuthorizationState} />
                    <PageTitleManager />
                    {!GetDisableTimeout() && <IdleTimeoutModal />}
                </DndProvider>
            </ThemeWrapper>
        </ErrorBoundary>
    );
}

interface ScreenProps {
    appAuthorizationState: AppAuthorizationState;
}

function Screen(props: ScreenProps): JSX.Element {
    const { appAuthorizationState } = props;
    switch (appAuthorizationState) {
        case AppAuthorizationState.Loading:
            return <SplashScreen />;
        case AppAuthorizationState.GoToLogin:
            return ['development', 'local'].includes(ResolveEnvironment()) ? <LocalStartScreen /> : <LoggedOutScreen />;
        case AppAuthorizationState.Denied:
            return <DeniedScreen />;
        case AppAuthorizationState.Allowed:
        case AppAuthorizationState.Anonymous:
        case AppAuthorizationState.Pending:
            return <BaseScreen appAuthorizationState={appAuthorizationState} />;
        default: {
            const exhaustiveCheck: never = appAuthorizationState;
            console.warn(`Unhandled appAuthorizationState: ${exhaustiveCheck}`);
            return <BaseScreen appAuthorizationState={appAuthorizationState} />;
        }
    }
}

interface ThemeWrapperPropsInternal {
    appTheme: AppliedAppTheme;
}

type ThemeWrapperProps = React.PropsWithChildren<ThemeWrapperPropsInternal>;

function ThemeWrapper(props: ThemeWrapperProps): JSX.Element {
    const { appTheme, children } = props;
    const themeMui = useMemo(() => GetMuiTheme(appTheme), [appTheme]);
    const colors = GetColors(appTheme);
    const { _cssVariables } = colors;

    useEffect(() => {
        document.body.classList.add(appTheme);
        const cssVars = document.getElementById('css-variables');

        if (cssVars)
            cssVars.innerHTML = `:root {
            ${_cssVariables}
        }`;

        return () => {
            document.body.classList.remove(appTheme);
            const cssVars = document.getElementById('css-variables');
            if (cssVars) cssVars.innerHTML = '';
        };
    }, [_cssVariables, appTheme]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeMui}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
}
