// @ts-strict-ignore
import React, { useRef, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Flex } from '..';
import { useColors } from 'hooks/UseColors';
import './SnexSegmentedControl.module.css';

type SnexSegmentedControlSegmentProps = {
    Icon?: React.FunctionComponent<{ color: string }>;
    label?: string; // optional, defaults to value
    value: string;
};

interface SnexSegmentedControlProps {
    activeBackgroundColor?: string;
    activeForegroundColor?: string;
    activeIndex?: number;
    defaultIndex?: number;
    inactiveBackgroundColor?: string;
    inactiveForegroundColor?: string;
    onSelect: (selectedValue: string) => any;
    segments: SnexSegmentedControlSegmentProps[];
    style?: React.CSSProperties;
    width?: string;
}

// This is still an experimental component
export const SnexSegmentedControl = React.memo((props: SnexSegmentedControlProps) => {
    const componentReady = useRef<boolean>(false);
    const colors = useColors();
    const {
        activeBackgroundColor = colors.primaryItemColor,
        activeForegroundColor = 'white',
        defaultIndex = 0,
        inactiveBackgroundColor = colors.segmentedControlBackgroundColor,
        inactiveForegroundColor = props.inactiveForegroundColor || colors.generalTextColor,
        onSelect,
        segments,
        width = '100%'
    } = props;
    const [localIndex, setLocalIndex] = useState<number>(defaultIndex);
    const [offsets, setOffsets] = useState<{ width: number; xPos: number }>({ width: 0, xPos: 0 });
    const childRef = useRef(null);

    // Parent can override active index with a prop
    const activeIndex = typeof props?.activeIndex === 'number' ? props.activeIndex : localIndex;

    useEffect(() => {
        const activeSegment = childRef?.current?.children[activeIndex] as HTMLElement;
        const { offsetWidth, offsetLeft } = activeSegment || {};

        setOffsets({ width: offsetWidth, xPos: offsetLeft });
    }, [activeIndex, segments]);

    useEffect(() => {
        componentReady.current = true;
    }, []);

    const onInputChange = (value: string, i: number) => {
        setLocalIndex(i);
        onSelect(value);
    };

    return (
        <Flex
            center
            className='segmented-control-container'
            style={
                {
                    '--activeBackground': activeBackgroundColor,
                    '--inactiveBackground': inactiveBackgroundColor,
                    '--activeForeground': activeForegroundColor,
                    '--inactiveForeground': inactiveForegroundColor,
                    '--selectedWidth': `${offsets.width}px`,
                    '--width': width,
                    '--xPos': `${offsets.xPos}px`
                } as React.CSSProperties
            }
        >
            <div className={`segmented-controls ${componentReady.current ? 'ready' : 'idle'} `} ref={childRef}>
                {segments.map((s, i) => {
                    const active = i === activeIndex;
                    return (
                        <button key={s.value} className={`segment ${active ? 'active' : 'inactive'}`} onClick={() => onInputChange(s.value, i)}>
                            <Typography variant='body2'>
                                {s.label || s.value}
                                {s.Icon && (
                                    <span style={{ marginLeft: '10px' }}>
                                        <s.Icon color={active ? activeForegroundColor : inactiveForegroundColor} />
                                    </span>
                                )}
                            </Typography>
                        </button>
                    );
                })}
            </div>
        </Flex>
    );
});
