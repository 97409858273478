import { Order } from 'phoenix/redux/models';
import { DetermineOrderType } from 'phoenix/util/DetermineOrderType';
import { FormattedTextList, Snex1LanguagePack } from './Snex1LanguagePack';
import { GetSecurityTypeFromStore } from 'phoenix/util';

export type ActionSummaryProps = {
    format?: 'short' | 'long';
    limitPrice: string;
    order: Order;
    quantity: string;
    stopPrice: string;
};
export type GetActionSummaryProps = ActionSummaryProps & {
    action: string;
    marketPriceText: string;
};

export function getActionSummary({ action, limitPrice, marketPriceText, order, quantity, stopPrice }: GetActionSummaryProps): string {
    const secType = GetSecurityTypeFromStore(order.symbol);
    const orderType = DetermineOrderType(order);
    const amt: string = (() => {
        switch (orderType) {
            case 'limit':
            case 'stoplimit':
                return limitPrice;
            case 'stop':
                return stopPrice;
            case 'market':
                return marketPriceText;
            case 'even':
                return 'Even';
        }
    })();

    const hideLiquidation = () => {
        switch (secType) {
            case 'mutual-fund':
                switch (`${action}`) {
                    case 'market full liquidation':
                        return `${action} ${quantity !== '0' ? ' ' + quantity : ''} @ ${amt}`;
                    default:
                        return `${action} ${quantity} @ ${amt}`;
                }
            default:
                return `${action} ${quantity} @ ${amt}`;
        }
    };
    return hideLiquidation();
}

export function getOffshoreMutualFundStatInfo(text: Partial<Snex1LanguagePack>): FormattedTextList {
    return [
        [
            {
                text: `${text?.fundScreen?.preNotificationDaysSubscriptions} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.preNotificationDaysSubscriptionsDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.settlementDaysSubscriptions} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.settlementDaysSubscriptionsDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.totalSettlementDaysSubscriptions} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.totalSettlementDaysSubscriptionsDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.cutoffTimeSubscriptions} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.cutoffTimeSubscriptionsDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.preNotificationDaysRedemptions} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.preNotificationDaysRedemptionsDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.settlementDaysRedemptions} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.settlementDaysRedemptionsDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.totalSettlementDaysRedemptions} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.totalSettlementDaysRedemptionsDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.cutoffTimeRedemptions} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.cutoffTimeRedemptionsDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.subscriptionsByShares} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.subscriptionsBySharesDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.subscriptionsByAmount} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.subscriptionsByAmountDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.redemptionsByShares} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.redemptionsBySharesDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.redemptionsByAmount} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.redemptionsByAmountDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.minInitialAmount} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.minInitialAmountDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.minAdditionalAmount} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.minAdditionalAmountDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.maximumAmount} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.maximumAmountDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.transferableFund} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.transferableFundDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.volatilityRange} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.volatilityRangeDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.riskCategory} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.riskCategoryDescription || ''
            }
        ]
    ];
}

export function getOffshoreMutualFundAboutInfo(text: Partial<Snex1LanguagePack>): FormattedTextList {
    return [
        ...(text?.infoIconHelpText?.one?.aboutSecurity || []),
        [
            {
                text: '\n'
            }
        ],
        [
            {
                text: `${text?.fundScreen?.inceptionDate} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.inceptionDateDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.fundGroup} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.fundGroupDescription || ''
            }
        ],
        [
            {
                text: `${text?.fundScreen?.fundHouse} ` || '',
                weight: 'bold'
            },
            {
                text: text?.fundScreen?.fundHouseDescription || ''
            }
        ]
    ];
}
